import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {formatDate} from "@angular/common";
import {AppConstants} from "../models";
import {IResponse} from "../models/i-api-response";
import {IDeliveriesPagination, IDelivery, IDeliveryDataMaster, IDeliveryEditLabOrderNum} from "../models/i-delivery";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  GetDeliveries(_patient: string, _labOrderNumber: string, _invoiceNumber: string, _headquarter: number, _status: string, _errorType: string, _reason: string, _authorization: string, _from: Date, _to: Date, _offset: number, _pageSize: number): Observable<IResponse<IDeliveriesPagination>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Deliveries/GetDeliveries?patient=${_patient}&labOrderNumber=${_labOrderNumber}&invoiceNumber=${_invoiceNumber}&headquarter=${_headquarter}&status=${_status}&errorType=${_errorType}&reason=${_reason}&authorization=${_authorization}&from=${formatDate(_from, 'yyyy-MM-dd','en')}&to=${formatDate(_to, 'yyyy-MM-dd','en')}&offset=${_offset}&pageSize=${_pageSize}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<IDeliveriesPagination>>(url, { headers });
  }

  GetDeliveriesByLabOrderNumber(_deliveryLabOrderNumber: string): Observable<IResponse<IDelivery[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Deliveries/GetDeliveriesByLabOrderNumber?labOrderNumber=${_deliveryLabOrderNumber}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<IDelivery[]>>(url, { headers });
  }

  UpdateDeliveries(_deliveries: IDelivery[]){
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Deliveries/UpdateDeliveries`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IResponse<IDelivery[]>>(url, _deliveries, { headers });
  }

  UpdateDeliveryLabOrderNum(_delivery: IDeliveryEditLabOrderNum){
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Deliveries/UpdateDeliveryLabOrderNum`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IResponse<IDelivery[]>>(url, _delivery, { headers });
  }
}
