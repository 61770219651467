import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IHeadquarter } from 'src/app/models/i-headquarter';
import { AlertService, AuthenticationService, PermsService } from 'src/app/services';
import { HeadquarterService } from 'src/app/services/headquarter.service';


@Component({
  selector: 'app-headquarter-list',
  templateUrl: './headquarter-list.component.html',
  styleUrls: ['./headquarter-list.component.scss']
})
export class HeadquarterListComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  searchField: string;
  headquarters: IHeadquarter[];
  headquartersFiltered: IHeadquarter[];
  currentUser: any;
  currentUserSubscription: Subscription;
  permisos: boolean = true;
  constructor(
    private permsService: PermsService,
    private headquarterService: HeadquarterService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) { 
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit(): void {
    this.searchField = '';
    this.headquarters = [];
    this.headquartersFiltered = [];
    this.CheckPermits();
    this.LoadHeadquarters();
  }

  CheckPermits(): void {
    this.permsService.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.Result) {
        let permListtable: any = data.perms;
        data.perms.forEach(Perm => {
          if (Perm.Name === "V_Headquarters") {
            this.permisos = Perm.Active;
          }
        });

      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
  }

  LoadHeadquarters(): void{
    this.blockUI.start('Cargando listas de sedes...');
    this.headquarterService.GetHeadquarters().pipe(finalize(()=>this.blockUI.stop())).subscribe(callBack => {
      if (callBack.Result) {
        this.headquarters = callBack.Data;
        this.headquartersFiltered = callBack.Data;
      } else {
        this.alertService.errorInfoAlert('Error al cargar la lista de sedes - ' + callBack.Error.Message);
      }
    }, error => {
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  FilterDatatable(event: CustomEvent): void {
    const searchTerm: string = this.searchField;
    if (searchTerm.length > 0) {

      this.headquartersFiltered = this.headquarters.filter(item => {
        if (item.Code !== null && item.Name !== null) {
          return (item.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1
            ||
            item.Name.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1
          );
        }
      });
    } else {
      this.headquartersFiltered = this.headquarters;
    }
  }

  NewHeadquerter(): void {
    this.router.navigate(['headquarter', 0]);
  }

  EditHeadquerter(_id: number): void {
    this.router.navigate(['headquarter', _id]);
  }

}
