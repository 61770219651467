import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AppConstants, IPatient} from '../models';
import {IResponse} from '../models/i-api-response';
import {StorageService} from './storage.service';
import {IPatientPagination} from "../models/ipatient";

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(
    private http: HttpClient
    , private storage: StorageService
  ) {
  }

  CreatePatient(_patient: IPatient): Observable<IResponse<IPatient>> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const URL = `${AppConstants.apiUrl}api/Patient/CreatePatient`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.post<IResponse<IPatient>>(URL, _patient, {headers});
  }

  UpdatePatient(_patient: IPatient): Observable<IResponse<IPatient>> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const URL = `${AppConstants.apiUrl}api/Patient/UpdatePatient`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.post<IResponse<IPatient>>(URL, _patient, {headers});
  }

  GetPatients(identification: string): Observable<IResponse<IPatient[]>> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const URL = `${AppConstants.apiUrl}api/Patient/GetPatients?pattern=${identification}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<IResponse<IPatient[]>>(URL, {headers});
  }

  GetPatientsPagination(_pattern: string = '', _take: number, _skip: number): Observable<IResponse<IPatientPagination>> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const URL = `${AppConstants.apiUrl}api/Patient/GetPatientsPagination?take=${_take}&skip=${_skip}&pattern=${_pattern}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<IResponse<IPatientPagination>>(URL, {headers});
  }

  GetPatient(_patientCode: string): Observable<IResponse<IPatient>> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const URL = `${AppConstants.apiUrl}api/Patient/GetPatient?patientCode=${_patientCode}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<IResponse<IPatient>>(URL, {headers});
  }

}
