import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppConstants } from '../models';
import { IResponse } from '../models/i-api-response';
import { IHeadquarter, IHeadquarterOption } from '../models/i-headquarter';
import { IBaseResponse } from '../models/responses';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class HeadquarterService {

  public currentHeadquarterSubject: Subject<IHeadquarter>;

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { 
    this.currentHeadquarterSubject = new Subject<IHeadquarter>();
  }

  StoreHeadquarter(_headquarter: IHeadquarter): Observable<IBaseResponse> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    
    const URL = `${AppConstants.apiUrl}api/Headquarter/StoreHeadquarter`;
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.post<IBaseResponse>(URL, _headquarter, { headers });
  }

  GetHeadquarter(_id: number): Observable<IResponse<IHeadquarter>>{
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    
    const URL = `${AppConstants.apiUrl}api/Headquarter/GetHeadquarter?id=${_id}`;
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<IResponse<IHeadquarter>>(URL, { headers });
  }

  GetHeadquarters(): Observable<IResponse<IHeadquarter[]>>{
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    
    const URL = `${AppConstants.apiUrl}api/Headquarter/GetHeadquarters`;
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<IResponse<IHeadquarter[]>>(URL, { headers });
  }

  GetHeadquartersByUser(_userId: string): Observable<IResponse<IHeadquarter[]>>{
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    
    const URL = `${AppConstants.apiUrl}api/Headquarter/GetHeadquartersByUser?userId=${encodeURIComponent(_userId)}`;
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<IResponse<IHeadquarter[]>>(URL, { headers });
  }

  SaveHeadquartersByUser(_userId: string, _headquarters: IHeadquarter[]): Observable<IBaseResponse>{

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    
    const URL = `${AppConstants.apiUrl}api/Headquarter/SaveHeadquartersByUser?userId=${encodeURIComponent(_userId)}`;
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.post<IBaseResponse>(URL, _headquarters, { headers });
  }

  GetCurrentUserHeadquarters(): Observable<IResponse<IHeadquarter[]>> {

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    
    const URL = `${AppConstants.apiUrl}api/Headquarter/GetCurrentUserHeadquarters`;
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<IResponse<IHeadquarter[]>>(URL, { headers });
  }

  GetUsers(): Observable<IResponse<any>> {

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    
    const URL = `${AppConstants.apiUrl}api/Users/GetUsers`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<IResponse<any>>(URL, { headers });
  }
}
