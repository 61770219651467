import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Users, UserAssigns, Company, SeriesbyUser, Series } from '../../../../models/index';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { PermsService, ItemService, UserService, SeriesService, SalesManService, AlertService } from '../../../../services/index';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IHeadquarter } from 'src/app/models/i-headquarter';
import {debounceTime, distinctUntilChanged, filter, finalize, map} from 'rxjs/operators';
import { HeadquarterService } from 'src/app/services/headquarter.service';
import {NgbModal, NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import { ITappUserSetting } from 'src/app/models/i-tapp-user-setting';
import {forEach} from "@angular/router/src/utils/collection";
import {TappService} from "../../../../services/tapp.service";
import {forkJoin, merge, Observable, OperatorFunction, Subject} from "rxjs";
import {ISalesPerson} from "../../../../models/i-sales-person";

@Component({
  selector: 'app-user-conf',
  templateUrl: './user-conf.component.html',
  styleUrls: ['./user-conf.component.scss']
})
export class UserConfComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  userId: number;
  user: UserAssigns;
  userList: Users[];
  userAssignsList: UserAssigns[];
  headquarters: IHeadquarter[];
  companyList: Company[];
  userGroup: FormGroup;
  disable: boolean;
  btnSendInfo: string; // cambia el titulo de el boton de envio
  PriceList: any[] = []; // lista de las listas de precios
  salesPersons: any[] = [];
  UserAssing: UserAssigns;
  modalTappSetting: any;
  @ViewChild('tapp') tapp!: TemplateRef<any>
  closeResult: string;
  tappSettings: ITappUserSetting[] = [];
  tappUserSettings: ITappUserSetting[] = [];

  /**
   * Emit a value when the sales person input is clicked
   */
  clickSalesPersonsInput$: Subject<string> = new Subject<string>();
  /**
   * Emit a value when the sales person input get focus
   */
  focusSalesPersonsInput$: Subject<string> = new Subject<string>();
  /**
   * Represent the sales persons typeahead input element
   */
  @ViewChild('salesPersonsTypeahead') salesPersonsTypeahead: NgbTypeahead;
  constructor(
    private activatedRoute: ActivatedRoute,
    private pService: PermsService,
    private uService: UserService,
    private fbg: FormBuilder,
    private router: Router,
    private itemService: ItemService,
    private smService: SalesManService,
    private alertService: AlertService,
    private headquarterService: HeadquarterService,
    private modalService: NgbModal,
    private tappService: TappService
  ) { }
  initVariables() {

    this.UserAssing = {} as UserAssigns;

    this.userGroup = this.fbg.group({
      cUserName: [{ value: '', disabled: this.disable }, [Validators.required]],
      cSAPUser: ['', [Validators.required]],
      SAPPass: ['', [Validators.required]],
      HeadquarterId: ['', [Validators.required]],
      CompanyId: [{ value: '', disabled: this.disable }, [Validators.required]],
      minDiscount: ['', [Validators.required, Validators.max(100), Validators.min(0)]],
      SlpCode: ['0', [Validators.required]],
      CenterCost: [''],
      PriceListDef: ['', [Validators.required]],
      Active: [''],
      SuperUser: [''],
      MinCreditPayment: ['', [Validators.required, Validators.max(100), Validators.min(0)]],
    });


    this.user = null;
    this.userList = [];
    this.userAssignsList = [];
    this.headquarters = [];
    this.companyList = [];
    this.tappUserSettings = [];

    this.getGetCompanies(); //Obtiene companias
    this.getUsers();//Obtiene lista de usuarios
    this.GetSalesPersons();//Obtiene vendedores
    this.GetPriceList();//Obtiene lista de precios
    this.getUserAssing();//Obtiene usuarios asignados

    if (this.userId > 0)
    {
      this.disable = true;
      this.btnSendInfo = 'Actualizar';
      this.getUserData(this.userId);
    }
    else
    {
      this.btnSendInfo = 'Crear';
    }
  }
  ngOnInit() {
    this.userId = parseInt(this.activatedRoute.snapshot.paramMap.get('userId'));

    this.disable = false;
    if (this.userId > 0) {
      this.disable = true;
    }
    this.initVariables();
  }

  getUsers(): void {
    this.blockUI.start('Cargando listas de usuarios...');
    this.pService.getUsers().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.Result) {
        this.userList = data.users;
        if (this.userId > 0) { this.getUserData(this.userId); }
        this.userGroup.patchValue({ cUserName: this.userList[0].Id });
      } else {
        this.alertService.errorAlert('Error al cargar la lista de usuarios - ' + data.errorInfo.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  getGetCompanies(): void {
    this.blockUI.start('Cargando listas de almacenes...');
    this.uService.getGetCompanies().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.Result) {
        this.companyList = data.companiesList;
        this.userGroup.patchValue({ CompanyId: this.companyList[0].Id });
      } else {
        this.alertService.errorAlert('Error al cargar la lista de almacenes - ' + data.errorInfo.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });

  }

  get f() { return this.userGroup.controls; }

  getUserAssing(): void {
    this.blockUI.start('Cargando listas de usuarios...');
    this.uService.getUserList().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.Result) {
        this.userAssignsList = data.Users;
        if (this.userId > 0) { this.getUserData(this.userId); }
        this.LoadHeadquarters();
        this.LoadTappUserSettings();
      } else {
        this.alertService.errorAlert('Error al cargar la lista de usuarios - ' + data.errorInfo.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  getUserData(pUserAssignId: number): void {
    this.UserAssing = this.userAssignsList.find(x => x.Id === pUserAssignId);

    if (!this.UserAssing)
    {
      return;
    }

    this.userAssignsList.forEach(userAssign => {
      if (pUserAssignId === userAssign.Id)
      {
        this.userList.forEach(us => {
          if (us.Id.toString() === userAssign.UserId)
          {
            this.userGroup.patchValue({ cUserName: us.Id });
          }
        });
      }
    });

    this.userGroup.controls.cSAPUser.setValue(this.UserAssing.SAPUser);
    this.userGroup.patchValue({ CompanyId: this.UserAssing.CompanyId });
    this.userGroup.patchValue({ HeadquarterId: this.UserAssing.HeadquarterId });
    this.userGroup.patchValue({ PriceListDef: this.UserAssing.PriceListDef });
    this.userGroup.controls.minDiscount.setValue(this.UserAssing.minDiscount);
    this.userGroup.patchValue({ SlpCode: this.UserAssing.SlpCode });
    this.userGroup.controls.SAPPass.setValue(this.UserAssing.SAPPass);
    this.userGroup.controls.CenterCost.setValue(this.UserAssing.CenterCost);
    this.userGroup.controls.Active.setValue(this.UserAssing.Active);
    this.userGroup.controls.SuperUser.setValue(this.UserAssing.SuperUser);
    this.userGroup.controls.MinCreditPayment.setValue(this.UserAssing.MinCreditPayment);
  }

  onSubmitCreate() {
    if (this.userId > 0) {
    } else {
      this.userId = 0;
    }

    this.user = {
      Id: this.userId,
      UserId: this.userGroup.controls.cUserName.value,
      SAPUser: this.userGroup.controls.cSAPUser.value,
      UserName: this.userGroup.controls.cUserName.value,
      CompanyId: this.userGroup.controls.CompanyId.value,
      HeadquarterId: this.userGroup.controls.HeadquarterId.value,
      minDiscount: this.userGroup.controls.minDiscount.value,
      SlpCode: this.userGroup.controls.SlpCode.value,
      SAPPass: this.userGroup.controls.SAPPass.value,
      CenterCost: this.userGroup.controls.CenterCost.value !== '' ? this.userGroup.controls.CenterCost.value : 0,
      PriceListDef: this.userGroup.controls.PriceListDef.value,
      Active: this.userGroup.controls.Active.value !== '' ? this.userGroup.controls.Active.value : false,
      SuperUser: this.userGroup.controls.SuperUser.value !== '' ? this.userGroup.controls.SuperUser.value : false,
      Series: [],
      CompanyName: '',
      MinCreditPayment: this.userGroup.controls.MinCreditPayment.value
    }
    if (this.userId > 0) {
      this.blockUI.start('Actualizando asignación de usuario, espere por favor');
      this.uService.UpdateUser(this.user).subscribe(next => {
        if (next.Result) {
          this.alertService.successInfoAlert('El usuario seleccionado debe cerrar sesión para aplicar los cambios');
          this.user = null;
          this.router.navigate(['AssignsUsers']);
        }
        else {
          this.alertService.errorAlert(`Error al crear el usuario, detalle: ${next.Error.Message}`);
        }
        this.blockUI.stop();
      }, error => {
        this.blockUI.stop();
        console.log(error);
        this.alertService.errorAlert(`Error al crear el usuario, detalle: ${error}`);
      }, () => { });
    } else {
      this.blockUI.start('Creando asignación de usuario, espere por favor');
      this.uService.CreateNewUser(this.user).subscribe(next => {
        if (next.Result) {
          this.alertService.successInfoAlert('El usuario seleccionado debe cerrar sesión para aplicar los cambios');
          this.user = null;
          this.router.navigate(['AssignsUsers']);
        }
        else {
          this.alertService.errorAlert(`Error al crear el usuario, detalle: ${next.Error.Message}`);
        }
        this.blockUI.stop();
      }, error => {
        this.blockUI.stop();
        console.log(error);
        this.alertService.errorAlert(`Error al crear el usuario, detalle: ${error}`);
      }, () => { });

    }

  }

  // trae la lista de precios para seleccionar cualquiera en la vusta de facturacion
  GetPriceList(): void {
    this.blockUI.start('Obteniendo listas de precios, espere por favor...'); // Start blocking
    this.itemService.GetPriceList()
      .subscribe((data: any) => {
        if (data.Result) {
          this.PriceList = data.priceList;
        } else {
          this.alertService.errorAlert(`Error: código: ${data.errorInfo.Code}, mensaje: ${data.errorInfo.Message}`);
        }
        this.blockUI.stop(); // Stop blocking
      }, (error: any) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
      });
  }

  /**
   * Send a request to retrieve the sales persons
   * @constructor
   */
  GetSalesPersons(): void
  {
    this.blockUI.start('Obteniendo vendedores, espere por favor...');

    this.smService.GetSalesPersons(true, false)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (response: any) => {
          if (response.Result)
          {
            this.salesPersons = response.salesManList;

            if (this.userId > 0)
            {
              this.getUserData(this.userId);
            }
          }
          else
          {
            this.alertService.errorAlert(`Error: código: ${response.errorInfo.Code}, mensaje: ${response.errorInfo.Message}`);
          }
        },
        error: (error) => {
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
        }
      });
  }
  cancel() {
    this.router.navigate(['/AssignsUsers']);
  }

  LoadHeadquarters(): void{
    this.blockUI.start('Cargando listas de sedes...');
    this.headquarterService.GetHeadquartersByUser(this.UserAssing.UserId).pipe(finalize(()=>this.blockUI.stop())).subscribe(callBack => {
      if (callBack.Result) {
        this.headquarters = callBack.Data;
      } else {
        this.alertService.errorInfoAlert('Error al cargar la lista de sedes - ' + callBack.Error.Message);
      }
    }, error => {
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  LoadTappUserSettings(): void {
    this.blockUI.start('Cargando listas de sedes...');
    this.tappService.GetTappUserSettings(this.UserAssing.UserId).pipe(finalize(()=>this.blockUI.stop())).subscribe(callBack => {
      if (callBack.Result) {
        this.tappUserSettings = callBack.Data;
      } else {
        this.alertService.errorInfoAlert('Error al cargar la lista de configuración de lealtad - ' + callBack.Error.Message);
      }
    }, error => {
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  OpenTappModalConfig(): void {
    // Esto se debe hacer despues de cargar las sedes por eso va aqui
    this.LoadTappSettings();

    this.modalTappSetting = this.modalService.open(this.tapp, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'Modal-lg'
    });
    this.modalTappSetting.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
    });
  }

  GetHeadquarterName(_headquarterId: number) {
    let headquarter = this.headquarters.find(h => h.Id === _headquarterId);
    return `${headquarter.Code} - ${headquarter.Name}`;
  }

  LoadTappSettings() {
    this.tappSettings = [];
    this.headquarters.forEach(h => {
      let tappUserSetting = this.tappUserSettings.find(t => t.HeadquarterId === h.Id);
      this.tappSettings.push({
        Id: tappUserSetting ? tappUserSetting.Id : 0,
        UserAssignId: tappUserSetting ? tappUserSetting.UserAssignId : this.UserAssing.Id,
        HeadquarterId: tappUserSetting ? tappUserSetting.HeadquarterId : h.Id,
        Store: tappUserSetting ? tappUserSetting.Store : "",
        Register: tappUserSetting ? tappUserSetting.Register : ""
      });
    });
  }

  CloseTappModalConfig() {
    this.modalTappSetting.close();
  }

  SaveSettingTapp() {
    this.blockUI.start('Gurdando configuración plan lealtad...');
    this.tappService.SaveTappUserSettings(this.UserAssing.UserId, this.tappSettings)
      .pipe(finalize(()=>this.blockUI.stop())).subscribe(callBack => {
      if (callBack.Result) {
        this.tappUserSettings = callBack.Data;
        this.CloseTappModalConfig();
      } else {
        this.alertService.errorInfoAlert('Error al guardar la lista de configuración de lealtad - ' + callBack.Error.Message);
      }
    }, error => {
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }


  /**
   * Filter the sales persons typeahead input options
   * @param pText$ Value that will be used to filter the options
   * @constructor
   */
  SearchSalesPersons: OperatorFunction<string, ISalesPerson[]> = (pText$: Observable<string>) =>
  {
    const debouncedText$ = pText$.pipe(debounceTime(200), distinctUntilChanged());

    const clicksWithClosedPopup$ = this.clickSalesPersonsInput$.pipe(filter(() => !this.salesPersonsTypeahead.isPopupOpen()));

    const inputFocus$ = this.focusSalesPersonsInput$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)
      .pipe(
        map((term) =>
          (term === '' ? this.salesPersons : this.salesPersons.filter((slp) => `${slp.SlpCode} - ${slp.SlpName}`.toLowerCase().includes(term.toLowerCase()))).slice(0, 10),
        ),
      );
  };

  /**
   * Format the result of sales persons typeahead
   * @param pResult Result of the filter
   * @constructor
   */
  SalesPersonTypeaheadFormatter = (pResult: ISalesPerson | string) => {
    if(typeof pResult === "object")
    {
      return `${pResult.SlpCode} - ${pResult.SlpName}`;
    }

    let salesPerson = this.salesPersons.find((slp) => slp.SlpCode == pResult)

    if(salesPerson)
    {
      return `${salesPerson.SlpCode} - ${salesPerson.SlpName}`;
    }

    return '';
  }

  /**
   * Handle the select event of the sales person typeahead
   * @param pEvent Event data
   * @constructor
   */
  OnSelectSalesPerson(pEvent: any): void
  {
    setTimeout(() => {
      this.userGroup.get("SlpCode").setValue(pEvent.item.SlpCode, {emitEvent: false});
    }, 0);
  }
}


