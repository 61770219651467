import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {IResponse} from "../models/i-api-response";
import {IBinLocation} from "../models/i-bin-location";
import {IService} from "../models/i-service";
import {IPagedRequestResponse} from "../models/responses";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class BinLocationsService implements IService {
  constructor(private http: HttpClient) { }

  Get(pItemCode: string, pWhsCode: string): Observable<IResponse<IPagedRequestResponse<IBinLocation[]>>> {
    return this.http.get<IResponse<IBinLocation[]>>(`api/Items/${pItemCode}/Warehouse/${pWhsCode}/BinLocations`)
      .pipe(
        map(response => ({
          Data: {
            Data: response.Data,
            RecordsCount: response.Data.length
          },
          Error: response.Error,
          Result: response.Result
        } as IResponse<IPagedRequestResponse<IBinLocation[]>>))
      )
  }


}
