import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlertService, CompanyService} from "../../../../services";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {settings} from "../../../../models";
import {ISalesForceSetting} from "../../../../models/i-settings";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-sales-force-config-modal',
  templateUrl: './sales-force-config-modal.component.html',
  styleUrls: ['./sales-force-config-modal.component.scss']
})
export class SalesForceConfigModalComponent implements OnInit {
  /**
   * Represent the component that will block the UI when a request is in process
   */
  @BlockUI("SalesForceModalBlockUI") blockUI: NgBlockUI;

  /**
   * Represent the field that are render in the UI
   */
  configurationForm!: FormGroup;

  /**
   * Represent the sales force setting that is in edition
   */
  salesForceSettingInEdition: settings;

  constructor(private activeModal: NgbActiveModal,
              private companyService: CompanyService,
              private alertsService: AlertService,
              private formBuilder: FormBuilder)
  {
    this.InitVariables();
  }

  ngOnInit() {
    this.SendInitialRequests();
  }

  /**
   * Initialize the component variables
   * @constructor
   * @private
   */
  private InitVariables(): void
  {
    this.configurationForm = this.formBuilder.group({
      Username: ['', Validators.required],
      Password: ['', Validators.required],
      ClientSecret: ['', Validators.required],
      TokenURL: ['', Validators.required],
      ClientId: ['', Validators.required],
      MedismartCustomerURL: ['', Validators.required]
    });
  }

  /**
   * Send the initial request to set up the component
   * @constructor
   * @private
   */
  private SendInitialRequests(): void
  {
    this.blockUI.start();

    this.companyService.GetSettingsbyId(12)
      .pipe(
        finalize(() => {
          this.blockUI.stop();
        })
      )
      .subscribe({
        next: (response) => {
          if(response.Data)
          {
            this.MapSaleForceSetting(response.Data);
          }
          else
          {
            this.alertsService.errorInfoAlert(response.Error.Message);
          }
        },
        error: (error) => {
          this.alertsService.errorInfoAlert(error);
        }
      })
  }

  /**
   * Map the configured sales force setting to the configuration form
   * @param pSetting Configured sales force setting
   * @constructor
   * @private
   */
  private MapSaleForceSetting(pSetting: settings): void
  {
    try
    {
      const salesForceSetting = JSON.parse(pSetting.Json) as ISalesForceSetting;

      this.configurationForm.patchValue({...salesForceSetting});

      this.salesForceSettingInEdition = pSetting;
    }
    catch(e)
    {
      this.alertsService.errorAlert(`No se pudo mapear la configuración de Sales Force. Detalles: ${typeof e == 'string' ? e : JSON.stringify(e)}`);
    }
  }

  /**
   * Close the current modal
   * @constructor
   */
  Close(): void
  {
    this.activeModal.dismiss();
  }

  /**
   * Send a request to update the Sales Force configuration
   * @constructor
   */
  SaveConfiguration(): void
  {
    this.blockUI.start();

    const setting = this.BuildSettingObject();

    this.companyService.SaveSettings(setting)
      .pipe(
        finalize(() => {
          this.blockUI.stop();
        })
      )
      .subscribe({
        next: (response) => {
          if(response.Result)
          {
            this.alertsService.successInfoAlert("Configuración guardad con éxito");
          }
          else
          {
            this.alertsService.errorAlert(`Ocurrió un error al intentar guardar la configuración. Detalles: ${response.Error.Message}`);
          }
        },
        error: (error) => {
          this.alertsService.errorAlert(error);
        }
      })
  }

  /**
   * Build the object that will be saved
   * @constructor
   * @private
   */
  private BuildSettingObject(): settings
  {
    let {Username,Password,ClientSecret,TokenURL,MedismartCustomerURL,ClientId} = this.configurationForm.getRawValue();

    let setting: settings = null;

    if(this.salesForceSettingInEdition)
    {
      setting = {
        ...this.salesForceSettingInEdition,
        Json: JSON.stringify({ Username, ClientSecret, MedismartCustomerURL, Password, TokenURL, ClientId } as ISalesForceSetting)
      } as settings
    }
    else
    {
      setting = {
        Codigo: 12,
        Vista: 'N/A',
        Json: JSON.stringify({ Username, ClientSecret, MedismartCustomerURL, Password, TokenURL, ClientId } as ISalesForceSetting)
      } as settings
    }

    Object.freeze(setting);

    return setting;
  }
}
