import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IResponse } from '../models/i-api-response';
import { StorageService } from './storage.service';
import { IDoctor } from '../models/i-doctor';
import { AppConstants } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
    ) {
  }

  GetDoctors(): Observable<IResponse<IDoctor[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Doctor/GetDoctors`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<IDoctor[]>>(url, { headers });
  }
}
