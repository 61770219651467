import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {IResponse} from "../models/i-api-response";
import {AppConstants} from "../models";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {StorageService} from "./storage.service";
import {ITemplate} from "../models/i-template";
import {formatDate} from "@angular/common";
import {IApInvoiceTemplate} from "../models/i-ap-invoice-template";
import {FileResponse} from "../models/responses";

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  GetTemplates(_status: string, _from: Date, _to: Date, _offset: number, _pageSize: number): Observable<IResponse<ITemplate[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Templates/GetTemplates?status=${_status}&from=${formatDate(_from, 'yyyy-MM-dd','en')}&to=${formatDate(_to, 'yyyy-MM-dd','en')}&offset=${_offset}&pageSize=${_pageSize}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<ITemplate[]>>(url, { headers });
  }

  UploadTemplateFile(_formData: FormData): Observable<IResponse<number>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Templates/UploadTemplateFile`;
    const headers = new HttpHeaders({
      // 'Content-Type': 'application/octet-stream',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post <IResponse<number>>(url, _formData, { headers });
  }

  GetApInvoiceTemplates(_templateId: number): Observable<IResponse<IApInvoiceTemplate[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Templates/GetApInvoiceTemplates?templateId=${_templateId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<IApInvoiceTemplate[]>>(url, { headers });
  }

  DownloadTemplateFile(): Observable<FileResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Templates/DownloadTemplateFile`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get <FileResponse>(url, { headers });
  }
}
