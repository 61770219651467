import { Component, OnInit } from '@angular/core';
import {ICategorySpecialty} from "../../../models/i-category-specialty";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {CategoriesSpecialtyService} from "../../../services/categories-specialty.service";
import {forkJoin} from "rxjs";
import {finalize} from "rxjs/operators";
import {AlertService, AuthenticationService, PermsService} from "../../../services";
import {IPagedRequestResponse, IPermissionsResponse} from "../../../models/responses";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  EditInventoryTransferRequestComponent
} from "../inventory-transfer-request/edit-inventory-transfer-request/edit-inventory-transfer-request.component";
import {IEditInventoryTransferRequestModalData} from "../../../models/i-edit-inventory-transfer-request-modal-data";
import {CategorySpecialtyEditComponent} from "./category-specialty-edit/category-specialty-edit.component";
import {FormControl} from "@angular/forms";
import {IKValue, IPermission} from "../../../models";
import {IResponse} from "../../../models/i-api-response";
import {ISession} from "../../../models/i-token";

@Component({
  selector: 'app-category-specialty-maintenance',
  templateUrl: './category-specialty-maintenance.component.html',
  styleUrls: ['./category-specialty-maintenance.component.scss']
})
export class CategorySpecialtyMaintenanceComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  /**
   * Represent the quantity of categories by specialty that match with the specified search criteria
   */
  categoriesBySpecialtyCount: number = 0;

  /**
   * Represent the categories by specialty that are rendered in the table
   */
  categoriesBySpecialty: ICategorySpecialty[] = [];

  /**
   * Represent the current table page
   */
  tablePage: number = 1;

  /**
   * Represent the max quantity of row that will be show the table in the page
   */
  tablePageSize: number = 14;

  /**
   * Represent the search criteria with the records will be filtered
   */
  searchCriteriaControl: FormControl = new FormControl('');

  /**
   * List of categories showed in the typeahead
   */
  categories: IKValue[] = [];

  /**
   * List of families showed in the typeahead
   */
  families: IKValue[] = [];

  /**
   * List of sub-families showed in the typeahead
   */
  subFamilies: IKValue[] = [];

  /**
   * Represent the information of the current user logged
   */
  currentUser: ISession;

  /**
   * Indicates if the user has permissions to access to the view
   */
  canAccessToView: boolean = false;
  constructor(
    private categoriesBySpecialtyService: CategoriesSpecialtyService,
    private alertService: AlertService,
    private modalController: NgbModal,
    private permissionService: PermsService,
    private authenticationService: AuthenticationService,
  ) {
    this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;

      this.GetUserPermissions();
    });
  }

  ngOnInit() {
    this.SendInitialRequests();
  }

  /**
   * Handle the table page change event
   * @constructor
   */
  HandleTablePageChange(): void
  {
    this.SearchCategoriesBySpecialty();
  }

  /**
   * Send a request to retrieve the user permissions
   * @constructor
   */
  private GetUserPermissions(): void
  {
    this.blockUI.start();

    this.permissionService.getPerms(this.currentUser.userId)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (response) => {
          this.MapUserPermissions(response);
        },
        error: (error) => {
          console.error(error);
        }
      });
  }

  /**
   * Map the permissions to the variables
   * @constructor
   * @private
   */
  private MapUserPermissions(pPermissionsResponse: IPermissionsResponse): void
  {
    if(pPermissionsResponse.perms)
    {
      this.canAccessToView = pPermissionsResponse.perms.some(p => p.Active && p.Name === 'Maintenance_CategoriesBySpecialty_Access');
    }
  }

  /**
   * Send the initial request to set up the component
   * @constructor
   */
  private SendInitialRequests(): void
  {
    this.blockUI.start();

    forkJoin([
      this.categoriesBySpecialtyService.GetPaged(this.tablePageSize, 0, ''),
      this.categoriesBySpecialtyService.GetCategories(),
      this.categoriesBySpecialtyService.GetFamilies(),
      this.categoriesBySpecialtyService.GetSubFamilies(),
    ])
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (responses) => {
          this.MapCategories(responses[1]);
          this.MapFamilies(responses[2]);
          this.MapSubFamilies(responses[3]);
          this.MapCategoriesBySpecialty(responses[0]);
        },
        error: (error) => {
          console.error(error);

          this.alertService.ShowAlert("error", {
            text: error
          });
        }
      })
  }

  /**
   * Map the categories response to the categories variable
   * @param pCategoriesResponse Response information
   * @constructor
   * @private
   */
  private MapCategories(pCategoriesResponse: IResponse<IKValue[]>): void
  {
    this.categories = pCategoriesResponse.Data ? pCategoriesResponse.Data : [];
  }

  /**
   * Map the families response to the families variable
   * @param pFamiliesResponse Response information
   * @constructor
   * @private
   */
  private MapFamilies(pFamiliesResponse: IResponse<IKValue[]>): void
  {
    this.families = pFamiliesResponse.Data ? pFamiliesResponse.Data : [];
  }

  /**
   * Map the sub-families response to the sub-families variable
   * @param pSubFamiliesResponse Response information
   * @constructor
   * @private
   */
  private MapSubFamilies(pSubFamiliesResponse: IResponse<IKValue[]>): void
  {
    this.subFamilies = pSubFamiliesResponse.Data ? pSubFamiliesResponse.Data : [];
  }

  /**
   * Map the categories by specialty to the show in the table
   * @param pCategoriesBySpecialtyResponse Paged request response with the list of category by specialty
   * @constructor
   * @private
   */
  private MapCategoriesBySpecialty(pCategoriesBySpecialtyResponse: IPagedRequestResponse<ICategorySpecialty[]>): void
  {
    this.categoriesBySpecialtyCount = pCategoriesBySpecialtyResponse.RecordsCount;

    this.categoriesBySpecialty = pCategoriesBySpecialtyResponse.Data
      .map(cs => ({
        ...cs,
        U_MantSubFam: this.subFamilies.some(sb => sb.Key == cs.U_MantSubFam) ? this.subFamilies.find(sb => sb.Key == cs.U_MantSubFam).Value :  cs.U_MantSubFam,
        U_MantCategoria: this.categories.some(sb => sb.Key == cs.U_MantCategoria) ? this.categories.find(sb => sb.Key == cs.U_MantCategoria).Value :  cs.U_MantCategoria,
        U_MantFamilia: this.families.some(sb => sb.Key == cs.U_MantFamilia) ? this.families.find(sb => sb.Key == cs.U_MantFamilia).Value :  cs.U_MantFamilia
      }));
  }


  /**
   * Open the modal for edit/create a category by specialty
   * @constructor
   */
  OpenCreateCategoryBySpecialtyModal(): void
  {
    const modal = this.modalController.open(CategorySpecialtyEditComponent, {
      backdrop: 'static',
      windowClass: 'category-specialty-modal'
    });

    modal.result.then((result) => {
    }, (reason) => {
      this.SearchCategoriesBySpecialty();
    });
  }

  /**
   * Open the modal for edit/create a category by specialty and pass the category specialty code
   * @param pCategorySpecialtyCode Code of the category specialty that will be edited
   * @constructor
   */
  EditCategorySpecialty(pCategorySpecialtyCode: string): void
  {
    const modal = this.modalController.open(CategorySpecialtyEditComponent, {
      backdrop: 'static',
      windowClass: 'category-specialty-modal'
    });

    modal.componentInstance.categoryBySpecialtyToEditCode = pCategorySpecialtyCode;

    modal.result.then((result) => {
    }, (reason) => {
      this.SearchCategoriesBySpecialty();
    });
  }

  /**
   * Send a request to retrieve the categories by specialty
   * @constructor
   */
  SearchCategoriesBySpecialty(): void
  {
    let searchCriteria = this.searchCriteriaControl.value;

    this.blockUI.start();

    this.categoriesBySpecialtyService.GetPaged(this.tablePageSize, this.tablePageSize * (this.tablePage - 1), searchCriteria)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (response) => {
          this.MapCategoriesBySpecialty(response);
        },
        error: (error) => {
          console.error(error);

          this.alertService.ShowAlert("error", {
            text: error
          });
        }
      });
  }
}
