import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Series } from 'src/app/models';
import { IHeadquarter, ISeriesByHeadquarter } from 'src/app/models/i-headquarter';
import { AlertService, AuthenticationService, PermsService, SeriesService } from 'src/app/services';
import { HeadquarterService } from 'src/app/services/headquarter.service';

@Component({
  selector: 'app-headquarter',
  templateUrl: './headquarter.component.html',
  styleUrls: ['./headquarter.component.scss']
})
export class HeadquarterComponent implements OnInit {
  serieList: Series[];
  InvoiceSeriesList: Series[];
  PaymentSeriesList: Series[];
  QuotationSeriesList: Series[];
  SaleOrderSeriesList: Series[];
  CustomerSeriesList: Series[];
  SupplierSeriesList: Series[];
  NCSeriesList: Series[];
  ApInvoiceSeriesList: Series[];
  ApPaymentSeriesList: Series[];
  InventoryCountingSeries: Series[];
  InventoryTransferRequestSeries: Series[];
  InventoryTransferSeries: Series[];

  headquarterForm: FormGroup;
  headquarterId: number;
  @BlockUI() blockUI: NgBlockUI;
  currentUser: any;
  currentUserSubscription: Subscription;
  permisos: boolean = true;
  constructor(
    private seriesService: SeriesService,
    private formBuilder: FormBuilder,
    private permsService: PermsService,
    private headquarterService: HeadquarterService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit(): void {
    this.headquarterId = Number(this.route.snapshot.params['id']);
    this.ResetForm();
    this.CheckPermits();
    this.GetSeriesLists();
  }

  CheckPermits(): void {
    this.permsService.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.Result) {
        let permListtable: any = data.perms;
        data.perms.forEach(Perm => {
          if (Perm.Name === "V_Headquarter") {
            this.permisos = Perm.Active;
          }
        });

      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
  }

  ResetForm(): void{
    this.headquarterForm = this.formBuilder.group({
      Id: [0, Validators.required],
      Code: ['', Validators.required],
      Name: ['', Validators.required],
      Status: [true, Validators.required],
      InvoiceSerie: ['', Validators.required],
      PaymentSerie: ['', Validators.required],
      QuotationSerie: ['', Validators.required],
      SaleOrderSerie: ['', Validators.required],
      CustomerSerie: ['', Validators.required],
      SupplierSerie: ['', Validators.required],
      NCSerie: ['', Validators.required],
      ApInvoiceSerie: ['', Validators.required],
      ApPaymentSerie: ['', Validators.required],
      InventoryCountingSeries: ['', Validators.required],
      InventoryTransferRequestSeries: ['', Validators.required],
      InventoryTransferSeries: ['', Validators.required]
    });
  }

  GetSeriesLists(): void {
    this.blockUI.start('Cargando listas de series..');
    this.seriesService.getSeriesList().pipe(finalize(()=>this.blockUI.stop())).subscribe((data: any) => {
      if (data.Result) {
        this.serieList = data.Series;
        this.ChargeSeriesListByType();
        if(this.headquarterId){
          this.LoadHeadquarter(this.headquarterId);
        }
      } else {
        this.alertService.errorAlert('Error al cargar la lista de series - ' + data.errorInfo.Message);
      }
    }, error => {
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  ChargeSeriesListByType(): void {

    this.InvoiceSeriesList = [];
    this.PaymentSeriesList = [];
    this.QuotationSeriesList = [];
    this.SaleOrderSeriesList = [];
    this.CustomerSeriesList = [];
    this.SupplierSeriesList = [];
    this.NCSeriesList= [];
    this.ApInvoiceSeriesList = [];
    this.ApPaymentSeriesList = [];
    this.InventoryCountingSeries = [];
    this.InventoryTransferRequestSeries = [];
    this.InventoryTransferSeries = [];

    for(let serie of this.serieList){
      switch (serie.DocType) {
        case 0:
          this.InvoiceSeriesList.push(serie);
          this.QuotationSeriesList.push(serie);
          this.PaymentSeriesList.push(serie);
          this.SaleOrderSeriesList.push(serie);
          this.CustomerSeriesList.push(serie);
          this.SupplierSeriesList.push(serie);
          this.NCSeriesList.push(serie);
          this.ApInvoiceSeriesList.push(serie);
          this.ApPaymentSeriesList.push(serie);
          break;
        case 1:
          this.InvoiceSeriesList.push(serie);
          break;
        case 2:
          this.QuotationSeriesList.push(serie);
          break;
        case 3:
          this.PaymentSeriesList.push(serie);
          break;
        case 4:
          this.SaleOrderSeriesList.push(serie);
          break;
        case 5:
          this.CustomerSeriesList.push(serie);
          break;
        case 6:
          this.SupplierSeriesList.push(serie);
          break;
        case 7:
          this.NCSeriesList.push(serie);
          break;
        case 8:
          this.ApInvoiceSeriesList.push(serie);
          break;
        case 9:
          this.ApPaymentSeriesList.push(serie);
          break;
        case 10: // Inventory Transfer Requests
          this.InventoryTransferRequestSeries.push(serie);
          break;
        case 11: // Inventory Transfers
          this.InventoryTransferSeries.push(serie);
          break;
        case 12:
          this.InventoryCountingSeries.push(serie);
          break;
      }
    }
  }

  LoadHeadquarter(_id: number): void {
    this.blockUI.start("Cargando datos de la sede")
    this.headquarterService.GetHeadquarter(_id).pipe(finalize(()=>this.blockUI.stop())).subscribe(callBack => {
      if(callBack.Result){
        this.headquarterForm.controls['Id'].setValue(callBack.Data.Id);
        this.headquarterForm.controls['Code'].setValue(callBack.Data.Code);
        this.headquarterForm.controls['Name'].setValue(callBack.Data.Name);
        this.headquarterForm.controls['Status'].setValue(callBack.Data.Status === 'A' ? true : false);

        if(callBack.Data.SeriesByHeadquarters && callBack.Data.SeriesByHeadquarters.length > 0){
          this.FillSeriesByType(callBack.Data.SeriesByHeadquarters);
        }
      } else {
        this.alertService.errorInfoAlert('Error al cargar la lista de sedes - ' + callBack.Error.Message);
      }
    }, error => {
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  FillSeriesByType(_series: ISeriesByHeadquarter[]): void{
    let idInvoiceSerie = _series.find(x => {
      let egSerie = this.InvoiceSeriesList.find(s => s.Id === x.SerieId);
      return (egSerie && x.SerieId === egSerie.Id)
    });
    this.headquarterForm.controls['InvoiceSerie'].setValue(idInvoiceSerie ? idInvoiceSerie.SerieId : 0);

    let idQuotationSerie = _series.find(x => {
      let egSerie = this.QuotationSeriesList.find(s => s.Id === x.SerieId);
      return (egSerie && x.SerieId === egSerie.Id)
    });
    this.headquarterForm.controls['QuotationSerie'].setValue(idQuotationSerie ? idQuotationSerie.SerieId : 0);

    let idPaymentSerie = _series.find(x => {
      let egSerie = this.PaymentSeriesList.find(s => s.Id === x.SerieId);
      return (egSerie && x.SerieId === egSerie.Id)
    });
    this.headquarterForm.controls['PaymentSerie'].setValue(idPaymentSerie? idPaymentSerie.SerieId : 0);

    let idSaleOrderSerie = _series.find(x => {
      let egSerie = this.SaleOrderSeriesList.find(s => s.Id === x.SerieId);
      return (egSerie && x.SerieId === egSerie.Id)
    });
    this.headquarterForm.controls['SaleOrderSerie'].setValue(idSaleOrderSerie ? idSaleOrderSerie.SerieId : 0);

    let idCustomerSerie = _series.find(x => {
      let egSerie = this.CustomerSeriesList.find(s => s.Id === x.SerieId);
      return (egSerie && x.SerieId === egSerie.Id)
    });
    this.headquarterForm.controls['CustomerSerie'].setValue(idCustomerSerie ? idCustomerSerie.SerieId : 0);

    let idSupplierSerie = _series.find(x => {
      let egSerie = this.SupplierSeriesList.find(s => s.Id === x.SerieId);
      return (egSerie && x.SerieId === egSerie.Id)
    });
    this.headquarterForm.controls['SupplierSerie'].setValue(idSupplierSerie ? idSupplierSerie.SerieId : 0);

    let idNCSerie = _series.find(x => {
      let egSerie = this.NCSeriesList.find(s => s.Id === x.SerieId);
      return (egSerie && x.SerieId === egSerie.Id)
    });
    this.headquarterForm.controls['NCSerie'].setValue(idNCSerie ? idNCSerie.SerieId : 0);

    let idApInvoiceSerie = _series.find(x => {
      let egSerie = this.ApInvoiceSeriesList.find(s => s.Id === x.SerieId);
      return (egSerie && x.SerieId === egSerie.Id)
    });
    this.headquarterForm.controls['ApInvoiceSerie'].setValue(idApInvoiceSerie ? idApInvoiceSerie.SerieId : 0);

    let idApPaymentSerie = _series.find(x => {
      let egSerie = this.ApPaymentSeriesList.find(s => s.Id === x.SerieId);
      return (egSerie && x.SerieId === egSerie.Id)
    });
    this.headquarterForm.controls['ApPaymentSerie'].setValue(idApPaymentSerie ? idApPaymentSerie.SerieId : 0);

    let inventoryCountingSeries = _series.find(x => {
      let egSerie = this.InventoryCountingSeries.find(s => s.Id === x.SerieId);
      return (egSerie && x.SerieId === egSerie.Id)
    });
    this.headquarterForm.controls['InventoryCountingSeries'].setValue(inventoryCountingSeries ? inventoryCountingSeries.SerieId : 0);

    let inventoryTransferRequestSeries = _series.find(x => {
      let egSerie = this.InventoryTransferRequestSeries.find(s => s.Id === x.SerieId);
      return (egSerie && x.SerieId === egSerie.Id)
    });
    this.headquarterForm.controls['InventoryTransferRequestSeries'].setValue(inventoryTransferRequestSeries ? inventoryTransferRequestSeries.SerieId : 0);

    let inventoryTransferSeries = _series.find(x => {
      let egSerie = this.InventoryTransferSeries.find(s => s.Id === x.SerieId);
      return (egSerie && x.SerieId === egSerie.Id)
    });
    this.headquarterForm.controls['InventoryTransferSeries'].setValue(inventoryTransferSeries ? inventoryTransferSeries.SerieId : 0);
  }

  GetSeriesByHeadquarter(): ISeriesByHeadquarter[]{
    let seriesByHeadquarters: ISeriesByHeadquarter[] = [];
    if(Number(this.headquarterForm.controls['InvoiceSerie'].value)){
      seriesByHeadquarters.push({Id: 0, SerieId: this.headquarterForm.controls['InvoiceSerie'].value, HeadquarterId: this.headquarterForm.controls['Id'].value});
    }
    if(Number(this.headquarterForm.controls['QuotationSerie'].value)){
      seriesByHeadquarters.push({Id: 0, SerieId: this.headquarterForm.controls['QuotationSerie'].value, HeadquarterId: this.headquarterForm.controls['Id'].value});
    }
    if(Number(this.headquarterForm.controls['PaymentSerie'].value)){
      seriesByHeadquarters.push({Id: 0, SerieId: this.headquarterForm.controls['PaymentSerie'].value, HeadquarterId: this.headquarterForm.controls['Id'].value});
    }
    if(Number(this.headquarterForm.controls['SaleOrderSerie'].value)){
      seriesByHeadquarters.push({Id: 0, SerieId: this.headquarterForm.controls['SaleOrderSerie'].value, HeadquarterId: this.headquarterForm.controls['Id'].value});
    }
    if(Number(this.headquarterForm.controls['CustomerSerie'].value)){
      seriesByHeadquarters.push({Id: 0, SerieId: this.headquarterForm.controls['CustomerSerie'].value, HeadquarterId: this.headquarterForm.controls['Id'].value});
    }
    if(Number(this.headquarterForm.controls['SupplierSerie'].value)){
      seriesByHeadquarters.push({Id: 0, SerieId: this.headquarterForm.controls['SupplierSerie'].value, HeadquarterId: this.headquarterForm.controls['Id'].value});
    }
    if(Number(this.headquarterForm.controls['NCSerie'].value)){
      seriesByHeadquarters.push({Id: 0, SerieId: this.headquarterForm.controls['NCSerie'].value, HeadquarterId: this.headquarterForm.controls['Id'].value});
    }
    if(Number(this.headquarterForm.controls['ApInvoiceSerie'].value)){
      seriesByHeadquarters.push({Id: 0, SerieId: this.headquarterForm.controls['ApInvoiceSerie'].value, HeadquarterId: this.headquarterForm.controls['Id'].value});
    }
    if(Number(this.headquarterForm.controls['ApPaymentSerie'].value)){
      seriesByHeadquarters.push({Id: 0, SerieId: this.headquarterForm.controls['ApPaymentSerie'].value, HeadquarterId: this.headquarterForm.controls['Id'].value});
    }
    if(Number(this.headquarterForm.controls['InventoryCountingSeries'].value)){
      seriesByHeadquarters.push({Id: 0, SerieId: this.headquarterForm.controls['InventoryCountingSeries'].value, HeadquarterId: this.headquarterForm.controls['Id'].value});
    }
    if(Number(this.headquarterForm.controls['InventoryTransferRequestSeries'].value)){
      seriesByHeadquarters.push({Id: 0, SerieId: this.headquarterForm.controls['InventoryTransferRequestSeries'].value, HeadquarterId: this.headquarterForm.controls['Id'].value});
    }

    if(Number(this.headquarterForm.controls['InventoryTransferSeries'].value)){
      seriesByHeadquarters.push({Id: 0, SerieId: this.headquarterForm.controls['InventoryTransferSeries'].value, HeadquarterId: this.headquarterForm.controls['Id'].value});
    }
    return seriesByHeadquarters;
  }

  Cancel(): void{
    this.router.navigate(['headquarters']);
  }

  Save(): void{
    this.blockUI.start("Guardando la sede...");
    let headquarter = {
      ...this.headquarterForm.value,
      Status: (this.headquarterForm.controls['Status'].value ? 'A' : 'I'),
      SeriesByHeadquarters: this.GetSeriesByHeadquarter()
    } as IHeadquarter;
    this.headquarterService.StoreHeadquarter(headquarter).pipe(finalize(()=>this.blockUI.stop())).subscribe(callBack => {
      if(callBack.Result){
        this.alertService.successAlert("Sede guardad exitosamente");
        this.router.navigate(['headquarters']);
      } else {
        this.alertService.errorInfoAlert('Error al guardar la sede - ' + callBack.Error.Message);
      }
    }, error => {
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }
}
