import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IInvoiceInMemory} from "src/app/models/i-invoice-in-memory";
import {LocalStorageVariables} from "../enum/local-storage-variables";
import {StorageService} from "./storage.service";
import {AlertService} from "./alert.service";
import {CommonService} from "./common.service";
import {filter} from "rxjs/operators";
import {CompanyService} from "./company.service";
import {CONFIG_VIEW} from "../models";
import {AuthenticationService} from "./authentication.service";

@Injectable({
  providedIn: 'root'
})
export class InvoicesInMemoryService {

  /**
   * Used to indicates if a memory invoice was added or deleted
   * @param ChangeType Indicates the kind of changes that was applied to the invoices in memory list
   * @private
   */
  private onInvoicesInMemoryChangedSubject$: Subject<'deleted' | 'added' | 'saved'> = new Subject<'deleted' | 'added'>();

  /**
   * Emits values when a memory invoice was added or deleted
   * @param ChangeType Indicates the kind of changes that was applied to the invoices in memory list
   */
  onInvoicesInMemoryChanged$: Observable<'deleted' | 'added' | 'saved'> = this.onInvoicesInMemoryChangedSubject$.asObservable();

  /**
   * Used to indicates that the application want to show the memory invoices list
   * @param ShouldShowInvoicesInMemory Indicates if should show the invoices in memory
   * @private
   */
  private onRequestShowInvoicesInMemorySubject$: Subject<boolean> = new Subject<boolean>();

  /**
   * Emits values when the application need to show the memory invoices list
   * @param ShouldShowInvoicesInMemory Indicates if should show the invoices in memory
   */
  onRequestShowInvoiceInMemory$: Observable<boolean> = this.onRequestShowInvoicesInMemorySubject$.asObservable();

  /**
   * Used to indicates that the user is adding manually an invoice in memory
   * @param DocumentKey Represent the identifier of the empty invoice in memory added
   * @private
   */
  private onManuallyInvoiceInMemoryAddedSubject$: Subject<string> = new Subject<string>();

  /**
   * Emits values when the user manually add an invoice in memory
   * @param DocumentKey Represent the identifier of the empty invoice in memory added
   */
  onManuallyInvoiceInMemoryAdded$: Observable<string> = this.onManuallyInvoiceInMemoryAddedSubject$.asObservable();

  /**
   * Used to indicates that should load an invoice in memory
   * @param DocumentKey Represent the identifier of the invoice in memory that should be loaded
   * @param ShouldSaveCurrentInvoice Indicates if should save the changes of the current invoice
   * @private
   */
  private onRequestLoadInvoiceInMemorySubject$: Subject<{ DocumentKey: string, ShouldSaveCurrentInvoice: boolean }> = new Subject<{ DocumentKey: string, ShouldSaveCurrentInvoice: boolean }>();

  /**
   * Emits values when the user try to load manually an invoice in memory
   * @param DocumentKey Represent the identifier of the invoice in memory that should be loaded
   * @param ShouldSaveCurrentInvoice Indicates if should save the changes of the current invoice
   */
  onRequestLoadInvoiceInMemory$: Observable<{ DocumentKey: string, ShouldSaveCurrentInvoice: boolean }> = this.onRequestLoadInvoiceInMemorySubject$.asObservable();

  /**
   * Used to indicates what is the currently invoice in memory laoded
   * @param DocumentKey Identifier of the loaded invoice in memory
   * @private
   */
  private onLoadInvoiceInMemorySubject$: Subject<string> = new Subject<string>();

  /**
   * Emits values when an invoice in memory is loaded
   * @param DocumentKey Identifier of the loaded invoice in memory
   */
  onLoadInvoiceInMemory$: Observable<string> = this.onLoadInvoiceInMemorySubject$.asObservable();

  /**
   * Represent the configuration about the memory invoices
   * @private
   */
  private invoicesInMemoryConfiguration: {DisplayName:string,MaxQuantity:number} = {DisplayName: "Factura", MaxQuantity: 3};

  /**
   * Represent the currenty loaded invoice in memory
   * @private
   */
  private loadedInvoiceInMemory: string = '';
  constructor(private localStorageService: StorageService,
              private alertService: AlertService,
              private commonService: CommonService,
              private companyService: CompanyService,
              private authenticationService: AuthenticationService) {
  }

  /**
   * Represent the max quantity of memory invoices that can be stored
   * @constructor
   */
  get InvoiceInMemoryMaxQuantity(): number
  {
    return this.invoicesInMemoryConfiguration.MaxQuantity;
  }

  /**
   * Represent the name which the memory invoice should be rendered
   * @constructor
   */
  get InvoiceInMemoryDisplayName(): string
  {
    return this.invoicesInMemoryConfiguration.DisplayName;
  }

  /**
   * Represent the identifier of the current invoice in memory that is loaded
   * @constructor
   */
  get CurrentLoadedInvoiceInMemory(): string
  {
    return this.loadedInvoiceInMemory;
  }

  /**
   * Save the invoice in memory in the local storage
   * @param pInvoiceInMemory The invoice in memory that will be saved in the local storage
   * @param pShouldShowAlert Indicates if should show an alert to indicates to the user the error, if occurs
   * @constructor
   */
  SaveInvoiceInMemory(pInvoiceInMemory: IInvoiceInMemory, pShouldShowAlert: boolean = false): boolean
  {
    try
    {
      let memoryInvoices = this.localStorageService.GetLocalStorageVariable<IInvoiceInMemory[]>(LocalStorageVariables.InvoicesInMemory);

      if(!memoryInvoices)
      {
        memoryInvoices = [];
      }

      let saveMemoryInvoice = memoryInvoices.find(mi => mi.DocumentKey === pInvoiceInMemory.DocumentKey)

      if(!saveMemoryInvoice)
      {
        if(memoryInvoices.length === this.invoicesInMemoryConfiguration.MaxQuantity)
        {
          return false;
        }

        memoryInvoices.push(pInvoiceInMemory);
      }
      else
      {
        let indexOfSavedInvoice = memoryInvoices.indexOf(saveMemoryInvoice);

        memoryInvoices[indexOfSavedInvoice] = pInvoiceInMemory;
      }

      this.localStorageService.SetLocalStorageVariable(LocalStorageVariables.InvoicesInMemory, memoryInvoices);

      this.onInvoicesInMemoryChangedSubject$.next(saveMemoryInvoice ? 'saved' : 'added');

      return true;
    }
    catch (e) {
      console.error("An error occurs trying to save the invoice in memory. Details: ", e);

      if(pShouldShowAlert)
      {
        this.alertService.ShowAlert("error", {
          title: 'Guardando factura en memoria',
          text: JSON.stringify(e)
        });
      }
    }

    return false;
  }

  /**
   * Delete the specified invoice in memory
   * @param pDocumentKey Identifier of the invoice in memory to delete
   * @constructor
   */
  DeleteInvoiceInMemory(pDocumentKey: string): boolean
  {
    try {
      let invoicesInMemory = this.GetInvoicesInMemory();

      if(!invoicesInMemory.length)
      {
        return true;
      }

      invoicesInMemory = invoicesInMemory.filter(iim => iim.DocumentKey !== pDocumentKey);

      this.localStorageService.SetLocalStorageVariable(LocalStorageVariables.InvoicesInMemory, invoicesInMemory);

      this.onInvoicesInMemoryChangedSubject$.next('deleted');

      return true;
    }
    catch (e) {
      console.error(`An error occurs trying to delete the invoice in memory "${pDocumentKey}". Details:`, e);

      return false;
    }
  }

  /**
   * Add an empty invoice in memory
   * @constructor
   */
  AddEmptyInvoiceInMemory(): boolean
  {
    try {

      if(this.MaxQuantityOfInvoiceInMemoryReached())
      {
        this.alertService.ShowAlert("info", {
          title: 'Agregar factura en memoria',
          text: "No es posible agregar una nueva factura en memoria, ya que se ha alcanzado el límite máximo permitido."
        });

        return false;
      }

      let documentKey = this.commonService.GenerateDocumentUniqueID(this.localStorageService.GetDocumentType());

      this.SaveInvoiceInMemory({
        DocumentKey: documentKey,
        DocumentLines: [],
        UDFsInformation: [],
        DocumentFormInformation: null,
        ElectronicInvoiceFormInformation: null,
        SalesForceInformation: null,
        TAPPInformation: null,
        UDFsList: []
      } as IInvoiceInMemory);

      this.onManuallyInvoiceInMemoryAddedSubject$.next(documentKey);

      return true;
    }
    catch (e) {
      console.error("An error occurs trying to add an empty invoice in memory. Details:", e);

      this.alertService.ShowAlert("error", {
        title: 'Agregando factura vacia',
        text: JSON.stringify(e)
      });

      return false;
    }
  }

  /**
   * Emits an event indicating what is the current invoice in memory loaded
   * @param pDocumentKey Identifier of the invoice in memory loaded
   * @param pShouldShowAlert Indicates if should show an alert indicating that a invoice in memory was loaded
   * @constructor
   */
  SetCurrentlyInvoiceInMemoryLoaded(pDocumentKey: string, pShouldShowAlert: boolean = true): void
  {
    this.loadedInvoiceInMemory = pDocumentKey;

    this.onLoadInvoiceInMemorySubject$.next(pDocumentKey);

    if(pDocumentKey && pShouldShowAlert)
    {
      this.alertService.ShowAlert("info", {
        title: "Carga de factura en memoria",
        text: 'La información de la factura en memoria ha sido cargada en el formulario.'
      }, {
        "Clave de documento": pDocumentKey
      });
    }
  }

  /**
   * Emits an event indicating if should show the invoices in memory
   * @param pShouldShow Indicates if should show invoices in memory
   * @constructor
   */
  ShowInvoicesInMemory(pShouldShow: boolean): void
  {
    this.onRequestShowInvoicesInMemorySubject$.next(pShouldShow);
  }

  /**
   * Emits an event indicating that should load the specified invoice in memory
   * @param pDocumentKey Identifier of the invoice in memory that will be loaded
   * @param pShouldSaveCurrentInvoice Indicates if should save the changes of the current invoice
   * @constructor
   */
  LoadInvoiceInMemory(pDocumentKey: string, pShouldSaveCurrentInvoice: boolean = true): void
  {
    this.onRequestLoadInvoiceInMemorySubject$.next({DocumentKey: pDocumentKey, ShouldSaveCurrentInvoice: pShouldSaveCurrentInvoice});
  }

  /**
   * Takes the latest invoice in memory and load it's information
   * @constructor
   */
  LoadLatestInvoiceInMemory(): void
  {
    let invoicesInMemory = this.GetInvoicesInMemory();

    if(!invoicesInMemory.length)
    {
      return;
    }

    let latestInvoiceInMemoryDocumentKey = invoicesInMemory[invoicesInMemory.length - 1].DocumentKey;

    this.LoadInvoiceInMemory(latestInvoiceInMemoryDocumentKey, false);
  }

  /**
   * Get a memory invoice from the local storage, if the document key is not provided the latest memory invoice will be returned
   * @param pDocumentKey Represent the document key of the memory invoice that will be loaded
   * @constructor
   */
  GetInvoiceInMemory(pDocumentKey: string = ''): IInvoiceInMemory | null
  {
    try {
      let invoicesInMemory = this.GetInvoicesInMemory();

      let invoiceInMemoryToLoad: IInvoiceInMemory;

      if(pDocumentKey)
      {
        invoiceInMemoryToLoad = invoicesInMemory.find(mi => mi.DocumentKey === pDocumentKey);
      }
      else
      {
        invoiceInMemoryToLoad = invoicesInMemory[invoicesInMemory.length - 1];
      }

      if(!invoiceInMemoryToLoad)
      {
        return null;
      }

      return invoiceInMemoryToLoad;
    }
    catch (e) {
      console.error("An error occurs trying to retrieve invoice in memory. Details:", e);

      return null;
    }
  }

  /**
   * Retrieve all stored memory invoices
   * @constructor
   */
  GetInvoicesInMemory(): IInvoiceInMemory[]
  {
    try
    {
      let invoicesInMemory = this.localStorageService.GetLocalStorageVariable<IInvoiceInMemory[]>(LocalStorageVariables.InvoicesInMemory);

      if(!invoicesInMemory)
      {
        return [];
      }

      return invoicesInMemory;
    }
    catch (e) {
      console.error("An error occurs trying to retrieve invoices in memory. Details:", e);

      return [];
    }
  }

  /**
   * Indicates if there are memory invoices to load
   * @constructor
   */
  HasInvoicesInMemory(): boolean
  {
    try {
      let invoicesInMemory = this.localStorageService.GetLocalStorageVariable<IInvoiceInMemory[]>(LocalStorageVariables.InvoicesInMemory);

      return !!invoicesInMemory && !!invoicesInMemory.length;
    }
    catch (e) {
      console.error("An error occurs trying to check if there are invoices in memory. Details:", e);

      return false;
    }
  }

  /**
   * Indicates if the last invoice in memory is an empty invoice
   * @constructor
   */
  LastInvoiceInMemoryIsEmpty(): boolean
  {
    let invoicesInMemory = this.GetInvoicesInMemory();

    if(!invoicesInMemory.length)
    {
      return true;
    }

    return invoicesInMemory[invoicesInMemory.length - 1].DocumentFormInformation === null;
  }

  /**
   * Indicates if the max quantity of invoices in memory allowed was reached
   * @constructor
   */
  MaxQuantityOfInvoiceInMemoryReached(): boolean
  {
    let invoicesInMemory = this.GetInvoicesInMemory();

    return invoicesInMemory.length === this.invoicesInMemoryConfiguration.MaxQuantity;
  }

  /**
   * Load the invoices in memory configuration, if an error occurs will be use default configuration.
   * @constructor
   */
  LoadInvoicesInMemoryConfiguration(): void
  {
    this.companyService.GetSettingsbyId(CONFIG_VIEW.Invoice)
      .subscribe({
        next: (response) => {
          if(response.Data && response.Data.Json)
          {
            let invoicesInMemoryConfiguration = JSON.parse(response.Data.Json);

            this.invoicesInMemoryConfiguration = {
              MaxQuantity: invoicesInMemoryConfiguration.Quantity,
              DisplayName: invoicesInMemoryConfiguration.Name
            };
          }
        },
        error: (e) => {
          console.error("An error occurs trying to load the invoices in memory configuration. Default configuration will be used. Details:", e);
        }
      })
  }
}
