import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import {concatMap, finalize, tap} from 'rxjs/operators';
import { IApInvoiceTemplate } from 'src/app/models/i-ap-invoice-template';
import {AlertService, AuthenticationService, CommonService, DocumentService, PermsService} from 'src/app/services';
import {TemplateService} from "../../../services/template.service";
import {ITemplate} from "../../../models/i-template";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TemplateStatus} from "../../../enum/enum";
import {formatDate} from "@angular/common";
import {Item} from "electron";

@Component({
  selector: 'app-template-loader',
  templateUrl: './template-loader.component.html',
  styleUrls: ['./template-loader.component.scss']
})
export class TemplateLoaderComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  permisos = true;
  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  files: any[] = [];
  processedTemplates: ITemplate[];
  searchForm: FormGroup;
  offset: number = 0;
  pageSize: number = 10;
  templateStatusList = Object.keys(TemplateStatus).map(key => ({ key: key, value: TemplateStatus[key] }));
  currentApInvoiceTemplates: IApInvoiceTemplate[];

  constructor(
    private commonService: CommonService,
    private alertService: AlertService,
    private permissionService: PermsService,
    private authenticationService: AuthenticationService,
    private documentService: DocumentService,
    private templateService: TemplateService,
    private formBuilder: FormBuilder,
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.processedTemplates = [];
    this.currentApInvoiceTemplates = [];
    this.CheckPermits();
    this.ResetSearchForm();
    this.SearchTemplates();
  }

  ResetSearchForm(): void{
    this.searchForm = this.formBuilder.group({
      Status: ['P', Validators.required],
      From: [formatDate(new Date(), 'yyyy-MM-dd','en'), Validators.required],
      To: [formatDate(new Date(), 'yyyy-MM-dd','en'), Validators.required]
    });
  }

  CheckPermits() {
    this.permissionService.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.Result) {
        data.perms.forEach(Perm => {
          if (Perm.Name === 'V_TempLoader') {
            this.permisos = Perm.Active;
          }
        });
      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
  }

  //#region File browser
  FileDropped($event): void {
    try{
      this.PrepareFilesList($event);
    } catch(exception){
      console.log(exception);
    }
  }

  FileBrowseHandler($event): void {
    try{
      console.log($event);
      this.PrepareFilesList($event.srcElement.files);
    } catch(exception){
      console.log(exception);
    }
  }
  PrepareFilesList(files: Array<any>): void {
    try{
      for (const item of files) {
        this.files = [];
        item.progress = 0;
        item.complete = false;
        if(['XLS', 'XLSX', 'CSV'].includes(item.name.split('.').pop().toUpperCase())){
          this.files.push(item);
          this.UploadTemplateFile();
        } else {
          this.alertService.errorInfoAlert('Solo se permiten archivos .xls, .xlsx o .csv');
        }
      }
      (<HTMLInputElement>document.getElementById('fileDropRef')).value = '';
      // this.UploadFilesSimulator(0);
    } catch(exception){
      console.log(exception);
    }
  }

  UploadFilesSimulator(index: number): void {
    try {
      setTimeout(() => {
        if (index === this.files.length) {
          return;
        } else {
          const progressInterval = setInterval(() => {
            if (this.files[index].progress === 100) {
              clearInterval(progressInterval);
              this.UploadFilesSimulator(index + 1);
              this.UploadTemplateFile(this.files[index]);
            } else {
              this.files[index].progress += 1;
            }
          }, 10);
        }
      }, 150);
    } catch(exception){
      console.log(exception);
    }
  }

  FormatBytes(bytes, decimals = 0): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  //#endregion

  UploadTemplateFile(file: any = null): void {
    try {
      const formData = new FormData();
      this.processedTemplates = [];

      if(this.files.length > 0){
        formData.append('file', this.files[0] as File);
      }

      this.blockUI.start('Cargando plantilla...');
      // file.complete = true

      this.templateService.UploadTemplateFile(formData)
        .pipe(finalize(() => this.blockUI.stop()))
        .pipe(tap(req => {
          try{
            if(req.Result){
              this.alertService.successInfoAlert('Carga de plantilla finalizada!');
            } else {
              this.alertService.errorInfoAlert(req.Error.Message);
            }
          } catch(exception){
            console.log(exception);
          }
        }), concatMap(next => {
        return this.templateService.GetTemplates(this.searchForm.controls["Status"].value, this.searchForm.controls["From"].value, this.searchForm.controls["To"].value, this.offset, this.pageSize).pipe(tap(req => {
          this.processedTemplates = req.Data;
        }))
      })).subscribe(req => {
      }, error => {
        this.alertService.errorInfoAlert(error);
      });
    } catch(exception){
      console.log(exception);
    }
  }

  SearchTemplates(): void {
    try {
      this.blockUI.start('Buscando...');

      this.templateService.GetTemplates(this.searchForm.controls["Status"].value, this.ParseDate(this.searchForm.controls["From"].value), this.ParseDate(this.searchForm.controls["To"].value), this.offset, this.pageSize)
        .pipe(finalize(() => this.blockUI.stop()))
        .subscribe(next => {
        if(next.Result){
          this.processedTemplates = next.Data;
        } else {
          console.log(next.Error);
        }
      }, error => {
        console.log(error);
      });
    } catch (ex){
      this.blockUI.stop()
      console.log(ex);
    }
  }

  ParseDate(dateString: string): Date {
    const [year, month, day] = dateString.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  ShowTemplateDetails(_template: ITemplate): void {
    try {
      this.blockUI.start('Cargando datos...');

      this.templateService.GetApInvoiceTemplates(_template.Id)
        .pipe(finalize(() => this.blockUI.stop()))
        .subscribe(next => {
          if(next.Result){
            this.currentApInvoiceTemplates = next.Data;
            (<HTMLButtonElement>document.getElementById('triggerAfterTemplateModal')).click();
          } else {
            console.log(next.Error);
          }
        }, error => {
          console.log(error);
        });
    } catch (ex){
      this.blockUI.stop()
      console.log(ex);
    }
  }

  DownloadTemplateFile(){
    this.blockUI.start("Descargando plantilla...");

    this.templateService.DownloadTemplateFile()
      .subscribe(response => {
        this.blockUI.stop();
        if (response.Result) {
          this.commonService.downloadFile(
            response.File,
            "PlantillaBase",
            'application/octet-stream',
            'xlsx');
        } else {
          this.alertService.errorAlert(response.Error.Message);
        }
      }, err => {
        this.blockUI.stop();
        this.alertService.errorAlert(err);
      });
  }

  FormatTemplateStatus(_status: string): string {
    switch (_status) {
      case '':
        return 'Todos';
      case 'P':
        return 'Pendiente';
      case 'R':
        return 'Procesando';
      case 'C':
        return 'Completado';
      case 'E':
        return 'Error';
      default:
        return 'Desconocido';
    }
  }

  FormatApInvoiceTemplateStatus(_status: string): string {
    switch (_status) {
      case 'P':
        return 'Pendiente';
      case 'R':
        return 'Renderizando';
      case 'C':
        return 'Completado';
      case 'E':
        return 'Error';
      default:
        return 'Desconocido';
    }
  }
}
