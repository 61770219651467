import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {
  IInventoryTransferRequest,
  IInventoryTransferRequestStates,
  IMinifiedInventoryTransferRequest, IMinifiedStockTransfer
} from "../models/i-inventory-transfer-request";
import {Observable} from "rxjs";
import {IResponse} from "../models/i-api-response";
import {formatDate} from "@angular/common";
import {IBaseResponse, IPagedRequestResponse} from "../models/responses";

@Injectable({
  providedIn: 'root'
})
export class InventoryTransferRequestsService {

  constructor(private http: HttpClient) { }

  /**
   * Send a POST request with the inventory transfer document information to create it on SAP.
   * @param _document Inventory transfer information
   * @constructor
   */
  Post(_document: IInventoryTransferRequest): Observable<IResponse<IInventoryTransferRequest>>
  {
    return this.http.post<IResponse<IInventoryTransferRequest>>('api/InventoryTransferRequests', _document);
  }

  /**
   * Send a PATCH request with the inventory transfer document information to update it on SAP.
   * @param _document Inventory transfer information
   * @constructor
   */
  Patch(_document: IInventoryTransferRequest): Observable<IBaseResponse>
  {
    return this.http.patch<IBaseResponse>('api/InventoryTransferRequests', _document);
  }

  /**
   * Send a GET request to retrieve the inventory transfer request states
   * @constructor
   */
  GetStates(): Observable<IResponse<IInventoryTransferRequestStates[]>>
  {
    return this.http.get<IResponse<IInventoryTransferRequestStates[]>>('api/InventoryTransferRequests/States');
  }

  /**
   * Send a GET request to retrieve the minified inventory transfer request
   * @param _docNum Number of the inventory transfer request (Has priority over other filters)
   * @param _dateFrom Date to start retrieving records
   * @param _dateTo Date to finish to retrieve records
   * @param _headquarter Headquarter where the document was created
   * @param _status Status of the documents to retrieve
   * @param _take Quantity of records to retrieve
   * @param _skip Quantity of records to ignore
   * @param pCreationType Indicates if should request only the transfer request created automatically, manually or both
   * @constructor
   */
  Get(_docNum: number, _dateFrom: Date, _dateTo: Date, _headquarter: string, _status: string, _take: number, _skip: number, pCreationType: string): Observable<IResponse<IPagedRequestResponse<IMinifiedInventoryTransferRequest[]>>>
  {
    return this.http.get<IResponse<IPagedRequestResponse<IMinifiedInventoryTransferRequest[]>>>('api/InventoryTransferRequests', {
      params: {
        docNum: _docNum.toString(),
        dateFrom: formatDate(_dateFrom, 'yyyy-MM-dd', 'en'),
        dateTo: formatDate(_dateTo, 'yyyy-MM-dd', 'en'),
        headquarter: _headquarter,
        status: _status,
        take: _take.toString(),
        skip: _skip.toString(),
        creationType: pCreationType
      }
    })
  }

  /**
   * Send a GET request to retrieve all information of an inventory transfer request
   * @param _docEntry Unique identifier of the inventory transfer request
   * @constructor
   */
  GetInventoryTransferRequest(_docEntry: number): Observable<IResponse<IInventoryTransferRequest>>
  {
    return this.http.get<IResponse<IInventoryTransferRequest>>(`api/InventoryTransferRequests/${_docEntry}`);
  }

  /**
   * Retrieve the stock transfer created from the specified transfer request
   * @param pTransferRequestDocEntry Transfer request unique identifier
   * @constructor
   */
  GetStockTransfersOfTransferRequest(pTransferRequestDocEntry: number): Observable<IResponse<IMinifiedStockTransfer[]>>
  {
    return this.http.get<IResponse<IMinifiedStockTransfer[]>>(`api/InventoryTransferRequests/${pTransferRequestDocEntry}/StockTransfers`);
  }
}
