import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {IResponse} from "../models/i-api-response";
import {StorageService} from "./storage.service";
import {AppConstants} from "../models";
import {ITappUserSetting} from "../models/i-tapp-user-setting";

@Injectable({
  providedIn: 'root'
})
export class TappService {

  constructor(
    private http: HttpClient,
    private storageSVC: StorageService
  ) {

  }

  Post(_identification: string, _bridgeId: string, _invoiceId: string, _invoiceDate: string, _userId: string, _invoiceAmount: number, _redeenedPoints, _products: any[], _rewards: number[]): Observable<IResponse<string>> {
    const body = {
      identification: _identification,
      tapp_bridge_id: _bridgeId,
      invoice_id: _invoiceId,
      invoiceDate: _invoiceDate,
      pos_user_id: _userId,
      invoice_amount: _invoiceAmount,
      redeemed_points: _redeenedPoints,
      products: _products,
      rewards_given: _rewards
    };

    const token = JSON.parse(this.storageSVC.getCurrentSession());

    const url = `${AppConstants.apiUrl}api/Tapp`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.post<IResponse<string>>(url, body, {headers});
  }

  Get(_bridgeId: string, _invoiceId: string, _invoiceDate: string, _identification: string, _userId: string): Observable<IResponse<string>> {
    const token = JSON.parse(this.storageSVC.getCurrentSession());

    const url = `${AppConstants.apiUrl}api/Tapp?bridgeId=${_bridgeId}&invoiceId=${_invoiceId}&invoiceDate=${_invoiceDate}&identification=${_identification}&userId=${_userId}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.get<IResponse<string>>(url, {headers});
  }

  GetTappUserSettings(_userId: string): Observable<IResponse<ITappUserSetting[]>>{
    const token = JSON.parse(this.storageSVC.getCurrentSession());

    const url = `${AppConstants.apiUrl}api/Tapp/GetTappUserSettings?userId=${encodeURIComponent(_userId)}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.get<IResponse<ITappUserSetting[]>>(url, {headers});
  }

  SaveTappUserSettings(_userId: string, _tappUserSettings: ITappUserSetting[]): Observable<IResponse<ITappUserSetting[]>>{
    const token = JSON.parse(this.storageSVC.getCurrentSession());

    const url = `${AppConstants.apiUrl}api/Tapp/SaveTappUserSettings?userId=${encodeURIComponent(_userId)}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.post<IResponse<ITappUserSetting[]>>(url, _tappUserSettings,{headers});
  }
}
