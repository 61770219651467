import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Globals } from 'src/app/globals';
import { IHeadquarter } from 'src/app/models/i-headquarter';
import { AlertService, AuthenticationService, PermsService } from 'src/app/services';
import { HeadquarterService } from 'src/app/services/headquarter.service';

@Component({
  selector: 'app-headquarters-by-user',
  templateUrl: './headquarters-by-user.component.html',
  styleUrls: ['./headquarters-by-user.component.scss']
})
export class HeadquartersByUserComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  userList: any[];
  headquartersAssign: IHeadquarter[];
  headquartersAvailable: IHeadquarter[];
  rdButons: FormGroup;
  user: string;
  currentUser: any;
  currentUserSubscription: Subscription;
  permisos: boolean  = true;

  constructor( 
    private permsService: PermsService,
    private headquarterService: HeadquarterService, 
    private formBuilder: FormBuilder, 
    private alertService: AlertService, 
    private authenticationService: AuthenticationService,
    public globals: Globals ) {
      this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
      }
    );
  }

  ngOnInit(): void {
    this.userList = [];
    this.headquartersAssign = [];
    this.headquartersAvailable = [];
	  this.checkPermits();
    this.chargeUser();
    this.rdButons = this.formBuilder.group({
      rControlQuitar: ['']
    });
  }

  checkPermits(): void {
	  this.permsService.getPerms(this.currentUser.userId).pipe(finalize(()=>this.blockUI.stop())).subscribe((data: any) => {
		  if (data.Result) {
        data.perms.forEach(Perm => {
          if(Perm.Name === "V_Headquarters_Assign"){
            this.permisos = Perm.Active;
          }
        });
		  } else {
        this.alertService.errorAlert(`Error al cargar los permisos, Error: ${data.errorInfo.Message}`);
			  this.permisos = false;
		  }
		}, error => {
      this.alertService.errorAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
		  this.permisos = false;
		});
  }

  chargeUser(): void {
    this.blockUI.start('Cargando listas de usuarios...');
    this.headquarterService.GetUsers().pipe(finalize(()=>this.blockUI.stop())).subscribe((result: any) => {
      if (result.Result) {
        result.Data.forEach(User => {
          if (User.Active) {
            this.userList.push(User);
          }
        });
        this.LoadHeadquarters(this.userList[0].Id);
      } else {
        this.alertService.errorAlert('Error al cargar la lista de usuarios - ' + result. errorInfo.Message);
      }
    }, error => {
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  LoadHeadquarters(_userId: string): void {
    this.user = _userId;
    this.headquartersAssign = [];
    this.headquartersAvailable = [];
    this.blockUI.start('Cargando listas de sedes...');
    this.headquarterService.GetHeadquarters().pipe(finalize(()=>this.blockUI.stop())).subscribe(callBackFirst => {
      if (callBackFirst.Result) {
        this.headquartersAvailable = [];
        this.headquarterService.GetHeadquartersByUser(_userId).pipe(finalize(()=>this.blockUI.stop())).subscribe(callBackSecod => {
          if (callBackSecod.Result) {
            this.headquartersAssign = [];
            this.headquartersAssign = callBackSecod.Data.filter(x => x.Status === 'A');
            this.headquartersAvailable = callBackFirst.Data.filter(x => x.Status === 'A' && !this.headquartersAssign.some(y => x.Id === y.Id));
          } else {
            this.headquartersAssign = [];
            this.alertService.errorInfoAlert('Error al cargar la lista de sedes - ' + callBackSecod.Error.Message);
          }
        }, errorSecod => {
          this.headquartersAssign = [];
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${errorSecod}`);
        });
      } else {
        this.headquartersAvailable = [];
        this.alertService.errorInfoAlert('Error al cargar la lista de sedes - ' + callBackFirst.Error.Message);
      }
    }, errorFirst => {
      this.headquartersAvailable = [];
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${errorFirst}`);
    });
  }

  AddAll(): void {
    this.headquartersAssign = this.headquartersAssign.concat(this.headquartersAvailable);
    this.headquartersAvailable = [];
  }

  Add(headquarter: IHeadquarter): void {
    let removed = this.headquartersAvailable.splice(this.headquartersAvailable.findIndex(x => x.Id === headquarter.Id), 1);
    if(removed && removed.length > 0) {
      this.headquartersAssign.push(removed[0]);
    }
  }

  DeleteAll(): void {
    this.headquartersAvailable = this.headquartersAvailable.concat(this.headquartersAssign);
    this.headquartersAssign = [];
  }

  Delete(headquarter: IHeadquarter): void {
    let removed = this.headquartersAssign.splice(this.headquartersAssign.findIndex(x => x.Id === headquarter.Id), 1);
    if(removed && removed.length > 0) {
      this.headquartersAvailable.push(removed[0]);
    }
  }

  SaveChanges(): void {
    this.blockUI.start('Guardando cambios, por favor espere...');
    this.headquarterService.SaveHeadquartersByUser(this.user, this.headquartersAssign).subscribe( (data: any) => {
      this.blockUI.stop();
      if (data.Result) {
        this.alertService.successInfoAlert('Se guardaron correctamente los cambios');
      } else {
        this.alertService.errorAlert('Error al intentar guardar los cambios - error: ' + data. errorInfo.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`); 
    });
    this.rdButons.controls.rControlQuitar.reset();
  }

}

