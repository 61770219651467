import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {IInventoryCounting} from "../../../models/i-inventory-counting";
import {formatDate} from "@angular/common";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {InventoryCountingService} from "../../../services/inventory-counting.service";
import {finalize} from "rxjs/operators";
import {AlertService, AuthenticationService, PermsService} from "../../../services";
import {Router} from "@angular/router";
import {ISession} from "../../../models/i-token";
import {forkJoin} from "rxjs";

@Component({
  selector: 'app-search-inventory-countings',
  templateUrl: './search-inventory-countings.component.html',
  styleUrls: ['./search-inventory-countings.component.scss']
})
export class SearchInventoryCountingsComponent implements OnInit {

  /**
   * Overlay that block the UI while a process it been executed
   */
  @BlockUI() blockUI: NgBlockUI;

  /**
   * Indicates if the user has permission to view the content of this page
   */
  canAccessToView: boolean;

  /**
   * Represent the values that the user input to filter the inventory countings
   */
  searchForm: FormGroup;

  /**
   * Represent the list of inventory countings that match with the specified filters
   */
  inventoryCountings: IInventoryCounting[];

  /**
   * Represent the list of inventory countings that will be displayed in the table
   */
  filterInventoryCountings: IInventoryCounting[];

  /**
   * Represent the current page of the table
   */
  tablePage: number;

  /**
   * Represent the session information of the current user
   */
  currentUser: ISession;
  constructor(private formBuilder: FormBuilder,
              private inventoryCountingsService: InventoryCountingService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private permissionService: PermsService,
              private alertService: AlertService) {
    this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.InitVariables();
    this.SendInitialRequests();
  }

  /**
   * Initialize the variables of component
   * @constructor
   */
  private InitVariables(): void
  {
    this.canAccessToView = false;

    this.tablePage = 1;

    this.inventoryCountings = [];

    this.filterInventoryCountings = [];

    let date = new Date();

    this.searchForm = this.formBuilder.group({
      DocNum: [null],
      DateFrom: [{ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }],
      DateTo: [{ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }],
      DocumentStatus: ['all'],
    });
  }


  /**
   * Send the initial request to set up the component
   * @constructor
   */
  private SendInitialRequests(): void
  {
    let {DocNum, DateFrom, DateTo, DocumentStatus} = this.searchForm.value;

    let dateFrom = new Date(DateFrom.year, DateFrom.month - 1, DateFrom.day);

    let dateTo = new Date(DateTo.year, DateTo.month - 1, DateTo.day);

    this.blockUI.start("Cargando...");

    forkJoin([
      this.inventoryCountingsService.GetInventoryCountings(DocNum || -1, dateFrom, dateTo, DocumentStatus),
      this.permissionService.getPerms(this.currentUser.userId)
    ])
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe(responses => {
        if(responses[0])
        {
          this.inventoryCountings = responses[0].Data ? responses[0].Data : [];
          this.FilterInventoryCountings();
        }

        if(responses[1].Result)
        {
          this.canAccessToView = responses[1].perms.some(p => p.Active && p.Name == 'Inventory_SearchCountings_Access');
        }
      }, error => {
        this.alertService.ShowAlert("error", {
          text: error
        });

        console.error(error);
      });

  }



  /**
   * Table row edit event handler
   * @param pInventoryCounting Inventory counting associated with the table row
   * @constructor
   */
  OnClickEditInventoryCounting(pInventoryCounting: IInventoryCounting): void
  {
    this.router.navigateByUrl(`inventory/countings/${pInventoryCounting.DocumentEntry}/edit`);
  }

  /**
   * Filter the inventory countings that should be displayed in the page
   * @constructor
   */
  OnInventoryCountingsTablePageChange(): void
  {
    this.FilterInventoryCountings();
  }

  /**
   * Send a request to retrieve the inventory countings that match with the specified filters
   * @constructor
   */
  SearchInventoryCountings(): void
  {
    let {DocNum, DateFrom, DateTo, DocumentStatus} = this.searchForm.value;

    let dateFrom = new Date(DateFrom.year, DateFrom.month - 1, DateFrom.day);

    let dateTo = new Date(DateTo.year, DateTo.month - 1, DateTo.day);

    this.blockUI.start();

    this.inventoryCountingsService.GetInventoryCountings(DocNum || -1, dateFrom, dateTo, DocumentStatus)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (response) => {
          this.inventoryCountings = response.Data ? response.Data : [];
          this.FilterInventoryCountings();
        },
        error: (error) => {
          this.alertService.ShowAlert("error", {
            text: error
          });

          console.error(error);
        }
      });
  }

  /**
   * Filter the inventory countings to show in the table
   * @constructor
   */
  private FilterInventoryCountings(): void
  {
    this.filterInventoryCountings = [...this.inventoryCountings].slice(this.tablePage - 1, 10);

    let skip = 10 * (this.tablePage - 1);

    this.filterInventoryCountings = [...this.inventoryCountings].slice(skip, skip + 10);
  }

  /**
   * Reset the fields and table rows
   * @constructor
   */
  CleanFields(): void
  {
    this.tablePage = 1;

    this.inventoryCountings = [];

    this.filterInventoryCountings = [];

    let date = new Date();

    this.searchForm = this.formBuilder.group({
      DocNum: [null],
      DateFrom: [{ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }],
      DateTo: [{ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }],
      DocumentStatus: ['all'],
    });

    this.SearchInventoryCountings();
  }
}
