import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import swal, { SweetAlertOptions, SweetAlertResult, SweetAlertType } from 'sweetalert2';
import { AppConstants } from '../models/constantes';
import {formatDate} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;

  constructor(router: Router) {
      // clear alert message on route change
      router.events.subscribe(event => {
          if (event instanceof NavigationStart) {
              if (this.keepAfterNavigationChange) {
                  // only keep for a single location change
                  this.keepAfterNavigationChange = false;
              } else {
                  // clear alert
                  this.subject.next();
              }
          }
      });
  }

  // llama la funcion de alert con estado success
  // recibe el mensaje que se quiere mostrar
  successAlert(message: string) {
    this.AlertaErrorMessage(message, 'success');
  }

  InfoAlertHtml(html: string, tittle:string) {
    swal({
      title:tittle,
      type: 'info',
      html: html,
      confirmButtonText: `Continuar`
    });
  }

  /**
   * Show a modal alert with the specified configurations
   * @param _type Type of the modal
   * @param _args Messages to show in the modal
   * @param _fieldsToShow Key value object to show in the modal
   * @constructor
   */
  async ShowAlert(_type: 'success' | 'info' | 'error' | 'warning' | 'question', _args: {
    text?: string;
    title?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    cancelButtonColor?: string;
    confirmButtonColor?: string;
  }, _fieldsToShow?: {[key:string]:string | undefined}): Promise<SweetAlertResult>
  {

    let htmlContent = `<h6 style="text-align: center;">${_args.text}</h6>`;

    if(_fieldsToShow)
    {
      htmlContent += '<div class="d-flex justify-content-start align-items-center flex-column" style="font-size: 0.9rem;">';

      for (const key in _fieldsToShow)
      {
        if(_fieldsToShow[key] == undefined) continue;

        htmlContent += `<div><span class="font-weight-bold mr-1">${key}:</span><span>${_fieldsToShow[key]}</span></div>`
      }

      htmlContent += '</div>';

      htmlContent += `
        <div style="margin-top: 8px; text-align: center; font-size: small; color: rgba(0,0,0,.5); font-weight: 500;">
            ${formatDate(new Date(), 'yyyy-MM-dd hh:mm:ss a', 'en')}
        </div>
      `
    }

    return await swal({
      type: _type,
      text: _args.text,
      title: _args.title,
      confirmButtonText: _args.confirmButtonText || 'Aceptar',
      cancelButtonText: _args.cancelButtonText,
      showCancelButton: !!_args.cancelButtonText,
      cancelButtonColor: _args.cancelButtonColor,
      confirmButtonColor: _args.confirmButtonColor,
      html: htmlContent
    });
  }

  // llama la funcion de alert con estado success
  // recibe el mensaje que se quiere mostrar
  successAlertHtml(message: string) {
    this.AlertaErrorMessageHtml(message, 'success');
  }
  // llama la funcion del toast con estado success
  // recibe el mensaje que se quiere mostrar
  successInfoAlert(message: string) {
    this.AlertMessage(message, 'success');
  }
  // llama la funcion de alert con estado error
  // recibe el mensaje que se quiere mostrar
  errorAlert(message: string) {
    this.AlertaErrorMessage(message, 'error');
  }
  // llama la funcion del toast con estado error
  // recibe el mensaje que se quiere mostrar
  errorInfoAlert(message: string) {
    this.AlertMessage(message, 'error');
  }

  // llama la funcion de alert con estado info
  // recibe el mensaje que se quiere mostrar
  infoAlert(message: string) {
    this.AlertaErrorMessage(message, 'info');
  }
  // llama la funcion del toast con estado info
  // recibe el mensaje que se quiere mostrar
  infoInfoAlert(message: string) {
    this.AlertMessage(message, 'info');
  }

  // llama la funcion de alert con estado warning
  // recibe el mensaje que se quiere mostrar
  warningAlert(message: string) {
    this.AlertaErrorMessage(message, 'warning');
  }
  // llama la funcion del toast con estado warning
  // recibe el mensaje que se quiere mostrar
  warningInfoAlert(message: string) {
    this.AlertMessage(message, 'warning');
  }

  getMessage(): Observable<any> {
      return this.subject.asObservable();
  }

  // muestra un toad en la parte superior de la pantalla informado sobre un determinado mensaje.
  // recibe el mensaje que se quiere mostrar y el tipo de mensaje, ya sea success, error, warning, info
  private AlertMessage(msn: any, tipo: any) {
    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 10000,
      confirmButtonText: `Continuar`
    });
    toast({
      type: tipo,
      title: msn
    });
  }

  // muestra el mensaje de alerta  en el centro de la pagina
  // recibe el mensaje que se quiere mostrar y el tipo de mensaje, ya sea success, error, warning, info
  private AlertaErrorMessage(_message: any, tipo: any) {
    let html = ``;

    let settings: SweetAlertOptions = {
    showCancelButton: false,
    confirmButtonText: 'Continuar',
    type: tipo
    };

    if (_message) {
    html += `<h6 style="text-align: center;">${_message}</h6>`;
    }

    html += ` <p style="text-align: center;"><span style="font-size: 12px;">${AppConstants.GetTime()}</span></p>`

    if (html) {
    settings.html = html;
    }

    swal(settings);
  }

  // muestra el mensaje de alerta  en el centro de la pagina
  // recibe el mensaje que se quiere mostrar y el tipo de mensaje, ya sea success, error, warning, info
  private AlertaErrorMessageHtml(msn: any, tipo: any) {
    swal({
      type: tipo,
      html: msn,
      confirmButtonText: `Continuar`
    });
  }

  ConfirmationAlert(titulo: string, msg: string, confirmButtonText: string) {
    let settings: SweetAlertOptions = {
      title: titulo,
      text: msg,
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#dc3545',
      confirmButtonText: confirmButtonText,
      cancelButtonText: 'Cancelar',
      type: 'question'
    };

    return swal(settings).then((result) => {
      return result.value;
    });
  }

  ContinueAlert(_title: string = ``, _message: string = ``, _type: SweetAlertType = 'success') {

    let html = ``;

    let settings: SweetAlertOptions = {
      showCancelButton: false,
      confirmButtonText: 'Continuar',
      type: _type
    };

    if (_title) {
     html += `<h2 style="text-align: center;">${_title}&nbsp;</h2>`;

     if (_message) html += `<hr>`;
    }

    if (_message) {
      html += `<h4 style="text-align: center;">${_message}</h4>`;
    }

    html += ` <p style="text-align: center;"><span style="font-size: 12px;">${AppConstants.GetTime()}</span></p><hr>`

    if (html) {
      settings.html = html;
    }

    return swal(settings).then((result) => {
      return result.value;
    });
  }

  Continue(_message: string, _type: SweetAlertType = 'success') {

    let html = ``;

    let settings: SweetAlertOptions = {
      showCancelButton: false,
      confirmButtonText: 'Continuar',
      type: _type
    };

    if (_message) {
      html += `<h5 style="text-align: center;">${_message}</h5>`;
    }

    html += ` <p style="text-align: center;"><span style="font-size: 13px;">${AppConstants.GetTime()}</span></p><hr>`

    if (html) {
      settings.html = html;
    }

    return swal(settings).then((result) => {
      return result.value;
    });
  }

  PromptedAlert(_message: string = ``, _type: SweetAlertType = 'success') {

    let settings: SweetAlertOptions = {
      title: ``,
      text: _message,
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#dc3545',
      confirmButtonText: `Continuar`,
      cancelButtonText: 'Cancelar',
      type: _type
    };

    let html = `<h5 style="text-align: center;">${_message}</h5><hr>`;

    settings.html = html;

    return swal(settings).then((result) => {
      return result.value;
    });
  }

  PromptedTitleddAlert(_title: string, _message: string = ``, _type: SweetAlertType = 'success') {

    let settings: SweetAlertOptions = {
      title: _title,
      text: _message,
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#dc3545',
      confirmButtonText: `Continuar`,
      cancelButtonText: 'Cancelar',
      type: _type
    };

    let html = `<h5 style="text-align: center;">${_message}</h5><hr>`;

    settings.html = html;

    return swal(settings).then((result) => {
      return result.value;
    });
  }
}
