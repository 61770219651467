import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {IMeasurementUnit} from "../models/i-measurement-unit";
import {IResponse} from "../models/i-api-response";
import {IService} from "../models/i-service";
import {IPagedRequestResponse} from "../models/responses";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MeasurementUnitsService implements  IService{

  constructor(private http: HttpClient) { }

  Get(pItemCode: string): Observable<IResponse<IPagedRequestResponse<IMeasurementUnit[]>>>
  {
    return this.http.get<IResponse<IMeasurementUnit[]>>(`api/Items/${pItemCode}/MeasurementUnits`)
      .pipe(
        map((response) => ({
          Data: {
            Data: response.Data,
            RecordsCount: response.Data.length
          },
          Result: response.Result,
          Error: response.Error
        } as IResponse<IPagedRequestResponse<IMeasurementUnit[]>>))
      )
  }
}
