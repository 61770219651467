import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable()
export class UrlInterceptor implements HttpInterceptor
{
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(req.url.includes('http') || req.url.includes('assets'))
    {
      return next.handle(req);
    }

    let clonedRequest = req.clone({url: `${environment.apiUrl}${req.url}`});

    return next.handle(clonedRequest);
  }
}
