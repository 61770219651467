import {Component, Input, OnInit} from '@angular/core';
import {ISalesForceCustomerInformationResponse} from "../../models/i-sales-force";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup} from "@angular/forms";
import {BlockUI, NgBlockUI} from "ng-block-ui";

@Component({
  selector: 'app-customer-sales-force-info-modal',
  templateUrl: './customer-sales-force-info-modal.component.html',
  styleUrls: ['./customer-sales-force-info-modal.component.scss']
})
export class CustomerSalesForceInfoModalComponent implements OnInit {

  /**
   * Represent the component that will block the UI when a request is in process
   */
  @BlockUI("SalesForceModalBlockUI") blockUI: NgBlockUI;

  /**
   * Represent the sales force information of the customer that will be shown
   */
  @Input() customerSalesForceInformation!: ISalesForceCustomerInformationResponse;

  /**
   * Represent the supplied customer identification number
   */
  @Input() customerIdentificationNumber!: string;

  /**
   * Represent the form with the sales force information of the customer
   */
  private salesForceInformationForm!: FormGroup;

  /**
   * Indicates if the showed information if of a beneficiary
   */
  isBeneficiary: boolean;

  constructor(private activeModal: NgbActiveModal,
              private formBuilder: FormBuilder)
  {
    this.InitVariables();
  }

  ngOnInit() {
    this.FillSalesInformationForm();
  }

  /**
   * Represent the form with the sales force information of the customer
   * @constructor
   */
  get SalesForceInformationForm(): FormGroup
  {
    return this.salesForceInformationForm;
  }

  /**
   * Initialize all component variables
   * @constructor
   * @private
   */
  private InitVariables(): void
  {
    this.salesForceInformationForm = this.formBuilder.group({
      CoverageType: [{value: '', disabled: true}],
      PhoneNumber: [{value: '', disabled: true}],
      MemberState: [{value: '', disabled: true}],
      TitularName: [{value: '', disabled: true}],
      Name: [{value: '', disabled: true}],
      ClientNumber: [{value: '', disabled: true}],
      BeneficiaryNumber: [{value: '', disabled: true}],
      BeneficiaryEmail: [{value: '', disabled: true}],
      IdentificationNumber: [{value: '', disabled: true}],
      ClientEmail: [{value: '', disabled: true}]
    });

    this.isBeneficiary = false;
  }

  /**
   * Fill the sales force information form
   * @constructor
   * @private
   */
  private FillSalesInformationForm(): void
  {
    const salesForceInformationFromAccountResults = this.customerSalesForceInformation.accountResults.find(slInfo => slInfo.cedula === this.customerIdentificationNumber);

    if(salesForceInformationFromAccountResults)
    {
      this.salesForceInformationForm.patchValue({
        CoverageType: salesForceInformationFromAccountResults.tipoCoberutura,
        PhoneNumber: salesForceInformationFromAccountResults.telefono,
        MemberState: salesForceInformationFromAccountResults.estado,
        Name: salesForceInformationFromAccountResults.nombre,
        ClientNumber: salesForceInformationFromAccountResults.numeroCliente,
        IdentificationNumber: salesForceInformationFromAccountResults.cedula,
        ClientEmail: salesForceInformationFromAccountResults.correo
      });

      this.isBeneficiary = false;

      return;
    }

    const salesForceInformationFromBenResults = this.customerSalesForceInformation.benResults.find(slInfo => slInfo.cedula === this.customerIdentificationNumber);

    if(salesForceInformationFromBenResults)
    {
      this.salesForceInformationForm.patchValue({
        CoverageType: salesForceInformationFromBenResults.tipoCoberutura,
        PhoneNumber: salesForceInformationFromBenResults.telefonoBen,
        MemberState: salesForceInformationFromBenResults.estado,
        TitularName: salesForceInformationFromBenResults.nombreTitular,
        Name: salesForceInformationFromBenResults.nombre,
        ClientNumber: salesForceInformationFromBenResults.numeroCliente,
        IdentificationNumber: salesForceInformationFromBenResults.cedula,
        BeneficiaryEmail: salesForceInformationFromBenResults.correoBen,
        BeneficiaryNumber: salesForceInformationFromBenResults.numeroBen
      });

      this.isBeneficiary = true;
    }
  }

  /**
   * Close the current modal
   * @constructor
   */
  Close(): void
  {
    this.activeModal.dismiss();
  }
}
