import { formatDate } from '@angular/common';
import { Component, DoCheck, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BlockUI } from 'ng-block-ui';
import { NgBlockUI } from 'ng-block-ui/models/block-ui.model';
import { Subscription } from 'rxjs/internal/Subscription';
import { finalize } from 'rxjs/operators';
import {AppConstants, ICommitedTransaction, IPPBalance, ITerminal} from 'src/app/models';
import { RequestDocumentType } from 'src/app/models/constantes';
import {IACQTransaction, IPPCashDeskClosing} from 'src/app/models/i-pp-transaction';
import { IPPBalanceRequest } from 'src/app/models/i-ppbalance-request';
import { IInvoicePaymentDetailResponse } from 'src/app/models/responses';
import { AlertService, AuthenticationService, BankService, PermsService } from 'src/app/services';
import swal from 'sweetalert2';
import { StorageService } from '../../../services/storage.service';

@Component({
  selector: 'app-terminals-balance',
  templateUrl: './terminals-balance.component.html',
  styleUrls: ['./terminals-balance.component.scss']
})
export class TerminalsBalanceComponent implements OnInit, DoCheck {
  //VARBOX
  terminal: ITerminal;

  terminals: ITerminal[];

  commitedTransactions: ICommitedTransaction[];

  balanceRequest: IPPBalanceRequest;

  @BlockUI() blockUI: NgBlockUI;

  currentUser: any; // variable para almacenar el usuario actual

  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual

  permisos: boolean = true;

  terminalTarget: ITerminal;

  terminalForm = new FormGroup({
    TerminalId: new FormControl(''),
    From: new FormControl(''),
    To: new FormControl('')
  });

  requestTypeForm: FormControl = new FormControl();

  constructor(private banksService: BankService
    , private alertService: AlertService
    , private renderer: Renderer2
    , private storageService:StorageService,
    private authenticationService: AuthenticationService,
    private permService: PermsService,) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngDoCheck(): void {
  }

  ngOnInit() {
    this.initVariables();
    this.getTerminals();
  }

  Balance(): void
  {
    this.commitedTransactions = [];

    let {TerminalId} = this.terminalForm.getRawValue();

    if (!TerminalId)
    {
      this.alertService.infoInfoAlert("Debe seleccionar una terminal.");

      return;
    }

    let cashDeskClosing: IPPCashDeskClosing = null;

    this.blockUI.start(`Generando cierre de tarjetas, espere por favor`);

    this.banksService.Balance(TerminalId)
      .pipe(
        finalize(() => {
          this.blockUI.stop();

          if(cashDeskClosing)
          {
            this.CreateCashDeskClosing(cashDeskClosing);
          }
        })
      )
      .subscribe(next => {
      try {
        if (next.Result)
        {
          cashDeskClosing = {
            SerializedTransaction: next.Data,
            Type: 'BA',
            TerminalId: TerminalId,
            IsApproved: next.Result,
            CreationDate: new Date()
          } as IPPCashDeskClosing;
        }
        else
        {
          this.alertService.errorAlert(`${AppConstants.GetError(next)}`);
        }
      }
      catch (error)
      {
        this.alertService.errorAlert(`${AppConstants.GetError(error)}`);
      }
    }, error => {
      this.alertService.errorAlert(`Error: ${JSON.stringify(error)}`);
    });
  }

  // Este viene de EMA CRMArine
  PreBalance(): void {
    this.commitedTransactions = [];

    let {TerminalId} = this.terminalForm.getRawValue();

    if (!TerminalId)
    {
      this.alertService.infoInfoAlert("Debe seleccionar una terminal.");

      return;
    }

    let cashDeskClosing: IPPCashDeskClosing = null;

    this.blockUI.start(`Generando precierre de tarjetas, espere por favor.`);

    this.banksService.PreBalance(TerminalId)
      .pipe(
        finalize(() => {
          this.blockUI.stop();

          if(cashDeskClosing)
          {
            this.CreateCashDeskClosing(cashDeskClosing);
          }
        })
      )
      .subscribe(next => {
        try {
          if (next.Result)
          {
            cashDeskClosing = {
              SerializedTransaction: next.Data,
              Type: 'PR',
              TerminalId: TerminalId,
              IsApproved: next.Result,
              CreationDate: new Date()
            } as IPPCashDeskClosing;
          }
          else
          {
            this.alertService.errorAlert(`${AppConstants.GetError(next)}`);
          }
        }
        catch (error)
        {
          console.error(error);

          this.alertService.errorAlert(`${AppConstants.GetError(error)}`);
        }
    }, error => {
      console.error(error);

      this.alertService.errorAlert(`No se pudo obtener el pre cierre, error: ${error}`);
    });

  }

  /**
   * Send a request to create a cash desk closing
   * @param _cashDeskClosing Information about the cash desk closing
   * @constructor
   */
  CreateCashDeskClosing(_cashDeskClosing: IPPCashDeskClosing): void {

    this.blockUI.start(`Procesando, espere por favor`);

    this.banksService.PPCashDeskClosing(_cashDeskClosing)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe(response => {
        try {
          if (response.Result)
          {
            this.commitedTransactions = response.Data;

            if ( this.commitedTransactions  && this.commitedTransactions.length > 0)
            {
              this.mapRequest();
            }
            else
            {
              this.alertService.infoInfoAlert(`No hay pre cierres realizados en el sistema`);
            }
          }
          else
          {
            this.alertService.errorAlert(`${AppConstants.GetError(response)}`);
          }
        }
        catch (errori) {
          console.info(errori);

          this.alertService.errorAlert(`${AppConstants.GetError(errori)}`);
        }
    }, error => {
      console.error(error);

      this.alertService.errorAlert(`${AppConstants.GetError(error)}`);
    });
  }



  GetCashDeskClosings(_requestType: string, _documentType: string): void
  {
    this.commitedTransactions = [];

    this.balanceRequest = this.terminalForm.value as IPPBalanceRequest;

    if(!this.balanceRequest.TerminalId)
    {
      this.alertService.infoInfoAlert("Debe seleccionar una terminal.");

      return;
    }

    this.blockUI.start(`Procesando, espere por favor.`);

    this.balanceRequest.DocumentType = _requestType;

    this.banksService.GetRequestsFromRegisters(this.balanceRequest)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (response) => {
          if (response.Result)
          {
            this.commitedTransactions = response.Data;

            this.alertService.successInfoAlert(`Operación terminada`);

            if (this.commitedTransactions && this.commitedTransactions.length > 0)
            {
              this.mapRequest();
            }
            else
            {
              this.alertService.infoInfoAlert(`No hay  ${_documentType} realizados en el sistema`);
            }
          }
          else
          {
            this.alertService.errorAlert(`${AppConstants.GetError(response)}}`);
          }
        },
        error: (error) => {
          this.alertService.errorAlert(`${AppConstants.GetError(error)}}`);
        }
      });
  }

  generate(): void {
    const REQUEST_TYPE = +this.requestTypeForm.value === 1 ? 'cierre' : 'pre cierre';
    swal({
      type: 'warning',
      title: 'Esta solicitud no puede ser cancelada',
      text: `¿ Desea generar el ${REQUEST_TYPE} ?`,
      showCancelButton: true,
      confirmButtonColor: '#049F0C',
      cancelButtonColor: '#ff0000',
      confirmButtonText: 'Sí',
      cancelButtonText: 'No'
    }).then(next => {
      console.log(Object.keys(next));
      if (!(Object.keys(next)[0] === 'dismiss')) {
        if (+this.requestTypeForm.value === 1) {
          this.Balance();
        }
        else {
          this.PreBalance();
        }
      }
    }, (dismiss) => { });
  }

  getRequest(): void {
    let documentType = 'pre cierres';
    let requestType = RequestDocumentType[RequestDocumentType.PRE_BALANCE];

    if (+this.requestTypeForm.value === 1) {
      documentType = 'cierres';
      requestType = RequestDocumentType[RequestDocumentType.BALANCE];
    }

    this.GetCashDeskClosings(requestType, documentType);

  }

  mapRequest(): void
  {
    this.commitedTransactions.forEach(x => {
      try {
        x.HostDate = this.convertDate(x.HostDate);
      } catch (error) {
        this.alertService.infoAlert(`No se pudo convertir la fecha del servidor`);
      }
    });

    let root = 0;
    let updatedValues = [];
    for (let c = 0; c < this.commitedTransactions.length; c++) {
      for (let y = 0; y < this.commitedTransactions.length; y++) {
        if ((this.commitedTransactions[c].ACQ === this.commitedTransactions[y].ACQ) && !updatedValues.find(u => u === this.commitedTransactions[c].ACQ)) {
          updatedValues.push(this.commitedTransactions[c].ACQ);
          let lastNode = {} as ICommitedTransaction;
          let totalTransactions = 0;
          if ((root % 2) === 0) {
            this.commitedTransactions.forEach(x => {
              if (x.ACQ === this.commitedTransactions[c].ACQ) {
                x.BlurredBackground = 'Y';
                x.TotalTransactions = 0;
                lastNode = x;
                totalTransactions += +(+x.SalesAmount).toFixed(2);
              }
            });
          }
          else {
            this.commitedTransactions.forEach(x => {
              if (x.ACQ === this.commitedTransactions[c].ACQ) {
                x.BlurredBackground = 'N';
                x.TotalTransactions = 0;
                lastNode = x;
                totalTransactions += +x.SalesAmount;
              }
            });
          }

          let TMP = this.commitedTransactions.find(x => x.ACQ === lastNode.ACQ); //.TotalTransactions = totalTransactions;
          let MITEM = { ...TMP };
          MITEM.AuthorizationNumber = '';
          MITEM.TerminalCode = '';
          MITEM.ReferenceNumber = '';
          MITEM.TransactionType = '';
          MITEM.ACQ = -1;
          MITEM.CreationDate = '';
          MITEM.InvoiceNumber = 'TOTAL';
          MITEM.SalesAmount = totalTransactions.toString();

          this.commitedTransactions.splice(this.commitedTransactions.findIndex(x => x.ACQ === lastNode.ACQ), 0,
            { ...MITEM });
          root++;
        }
      }
    }

    this.commitedTransactions = this.commitedTransactions.reverse();
  }

  getTerminals(): void {
    this.terminals = this.storageService.GetPPTerminalsbyUser();
  }

  convertDate(strA: string) {
    return strA.slice(0, 2) + '/' + strA.slice(2, 4) + '/' + strA.slice(4);
  }

  initVariables(): void {
    this.CheckPermits();
    this.resetTerminalForm();
    this.terminalTarget = null;
    this.commitedTransactions = [];
    this.getTerminals();
  }

  resetTerminalForm(): void {
    const DATE = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.terminalForm.patchValue({
      TerminalId: -1,
      From: DATE,
      To: DATE
    });

    this.requestTypeForm.setValue('0');
  }
  // Verifica si el usuario tiene permiso para acceder a la pagina
  CheckPermits() {
    this.permService.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.Result) {
        data.perms.forEach(Perm => {
          if (Perm.Name === 'V_CloseCardPP') {
            this.permisos = Perm.Active;
          }
        });
      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
  }
}
