import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {IBaseResponse, IPagedRequestResponse} from "../models/responses";
import {ICategorySpecialty} from "../models/i-category-specialty";
import {BaseResponse, IKValue} from "../models";
import {IResponse} from "../models/i-api-response";

@Injectable({
  providedIn: 'root'
})
export class CategoriesSpecialtyService {

  constructor(private http: HttpClient) { }

  /**
   * Send a request to retrieve the categories by specialty that match with the specified search criteria
   * @param pTake Quantity of records that will be retrieved
   * @param pSkip Quantity of records that will be ignored
   * @param pSearchCriteria Search criteria what records must match
   * @constructor
   */
  GetPaged(pTake: number, pSkip: number, pSearchCriteria: string): Observable<IPagedRequestResponse<ICategorySpecialty[]>>
  {
    return this.http.get<IPagedRequestResponse<ICategorySpecialty[]>>('api/CategoriesBySpecialty', {
      params: {
        take: pTake.toString(),
        skip: pSkip.toString(),
        searchCriteria: pSearchCriteria
      }
    });
  }

  /**
   * Send a request to retrieve all active categories by specialty
   * @constructor
   */
  GetAllActive(): Observable<IResponse<ICategorySpecialty[]>>
  {
    return this.http.get<IResponse<ICategorySpecialty[]>>('api/CategoriesBySpecialty');
  }

  /**
   * Send a request to retrieve a category by specialty that match with the specified search criteria
   * @param pCategoryCode Code of the category specialty that will be retrieved
   * @constructor
   */
  GetCategorySpecialty(pCategoryCode: string): Observable<IPagedRequestResponse<ICategorySpecialty[]>>
  {
    return this.http.get<IPagedRequestResponse<ICategorySpecialty[]>>(`api/CategoriesBySpecialty/${pCategoryCode}`);
  }


  /**
   * Send a request to create a category by specialty
   * @param pCategoryBySpecialty Category by specialty information that will be created
   * @constructor
   */
  Post(pCategoryBySpecialty: ICategorySpecialty): Observable<IBaseResponse>
  {
    return this.http.post<IBaseResponse>('api/CategoriesBySpecialty', pCategoryBySpecialty);
  }

  /**
   * Send a request to update a category by specialty
   * @param pCategoryBySpecialty Category by specialty information that will be updated
   * @param pCode Code of the category by specialty that will be updated
   * @constructor
   */
  Patch(pCategoryBySpecialty: ICategorySpecialty, pCode: string): Observable<IBaseResponse>
  {
    return this.http.patch<IBaseResponse>(`api/CategoriesBySpecialty/${pCode}`, pCategoryBySpecialty);
  }

  /**
   * Send a request to retrieve the categories
   * @constructor
   */
  GetCategories(): Observable<IResponse<IKValue[]>>
  {
    return this.http.get<IResponse<IKValue[]>>('api/CategoriesBySpecialty/Categories');
  }


  /**
   * Send a request to retrieve the families (specialties)
   * @constructor
   */
  GetFamilies(): Observable<IResponse<IKValue[]>>
  {
    return this.http.get<IResponse<IKValue[]>>('api/CategoriesBySpecialty/Families');
  }


  /**
   * Send a request to retrieve the sub-families (Items groups)
   * @constructor
   */
  GetSubFamilies(): Observable<IResponse<IKValue[]>>
  {
    return this.http.get<IResponse<IKValue[]>>('api/CategoriesBySpecialty/SubFamilies');
  }
}
