import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild
} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {EMPTY, forkJoin, merge, Observable, of, OperatorFunction, Subject, Subscription, throwError} from "rxjs";
import {
  catchError,
  concatMap,
  debounceTime, delay,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  tap
} from "rxjs/operators";
import {CurrencyPipe, DatePipe, DecimalPipe, DOCUMENT, formatDate} from "@angular/common";
import {ModalDismissReasons, NgbModal, NgbModalOptions, NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import swal from 'sweetalert2';
// MODELOS
import {
  Company,
  CONFIG_VIEW,
  IdentificationType,
  IDocumentLine,
  IDocumentToSync,
  IInvoiceType,
  IKValue,
  IMappedPayment,
  IModalStatus,
  IPatient,
  IPermission,
  IPPTransaction,
  ITerminal,
  ITerminalByUser,
  IUdf,
  IUdfTarget,
  IViewGroup,
  Params,
  PayTermsEnum,
  settings,
  UserAssigns
} from "./../../../models/index";
import {
  BaseTypeLines,
  BoDocumentTypes,
  BoRcptInvTypes,
  ItemsClass,
  PaymentResults,
  ReportType,
  SOAndSQActions
} from "../../../enum/enum";
// SERVICIOS
import {ActivatedRoute, Router} from '@angular/router';
import {
  AccountService,
  AlertService,
  AuthenticationService,
  BankService,
  BusinessPartnerService,
  CardService,
  CommonService,
  CompanyService,
  DocumentService,
  EventService,
  ExRateService,
  ItemService,
  JsonDataService,
  ParamsService,
  PaymentService,
  PermsService,
  ReportsService,
  SalesManService,
  StorageService,
  TaxService,
  UserService
} from "../../../services/index";

import {SOAndSQService} from 'src/app/services/soand-sq.service';
// Electron renderer service
import {ElectronRendererService} from '../../../electronrenderer.service';
import {UdfsService} from "src/app/services/udfs.service";
import {DOCUMENT_ALIAS} from "src/app/models/constantes";
import {IudfValue} from "src/app/models/iudf-value";
import {EventManager} from "@angular/platform-browser";
import {DocumentsToSyncService} from "src/app/services/documents-to-sync-service.service";
import {GoodsReceiptStockService} from "src/app/services/goodsReceiptStockService";
import {IMemoryInvoiceOld, IInvoiceInMemory} from "src/app/models/i-invoice-in-memory";
import {InvoicesInMemoryService} from "src/app/services/invoices-in-memory.service";
import {PaymentComponent} from "src/app/components/payment/payment.component";
import {IInvoiceInfoForPayment, IOnPaymentFail} from "src/app/models/i-payment-data";
import {CreateInvoice, DocumentModel} from "src/app/models/i-invoice-document";
import {BasePayment, PaymentLines} from "src/app/models/i-payment-document";
import {Currency} from 'src/app/models/i-currency';
import {RouterExtServiceService} from "src/app/services/router-ext-service.service";
import {IDocument, IQuotDocument, ISaleDocument} from "src/app/models/i-document";
import {IContableAccounts} from "src/app/models/i-contableaccounts";
import {PatientComponent} from "src/app/components/patient/patient.component";
import {IResponse} from "src/app/models/i-api-response";
import {IDoctor} from '../../../models/i-doctor';
import {ISpeciality} from '../../../models/i-speciality';
import {DoctorService} from '../../../services/doctor.service';
import {IMinifiedWarehouse} from "src/app/models/i-minified-warehouse";
import {FieldService} from "src/app/services/field.service";
import {IRequiredField} from "src/app/models/i-required-field";
import {IFieldRule} from "src/app/models/i-field-rule";
import {TappService} from "../../../services/tapp.service";
import {ICard, ITapp} from "../../../models/i-tapp-config";
import {IMinifiedCustomer} from "../../../models/i-minified-customer";
import {ISalesPerson} from "../../../models/i-sales-person";
import {
  IInvoiceTypeResponse,
  IIPriceResponse,
  IItemsNamesResponse,
  IPermissionsResponse,
  ISalesPersonResponse,
  IUdfCategoryResponse,
  IUdfsByCategoryResponse,
  IUdfsTargetResponse
} from "../../../models/responses";
import {BusinessPartnerModel} from "../../../models/i-business-partner-model";
import {ISalesForceCustomerInformationResponse} from "../../../models/i-sales-force";
import {
  CustomerSalesForceInfoModalComponent
} from "../../../components/customer-sales-force-info-modal/customer-sales-force-info-modal.component";
import {ITransaction} from "../../../models/i-pp-transaction";
import {KeyValuePair} from "../../../models/key-value";
import {LocalStorageVariables} from "../../../enum/local-storage-variables";
import { IFarmaValue } from "src/app/models/i-farma-value";

// CONSTANTS
const printJS = require("print-js");

// import { ConsoleReporter } from "jasmine";

// PIPES

export enum KEY_CODE {
  F4 = 115,
  F7 = 118,
  F8 = 119
}


@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  providers: [DecimalPipe, CurrencyPipe, DatePipe]
})
export class DocumentsComponent implements OnInit, OnDestroy, DoCheck, AfterViewInit {
  //varbox
  currentCustomer: IMinifiedCustomer;
  canEditPaymentTerms: boolean = false;
  requiredFields: IRequiredField[] = [];
  fieldRules: IFieldRule[] = [];
  warehouses: IMinifiedWarehouse[];
  invalidPatient: boolean;
  isAllowEditLinePrice: boolean;
  isPaymentPercent: boolean = false;
  minCreditPayment: number;
  canAllowDiscount: boolean;
  authorizatorPermissions: IPermission[];
  userCredentialsForm: FormGroup;
  authorizationModalMessage: string;
  @ViewChild('radcot') radcot: ElementRef<HTMLInputElement>;
  doctors: IDoctor[] = [];
  specialities: ISpeciality[] = [];
  mappedPayments: IMappedPayment[];
  isAllowedToEditCardNumber: boolean;
  requiredCardAccount: string;
  requiredCashAccount: string;
  requiredTransferAccount: string;
  defaultCardNumber: string;
  patientCodes: string[];
  patient: IPatient;
  searchTypeManual: boolean; // Validación por 2ble petición en typeahead cuando se agrega ítem por búsqueda manual,sin scanner.
  isProcessing: boolean;
  modalInvoiceContent: boolean;
  isScanning: boolean;
  isLockedByScanner: boolean;
  buildedData: string;
  isLockedIdSearch = false;
  currentNgbIndex = 0;
  documentTitle: string; //Titulo boton accion documento
  canViewThePage: boolean = true;
  isBilling: boolean;//Validacion flag para evitar reenvio de solicitudes pago al mismo tiempo
  attemptsWhileBilling: number = 0;
  lastInvoice: number;
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  $requestViewer: Subject<number>;
  currentUser: any;
  currentRequest: number;
  subscriptions$: Subscription;//Contiene la lista de todas las subscriptions
  subscriptionsf: Subscription[] = []; // Contiene la lista de todas las subscriptions
  requestToAwait: number;
  submitted = false; // variable para reconcer si se envio una peticion o no
  isEditable: boolean;

  //TAPP
  redeemedPoints: number = 0;
  redeemedRewards: any[] = [];
  openTappInvoiceResponse: any = null;
  closeTappInvoiceResponse: any = null;
  pointsForm!: FormGroup;
  tappSetting!: ITapp;
  farmaValueSetting!: IFarmaValue;
  modalTapp!: any;
  @ViewChild('consultPointsTapp') modalTappConsultPoints: TemplateRef<any>;

  isAllowedPriceListChange: boolean; // Controla el cambio de lista por parte del usuario
  isAllowedGoodReceipt: boolean; // Permiso para realizar ajuste de inventario
  isChangeTax = false; //permiso para cambiar impuesto a item
  isPermBonus = false; //permiso facturar items bonificados
  isAllowedWHChange: boolean; //Permiso cambiar almacen al item
  DEFAULT_BUSINESS_PARTNER: string; //Creacion carga cliente configurado en consola, edicion de documento
  // invoiceType: string; // contiene el valor definido en un JSON para el tipo de Factura
  @BlockUI("documents-block-ui") blockUI: NgBlockUI;
  TotalCol: FormControl = new FormControl();
  TotalUSD: FormControl = new FormControl();
  Cant: FormControl = new FormControl();
  ItemInfo: FormControl = new FormControl();
  documentForm: FormGroup; // formulario para la orden de venta
  feForm: FormGroup;//Formulario datos factura electronica
  typeArrow: boolean; // Hace toggle al darle click
  typesInvoice: IInvoiceType[] = [];
  viewParamList: any[] = []; // llena la lista con los componentes parametrizados
  viewParamListHeader: any[] = []; // llena la lista con los componentes parametrizados
  viewParamListTotales: any[] = []; // llena la lista con los componentes parametrizados
  viewParamTitles: any[] = []; // llena la lista con los titulos de las paginas parametrizados
  MapWidth: any;//Configuracion tabla
  tableLength: number;//Tamano tabla
  // --------Campos Parametrizados cabezera
  lbCardCode: Params = new Params(); // etiqueta para CardCode
  txtCardCode: Params = new Params(); // campo para CardCode
  lbCardName: Params = new Params(); // etiqueta para CardName
  txtCardName: Params = new Params(); // campo para CardName
  lbCurrency: Params = new Params(); // etiqueta para CardName
  txtCurrency: Params = new Params(); // campo para CardName
  txtPayTerms: Params = new Params(); // campo para Terminos de pagos
  lbPayTerms: Params = new Params(); // etiqueta para Terminos de pagos
  txtPriceList: Params = new Params(); // campo para Listas de precios
  lbPriceList: Params = new Params(); // etiqueta para Listas de precios
  txtComments: Params = new Params(); // campo para el comentario
  lbComments: Params = new Params(); // etiqueta para el comentario
  lbSLP: Params = new Params(); // etiqueta para el vendedor
  txtSLP: Params = new Params(); // campo para el vendedor
  // -------- Campos para metrizables de totales
  lbTotalExe: Params = new Params(); // etiqueta para Total sin impuestos
  txtTotalExe: Params = new Params(); // campo para Total sin impuestos
  lbDiscount: Params = new Params(); // etiqueta para descuentos
  txtDiscount: Params = new Params(); // campo para descuentos
  lbTaxes: Params = new Params(); // etiqueta para Impuestos
  txtTaxes: Params = new Params(); // campo para Impuestos
  lbTotal: Params = new Params(); // etiqueta para Total
  txtTotal: Params = new Params(); // campo para Total

  bpList: IMinifiedCustomer[] = []; // lista de clientes
  bpCodeList: string[] = []; // lista de los Códigos de clientes
  bpNameList: string[] = []; // lista de los nombres de clientes
  PayTermsList: any[] = []; // lista de los Terminos de pagos
  PriceList: any[] = []; // lista de las listas de precios
  defaultSlpCode: number = -1;
  defaultSlpCodeStr: number;
  defaultGroupNum: string;
  defaultListNum: string;
  defaultContado: string;

  identificationTypeList: any[] = [];

  setCurr: string; // tipo de moneda escogida en simbolo
  currencyList: Currency[] = []; // lista de tipos de cambio
  allCurrencyList: Currency[] = []; // lista de todos los tipos de cambio existentes en la aplicacion
  userCurrency: string; // Usado para precargar el tipo de moneda que usara el usuario
  currencyPayment: string; // moneda selecionada al buscar los anticipos
  currencyChange: number; // monto del tipo de cambio
  DailyExRate: number;//monto del tipo de cambio

  maxDiscuont: number;
  hasLines: boolean = false; //dice si el pago se realizo correctamente

  itemsTypeaheadList: string[] = []; // lista de la concatenacion del Código con el nombre del item
  itemsCodeList: string[] = []; // lista de los Códigos de items
  itemsNameList: string[] = []; // lista de los nombres de items
  userAssignsList: UserAssigns[] = [];
  paymentTypes: any[] = [
    {id: '1', name: "A30Dias"},
    {id: '2', name: "Contado"},
  ];

  conta: number; // variable contador para colocar un 'id' a la lista de items
  total: number; // variable para almacenar el total de la factura
  totalUSD: number;
  tax: number; // variable para almacenar el total de impuestos
  discount: number; // variable para almacenar el total de descuento
  totalWithoutTax: number; // variable para almacenar el total sin impuesto
  taxesList: any[] = []; // lista de los impuestos
  companiesList: any[] = []; // lista de las compannias
  uniqueInvCode: string;
  salesPersons: ISalesPerson[] = []; // lista de los vendedores
  itemsList: IDocumentLine[] = []; // lista de items
  baseLines: IDocumentLine[]; // lista de items
  mappedUdfs: IUdfTarget[];
  udfTargets: IKValue[];
  udfs: IUdf[];

  isOnCreateUpdateMode: boolean; //Creacion edicion documento, manejo documento en memoria  currentMemoryInvoice: IMemoryInvoice;
  InvoicesInMemoryAccepted: number;
  addMemoryInvoiceSubscription: Subscription;
  loadMemoryInvoiceSubscription: Subscription;
  currentMemoryInvoice: IMemoryInvoiceOld;
  public expandedIndex: number; // variable para manejar el collapse de los items y reconocer sobre cual se va a trabajar

  DeviationMessage: string = '';

  maxWeightTo0: number = 0.01;
  isOnGroupLine: boolean;//Agrupacion de lineas en documento
  isLineMode: boolean;//Orden de lineas en documento al inicio o final
  isRequestinBacPayment: boolean;
  @ViewChild('editUnitPrice') editUnitPrice: ElementRef; // Referencia al input de edicion de precio por linea, para poder setear el focus cuando se va a editar
  onlineEvent: Observable<Event>; // Permite detectar el evento de la conexion cuando se recupera
  offlineEvent: Observable<Event>;// Permite detectar el evento de la conexion cuanod se pierde
  COMPANY: Company; // Usada para guardar las configuraciones del a compania
  usersCredentials: string;
  TO_FIXED_PRICE: string; // Contiene la cantidad de decimales a usar en el precio unitario
  TO_FIXED_TOTALLINE: string; // Contiene la cantidad de decimales a usar en el total de linea
  TO_FIXED_TOTALDOCUMENT: string; // Contiene la cantidad de decimales a usar en el total del documento
  documents: IDocumentToSync[];//ref Offline view
  documentPendingSync: number;//ref Offline view
  flagForm: boolean; //Validacion flag para evitar reenvio de solicitudes al mismo tiempo, facturas credito
  lines: any[] = []; // Modelo para ajuste de inventario
  CommentInv: string;
  GoodsReceiptAccount: string;
  CRCTotal: number; // Total en colones
  whName: string;
  whCode: string;
  isOnSubmit: boolean = false;

  WHAvailableItemList: any[] = []; // lista de los items disponibles por almacen
  indexAvaItem: number; // indice de la lista del item seleccionado para la disponibilidad
  itemCode: string; // variable para almacenar el Código del ite seleccionado para buscar la disponibilidad
  seriesList: any[] = []; // lista de las series de un item po almacen
  closeResult: string; // variable para saber porque se cerro la modal
  modalReference: any; // instancia de la modal de terminal y sucursal
  saleDocumentModel: IDocument;
  NameActionDocument: string;

  terminalsByUser: ITerminalByUser[];
  terminals: ITerminal[];
  pPTransaction: IPPTransaction;
  accountList: IContableAccounts; // lista de cuentas
  cardsList: any[] = []; // lista de tarjetas
  banksList: any[] = []; // lista de bancos
  modalPay: any; // instancia de la modal de pagos
  // modalChange: any; // instancia de la modal de vueltos
  TotalG: number; // monto total del pago

  TotalCash: number; // monto total del pago en efectivo
  TotalCards: number; // monto total del pago en tarjetas
  TotalTransfer: number; // monto total del pago en transferencia
  TotalCheck: number; // monto total del pago en cheque
  V_CreditCards: any[] = []; // lista de pagos registrados con tarjetas de credito
  CardName: string; // nombre de la tarjeta seleccionada para el pago con tarjeta
  V_Checks: any[] = []; // lista de pagos registrados con cheques
  isPagoCuenta: boolean;
  unamePattern = "^d{2}/d{2}$";
  btnVisibleBool: boolean;

  udfsOIGN: IUdf[];
  mappedUdfsOIGN: IUdfTarget[];
  udfTargetsOIGN: IKValue[];

  InvalidCardName: boolean;
  returnedDocEntry: number;
  returnedDocNum: number;
  returnedNumFE: number;

  isOnOrderMode: boolean;
  isOnInvoiceDocument: boolean;//Mostrar data fe en modal

  @ViewChild('closebuttonInvSetting') closebuttonInvSetting;
  SettingOIGNJson: any;
  //#Pagos, variables requeridas para cuando falla creando el documento al que se le realizaron pagos.
  IsPaymentFail: boolean = false;
  PaymentFail: IOnPaymentFail;
  Payment: BasePayment;
  title: string; // titulo de la vista
  feOpen: boolean = false; // dice si la pestana de datos de fe esta abierta
  _timeout: any = null;
  changeColones: number; // vuelto en colones
  changeDolares: number; // vuelto en dolares

  lastSO: number;  // guarda el id de la ultima orden de venta generada, se usa para imprimir
  lastQuotation: number; // guarda el id de la ultima oferta de venta generada, se usa para imprimir

  priceList: number;
  DefaultCardValid: String;
  ProcessingScanning: boolean;
  @ViewChild("name") nameField: ElementRef;
  DefaultCardNumber: string;

  /**
   * Emit a value when the sales person input is clicked
   */
  clickSalesPersonsInput$: Subject<string> = new Subject<string>();
  /**
   * Emit a value when the sales person input get focus
   */
  focusSalesPersonsInput$: Subject<string> = new Subject<string>();
  /**
   * Represent the sales persons typeahead input element
   */
  @ViewChild('salesPersonsTypeahead') salesPersonsTypeahead: NgbTypeahead;

  /**
   * Contains all configured udfs for this view
   */
  udfsForm: FormGroup = new FormGroup({});

  /**
   * Represent the sales force information of the selected customer
   */
  businessPartnerSalesForceInformation: ISalesForceCustomerInformationResponse | undefined;

  /**
   * Indicates if the customer is an active member of Medismart based on the sales force requested information
   */
  businessPartnerIsAnActiveMemberOfMedismart: boolean;
  /**
   * List of PinPad transactions
   */
  transactions: ITransaction[] = [];

  /**
   * Indicates if the selected customer identification has sales force information
   */
  hasSalesForceInformation: boolean = false;

  /**
   * Contains only the subscriptions to the forms
   */
  formsSubscriptions$: Subscription = new Subscription();

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (!event.ctrlKey && event.altKey && !event.shiftKey && event.keyCode === 73) {
      console.log('ALT + I');
    }
    if (!event.ctrlKey && event.altKey && !event.shiftKey && event.keyCode === 79) {
      console.log('ALT + O');
    }
    if (!event.ctrlKey && event.altKey && !event.shiftKey && event.keyCode === 80) {
      console.log('ALT + P');
    }
    switch (event.keyCode) {
      case KEY_CODE.F4: {
        this.nameField.nativeElement.focus();
        break;
      }
      case KEY_CODE.F7: {
        if (!this.isOnSubmit) {
          this.isOnSubmit = true;
          this.onSubmit(this.radcot);
          break;
        }
      }
      case KEY_CODE.F8: {
        if (!this.isOnSubmit) {
          this.CreateNew();
          break;
        }
      }
    }
  };


  /**
   * Indicates if the user can change the sales person of the document.
   */
  canChangeSalesPerson: boolean = false;

  /**
   * Used to manage events when the initial information is loaded
   */
  private initialInformationLoadedSubject$: Subject<boolean> = new Subject<boolean>();

  /**
   * Emits a value when the initial information is loaded
   */
  initialInformationLoaded$: Observable<boolean> = this.initialInformationLoadedSubject$.asObservable();

  /**
   * Code defined for farma value category
   */
  farmaValueCategoryCode: string = '';

  /**
   * Represent the list of triple warranty categories codes
   */
  tripleWarrantyCategoriesCodes: string[] = [];


  constructor(
    private fieldService: FieldService,
    private invoicesInMemoryService: InvoicesInMemoryService,
    private eventManager: EventManager,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private itemService: ItemService,
    private cdr: ChangeDetectorRef,
    private bpService: BusinessPartnerService,
    private sPerm: PermsService,
    private decimalPipe: DecimalPipe,
    private documentService: DocumentService,
    private taxService: TaxService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private paramsService: ParamsService,
    private companyService: CompanyService,
    private uService: UserService,
    private reportsService: ReportsService,
    private accountService: AccountService,
    private exRateService: ExRateService,
    private exrate: ExRateService,
    private cardService: CardService,
    private bankService: BankService,
    private alertService: AlertService,
    private jsonDataService: JsonDataService,
    private smService: SalesManService,
    private cp: CurrencyPipe,
    private electronRendererService: ElectronRendererService,
    private storage: StorageService,
    private paymentService: PaymentService,
    private datePipe: DatePipe,
    private storageService: StorageService,
    private commonService: CommonService,
    private soAdnSQService: SOAndSQService,
    private udfService: UdfsService,
    private goodsReciptStockService: GoodsReceiptStockService,
    private documentsToSyncService: DocumentsToSyncService,
    private activatedRoute: ActivatedRoute,
    private extRuterService: RouterExtServiceService,
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    private eventService: EventService,
    private doctorService: DoctorService,
    private tappService: TappService,
  ) {
    this.subscriptions$ = new Subscription();

    this.$requestViewer = new Subject();
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.expandedIndex = -1;
    // La variable no se usa para agrega el listener para poder reconocer los caracteres de la pistola
    const removeGlobalEventListener = this.eventManager.addGlobalEventListener(
      'document',
      'keyup',
      (ev) => {
        if (this.ItemInfo && this.ItemInfo.value) {
          if (Number(this.ItemInfo.value.toString())) {
            this.isScanning = true;
            this.buildedData = this.ItemInfo.value;
          }

          if (ev.key === 'Enter') {
            if (this._document && this._document.activeElement && this._document.activeElement.id && this._document.activeElement.id == 'ItemCodeManualTrigger') {
              this.isLockedByScanner = true;
              this.isScanning = false;
            }
          }
        }
      }
    );
  }


  // events section

  EVENTS: { [K: string]: Function } = {
    RaisePatientModal: _ => this.RaisePatientModal()
  };

  ngDoCheck(): void {

    const parent: HTMLElement = document.getElementById('scrollable-dropdown-menu');
    if (parent) {
      if (parent.children.length > 0) {
        const child = parent.children[1];
        if (child) {
          this.renderer.setStyle(child, 'max-height', '300px');
          this.renderer.setStyle(child, 'overflow-y', 'auto');
        }
      }
    }

    if ((this.isLockedByScanner && this.itemsTypeaheadList.find(x => x.indexOf(this.buildedData) > -1))) {
      try {

        let buttons = document.getElementsByClassName('dropdown-item');
        setTimeout(() => {
          let dynamicId = `hardcodedId`;
          if (buttons[0]) {
            dynamicId = buttons[0].getAttribute('id');
            if (dynamicId.indexOf('ngb-typeahead') < 0 || !(<HTMLButtonElement>document.getElementById(dynamicId))) {
              this.alertService.infoAlert('No se pudo identificar la generación dinámica del componente, por favor seleccione el producto manualmente');
            } else {
              (<HTMLButtonElement>document.getElementById(dynamicId)).click();
            }
          } else {
            (<HTMLButtonElement>document.getElementById('helperClick')).focus();
            (<HTMLButtonElement>document.getElementById('helperClick')).click();
            (<HTMLButtonElement>document.getElementById('ItemCodeManualTrigger')).focus();
            (<HTMLButtonElement>document.getElementById('ItemCodeManualTrigger')).click();
            this.isScanning = false;
            this.addItems(null, true);


            //this.ItemInfo.setValue(``);
          }
        }, 0);
        this.isLockedByScanner = false;
      } catch (error) {
        this.alertService.infoAlert(`Error: ${error}`);
        this.isLockedByScanner = false;
        console.log(error);
      }
    } else {
      if (this.isLockedByScanner && !this.isScanning && !(this.itemsTypeaheadList.find(x => x.indexOf(this.ItemInfo.value) > -1))) {
        this.alertService.infoInfoAlert(`No existe ${this.ItemInfo.value}`);
        this.ItemInfo.setValue(``);
        this.isLockedByScanner = false;
      }
    }
  }

  ngAfterViewInit(): void
  {
  }

  ngOnDestroy()
  {
    if(this.GetDocumentAlias() === 'OINV')
    {
      this.invoicesInMemoryService.SaveInvoiceInMemory(this.BuildInvoiceInMemory());
    }

    this.formsSubscriptions$.unsubscribe();

    this.storageService.SetPatientToPreload(null);

    this.commonService.hasDocument.next(``);

    this.storage.SaveBreadCrum(``);

    this.currentUserSubscription.unsubscribe();

    this.storage.SaveDocEntry(-1);

    this.storage.SaveDocumentType(-1);

    this.storage.SaveCustomerData('{}');

    if (this.subscriptions$) this.subscriptions$.unsubscribe();

    localStorage.removeItem("SOAndSQAction");

    const STATUS = {
      documentInformationSync: false,
      connectionType: false
    };

    this.commonService.offlineInformationPending.next(STATUS);

    this.invoicesInMemoryService.ShowInvoicesInMemory(false);
  }

  ngOnInit()
  {
    this.ListenInitialInformationLoad();

    this.patient = this.storageService.GetPreloadPatient();

    this.storage.SaveDocumentType(parseInt(this.activatedRoute.snapshot.paramMap.get('id')));

    this.DefineTitleDocument();

    this.InitVariables();

    this.SendInitialRequests();
  }

  /**
   * Listen the event when the initial information is loaded
   * @constructor
   * @private
   */
  private ListenInitialInformationLoad(): void {
    if(this.invoicesInMemoryService.HasInvoicesInMemory())
    {
      this.subscriptions$.add(
        this.initialInformationLoaded$
          .pipe(
            filter(loaded => loaded),
            map(loaded => {
              this.blockUI.reset();

              this.blockUI.start("Cargando factura en memoria...");

              return loaded;
            }),
            delay(50)
          )
          .subscribe({
            next: (loaded) => {
              if(loaded)
              {
                this.LoadInvoiceInMemory();

                this.invoicesInMemoryService.ShowInvoicesInMemory(true);
              }
            }
        })
      );
    }
    else
    {
      this.subscriptions$.add(
        this.initialInformationLoaded$
          .pipe(
            filter(loaded => loaded)
          )
          .subscribe({
            next: (loaded) => {
              this.AddEmptyInvoiceIfThereAreNotInvoiceInMemory();
            }
          })
      );
    }
  }

  /**
   * Send the initial request to get the required information to set up the component
   * @constructor
   * @private
   */
  private SendInitialRequests(): void
  {
    this.blockUI.reset();

    this.blockUI.start("Cargando datos iniciales...");

    this.CheckPermissionsObservable()
      .pipe(
        concatMap(response => {
          return forkJoin([
            this.GetViewParamatersObservable(),
            this.GetInvoiceTypesObservable(),
            this.GetCompaniesObservable(),
            this.GetMaxDiscountObservable(),
            this.GetSpecialtiesObservable(),
            this.GetConfiguredUDFsObservable(this.GetDocumentAlias()),
            this.GetItemsObservable(this.documentForm.controls['Speciality'].value),
            this.GetWarehouseInformationObservable(),
            this.GetDefaultSalesPersonCodeObservable(),
            this.GetDoctorsObservable(),
            this.GetTaxesObservable(),
            this.GetExchangeRateObservable(),
            this.GetAccountsObservable(),
            this.GetCardsObservable(),
            this.GetBanksAccountsObservable(),
            this.GetMinimumCreditPaymentObservable(),
            this.GetRequiredFieldsAndRulesObservable(),
            this.GetMappedPaymentsObservable(),
            this.GetCustomersObservable(),
            this.GetTappSettingObservable(),
            this.GetFarmaValueSettingObservable(),
            this.GetDefaultPaymentSettingsObservable(),
            this.GetViewGroupsObservable(),
            this.GetDefaultBusinessPartnerSettings(),
            this.GetConfiguredUDFsOIGNObservable(),
            this.GetDocumentInEditionInformationObservable(),
            this.GetTripleWarrantyCategoriesCodesObservable(),
            this.GetFarmaValueCategoryCodeObservable()
          ]);
        }),
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (responses) => {
          this.initialInformationLoadedSubject$.next(true);
        },
        error: (error) => {
          console.error("An error occurs trying to retrieve the initial information to set up the component. Details:", error);

          this.initialInformationLoadedSubject$.next(false);
        }
      });
  }

  private InitVariables(): void {
    this.ListenManuallyAddOfInvoicesInMemory();

    this.ListenManuallyLoadOfInvoiceInMemory();

    this.redeemedRewards = [];

    this.invalidPatient = false;

    this.authorizatorPermissions = [];

    this.mappedPayments = [];

    this.patientCodes = [];

    this.eventService.Flow().pipe(filter(next => next.View === this.router.url.split('/')[1])).subscribe(next => this.EVENTS[next.Target]());

    this.ProcessingScanning = false;

    this.isEditable = true;

    this.userCurrency = '';

    this.isChangeTax = false;

    this.isPermBonus = false;

    this.NameActionDocument = ''

    this.isOnOrderMode = false;

    this.isOnInvoiceDocument = false;

    this.currentMemoryInvoice as IMemoryInvoiceOld;

    this.buildedData = '';

    this.isScanning = false;

    this.isProcessing = false;

    this.searchTypeManual = false;

    this.isLockedByScanner = false;

    this.isAllowedPriceListChange = false;

    this.isAllowedGoodReceipt = false;

    this.isAllowedWHChange = false;

    this.currentRequest = 0;

    this.typeArrow = false;

    this.isOnCreateUpdateMode = false;

    this.terminals = [];

    this.identificationTypeList = IdentificationType;

    this.itemsList = [];
    this.udfTargets = [];

    this.udfs = [];

    this.udfsOIGN = [];

    this.udfTargetsOIGN = [];

    this.isRequestinBacPayment = false;

    this.transactions = [];

    this.terminals = [];

    this.InvalidCardName = false;

    this.Payment = {} as BasePayment;

    this.documentForm = this.fb.group({
      DocumentType: ['', Validators.required],
      Code: [''],
      Speciality: [''],
      Doctor: [''],
      cardCode: [this.DEFAULT_BUSINESS_PARTNER, Validators.required],
      cardName: ['', Validators.required],
      PayTerms: ['', Validators.required],
      PriceList: ['', Validators.required],
      SlpList: ['', Validators.required],
      paymentType: [''],
      Comment: '',
      AnotherPayment: [''],
      IsMedismartCustomer: [false]
    });

    this.ListenCardNameChanges();

    this.ListenIsMedismartCustomerCheckboxChanges();

    this.userCredentialsForm = this.fb.group({
      Email: [''],
      Password: ['']
    });

    this.createPointsForm();

    this.documentForm.controls['Doctor'].disable();

    const DOC_ENTRY = this.storage.GetDocEntry();

    if (DOC_ENTRY > 0)
    {
      this.DEFAULT_BUSINESS_PARTNER = JSON.parse(this.storage.GetCustomerData()).CardCode;
    }

    this.CreateFEForm();

    this.generateUniqueInvoiceId();

    this.COMPANY = this.storage.getCompanyConfiguration();

    this.TO_FIXED_PRICE = `1.${this.COMPANY.DecimalAmountPrice}-${this.COMPANY.DecimalAmountPrice}`;

    this.TO_FIXED_TOTALLINE = `1.${this.COMPANY.DecimalAmountTotalLine}-${this.COMPANY.DecimalAmountTotalLine}`;

    this.TO_FIXED_TOTALDOCUMENT = `1.${this.COMPANY.DecimalAmountTotalDocument}-${this.COMPANY.DecimalAmountTotalDocument}`;

    this.usersCredentials = JSON.parse(this.storage.getCurrentSession()).UserName;

    this.defaultGroupNum = "-1";

    this.defaultListNum = "-1";

    this.defaultContado = "Contado";

    this.defaultSlpCodeStr = -1;

    //Configuracion tabla
    this.tableLength = 1000;

    this.MapWidth = {};

    this.MapWidth["Id"] = 80;

    this.MapWidth["ItemCode"] = 350;

    this.MapWidth["Speciality"] = 150;

    this.MapWidth["Doctor"] = 200;

    this.MapWidth["UnitPrice"] = 200;

    this.MapWidth["Marca"] = 200;

    this.MapWidth["Group"] = 200;

    this.MapWidth["Quantity"] = 110;

    this.MapWidth["SubGroup"] = 100;

    this.MapWidth["ItemName"] = 350;

    this.MapWidth["Discount"] = 80;

    this.MapWidth["TaxRate"] = 80;

    this.MapWidth["TaxCode"] = 80;

    this.MapWidth["WhsCode"] = 80;

    this.MapWidth["WhsName"] = 200;

    this.MapWidth["LastDate"] = 100;

    this.MapWidth["LinTot"] = 100;

    this.MapWidth["Serie"] = 100;

    this.MapWidth["Opc"] = 100;

    this.Cant.setValue(1);

    this.requestToAwait = 2;

    this.conta = 0.0;

    this.total = 0.0;

    this.totalUSD = 0.0;

    this.tax = 0.0;

    this.discount = 0.0;

    this.totalWithoutTax = 0.0;

    this.identificationTypeList = IdentificationType;

    this.lines = [];

    this.isOnOrderMode = DOC_ENTRY > 0;

    this.isOnCreateUpdateMode = DOC_ENTRY > 0;

    this.openTappInvoiceResponse = null;

    this.closeTappInvoiceResponse = null;

    this.businessPartnerIsAnActiveMemberOfMedismart = false;
  }

  /**
   * Add an invoice in memory if there aren't invoices in memory
   * @constructor
   * @private
   */
  private AddEmptyInvoiceIfThereAreNotInvoiceInMemory(): void
  {
    if(!this.invoicesInMemoryService.HasInvoicesInMemory() && this.GetDocumentAlias() === 'OINV')
    {
      this.invoicesInMemoryService.SaveInvoiceInMemory(this.BuildInvoiceInMemory());

      this.invoicesInMemoryService.SetCurrentlyInvoiceInMemoryLoaded(this.uniqueInvCode, false);
    }
  }

  /**
   * Return the document request as observable with mappings
   * @constructor
   * @private
   */
  private GetDocumentInEditionInformationObservable()
  {
    const DOC_ENTRY = this.storage.GetDocEntry();

    if (DOC_ENTRY <= 0)
    {
      return of(null);
    }

    if([SOAndSQActions.EditQuotation, SOAndSQActions.EditOrder].includes(this.storage.GetDocumentType()))
    {
      this.documentForm.controls['cardName'].disable();

      this.documentForm.controls['cardCode'].disable();

      this.defaultSlpCode = JSON.parse(this.storage.GetCustomerData()).SlpCode;

      return this.GetDocumentObservable(DOC_ENTRY);
    }
    else
    {
      return this.GetBaseLinesObservable(DOC_ENTRY);
    }
  }


  /**
   * Return the document alias based on the document type
   * @constructor
   * @private
   */
  private GetDocumentAlias(): DOCUMENT_ALIAS
  {
    switch (this.storage.GetDocumentType()) {
      case SOAndSQActions.EditQuotation:
      case SOAndSQActions.CreateQuotation:
        return DOCUMENT_ALIAS.SALE_QUOTATION;

      case SOAndSQActions.CopyToOrder:
      case SOAndSQActions.EditOrder:
      case SOAndSQActions.CopyToInvoice:
      case SOAndSQActions.CreateSaleOrder:
        return DOCUMENT_ALIAS.SALE_ORDER;

      case SOAndSQActions.CreateInvoice:
        return DOCUMENT_ALIAS.INVOICE;
    }
  }

  /**
   * Return view groups request as observable with mapping
   * @constructor
   * @private
   */
  private GetViewGroupsObservable(): Observable<any>
  {
    return this.companyService.GetViewGroupList()
      .pipe(
        map(response => {
          if (response.Result)
          {
            ((response.ViewGroupList) as IViewGroup[]).forEach(x => {
              switch (this.storage.GetDocumentType()) {
                case SOAndSQActions.EditOrder:
                case SOAndSQActions.CopyToOrder:
                case SOAndSQActions.CreateSaleOrder:
                  if (x.CodNum === 1) {//SO
                    this.isOnGroupLine = x.isGroup;
                    this.isLineMode = x.LineMode; //Orden de lineas al inicio o final
                  }
                  break;
                case SOAndSQActions.EditQuotation:
                case SOAndSQActions.CreateQuotation:
                  if (x.CodNum === 4) {//QO
                    this.isOnGroupLine = x.isGroup;
                    this.isLineMode = x.LineMode; //Orden de lineas al inicio o final
                  }
                  break;
                case SOAndSQActions.CreateInvoice:
                case SOAndSQActions.CopyToInvoice:
                  if (x.CodNum === 3) {//INVO
                    this.isOnGroupLine = x.isGroup;
                    this.isLineMode = x.LineMode; //Orden de lineas al inicio o final
                  }
                  break;
              }
            });
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }
  /**
   * Listen changes in the UDF U_Categoria
   * @constructor
   * @private
   */
  private ListenCategoryUdfChanges(): void
  {
    let control = this.udfsForm.get("U_Categoria");

    if(control)
    {
      this.formsSubscriptions$.add(
        control.valueChanges
          .pipe(
            distinctUntilChanged()
          )
          .subscribe({
            next: (value) => {
              if(this.itemsList.length > 0)
              {
                this.alertService.ShowAlert("warning", {
                  text: "Al cambiar la categoría, es posible que los datos en las líneas no correspondan con la categoría seleccionada."
                });
              }

              this.OnSelectTripleWarrantyCategory(value);
              this.OnSelectFarmaValueCategory(value);
            }
          })
      );
    }
  }

  /**
   * Checks if the specified category is a triple warranty category and then show the "Last invoice number" UDF
   * @param pCategoryCode Code of the selected category to check
   * @constructor
   * @private
   */
  private OnSelectTripleWarrantyCategory(pCategoryCode: string)
  {
    if(!this.tripleWarrantyCategoriesCodes.some(twc => twc === pCategoryCode))
    {
      this.RemoveControlFromUDFsForm("U_EMA_LastInvoiceNumber");

      return;
    }

    this.AddControlToUDFsForm("U_EMA_LastInvoiceNumber");
  }

  /**
   * Listen changes in the card name form field
   * @constructor
   * @private
   */
  private ListenCardNameChanges(): void
  {
    this.formsSubscriptions$.add(
      this.documentForm.get("cardName").valueChanges
        .pipe(
          filter(value => value != undefined)
        )
        .subscribe({
          next: (value) => {
            let finalValue = typeof value == "string" ? value : value.CardName;

            this.SetValueToUDF("U_Paciente", finalValue, "String");
          }
        })
    );
  }

  private createPointsForm(): void {
    this.pointsForm = this.fb.group({
      pointsSearch: ['']
    });
  }

  /**
   * Returns the document request as observable with mapping
   * @param pDocumentEntry Entry of the document that will be retrieved
   * @constructor
   * @private
   */
  private GetDocumentObservable(pDocumentEntry: number): Observable<IResponse<IDocument>>
  {
    return this.soAdnSQService.GetDocument(pDocumentEntry, this.storage.GetDocumentType())
      .pipe(
        map(next => {
          if (next.Result) {
            this.conta = 0;
            this.total = 0;
            this.itemsList = [];
            this.baseLines = next.Data.DocumentLines;
            this.saleDocumentModel = next.Data;

            setTimeout(() => {
              this.documentForm.patchValue({
                PayTerms: this.saleDocumentModel.PaymentGroupCode,
                SlpList: this.saleDocumentModel.SalesPersonCode,
                PriceList: this.saleDocumentModel.U_ListNum,
                DocumentType: this.saleDocumentModel.U_TipoDocE,
                cardName: this.saleDocumentModel.CardName,
                Comment: this.saleDocumentModel.Comments
              });
            }, 500);

            if (this.saleDocumentModel.U_TipoIdentificacion !== null) {
              this.feForm.patchValue({
                IdType: this.saleDocumentModel.U_TipoIdentificacion,
                Identification: this.saleDocumentModel.U_NumIdentFE,
                Email: this.saleDocumentModel.U_CorreoFE,
              });

              (<HTMLButtonElement>document.getElementById('triggerFECollapser')).click();
            }
            this.priceList = this.saleDocumentModel.U_ListNum;

            this.userCurrency = this.saleDocumentModel.DocCurrency;

            this.documentForm.patchValue({
              DocumentType: this.saleDocumentModel.U_TipoDocE,
              cardName: this.saleDocumentModel.CardName
            });

            next.Data.DocumentLines.forEach(x => {
              this.conta++;
              this.total += x.UnitPrice;
              const tot = x.UnitPrice * x.Quantity;
              let itemAux = {
                Id: this.conta,
                LinTot: tot,
                ItemCode: `${x.ItemCode} - ${x.ItemName}`,
                Item: x.ItemCode,
                ItemName: x.ItemName,
                Speciality: x.Speciality,
                U_Doctor: x.U_Doctor,
                DiscountPercent: x.DiscountPercent,
                DiscountG: x.DiscountG,
                Quantity: x.Quantity,
                U_SugPrice: 0,
                Serie: '',
                TaxCode: x.TaxCode,
                TaxRate: x.TaxRate != 0.0 ? x.TaxRate : 0.00,
                UnitPrice: +x.UnitPrice.toFixed(this.COMPANY.DecimalAmountPrice),
                WarehouseCode: x.WarehouseCode,
                WhsName: x.WhsName,
                TaxOnly: '',//x.TaxOnly ? 'tYES' : 'tNO',
                BaseEntry: x.BaseEntry == 0 ? null : x.BaseEntry,
                BaseType: x.BaseType,
                BaseLine: x.BaseLine == -1 ? null : x.BaseLine,
                LineNum: x.LineNum,
                LastPurchasePrice: +x.LastPurchasePrice.toFixed(this.COMPANY.DecimalAmountPrice),
                InvntItem: x.InvntItem,
                available: x.OnHand,
                LineStatus: x.LineStatus,
                ItemClass: x.ItemClass
              } as IDocumentLine;


              this.isLineMode ? this.itemsList.push(itemAux) : this.itemsList.unshift(itemAux);

              this.isLineMode ? this.LineTotalCalculate(this.itemsList.length - 1) : this.LineTotalCalculate(0);

              this.UpdateDocumentAmounts();
            });
          }
          else
          {
            this.alertService.ShowAlert("error", {text: next.Error.Message});
          }

          return next;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  /**
   * Return the document base lines request as observable with mapping
   * @param pDocumentEntry Entry of the document that will be retrieved
   * @constructor
   * @private
   */
  private GetBaseLinesObservable(pDocumentEntry: number): Observable<IResponse<IDocument>>
  {
    return this.soAdnSQService.GetDocument(pDocumentEntry, this.storage.GetDocumentType())
      .pipe(
        concatMap(response => {
          return this.bpService.GetCustomerDetails('-1', this.saleDocumentModel.CardCode)
            .pipe(
              map(customerDetailsResponse => {
                if (customerDetailsResponse.Result && customerDetailsResponse.Data)
                {
                  this.priceList = +customerDetailsResponse.Data.ListNum;
                }

                return response;
              })
            )
        }),
        map(response => {
          if (response.Result)
          {
            this.conta = 0;

            this.total = 0;

            this.itemsList = [];

            this.saleDocumentModel = response.Data;

            this.documentForm.patchValue({cardName: this.saleDocumentModel.CardName});

            this.baseLines = response.Data.DocumentLines;

            this.documentForm.patchValue({
              DocumentType: this.saleDocumentModel.U_TipoDocE,
              Comment: this.saleDocumentModel.Comments,
              PayTerms: this.saleDocumentModel.PaymentGroupCode,
              PriceList: this.saleDocumentModel.U_ListNum,
              SlpList: this.saleDocumentModel.SalesPersonCode,
            });

            this.priceList = this.saleDocumentModel.U_ListNum;

            this.userCurrency = this.saleDocumentModel.DocCurrency;

            this.currencyPayment = this.userCurrency;

            if (this.saleDocumentModel.U_TipoIdentificacion !== null)
            {
              this.feForm.patchValue({
                IdType: this.saleDocumentModel.U_TipoIdentificacion,
                Identification: this.saleDocumentModel.U_NumIdentFE,
                Email: this.saleDocumentModel.U_CorreoFE
              });

              (<HTMLButtonElement>document.getElementById('triggerFECollapser')).click();
            }

            const DECS = (() => +`1${`0`.repeat(this.COMPANY.DecimalAmountTotalDocument)}`)();

            response.Data.DocumentLines.filter(x => x.LineStatus !== 'C').forEach(x => {
              this.conta++;

              const tot = Math.round((x.Quantity * x.UnitPrice) * DECS) / DECS;

              let itemAux = {
                Id: this.conta,
                LinTot: tot,
                ItemCode: `${x.ItemCode} - ${x.ItemName}`,
                Item: x.ItemCode,
                ItemName: x.ItemName,
                Speciality: x.Speciality,
                U_Doctor: x.U_Doctor,
                DiscountPercent: x.DiscountPercent,
                DiscountG: x.DiscountG,
                Quantity: x.Quantity,
                U_SugPrice: 0,
                Serie: '',
                TaxCode: x.TaxCode,
                TaxRate: x.TaxRate != 0.0 ? x.TaxRate : 0.00,
                UnitPrice: +x.UnitPrice.toFixed(this.COMPANY.DecimalAmountPrice),// JSON.parse(this.storage.GetCustomerData()).Currency === 'COL' ? x.UnitPrice : (parseFloat(Number(x.UnitPrice / this.DailyExRate).toString())),
                WarehouseCode: x.WarehouseCode,
                WhsName: x.WhsName,
                TaxOnly: '',//x.TaxOnly ? 'tYES' : 'tNO',
                BaseEntry: x.BaseEntry,
                BaseType: x.BaseType,
                BaseLine: x.BaseLine == -1 ? null : x.BaseLine,
                LineNum: x.LineNum,
                LastPurchasePrice: +x.LastPurchasePrice.toFixed(this.COMPANY.DecimalAmountPrice),
                InvntItem: x.InvntItem,
                available: x.OnHand,
                LineStatus: x.LineStatus,
                ItemClass: x.ItemClass
              } as IDocumentLine;

              this.isLineMode ? this.itemsList.push(itemAux) : this.itemsList.unshift(itemAux);

              this.isLineMode ? this.LineTotalCalculate(this.itemsList.length - 1) : this.LineTotalCalculate(0);

              this.UpdateDocumentAmounts();
            });
          }
          else
          {
            this.alertService.ShowAlert("error", {text: response.Error.Message});
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  //#endregion


  // #Componente de pagos
  GenerateDataForPayment(_requestPaymentCurrency?: string): any
  {
    let documentValue = this.documentForm.getRawValue();

    let InvoiceInfo: IInvoiceInfoForPayment = {
      CardCode: documentValue.cardCode,
      Currency: this.userCurrency,
      SlpCode: documentValue.SlpList,
      uniqueInvCode: this.uniqueInvCode,
      Comment: 'Pago de factura',
      accountPayment: false
    }
    const DECS = (() => +`1${`0`.repeat(this.COMPANY.DecimalAmountTotalDocument)}`)();

    let unvattedAmount = this.itemsList.filter(x => x.TaxCode === '4IVA').reduce((acc, obj) => acc + (Math.round((obj.LinTot / 1.04) * DECS) / DECS), 0);

    const requiredDataForPay = {
      MinCreditPayment: this.minCreditPayment,
      IsPaymentPercent: this.isPaymentPercent,
      IsAllowedToEditCardNumber: this.isAllowedToEditCardNumber,
      lists: {
        accountList: this.accountList,
        V_CreditCards: this.V_CreditCards,
        cardsList: this.cardsList,
        currencyList: this.currencyList,
        banksList: this.banksList,
        V_Checks: this.V_Checks,
      },
      Currency: {
        currencyPayment: _requestPaymentCurrency || this.currencyPayment,
      },
      ExRate: this.DailyExRate,
      UserCurrency: this.userCurrency,
      DocumentTotals: {
        currencyChange: this.currencyChange, // Cambio actual
        totalUSD: this.totalUSD, // Total del documento en dolares
        total: this.total, // Total del documento en colones
        TotalUnvatted: +(this.itemsList.filter(x => /* x.ItemClass === ItemsClass.Service && */ x.TaxCode !== '4IVA').reduce(function (acc, obj) {
          return acc + obj.LinTot
        }, 0)).toFixed(this.COMPANY.DecimalAmountTotalLine),
        VatRefunded: Math.round(this.itemsList.filter(x => x.TaxCode === '4IVA').reduce((acc, obj) => acc + (obj.LinTot / 1.04) * 0.04, 0) * DECS) / DECS,
        UnVattedAmount: unvattedAmount
      },
      InvoiceInfo: InvoiceInfo,
      PinpadInfo: {
        PreDocument: this.GeneratePreDocumentForPPPayment(), // Documento que se requiere por el BAC para pp
        PrePaymentLines: this.CreatePaymentLines(),
        Transactions: this.transactions
      },
      OnFail: {
        IsFail: this.IsPaymentFail,
        DataForFail: this.PaymentFail
      },
      CardValid: this.DefaultCardValid,
      DefaultCardNumber: this.DefaultCardNumber,
      RequiredTransferRef: true,
      TappSettings: this.tappSetting,
      OpenTappInvoiceResponse: this.openTappInvoiceResponse,
      AvailableTappRewards: this.openTappInvoiceResponse && this.openTappInvoiceResponse.tapp_customer_rewards && this.openTappInvoiceResponse.tapp_customer_points ? this.FilterInflateRewards(this.openTappInvoiceResponse.tapp_customer_rewards, this.openTappInvoiceResponse.tapp_customer_points, this.total) : null,
      FarmaValueSettings: this.GenerateFarmaValueSettings(),
      IspaymentRecived: false
    };

    return requiredDataForPay;
  }

  GeneratePreDocumentForPPPayment() {

    const linesList: any[] = [];
    this.itemsList.filter(x => x.LineStatus !== 'C').forEach(element => {
      if (element.ItemCode !== '') {
        const auxiliar = {...element};
        auxiliar.ItemCode = element.Item;
        linesList.push(auxiliar);
      }
    });

    let FE = {
      'IdType': this.feForm.value.IdType,
      'Email': this.feForm.value.Email,
      'Identification': this.feForm.value.Identification
    }

    let documentValue = this.documentForm.getRawValue();

    const Invoice = {
      'InvoiceLinesList': linesList,
      'DocumentType': documentValue.DocumentType,
      'CardCode': documentValue.cardCode,
      'CardName': documentValue.cardName,
      'PayTerms': documentValue.PayTerms,
      'Comment': documentValue.Comment,
      'SlpCode': documentValue.SlpList,
      'FEInfo': FE,
      'CLVS_POS_UniqueInvId': this.uniqueInvCode
    };
    return Invoice;
  }

  // Levanta el componente de pagos y retorna los pagos realizados.
  RisePaymentComponent(): void {
    let modalOption: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'Modal-Xl'
    };

    if(this.udfsForm.get("U_Categoria").value == this.farmaValueCategoryCode){

      this.blockUI.start("Validando cupones...");

      forkJoin(
        this.documentService.ValidateFarmaValueCupon(this.udfsForm.get("U_Cupon_FarmaV").value), 
        this.documentService.ValidateFarmaValueCupon(this.udfsForm.get("U_Cupon_FarmaV2").value)
      ).subscribe(next => {

        if(next[0].Data){
          this.farmaValueSetting.Monto1 = next[0].Data.Amount;
          this.farmaValueSetting.Valid1 = next[0].Data.Valid;
        }

        if(next[1].Data){
          this.farmaValueSetting.Monto2 = next[1].Data.Amount;
          this.farmaValueSetting.Valid2 = next[1].Data.Valid;
        }

        this.modalPay = this.modalService.open(PaymentComponent, modalOption);

        this.modalPay.componentInstance.requiredData = this.GenerateDataForPayment();

        this.blockUI.stop();

        this.modalPay.result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
          console.log('result', result)
        }, (reason) => {
          if (reason.status === PaymentResults.Created)
          {
            this.Payment = reason.Payment;
    
            this.changeColones = reason.Changes.COL;
    
            this.changeDolares = reason.Changes.USD;
    
            this.authorizatorPermissions = [];
    
            this.PaymentFail = reason.OnFail;
    
            this.Payment.PaymentInvoices = this.CreatePaymentLines();
    
            this.terminals = reason.PinpadInfo.terminals;
    
            this.transactions = reason.PinpadInfo.pinPadCards;
    
            this.CreatePay(this.Payment);
    
          }
          else if (reason.status == PaymentResults.CancelButton)
          {
            this.IsPaymentFail = false;
    
            this.transactions = [];
          }
        });
      }, error => {
        this.blockUI.stop();

        this.modalPay = this.modalService.open(PaymentComponent, modalOption);

        this.modalPay.componentInstance.requiredData = this.GenerateDataForPayment();

        this.modalPay.result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
          console.log('result', result)
        }, (reason) => {
          if (reason.status === PaymentResults.Created)
          {
            this.Payment = reason.Payment;
    
            this.changeColones = reason.Changes.COL;
    
            this.changeDolares = reason.Changes.USD;
    
            this.authorizatorPermissions = [];
    
            this.PaymentFail = reason.OnFail;
    
            this.Payment.PaymentInvoices = this.CreatePaymentLines();
    
            this.terminals = reason.PinpadInfo.terminals;
    
            this.transactions = reason.PinpadInfo.pinPadCards;
    
            this.CreatePay(this.Payment);
    
          }
          else if (reason.status == PaymentResults.CancelButton)
          {
            this.IsPaymentFail = false;
    
            this.transactions = [];
          }
        });
      });
    } else {
      this.modalPay = this.modalService.open(PaymentComponent, modalOption);

      this.modalPay.componentInstance.requiredData = this.GenerateDataForPayment();

      this.modalPay.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
        console.log('result', result)
      }, (reason) => {
        if (reason.status === PaymentResults.Created)
        {
          this.Payment = reason.Payment;
  
          this.changeColones = reason.Changes.COL;
  
          this.changeDolares = reason.Changes.USD;
  
          this.authorizatorPermissions = [];
  
          this.PaymentFail = reason.OnFail;
  
          this.Payment.PaymentInvoices = this.CreatePaymentLines();
  
          this.terminals = reason.PinpadInfo.terminals;
  
          this.transactions = reason.PinpadInfo.pinPadCards;
  
          this.CreatePay(this.Payment);
  
        }
        else if (reason.status == PaymentResults.CancelButton)
        {
          this.IsPaymentFail = false;
  
          this.transactions = [];
        }
      });
    }
  }

  CreatePaymentLines(): PaymentLines[] {

    const PaymentLines: PaymentLines[] = [];

    let payIsLocalCurrency: boolean = this.Payment.DocCurrency == "COL";

    let documentPriceList = this.documentForm.get("PriceList").value;

    let docIsLocalCurrency: boolean = this.PriceList.find(pl => pl.ListNum == documentPriceList).PrimCurr == "COL";

    let totalApplied: number = this.GetTotalApplied();

    let AppliedFC: number = 0;

    let SumApplied: number = 0;

    if (docIsLocalCurrency) {
      if (!payIsLocalCurrency) {
        SumApplied = totalApplied * this.currencyChange;

        AppliedFC = totalApplied;
      } else {
        SumApplied = totalApplied;
      }
    } else {
      if (!payIsLocalCurrency) {
        AppliedFC = totalApplied;
      } else {
        SumApplied = totalApplied;

        AppliedFC = totalApplied / this.currencyChange;
      }
    }

    PaymentLines.push({
      AppliedFC: Number(AppliedFC.toFixed(this.COMPANY.DecimalAmountTotalDocument)),
      DocEntry: 0,
      InvoiceType: BoRcptInvTypes.it_Invoice,
      SumApplied: Number(SumApplied.toFixed(this.COMPANY.DecimalAmountTotalDocument))
    });
    return PaymentLines;
  }

  GetTotalApplied(): number {
    let totalApplied: number = 0;

    totalApplied += this.Payment.CashSum;

    if (this.Payment.PaymentCreditCards) {
      this.Payment.PaymentCreditCards.forEach(c => {
        totalApplied += c.CreditSum;
      });
    }

    totalApplied += this.Payment.TransferSum;

    return totalApplied;
  }

  CreatePay(payment: BasePayment) {

    if (this.isBilling) {
      this.attemptsWhileBilling++;

      console.log('Intento duplicación de factura ', this.attemptsWhileBilling);

      return;
    }

    this.blockUI.start('Generando factura y pago, espere por favor...');

    this.isBilling = true;

    this.flagForm = true;

    let document = this.BuildInvoiceDocument(payment);

    this.documentService.CreateInvoice(document).pipe(
      switchMap((data: any) => {
          if (this.openTappInvoiceResponse && data.Result) {
            return this.PointsTapp(data.DocEntry, document).pipe(map(res => {
              return data;
            }))
          } else {
            return of(data);
          }
        }
      )
    ).subscribe((data: any) => {
      this.blockUI.stop();

      if (data.Result) {
        this.flagForm = false;

        this.isOnInvoiceDocument = true;
        this.NameActionDocument = 'Factura creada correctamente';
        this.returnedDocEntry = data.DocEntry;
        this.returnedDocNum = data.DocNum;
        this.returnedNumFE = data.NumDocFe;
        this.baseLines = null;
        this.IsPaymentFail = false;
        this.uniqueInvCode = '';
        this.authorizatorPermissions = [];

        this.RiseInvoceCompleteModal(true);

        const STATUS = {
          documentInformationSync: true,
          connectionType: this.storage.getConnectionType()
        };

        this.commonService.offlineInformationPending.next(STATUS);

        this.baseLines = null;

        this.printARInvoice(data.DocEntry, this.transactions.length);

        this.lastInvoice = data.DocEntry;

        this.btnVisibleBool = false;
      } else {
        this.blockUI.stop();
        this.IsPaymentFail = true;
        this.RisePaymentComponent();

        this.blockUI.stop();
        this.alertService.errorAlert(`Error al crear el documento: código: ${data.Error.Code}, mensaje: ${data.Error.Message}`);
        this.attemptsWhileBilling = 0;
        this.isBilling = false;
        this.flagForm = false;
      }
    }, (error) => {
      this.IsPaymentFail = true;
      this.RisePaymentComponent();

      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
      this.attemptsWhileBilling = 0;
      this.isBilling = false;
      this.flagForm = false;
    });

  }

  RiseInvoceCompleteModal(modalContent: boolean): void {
    this.modalInvoiceContent = modalContent;
    (<HTMLButtonElement>document.getElementById('triggerAfterPayModal')).click();

  }

  PrintDocument() {
    switch (this.storage.GetDocumentType()) {
      case SOAndSQActions.CreateQuotation:
      case SOAndSQActions.EditQuotation:
        this.printQandSO(this.lastQuotation, ReportType.Quotation);
        break;
      case SOAndSQActions.CreateSaleOrder:
      case SOAndSQActions.CopyToOrder:
      case SOAndSQActions.EditOrder:
        this.printQandSO(this.lastSO, ReportType.SaleOrder);
        break;
      case SOAndSQActions.CreateInvoice:
      case SOAndSQActions.CopyToInvoice:
        this.printARInvoice(this.lastInvoice, this.transactions.length);
        break;
    }

  }

  /**
   * Print the invoice
   * @param pDocEntry Document entry of the invoice
   * @param pPinPadTransactionsCount Count of PinPad transactions applied to this invoice
   */
  printARInvoice(pDocEntry: number, pPinPadTransactionsCount: number)
  {
    if (pPinPadTransactionsCount > 0)
    {
      let rawData = `>count:${pPinPadTransactionsCount}`;

      let isQuickPayment: boolean = false;

      this.transactions.forEach((x, index) => {

        const EMVS_STREAM = JSON.parse(this.transactions[index].SerializedTransaction)['EMVStreamResponse'];

        const RIGHT_SIDE = +EMVS_STREAM.salesAmount.slice(0, -2);

        const LEFT_SIDE = +`0.${EMVS_STREAM.salesAmount.slice(-2, EMVS_STREAM.salesAmount.length)}`;

        const TERMINAL = this.terminals.find(y => y.TerminalId == this.transactions[index].TerminalId);

        isQuickPayment = (RIGHT_SIDE + LEFT_SIDE <= TERMINAL.QuickPayAmount)
          && (EMVS_STREAM.entryMode.includes('CLC') || EMVS_STREAM.entryMode.includes('CHP'));

        const OFFSET = index + 1;
        rawData += `>cdn${OFFSET}:${EMVS_STREAM['maskedCardNumber']}`;
        rawData += `>aut${OFFSET}:${EMVS_STREAM['authorizationNumber']}`;
        rawData += `>ref${OFFSET}:${EMVS_STREAM['referenceNumber']}`;
        rawData += `>ter${OFFSET}:${x.TerminalId}`;
        rawData += `>amt${OFFSET}:${RIGHT_SIDE + LEFT_SIDE}`;
        rawData += `>cur${OFFSET}:${TERMINAL.Currency}`;
        rawData += `>qkp${OFFSET}:${+isQuickPayment}`;
        rawData += `>ptt${OFFSET}:${EMVS_STREAM['printTags']['string']}`
        rawData += `>end${OFFSET}`;
      });

      this.blockUI.start();

      this.reportsService.PrintReportPP(pDocEntry, rawData)
          .pipe(
          finalize(() => this.blockUI.stop())
        )
          .subscribe({
                next: (response) => {
            if (!isQuickPayment)
            {
              printJS({
                printable: response.SignedReport,
                type: 'pdf',
                base64: true
              });
            }
            else
            {
              printJS({
                printable: response.SignedReport,//response.UnsignedReport,
                type: 'pdf',
                base64: true
              });
            }
          },
            error: (error) => {
            console.error(error);

            this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
          }
        });
    }
    else
    {
      this.blockUI.start();

      this.reportsService.printReport(pDocEntry, ReportType.ArInvoice)
        .pipe(
          finalize(() => this.blockUI.stop())
        )
        .subscribe({
          next: (response) => {
            if(response.Result)
            {
              printJS({
                printable: response.Data,
                type: 'pdf',
                base64: true
              });
            }
          },
            error: (error) => {
            console.error(error);

            this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
          }
        });
    }
  }

  printQandSO(_docEntry: number, _reportType: number): void {
    this.blockUI.start('Generando la impresión...');
    this.reportsService.printReport(_docEntry, _reportType)
      .subscribe(data => {
        this.blockUI.stop();
        if (data.Result) {
          if (this.electronRendererService.CheckElectron()) {
            let fileName = 'Quotation_' + _docEntry + '.pdf';
            let file = {"fileName": fileName, "file": data.Data};
            this.electronRendererService.send('Print', file);
          } else {
            printJS({
              printable: data.Data,
              type: 'pdf',
              base64: true
            })
          }
        } else {
          this.alertService.errorInfoAlert(`Error obteniendo reporte, error: ${data.Error.Code}-${data.Error.Message}`);
        }
      }, error => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
      });
  }

  /**
   * Start the authorization process for discounts
   * @param pContent Modal content
   * @constructor
   * @private
   */
  private StartDiscountsAuthorizationProcess(pContent: any): boolean
  {
    // Determina si algun item no tiene descuento previo definido
    const NOT_DISCOUNT = this.itemsList.some(x => !x.HasDiscount && x.DiscountPercent > 0 && !x.IsDiscountByTapp);

    const DISCOUNT_CHANGE = this.itemsList.some(x => x.HasDiscount && x.DiscountPercent !== x.DiscountG && !x.IsDiscountByTapp);

    const IS_DISCOUNT_OVERFLOWED = this.itemsList.some(x => (x.DiscountPercent ? x.DiscountPercent : 0) > this.maxDiscuont && !x.HasDiscount && !x.IsDiscountByTapp);

    this.authorizationModalMessage = `Es requerido un usuario autorizador para este proceso`;

    const DISCOUNT_PERMISSION = this.authorizatorPermissions.find(x => x.Name == 'W_AllowDiscounts');

    const HAS_LINES_WITH_AUTHORIZATION_REQUIRED = this.itemsList.some(l => l.AuthorizationRequired);

    if (((NOT_DISCOUNT && IS_DISCOUNT_OVERFLOWED) || DISCOUNT_CHANGE || HAS_LINES_WITH_AUTHORIZATION_REQUIRED) &&
      !this.canAllowDiscount &&
      (this.storage.GetDocumentType() == SOAndSQActions.CreateInvoice))
    {
      if (this.authorizatorPermissions.length > 0)
      {
        if (!DISCOUNT_PERMISSION || !DISCOUNT_PERMISSION.Active)
        {
          this.authorizationModalMessage = `El usuario autorizador no tiene permiso para autorizar descuentos`;

          this.RaiseAutenticationModal(pContent);

          return true;
        }
      }
      else
      {
        this.RaiseAutenticationModal(pContent);

        return true;
      }
    }

    return false;
  }

  //#region Creacion documento
  onSubmit(content: any) {

    if(this.StartDiscountsAuthorizationProcess(content))
    {
      return;
    }

    if (!this.UdfPaymentValidation())
    {
      this.blockUI.stop();
      this.isBilling = false;
      this.attemptsWhileBilling = 0;
      return;
    }

    if (this.flagForm)
    {
      this.alertService.infoAlert('Intento duplicación documento');
      return;
    }

    if (this.invalidPatient)
    {
      this.alertService.PromptedTitleddAlert("El paciente ingresado no existe", `¿ Desea crearlo ?`, `question`)
      .then(result => {
        if (result)
        {
          this.RaisePatientModal();
        }
        else
        {
          this.alertService.Continue('Se cambiará al cliente por defecto', 'info')
          .then(result => {
            this.bpService.GetCustomerDetails(this.DEFAULT_BUSINESS_PARTNER.toString(), this.DEFAULT_BUSINESS_PARTNER.toString())
              .subscribe({
                next: (response) => {
                  //TODO: Change U_NumIdentFE value here

                  this.documentForm.patchValue({
                    cardCode: response.Data.CardCode,
                    Code: response.Data.Code,
                    cardName: {
                      CardName: response.Data.CardName,
                      CardCode: response.Data.CardCode,
                      Cedula: response.Data.Cedula,
                      Code: response.Data.Code.toString()
                    }
                  });
                },
                error: (error) => {
                  this.alertService.errorInfoAlert(error);
                }
              });
          });
        }
      });

      return;
    }

    if (this.itemsList.length === 0)
    {
      this.alertService.errorInfoAlert(`Por favor agregue un producto al menos`);
      return;
    }

    // Validar aplica porcentaje pago
    if (this.storage.GetDocumentType() == SOAndSQActions.CreateInvoice)
    {
      this.isPaymentPercent = (this.minCreditPayment > 0) && this.itemsList.every(x => x.Speciality === this.itemsList[0].Speciality) && this.specialities.find(x => x.Code === this.itemsList[0].Speciality).PorcentajePago;
    }

    const CORRUPTED_QUANTITY = this.itemsList.find(x => x.Quantity <= 0);

    if (CORRUPTED_QUANTITY)
    {
      this.alertService.errorAlert(`Cantidad del producto   ${CORRUPTED_QUANTITY.ItemCode}, debe ser mayor a 0`);
      return;
    }

    if (!this.COMPANY.HasZeroBilling)
    {

      const CORRUPTED_ITEM = this.itemsList.find(x => x.LinTot == 0);

      if (CORRUPTED_ITEM) {
        this.alertService.errorAlert(`El total de linea del producto "${CORRUPTED_ITEM.ItemCode}" es 0, elimínelo por favor.`);
        return;
      }

      const DIFERENCECOST_ITEM = this.itemsList.find(x => +x.UnitPrice <= (x.LastPurchasePrice ? +x.LastPurchasePrice : 0) && +x.UnitPrice != 0);

      if (DIFERENCECOST_ITEM)
      {
        this.alertService.errorAlert(`Costo del artículo "${DIFERENCECOST_ITEM.ItemCode}" es mayor o igual al precio de venta, modifique precio por favor. Precio venta: ${DIFERENCECOST_ITEM.UnitPrice} Precio costo: ${DIFERENCECOST_ITEM.LastPurchasePrice} `);
        return;
      }

    }
    else
    {
      const DIFERENCECOST_ITEM = this.itemsList.find(x => +x.UnitPrice <= (x.LastPurchasePrice ? +x.LastPurchasePrice : 0) && +x.UnitPrice != 0);

      if (DIFERENCECOST_ITEM)
      {
        this.alertService.errorAlert(`Costo del artículo "${DIFERENCECOST_ITEM.ItemCode}" es mayor o igual al precio de venta, modifique precio por favor. Precio venta: ${DIFERENCECOST_ITEM.UnitPrice} Precio costo: ${DIFERENCECOST_ITEM.LastPurchasePrice} `);
        return;
      }
    }

    const AVAILABLE_INV = this.itemsList.find(x => +x.available < (this.itemsList.filter(y => y.Item == x.Item && y.WarehouseCode == x.WarehouseCode && y.ItemClass != ItemsClass.Service).reduce((p, c) => {
      return p + c.Quantity
    }, 0)) && x.InvntItem === "Y");

    if (AVAILABLE_INV)
    {
      // Generar data necesaria para enviar a modal de pago
      if ((this.storage.GetDocumentType() == SOAndSQActions.CreateInvoice) || (this.storage.GetDocumentType() == SOAndSQActions.CopyToInvoice))
      {

        if (this.isAllowedGoodReceipt)
        {
          this.InventorySettings();
        }
        else
        {
          this.alertService.infoInfoAlert(`Existen artículos que no cuentan con inventario, elimínelos para crear la factura.`);
        }

        return;
      }
      else
      {
        //Generar data necesaria para enviar a modal de pago
        if ((this.storage.GetDocumentType() == SOAndSQActions.CopyToOrder) || (this.storage.GetDocumentType() == SOAndSQActions.CreateSaleOrder)
          || (this.storage.GetDocumentType() == SOAndSQActions.EditOrder))
        {
          this.alertService.infoInfoAlert(`Sin stock, no hay cantidad solicitada para el producto ${AVAILABLE_INV.ItemCode}, disponible:${AVAILABLE_INV.available} `);
          return;
        }
      }
    }

    if (!this.salesPersons.find(x => x.SlpCode == this.documentForm.controls.SlpList.value))
    {
      this.alertService.infoInfoAlert(`Por favor seleccione un vendedor`);
      return;
    }

    //this.documentForm.patchValue({cardCode: (<HTMLInputElement>document.getElementById(`customerCode`)).value});

    if (this.documentForm.invalid)
    {
      this.isOnSubmit = false;

      this.alertService.infoAlert("Debe haber seleccionado tipo factura, cliente, término de pago");

      return;
    }

    //Generar data necesaria para enviar a modal de pago
    if ((this.storage.GetDocumentType() == SOAndSQActions.CreateInvoice) || (this.storage.GetDocumentType() == SOAndSQActions.CopyToInvoice))
    {
      const HAS_ANOTHER_PAYMENT = this.documentForm.controls.AnotherPayment.value !== '-1';

      if (Number(this.PayTermsList.find(x => x.GroupNum == this.documentForm.controls.PayTerms.value).Type) === PayTermsEnum.Credito)
      {
        const DOCUMENT = this.BuildInvoiceDocument();

        if (this.ValidateRequiredFields(DOCUMENT))
        {
          return;
        }

        let usedMappedPayment = ``;

        if (HAS_ANOTHER_PAYMENT)
        {
          const PAYMENT_METHOD: IMappedPayment = this.mappedPayments.find(x => x.FldValue === this.documentForm.controls.AnotherPayment.value);

          if (!PAYMENT_METHOD)
          {
            this.alertService.infoInfoAlert(`No se encontró el medio de pago solicitado`);
            return;
          }

          usedMappedPayment = ` con el método de pago ${PAYMENT_METHOD.Descr}`;

          DOCUMENT.Invoice.CardCode = PAYMENT_METHOD.CardCode;

          DOCUMENT.Invoice.UdfTarget.push({
            Name: `U_OtroMP`,
            Value: PAYMENT_METHOD.FldValue,
            FieldType: `1`
          } as IUdfTarget);
        }

        this.blockUI.start(`Generando factura crédito${usedMappedPayment}, espere por favor`);

        this.flagForm = true;

        this.documentService.CreateInvoice(DOCUMENT)
          .subscribe((data: any) => {
            if (data.Result)
            {
              this.uniqueInvCode = "";
              this.blockUI.stop();
              this.baseLines = null;
              this.isOnInvoiceDocument = true;
              this.NameActionDocument = 'Factura crédito creada correctamente';
              this.returnedDocEntry = data.DocEntry;
              this.returnedDocNum = data.DocNum;
              this.returnedNumFE = data.NumDocFe;
              this.authorizatorPermissions = [];

              this.RiseInvoceCompleteModal(false);

              const STATUS = {
                documentInformationSync: true,
                connectionType: this.storage.getConnectionType()
              };
              this.commonService.offlineInformationPending.next(STATUS);
              this.printARInvoice(data.DocEntry, this.transactions.length);
              this.lastInvoice = data.DocEntry;
              this.btnVisibleBool = false;
              this.flagForm = false;
            }
            else
            {
              this.blockUI.stop();
              this.flagForm = false;
              this.alertService.errorAlert(`Error al crear el pago: código: ${data.Error.Code}, mensaje: ${data.Error.Message}`);
              this.attemptsWhileBilling = 0;
              this.isBilling = false;
              this.flagForm = false;
            }
          }, (error) => {
            this.blockUI.stop();
            this.flagForm = false;
            this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
          });
      }
      else
      {
        this.isRequestinBacPayment = false;

        const DOCUMENT = this.BuildInvoiceDocument();

        if (this.ValidateRequiredFields(DOCUMENT))
        {
          return;
        }

        this.RisePaymentComponent();
      }
    }
    else
    {
      switch (this.storage.GetDocumentType()) {
        case SOAndSQActions.CreateQuotation:
        case SOAndSQActions.EditQuotation:
          this.PostQuotation();
          break;
        case SOAndSQActions.CreateSaleOrder:
        case SOAndSQActions.CopyToOrder:
        case SOAndSQActions.EditOrder:
          this.PostSaleOrder();
          break;
      }
    }
  }

  PostSaleOrder() {
    let linesList: IDocumentLine[] = this.itemsList.map((line, index) => {
      let item = {
        ItemCode: line.Item,
        DiscountPercent: line.DiscountPercent,
        DiscountG: line.DiscountG,
        Quantity: line.Quantity,
        TaxCode: line.TaxCode,
        TaxRate: line.TaxRate,
        UnitPrice: line.UnitPrice,
        WarehouseCode: line.WarehouseCode,
        TaxOnly: line.TaxOnly ? 'tYES' : 'tNO',
        LineNum: line.LineNum,
        BaseEntry: line.BaseEntry,
        BaseLine: line.BaseLine,
        BaseType: line.BaseType || -1,
        LineStatus: line.LineStatus
      } as IDocumentLine

      return item;

    });

    if (this.storage.GetDocEntry() > 0) {
      if (this.baseLines && this.storage.GetDocumentType() === SOAndSQActions.CopyToOrder) {
        let documentEntry = this.storage.GetDocEntry();
        this.baseLines.forEach(i => {
          let aux = linesList.find(x => x.ItemCode === i.ItemCode && !x['read']);
          if (aux) {
            aux['read'] = true;
            aux.BaseEntry = documentEntry;
            aux.BaseLine = i.BaseLine;
            aux.BaseType = BaseTypeLines.SALE_ORDER;
          }
        });
      }
    }

    let DocEntry: number = (this.storage.GetDocumentType() == SOAndSQActions.EditOrder) ? this.storage.GetDocEntry() : 0;

    const SaleDocument = {
      U_Online: '0',
      DocEntry: DocEntry,
      BaseEntry: this.storage.GetDocEntry(),
      DocumentLines: linesList,
      CardCode: this.documentForm.controls.cardCode.value,
      CardName: this.documentForm.controls.cardName.value,
      DocCurrency: this.userCurrency,
      PaymentGroupCode: this.documentForm.controls.PayTerms.value,
      SalesPersonCode: this.documentForm.controls.SlpList.value,
      Comments: this.documentForm.controls.Comment.value,
      DocType: BoDocumentTypes.dDocument_Items,
      U_TipoIdentificacion: (this.feForm.controls.IdType.value != '00') ? this.feForm.controls.IdType.value : null,
      U_NumIdentFE: this.feForm.controls.Identification.value,
      U_CorreoFE: this.feForm.controls.Email.value,
      U_ObservacionFE: this.feForm.value.ObservacionFE,
      U_CLVS_POS_UniqueInvId: this.uniqueInvCode,
      UdfTarget: this.mappedUdfs,
      U_TipoDocE: this.documentForm.controls.DocumentType.value,
      DocDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      DocDueDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      U_ListNum: this.documentForm.controls.PriceList.value

    } as ISaleDocument;


    if ((this.storage.GetDocumentType() == SOAndSQActions.CreateSaleOrder) || (this.storage.GetDocumentType() == SOAndSQActions.CopyToOrder)) {
      SaleDocument.DocumentLines = linesList.filter(x => x.LineStatus !== 'C');
      this.blockUI.start('Creando orden de venta, espere por favor...');
      this.flagForm = true;
      this.documentService.PostDocumentSaleOrder(SaleDocument)
        .subscribe((data: any) => {
          this.blockUI.stop(); // Stop blocking
          if (data.Result) {
            this.flagForm = false;
            this.NameActionDocument = 'Órden de venta creada con éxito';
            this.returnedDocEntry = data.DocEntry;
            this.returnedDocNum = data.DocNum;
            this.lastSO = data.DocEntry;
            (<HTMLButtonElement>document.getElementById('triggerAfterPayModal')).click();
            // this.alertService.successAlert(` Orden de venta creada con éxito, número documento: ${data.DocNum}, DocEntry: ${data.DocEntry}`);
          } else {
            this.flagForm = false;
            this.alertService.errorAlert(`Error al intentar crear el documento, Código: ${data.Error.Code}, Mensaje: ${data.Error.Message}`);
          }
          this.isOnSubmit = false;
        }, (error: any) => {
          this.flagForm = false;
          this.isOnSubmit = false;
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
        });
    }
    if (this.storage.GetDocumentType() == SOAndSQActions.EditOrder) {
      this.blockUI.start('Actualizando orden de venta, espere por favor...');
      this.flagForm = true;
      this.documentService.PostUpdateSaleOrder(SaleDocument)
        .subscribe((data: any) => {
          this.blockUI.stop(); // Stop blocking
          if (data.Result) {
            this.flagForm = false;
            this.NameActionDocument = 'Órden de venta actualizada con éxito';
            this.returnedDocEntry = data.DocEntry;
            this.returnedDocNum = data.DocNum;
            this.lastSO = data.DocEntry;
            (<HTMLButtonElement>document.getElementById('triggerAfterPayModal')).click();
            // this.alertService.successAlert(` Orden de venta creada con éxito, número documento: ${data.DocNum}, DocEntry: ${data.DocEntry}`);
          } else {
            this.flagForm = false;
            this.alertService.errorAlert(`Error al intentar crear el documento, Código: ${data.Error.Code}, Mensaje: ${data.Error.Message}`);
          }
          this.isOnSubmit = false;
        }, (error: any) => {
          this.flagForm = false;
          this.isOnSubmit = false;
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
        });
    }
  }

  PostQuotation() {

    let linesList: IDocumentLine[] = this.itemsList.map(line => {

      let item = {
        ItemCode: line.Item,
        DiscountPercent: line.DiscountPercent,
        DiscountG: line.DiscountG,
        Quantity: line.Quantity,
        TaxCode: line.TaxCode,
        TaxRate: line.TaxRate,
        UnitPrice: line.UnitPrice,
        WarehouseCode: line.WarehouseCode,
        TaxOnly: line.TaxOnly ? 'tYES' : 'tNO',
        LineNum: line.LineNum,
        BaseEntry: null,
        BaseLine: null,
        BaseType: -1,
        LineStatus: line.LineStatus

      } as IDocumentLine;

      return item;

    });

    let docEntry = this.storage.GetDocEntry();

    let Quotation = {
      DocEntry: docEntry,
      CardCode: this.documentForm.controls.cardCode.value,
      CardName: this.documentForm.controls.cardName.value,
      Comments: this.documentForm.controls.Comment.value,
      DocCurrency: this.userCurrency,
      DocDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      DocDueDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      DocType: BoDocumentTypes.dDocument_Items,
      PaymentGroupCode: this.documentForm.controls.PayTerms.value,
      SalesPersonCode: this.documentForm.controls.SlpList.value,
      U_Online: '0',
      U_TipoDocE: this.documentForm.controls.DocumentType.value,
      U_TipoIdentificacion: (this.feForm.controls.IdType.value != '00') ? this.feForm.controls.IdType.value : null,
      U_CorreoFE: this.feForm.value.Email,
      U_NumIdentFE: this.feForm.value.Identification,
      U_ObservacionFE: this.feForm.value.ObservacionFE,
      DocumentLines: linesList,
      UdfTarget: this.mappedUdfs,
      U_CLVS_POS_UniqueInvId: this.uniqueInvCode,
      U_ListNum: this.documentForm.controls.PriceList.value
    } as IQuotDocument;


    if (this.storage.GetDocumentType() == SOAndSQActions.CreateQuotation) {
      this.blockUI.start('Creando cotización, espere por favor...');
      this.flagForm = true;
      this.documentService.PostDocumentQuotation(Quotation)
        .subscribe((data: any) => {
          this.blockUI.stop(); // Stop blocking
          if (data.Result) {
            this.flagForm = false;
            this.NameActionDocument = 'Cotización creada con éxito';
            this.returnedDocEntry = data.DocEntry;
            this.returnedDocNum = data.DocNum;
            this.lastQuotation = data.DocEntry;
            (<HTMLButtonElement>document.getElementById('triggerAfterPayModal')).click();
            // this.alertService.successAlert(` Orden de venta creada con éxito, número documento: ${data.DocNum}, DocEntry: ${data.DocEntry}`);
          } else {
            this.flagForm = false;
            this.alertService.errorAlert(`Error al intentar crear el documento, Código: ${data.Error.Code}, Mensaje: ${data.Error.Message}`);
          }
          this.isOnSubmit = false;
        }, (error: any) => {
          this.flagForm = false;
          this.isOnSubmit = false;
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
        });

    }
    if (this.storage.GetDocumentType() == SOAndSQActions.EditQuotation) {
      this.blockUI.start('Actualizando cotización, espere por favor...');
      this.flagForm = true;
      this.documentService.PostUpdateQuotation(Quotation)
        .subscribe((data: any) => {
          this.blockUI.stop(); // Stop blocking
          if (data.Result) {
            this.flagForm = false;
            this.NameActionDocument = 'Cotización actualizada con éxito';
            this.returnedDocEntry = data.DocEntry;
            this.returnedDocNum = data.DocNum;
            this.lastQuotation = data.DocEntry;
            (<HTMLButtonElement>document.getElementById('triggerAfterPayModal')).click();
            // this.alertService.successAlert(` Orden de venta creada con éxito, número documento: ${data.DocNum}, DocEntry: ${data.DocEntry}`);
          } else {
            this.flagForm = false;
            this.alertService.errorAlert(`Error al intentar crear el documento, Código: ${data.Error.Code}, Mensaje: ${data.Error.Message}`);
          }
          this.isOnSubmit = false;
        }, (error: any) => {
          this.flagForm = false;
          this.isOnSubmit = false;
          this.blockUI.stop(); // Stop blocking
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
        });
    }

  }

  /**
   * Build the invoice document with the payments
   * @param payment Information of the payments
   * @constructor
   */
  BuildInvoiceDocument(payment: any = null): CreateInvoice {
    let invoiceLines: IDocumentLine[] = this.itemsList
      .filter(x => x.LineStatus !== 'C')
      .map(line => {
        let item = {
          ItemCode: line.Item,
          ItemName: line.ItemName,
          DiscountPercent: line.DiscountPercent,
          DiscountG: line.DiscountG,
          Quantity: line.Quantity,
          TaxCode: line.TaxCode,
          TaxRate: line.TaxRate,
          UnitPrice: line.UnitPrice,
          WarehouseCode: line.WarehouseCode,
          TaxOnly: line.TaxOnly ? 'tYES' : 'tNO',
          BaseEntry: line.BaseEntry,
          BaseLine: line.BaseLine,
          BaseType: line.BaseType || -1,
          LineStatus: line.LineStatus,
          U_Doctor: line.U_Doctor,
          Speciality: line.Speciality,
          Supplier: line.Supplier,
          Requestable: line.Requestable
        } as IDocumentLine;

        return item;
    });

    if (this.storage.GetDocEntry() > 0) {

      if (this.baseLines)
        this.baseLines.forEach(i => {
          let aux = invoiceLines.find(x => x.ItemCode === i.ItemCode && !x['read']);

          if (aux) {
            aux['read'] = true;
            aux.BaseEntry = this.storage.GetDocEntry();
            aux.BaseLine = i.LineNum;
            aux.BaseType = BaseTypeLines.INVOICE;
          }
        });
    }

    let documentValue = this.documentForm.getRawValue();

    let invoice = {
      BaseEntry: this.storageService.GetDocEntry(),
      CardCode: documentValue.cardCode,
      CardName: documentValue.cardName,
      Comments: documentValue.Comment,
      DocCurrency: this.userCurrency,
      DocDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      DocType: BoDocumentTypes.dDocument_Items,
      PaymentGroupCode: documentValue.PayTerms,
      SalesPersonCode: documentValue.SlpList,
      U_Online: '0',
      U_TipoDocE: documentValue.DocumentType,
      U_TipoIdentificacion: this.feForm.value.IdType === '00' ? null : this.feForm.value.IdType,
      U_CorreoFE: this.feForm.value.Email,
      U_NumIdentFE: this.feForm.value.Identification,
      U_ObservacionFE: null,
      DocumentLines: invoiceLines,
      UdfTarget: this.mappedUdfs,
      U_CLVS_POS_UniqueInvId: this.uniqueInvCode,
      U_ListNum: this.documentForm.controls.PriceList.value,
      U_withTransactionTapp: this.openTappInvoiceResponse ? 1 : 0,
      U_EMA_TAPP_IDENTIFICATION: this.openTappInvoiceResponse ? this.openTappInvoiceResponse.tapp_customer.identification : null
    } as DocumentModel;

    const TRANSACTIONS = payment ? payment.Transactions : null;

    if (payment && payment.Transactions) delete payment.Transactions;

    let createInvoiceReturn: CreateInvoice = {
      Invoice: invoice,
      Payment: payment,
      Transactions: TRANSACTIONS
    };

    return createInvoiceReturn;
  }

  //#endregion

  //#region Items

  cant: number;

  addItems(item, _isManualOverride = false) {
    if (this.ItemInfo.value)
    {
      let code = `harcodedCode`;

      let mobileNavigatorObject: any = window.navigator;

      if (this.ProcessingScanning)
      {
        return;
      }

      this.ProcessingScanning = true;

      if (_isManualOverride)
      {
        if (this.searchTypeManual)
        {
          this.ItemInfo.setValue('');
          this.Cant.setValue(1);
          this.buildedData = ``;
          this.ProcessingScanning = false;
          return;
        }

        for (let c = 0; c < this.itemsTypeaheadList.length; c++)
        {
          const BARCODE = this.itemsTypeaheadList[c].split(' COD. ')[1];

          if (BARCODE === this.ItemInfo.value || BARCODE === this.ItemInfo.value.split(' COD. ')[1])
          {
            code = this.itemsTypeaheadList[c].split(' COD. ')[0];
          }
        }

        if (code == `harcodedCode`)
        {
          const ITEM = this.itemsTypeaheadList.find((x) => x.includes(this.ItemInfo.value));

          if (!ITEM)
          {
            this.alertService.infoInfoAlert(`No existe coincidencia para ${this.ItemInfo.value}`);
            this.ItemInfo.setValue('');
            this.nameField.nativeElement.focus();
            this.ProcessingScanning = false;
            return;
          }

          code = ITEM.split(" COD. ")[0];

        }

        if (code == `harcodedCode` && item && item.item)
        {
          code = item.item.split(' COD. ')[0];
        }

        this.searchTypeManual = false;

      }
      else
      {
        code = item.item.split(' COD. ')[0];

        this.searchTypeManual = true;

      }

      if (mobileNavigatorObject && mobileNavigatorObject.clipboard)
      {
        mobileNavigatorObject.clipboard.readText()
          .then(text => {
            const priceList = this.documentForm.get('PriceList').value;
            if (!isNaN(parseInt(text))) {
              if (Number(text) > this.maxWeightTo0) {
                mobileNavigatorObject.clipboard.writeText("")
                  .then(text => {
                  })
                  .catch(err => {
                  });
              }
            }

            this.cant = Number(this.Cant.value);

            let cant2 = Number(this.Cant.value);

            let searchitem = true;

            const DECS = (() => +`1${`0`.repeat(this.COMPANY.DecimalAmountTotalDocument)}`)();

            if (this.isOnGroupLine)
            {
              var index: number = this.itemsList.indexOf(this.itemsList.find(x => x.Item == code));

              if (index != -1)
              {
                this.itemsList[index].Quantity += this.cant;
                this.itemsList[index].LinTot = Math.round((this.itemsList[index].Quantity * this.itemsList[index].UnitPrice) * DECS) / DECS;
                //this.itemsList[index].LinTot = this.itemsList[index].Quantity * this.itemsList[index].UnitPrice;
                searchitem = false;
                this.LineTotalCalculate(index);
                this.UpdateDocumentAmounts();
                this.GetAvailableItemInventory(code, this.itemsList[index].Quantity);
                this.ItemInfo.setValue('');
                this.Cant.setValue(1);
                this.searchTypeManual = false;
                this.ProcessingScanning = false;
                this.nameField.nativeElement.focus();

                return;
              }
            }

            if (this.isProcessing)
            {
              this.ItemInfo.setValue('');
              this.Cant.setValue(1);
              this.buildedData = ``;
              return;
            }

            this.isProcessing = true;

            this.blockUI.start('Obteniendo información del artículo, espere por favor...');

            this.hasLines = true;

            // Get default warehouse to find item info
            let warehouse = this.warehouses.find(x => x.Speciality === this.documentForm.controls['Speciality'].value);

            const CARDCODE = this.documentForm.get("cardCode").value;

            this.itemService.GetItemByItemCode(code, priceList, (warehouse ? warehouse.WhsCode : ''), CARDCODE, this.GetSelectedCategory(), this.businessPartnerIsAnActiveMemberOfMedismart)
              .pipe(
                concatMap(response => {
                  if(warehouse)
                  {
                    return of(response);
                  }

                  let headquarter = this.storage.GetCurrentHeadquarter();

                  return this.itemService.GetWHAvailableItem(code, (headquarter ? headquarter.Id : 0), response.Item.Speciality)
                    .pipe(
                      map(inventoryResponse => {
                        if(inventoryResponse.whInfo && inventoryResponse.whInfo.length)
                        {
                          response.Item.OnHand = inventoryResponse.whInfo[0].Disponible;
                        }

                        return response;
                      })
                    );
                }),
                finalize(() => this.blockUI.stop())
              )
              .subscribe({
                next: (data) => {
                  this.isProcessing = false;
                  this.searchTypeManual = false;
                  if (data.Result)
                  {
                    if (data.Item.HasInconsistency)
                    {
                      this.alertService.infoAlert(data.Item.InconsistencyMessage);
                    }

                    if (searchitem)
                    {
                      this.conta++;
                      this.total += data.Item.UnitPrice;
                      let tot = (data.Item.UnitPrice * this.cant);

                      // Filter default item warehouse
                      this.whCode = '';
                      this.whName = '';

                      if (data.Item.Speciality)
                      {
                        let warehouse = this.warehouses.find(x => x.Speciality === data.Item.Speciality);
                        if (warehouse)
                        {
                          this.whCode = warehouse.WhsCode;
                          this.whName = warehouse.WhsName;
                        }
                      }


                      let AUXILIAR_ITEM = {
                        Id: this.conta,
                        LinTot: tot,
                        ItemCode: `${data.Item.ItemCode} - ${data.Item.ItemName}`,
                        Item: data.Item.ItemCode,
                        ItemName: `${data.Item.ItemName}`,
                        Speciality: data.Item.Speciality,
                        U_Doctor: this.GetFirstLineSelectedDoctor(),
                        DiscountPercent: data.Item.Discount,
                        DiscountG: data.Item.DiscountG,
                        Quantity: this.cant,
                        U_SugPrice: 0,
                        Serie: '',
                        TaxCode: data.Item.TaxCode,
                        TaxRate: data.Item.TaxRate != 0.0 ? data.Item.TaxRate : 0.00,
                        UnitPrice: data.Item.UnitPrice,
                        WarehouseCode: this.whCode,
                        WhsName: this.whName,
                        TaxOnly: '',//data.Item.TaxOnly ? 'tYES' : 'tNO',
                        LineNum: null,
                        LastPurchasePrice: Number(data.Item.LastPurchasePrice.toFixed(this.COMPANY.DecimalAmountPrice)),
                        InvntItem: data.Item.InvntItem,
                        available: data.Item.OnHand,
                        LineStatus: 'O',
                        ItemClass: data.Item.ItemClass,
                        HasDiscount: data.Item.HasDiscount,
                        Supplier: data.Item.Supplier,
                        Requestable: data.Item.Requestable,
                        ItemGroupCode: data.Item.ItemGroup,
                        AuthorizationRequired: data.Item.AuthorizationRequired
                      } as IDocumentLine;

                      if (AUXILIAR_ITEM.Requestable && !AUXILIAR_ITEM.Supplier)
                      {
                        this.alertService.infoAlert('El artículo ' + AUXILIAR_ITEM.ItemName + ' no cuenta con un proveedor configurado.');
                      }

                      if (AUXILIAR_ITEM.TaxCode === '')
                      {
                        this.alertService.infoInfoAlert('El artículo ' + AUXILIAR_ITEM.ItemName + ' no cuenta con el código del impuesto.');
                      }

                      if (this.itemsList.some(x => x.Speciality !== AUXILIAR_ITEM.Speciality))
                      {
                        this.alertService.infoAlert('Uno o más artículos agregados poseen una especialidad diferente, lo que invalida el porcentaje de pago mínimo para este documento');
                      }

                      AUXILIAR_ITEM.UnitPrice = Number(AUXILIAR_ITEM.UnitPrice.toFixed(this.COMPANY.DecimalAmountPrice));

                      if (this.userCurrency != 'COL' && this.userCurrency != 'USD')
                      {
                        AUXILIAR_ITEM.Quantity = 1;
                        AUXILIAR_ITEM.LinTot = 0;
                        AUXILIAR_ITEM.UnitPrice = 0;
                        this.storage.setLog(`ERROR!, fecha: ${new Date()} ---(${this.userCurrency})`);
                      }

                      if(this.isLineMode)
                      {
                        this.itemsList.push(AUXILIAR_ITEM);
                        this.LineTotalCalculate(this.itemsList.length - 1)
                      }
                      else
                      {
                        this.itemsList.unshift(AUXILIAR_ITEM)
                        this.LineTotalCalculate(0)
                      }

                      this.OnDocumentLinesChange();

                      this.UpdateDocumentAmounts();
                    }

                    this.Cant.setValue(1);
                    this.ItemInfo.setValue('');
                    this.ProcessingScanning = false;
                    this.nameField.nativeElement.focus();
                    const LastPP = data.Item.LastPurchasePrice ? data.Item.LastPurchasePrice : 0;

                    if (data.Item.UnitPrice <= LastPP && data.Item.UnitPrice != 0)
                    {
                      this.alertService.infoInfoAlert(`Costo del Artículo: ${data.Item.ItemCode}-${data.Item.ItemName} es mayor o igual al precio de venta. Modifique precio por favor`);//Precio costo:	₡${data.Item.LastPurchasePrice} Precio Venta: ₡${data.Item.UnitPrice}
                    }

                    let cantsingroupLine: number = 0;

                    this.itemsList.forEach(x => {
                      if (x.Item === code) {
                        cantsingroupLine += x.Quantity;
                      }
                    });

                    if (((cantsingroupLine) > +data.Item.OnHand) && data.Item.ItemClass != ItemsClass.Service)
                    {
                      index = this.itemsList.indexOf(this.itemsList.find(x => x.Item == code && x.InvntItem === "Y"));

                      if (index != -1)
                      {
                        this.alertService.infoInfoAlert(`Sin stock, total solicitado en el almacén: ${cantsingroupLine}, disponible: ${+data.Item.OnHand} `);
                        searchitem = false;
                        this.LineTotalCalculate(index)
                        this.UpdateDocumentAmounts();
                      }
                    }

                    (<HTMLElement>document.getElementById('helperClick')).click();

                  }
                  else
                  {
                    this.ProcessingScanning = false;
                    this.alertService.errorAlert(`Error: no se pudo obtener la información del item solicitado: código: ${data.Error.Code}, mensaje: ${data.Error.Message}`);
                  }

                  this.ProcessingScanning = false;
                },
                error: (error) => {
                  this.isProcessing = false;
                  this.searchTypeManual = false;
                  this.ProcessingScanning = false;
                  this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
                }
              });
          })
          .catch(err => {
            this.isProcessing = false;
            this.searchTypeManual = false;
            this.ProcessingScanning = false;
            this.blockUI.stop();
            // console.error('Failed to read clipboard contents: ', err);
          });
      }
    }
  }

  /**
   * Return the selected doctor in the first added line
   * @constructor
   */
  GetFirstLineSelectedDoctor(): string
  {
    if(!this.itemsList || !this.itemsList.length)
    {
      return '';
    }

    let firstLineAdded = [...this.itemsList].pop();

    return firstLineAdded.U_Doctor;
  }

  /**
   * Handle the action when document line changed
   * @constructor
   */
  private OnDocumentLinesChange(): void
  {
    this.DoRequiredUDFOrdenLaboratorio();
  }


  /**
   * Check the list of document lines to do required the UDF U_OrdenLaboratorio if the items if of a specific group
   * @constructor
   * @private
   */
  private DoRequiredUDFOrdenLaboratorio(): void
  {
    let uOrdenLaboratorio = this.udfs.find(u => u.Name == "U_OrdenLaboratorio");

    if(!uOrdenLaboratorio)
    {
      return;
    }

    // 100: A011 LENTES
    // 140: A999 OTROS EXENTO
    uOrdenLaboratorio.IsRequired = this.itemsList.some(i => ["100", "140"].includes(i.ItemGroupCode.toString()));

    if(uOrdenLaboratorio.IsRequired)
    {
      this.udfsForm.get("U_OrdenLaboratorio").setValidators(Validators.required);
      this.udfsForm.get("U_OrdenLaboratorio").updateValueAndValidity();
    }
    else
    {
      this.udfsForm.get("U_OrdenLaboratorio").clearValidators();
      this.udfsForm.get("U_OrdenLaboratorio").updateValueAndValidity();
    }
  }

  // funcion para seleccionar un almacen nuevo para el item a facturar
  AvaItemSelected(event, avaItem, idx: number) {
    if (event.type === 'dblclick') {
      if (!this.isAllowedWHChange) {
        this.alertService.infoInfoAlert(`No tiene permiso para cambiar el almacén`);
        return;
      }
      if (+this.itemsList[this.indexAvaItem].Quantity === 0) {
        this.alertService.infoInfoAlert(`Por favor indique la cantidad en el ítem antes de cambiar el almacén`);
        return;
      }
      if (this.itemsList[this.indexAvaItem].Quantity > avaItem.Disponible && this.itemsList[this.indexAvaItem].InvntItem === "Y") {
        this.close();
        this.alertService.infoInfoAlert(`No se puede cambiar el almacén, stock insuficiente. Solicitado: ${this.itemsList[this.indexAvaItem].Quantity}, disponible: ${avaItem.Disponible}`);
        return;
      }

      // const QUANTITYTOTAL = this.itemsList.filter(y => y.Item == this.itemsList[idx].Item && this.itemsList[idx].InvntItem === "Y").reduce((p, c) => { return p + c.Quantity }, 0);

      // if (QUANTITYTOTAL > avaItem.Disponible && this.itemsList[idx].InvntItem === "Y") {
      //   this.close();
      //   this.alertService.infoInfoAlert(`No se puede cambiar el almacén, stock insuficiente. Solicitado: ${+QUANTITYTOTAL}, disponible: ${avaItem.Disponible}`);
      //   return;
      // }

      this.itemsList[this.indexAvaItem].WarehouseCode = avaItem.WhsCode;
      this.itemsList[this.indexAvaItem].WhsName = avaItem.WhsName;
      this.itemsList[this.indexAvaItem].Serie = '';
      this.itemsList[this.indexAvaItem].SysNumber = 0;
      this.itemsList[this.indexAvaItem].available = avaItem.Disponible;

      this.close();
      // (<HTMLButtonElement>document.getElementById('triggerWhsClose')).click();
    }
  }

  // funcion para obtener la informacion de los disponibles de un item en los almacenes
  // recibe como parametros el item y el index
  GetWHAvailableItem(pEvent: any, pContent: any, pDocumentLine: IDocumentLine, pDocumentLineIndex: number) {
    if (pDocumentLine.Item !== '')
    {
      if (pEvent.type === 'dblclick')
      {
        pEvent.srcElement.blur();

        pEvent.preventDefault();

        let headquarter = this.storage.GetCurrentHeadquarter();

        this.blockUI.start('Obteniendo disponibilidad del artículo, espere por favor...');

        this.itemService.GetWHAvailableItem(pDocumentLine.Item, (headquarter ? headquarter.Id : 0), pDocumentLine.Speciality)
          .pipe(
            finalize(() => this.blockUI.stop())
          )
          .subscribe({
            next: (response) => {
              if (response.Result)
              {
                this.WHAvailableItemList.length = 0;

                this.itemCode = pDocumentLine.Item;

                this.indexAvaItem = pDocumentLineIndex;

                this.WHAvailableItemList = response.whInfo;

                if (response.whInfo.length > 0)
                {
                  this.expandedIndex = -1;

                  this.expandRow(this.expandedIndex);

                  this.openModal(pContent);
                }
                else
                {
                  this.alertService.infoInfoAlert('Este artículo no posee disponibles en ningún almacén para esa especialidad en la sede selecionada.');
                }
              }
              else
              {
                this.alertService.errorAlert('Error al Obtener disponibilidad el artículo - ' + response.Error.Message);
              }
            },
            error: (error) => {
              this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
            }
          });
      }

    }
  }

  expandRow(index: number): void {
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
  }

  // funcion para cerrar la modal
  close() {
    this.modalReference.close();
  }

  openModal(content) {
    this.modalReference = this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg'});
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  // funcion para eliminar el item de la lista
  // recibe como parametro el item a eliminar
  removeItem(item) {
    if (item !== null)
    {
      const index = this.itemsList.indexOf(item);

      this.itemsList.splice(index, 1);

      this.OnDocumentLinesChange();

      this.UpdateDocumentAmounts();

      if (this.itemsList.length > 0)
      {
        this.hasLines = true;
      }
      else
      {
        this.hasLines = false
      };
    }
    else
    {
      this.alertService.warningInfoAlert(
        "No se puede eliminar la ultima linea del documento"
      );
    }
  }

  OnClickTaxOnly(ItemLine) {
    ItemLine.TaxOnly = !ItemLine.TaxOnly;
    this.UpdateDocumentAmounts();
  }

  changeTaxCode(i: number, item: any) {
    const rate = this.taxesList.find(i => i.TaxCode === item.TaxCode.toUpperCase());
    const idx: number = this.itemsList.indexOf(this.itemsList.find(x => x.ItemCode == item.ItemCode));
    if (typeof rate !== 'undefined') {
      this.itemsList[idx].TaxRate = parseFloat(this.decimalPipe.transform(rate.TaxRate, '.2'));
      this.itemsList[idx].TaxCode = rate.TaxCode.toUpperCase();
      this.LineTotalCalculate(idx);
    }
  }

  // funcion para calcular el total de la linea
  // recibe como parametro el index de la lista de items
  LineTotalCalculate(idx: number) {

    if (this.itemsList[idx].DiscountPercent == null) {
      this.itemsList[idx].DiscountPercent = 0;
    }

    if (this.itemsList[idx].IsDiscountByTapp) {
      if (this.itemsList[idx].reward_type === 3 || this.itemsList[idx].reward_type === 2 || this.itemsList[idx].reward_type === 1) {
        this.itemsList[idx].DiscountPercent = Number((this.itemsList[idx].real_cost / this.itemsList[idx].UnitPrice) * 100);
      }
    }

    const DECS = (() => +`1${`0`.repeat(this.COMPANY.DecimalAmountTotalDocument)}`)();

    const qty = this.itemsList[idx].Quantity;

    const price = this.itemsList[idx].UnitPrice;

    let FIRST_SUBTOTAL = Number(Math.round((qty * price) * DECS) / DECS);

    const LINE_DISCOUNT = ((Math.round(FIRST_SUBTOTAL * DECS) / DECS) * (this.itemsList[idx].DiscountPercent / 100));

    const SUBTOTAL_WITH_LINE_DISCOUNT = (Math.round(((FIRST_SUBTOTAL - LINE_DISCOUNT)) * DECS) / DECS);

    const SUBTOTAL_WITH_HEADER_DISCOUNT = SUBTOTAL_WITH_LINE_DISCOUNT;

    const CURRENT_TAX_RATE = this.itemsList[idx].TaxRate / 100;

    const TOTAL_TAX = SUBTOTAL_WITH_HEADER_DISCOUNT * CURRENT_TAX_RATE;

    const TOTAL_WITHOUT_TAX = SUBTOTAL_WITH_HEADER_DISCOUNT * +!this.itemsList[idx].TaxOnly

    this.itemsList[idx].LinTot = Math.round((Math.round((TOTAL_WITHOUT_TAX) * DECS) / DECS + Math.round(TOTAL_TAX * DECS) / DECS + Number.EPSILON) * DECS) / DECS;

    this.UpdateDocumentAmounts();
  }

  LineTotalCalculateExt(_idx: number) {
    if (this.itemsList[_idx].DiscountPercent == null) {
      this.itemsList[_idx].DiscountPercent = 0;
    }

    const DECS = (() => +`1${`0`.repeat(this.COMPANY.DecimalAmountTotalDocument)}`)();

    const qty = this.itemsList[_idx].Quantity;

    const price = this.itemsList[_idx].UnitPrice;

    let FIRST_SUBTOTAL = Number(Math.round((qty * price) * DECS) / DECS);

    const LINE_DISCOUNT = ((Math.round(FIRST_SUBTOTAL * DECS) / DECS) * (this.itemsList[_idx].DiscountPercent / 100));

    const SUBTOTAL_WITH_LINE_DISCOUNT = (Math.round(((FIRST_SUBTOTAL - LINE_DISCOUNT)) * DECS) / DECS);

    const SUBTOTAL_WITH_HEADER_DISCOUNT = SUBTOTAL_WITH_LINE_DISCOUNT;

    const CURRENT_TAX_RATE = this.itemsList[_idx].TaxRate / 100;

    const TOTAL_TAX = SUBTOTAL_WITH_HEADER_DISCOUNT * CURRENT_TAX_RATE;

    const TOTAL_WITHOUT_TAX = SUBTOTAL_WITH_HEADER_DISCOUNT * +!this.itemsList[_idx].TaxOnly

    this.itemsList[_idx].LinTot = Math.round((Math.round((TOTAL_WITHOUT_TAX) * DECS) / DECS + Math.round(TOTAL_TAX * DECS) / DECS + Number.EPSILON) * DECS) / DECS;

    this.UpdateDocumentAmounts();

  }

  //#endregion

  //#region Config vista document
  /**
   * Based on the local storage document type variable, defines the title of the page
   * @constructor
   */
  DefineTitleDocument(): void
  {
    switch (this.storage.GetDocumentType()) {
      case SOAndSQActions.EditOrder:
        this.documentTitle = 'Actualizar orden de venta'
        break;
      case SOAndSQActions.EditQuotation:
        this.documentTitle = 'Actualizar cotización'
        break;
      case SOAndSQActions.CopyToOrder:
      case SOAndSQActions.CreateSaleOrder:
        this.documentTitle = 'Crear orden de venta';
        break;
      case SOAndSQActions.CopyToInvoice:
      case SOAndSQActions.CreateInvoice:
        this.documentTitle = 'Crear factura';
        break;
      case SOAndSQActions.CreateQuotation:
        this.documentTitle = 'Crear cotización';
        break;
    }
  }

  DefineDocument(): void {
    switch (this.storage.GetDocumentType()) {
      case SOAndSQActions.EditOrder:
      case SOAndSQActions.CopyToOrder:
      case SOAndSQActions.CreateSaleOrder:
        this.documentTitle = 'Crear orden de venta';
        this.storage.SaveDocumentType(SOAndSQActions.CreateSaleOrder);
        this.router.navigate(['/', 'so', `${SOAndSQActions.CreateSaleOrder}`]);
        break;
      case SOAndSQActions.EditQuotation:
      case SOAndSQActions.CreateQuotation:
        this.documentTitle = 'Crear cotización';
        this.storage.SaveDocumentType(SOAndSQActions.CreateQuotation);
        this.router.navigate(['/', 'quotation', `${SOAndSQActions.CreateQuotation}`]);
        break;
      case SOAndSQActions.CreateInvoice:
      case SOAndSQActions.CopyToInvoice:
        this.documentTitle = 'Crear factura';
        this.storage.SaveDocumentType(SOAndSQActions.CreateInvoice);
        this.router.navigate(['/', 'invo', `${SOAndSQActions.CreateInvoice}`]);
        break;
    }
  }

  //#endregion

  //#region Limpiar vista documento
  ClearItemList() {
    // i need to set the current list over here
    const PRICE_LIST = this.PriceList.find(x => x.ListNum === +this.documentForm.get('PriceList').value);


    if (PRICE_LIST) {

      this.userCurrency = PRICE_LIST.PrimCurr;
      this.currencyPayment = this.userCurrency;
      if (PRICE_LIST.HasInconsitencies) {
        this.alertService.infoAlert(`Esta lista tiene inconsitencias usa colones y dólares`);
        return;
      }
    }
    this.itemsList.length = 0;
    this.GetItems(this.documentForm.controls['Speciality'].value);
    this.GetTaxes();
    this.GetAccount();
    this.GetCards();
    this.GetAccountsBank();
  }

  /**
   * Refresh the document information and load the lasted invoice in memory
   * @param pRemoveCurrentInvoiceInMemory Indicates if should delete the current invoice in memory
   * @constructor
   */
  CreateNew(pRemoveCurrentInvoiceInMemory: boolean = true): void
  {
    this.formsSubscriptions$.unsubscribe();

    this.formsSubscriptions$ = new Subscription();

    this.storageService.SetPatientToPreload(null);

    this.patient = null;

    this.isOnInvoiceDocument = false;

    this.ItemInfo.setValue('');

    this.buildedData = '';

    this.isScanning = false;

    this.isLockedByScanner = false;

    this.searchTypeManual = false;

    this.ProcessingScanning = false;

    this.NameActionDocument = ''

    this.invalidPatient = false;

    this.terminals = []

    this.transactions = [];

    this.InvalidCardName = false;

    this.commonService.hasDocument.next(``);

    this.storage.SaveBreadCrum(``);

    if (this.typeArrow) (<HTMLButtonElement>(document.getElementById('triggerFECollapser'))).click();

    this.storage.SaveDocEntry(-1);

    this.storage.SaveUIAction(-1);

    this.storage.SaveCustomerData('{}');

    this.btnVisibleBool = true;

    this.itemsList = [];

    this.udfsForm.reset();

    this.DoRequiredUDFOrdenLaboratorio();

    this.V_CreditCards.length = 0;

    this.V_Checks.length = 0;

    this.conta = 0;

    this.total = 0;

    this.totalUSD = 0;

    this.tax = 0;

    this.discount = 0;

    this.totalWithoutTax = 0;

    this.identificationTypeList = IdentificationType;

    this.documentForm = this.fb.group({
      DocumentType: ['', Validators.required],
      Code: [''],
      Speciality: [''],
      Doctor: [''],
      cardCode: ['', Validators.required], //CD0051 - SUPER // C0001 - DISUMED
      cardName: ['', Validators.required],
      PayTerms: ['0'],
      PriceList: ['', Validators.required],
      SlpList: ['', Validators.required],
      paymentType: [''],
      Comment: '',
      AnotherPayment: [''],
      IsMedismartCustomer: [false]
    });

    this.ListenCardNameChanges();

    this.ListenIsMedismartCustomerCheckboxChanges();

    this.userCredentialsForm = this.fb.group({
      Email: [''],
      Password: ['']
    });

    this.documentForm.controls['Doctor'].disable();

    if (this.mappedPayments && this.mappedPayments.length > 0)
    {
      this.documentForm.patchValue({
        AnotherPayment: this.mappedPayments[0].FldValue
      });
    }

    this.CreateFEForm();

    this.Cant.setValue(1);

    this.nameField.nativeElement.focus();

    this.Cant.setValue(1);

    this.nameField.nativeElement.focus();

    this.currencyPayment = 'COL';

    this.generateUniqueInvoiceId();

    this.currencyPayment = 'COL';

    this.hasLines = false;

    this.isBilling = false;

    this.attemptsWhileBilling = 0;

    this.openTappInvoiceResponse = null;

    this.closeTappInvoiceResponse = null;

    if(pRemoveCurrentInvoiceInMemory && this.GetDocumentAlias() === 'OINV')
    {
      this.invoicesInMemoryService.DeleteInvoiceInMemory(this.invoicesInMemoryService.CurrentLoadedInvoiceInMemory);
    }

    this.blockUI.start("Cargando información...");

    forkJoin([
      this.GetDefaultBusinessPartnerSettings()
        .pipe(
          concatMap(response => this.GetCustomersObservable())
        ),
      this.GetDefaultPaymentSettingsObservable(),
      this.GetMaxDiscountObservable(),
      this.GetViewParamatersObservable(),
      this.GetPricesListObservable(),
      this.GetSalesPersonsObservable(),
      this.GetTaxesObservable(),
      this.GetAccountsObservable(),
      this.GetCardsObservable(),
      this.GetBanksAccountsObservable(),
      this.GetPaymentTermsObservable(),
      this.GetInvoiceTypesObservable(),
      this.GetCompaniesObservable(),
      this.GetUDFsDevelopmentObservable(),
      this.GetConfiguredUDFsOIGNObservable(),
      this.GetConfiguredUDFsObservable(this.GetDocumentAlias())
    ])
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (responses) => {
          if(this.GetDocumentAlias() === 'OINV')
          {
            this.AddEmptyInvoiceIfThereAreNotInvoiceInMemory();

            this.invoicesInMemoryService.LoadLatestInvoiceInMemory();
          }
        },
        error: (error) => {
          console.error("An error occurs trying to refresh the componente information. Details:", error);

          this.alertService.ShowAlert("error", {
            title: 'Cargando información',
            text: JSON.stringify(error)
          });
        }
      });
  }

  /**
   * Return tapp setting request as observable with mapping
   * @constructor
   * @private
   */
  private GetTappSettingObservable(): Observable<IResponse<settings>>
  {
    return this.companyService.GetSettingsbyId(CONFIG_VIEW.POINTS)
      .pipe(
        map(response => {
          if (response.Result)
          {
            if (response.Data && response.Data.Json && response.Data.Json !== '')
            {
              this.tappSetting = JSON.parse(response.Data.Json);
            }
          }
          else
          {
            this.alertService.ShowAlert("error", {text: 'Ocurrió un error obteniendo configuración de puntos' + response.Error.Message});
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text:error});

          return of(null);
        })
      );
  }

  /**
   * Return farma value setting request as observable with mapping
   * @constructor
   * @private
   */
   private GetFarmaValueSettingObservable(): Observable<IResponse<settings>>
   {
     return this.companyService.GetSettingsbyId(CONFIG_VIEW.FARMAVALUE)
       .pipe(
         map(response => {
           if (response.Result)
           {
             if (response.Data && response.Data.Json && response.Data.Json !== '')
             {
               this.farmaValueSetting = JSON.parse(response.Data.Json);
             }
           }
           else
           {
             this.alertService.ShowAlert("error", {text: 'Ocurrió un error obteniendo configuración de farma value' + response.Error.Message});
           }
 
           return response;
         }),
         catchError(error => {
           this.alertService.ShowAlert("error", {text:error});
 
           return of(null);
         })
       );
   }

  GetDefaultBusinessPartnerSettings(): Observable<IResponse<settings>> {
    return this.companyService.GetSettingsbyId(CONFIG_VIEW.BussinesPartner)
      .pipe(
        map(response => {
          if (response.Result)
          {
            let result = JSON.parse(response.Data.Json);

            this.DEFAULT_BUSINESS_PARTNER = result.DefaultBussinesPartnerCustomer;

            const PATIENT = this.storageService.GetPreloadPatient();

            if (PATIENT)
            {
              this.DEFAULT_BUSINESS_PARTNER = PATIENT.Code.toString();
            }
          }
          else
          {
            this.alertService.ShowAlert("error", {text: 'Ocurrió un error obteniendo configuración de socios de negocios por defecto ' + response.Error.Message});
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }


  GetDefaultPaymentSettings(): void {
    this.companyService.GetSettingsbyId(CONFIG_VIEW.Payment).subscribe(response => {
      if (response.Result) {
        let result = JSON.parse(response.Data.Json);
        this.DefaultCardValid = result.CardValid;
        this.DefaultCardNumber = result.CardNumber;
      } else {
        this.alertService.errorAlert('Ocurrió un error obteniendo configuración de pagos ' + response.Error.Message);
      }
    }, err => {
      this.alertService.errorAlert('Ocurrió un error obteniendo configuración de pagos' + err);
    });
  }

  /**
   * Return default payment settings request as observable with mapping
   * @constructor
   * @private
   */
  private GetDefaultPaymentSettingsObservable(): Observable<IResponse<settings>>
  {
    return this.companyService.GetSettingsbyId(CONFIG_VIEW.Payment)
      .pipe(
        map(response => {
          if (response.Result)
          {
            let result = JSON.parse(response.Data.Json);

            this.DefaultCardValid = result.CardValid;

            this.DefaultCardNumber = result.CardNumber;
          }
          else
          {
            this.alertService.ShowAlert("error", {text: 'Ocurrió un error obteniendo configuración de pagos ' + response.Error.Message});
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  //#endregion

  //#region datos FE
  // convenience getter for easy access to form fields
  get f() {
    return this.documentForm.controls;
  }

  get fe() {
    return this.feForm.controls;
  }

  identificationTypeChange(IdentificationValue: string) {
    switch (IdentificationValue) {
      case '00': {
        this.feForm.controls['Identification'].setValidators([]);
        this.feForm.controls['Identification'].updateValueAndValidity();
        this.feForm.controls['Direccion'].setValidators([]);
        this.feForm.controls['Direccion'].updateValueAndValidity();
        this.feForm.controls['Email'].setValidators([]);
        this.feForm.controls['Email'].updateValueAndValidity();
        break;
      }
      case '01': {
        this.validatorFeForm(9, 9);
        // this.getProvinces();
        break;
      }
      case '02':
      case '04': {
        this.validatorFeForm(10, 10);
        // this.getProvinces();
        break;
      }
      case '03': {
        this.validatorFeForm(11, 12);
        // this.getProvinces();
        break;
      }
    }

  }

  queryFEData2() {
    let paymentTerm = this.PayTermsList.find(x => x.GroupNum === Number(this.documentForm.controls.PayTerms.value));


    if (paymentTerm.Type === PayTermsEnum.Contado && this.feForm.controls.IdType.value !== "") {
      this.blockUI.start('Obteniendo datos FE...');
      this.bpService.GetCustomersCont(this.feForm.controls.IdType.value, this.feForm.controls.Identification.value).subscribe((data: any) => {
        this.blockUI.stop();
        if (data.Result) {
          this.documentForm.patchValue({cardName: data.FEInfo.CardName});
          this.feForm.patchValue({Email: data.FEInfo.Email});
        } else {
          // busca info del cliente en el padron
          this.blockUI.stop();
          this.blockUI.start('Obteniendo datos del padrón...');
          if (this.feForm.controls.Identification.value != '') {

            this.bpService.GetCustomersContPadron(this.feForm.controls.Identification.value).subscribe((data) => {
              this.blockUI.stop();
              if (data.tipoIdentificacion == this.feForm.controls.IdType.value) {
                this.documentForm.patchValue({cardName: data.nombre});
              } else {
                this.alertService.errorInfoAlert(`Error no se ha encontrado la información en el padron del número de identificación ingresado.`);
              }
            }, (error: any) => {
              this.blockUI.stop();
              this.alertService.errorInfoAlert(`Error Padrón: No se ha encontrado la información en el padron del número de identificación ingresado.`);
            });

          }
        }
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error Obteniendo datos FE!!!, error: ${error.error.Message}`);
      });
    }
  }

  validatorFeForm(min: number, max: number) {
    this.feForm.controls['Identification'].setValidators([Validators.required, Validators.minLength(min), Validators.maxLength(max)]);
    this.feForm.controls['Identification'].updateValueAndValidity();
    this.feForm.controls['Direccion'].setValidators([Validators.required, Validators.maxLength(250)]);
    this.feForm.controls['Direccion'].updateValueAndValidity();
    this.feForm.controls['Email'].setValidators([Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]);
    this.feForm.controls['Email'].updateValueAndValidity();
  }

  toggleArrow(): void {
    this.typeArrow = !this.typeArrow;
  }

  //#endregion

  //#region carga objetos en comun para documentos de ventas

  TypesInvoice(): void {
    this.documentService.GetInvoiceTypes().subscribe(next => {
      if (next.Result) {

        this.typesInvoice = next.InvoiceTypes;
        this.documentForm.patchValue({
          DocumentType: this.typesInvoice.find(x => x.IsDefault).Name,
        });
      } else {
        console.log(next);
        this.alertService.errorInfoAlert(`Tipos de factura no encontrados ${next.Error ? next.Error.Message : ''}`);
      }
    }, error => {
      console.log(error);
      this.alertService.errorInfoAlert(`Tipos de factura no encontrados ${error}`);
    });
  }

  /**
   * Return the invoice types request as observable with mapping
   * @constructor
   * @private
   */
  private GetInvoiceTypesObservable(): Observable<IInvoiceTypeResponse>
  {
    return this.documentService.GetInvoiceTypes()
      .pipe(
        map(response => {
          if (response.Result)
          {
            this.typesInvoice = response.InvoiceTypes;

            this.documentForm.patchValue({
              DocumentType: this.typesInvoice.find(x => x.IsDefault).Name,
            });
          }
          else
          {
            console.error(`Tipos de factura no encontrados ${response.Error ? response.Error.Message : ''}`);
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  /**
   * Return permissions request as observable with mapping
   * @constructor
   * @private
   */
  private CheckPermissionsObservable(): Observable<IPermissionsResponse>
  {
    this.canAllowDiscount = false;

    if (!this.currentUser) return throwError(() => "Permissions can't be retrieved because the current user information was not found.");

    return this.sPerm.getPerms(this.currentUser.userId)
      .pipe(
        map(response => {
          if (response.Result)
          {
            let activePermissions: IPermission[] = response.perms.filter(perm => perm.Active);

            switch (this.storage.GetDocumentType())
            {
              case SOAndSQActions.EditOrder:
              case SOAndSQActions.CopyToOrder:
              case SOAndSQActions.CreateSaleOrder:
                this.canViewThePage = activePermissions.some(perm => perm.Name == "V_SO");
                this.canChangeSalesPerson = activePermissions.some(perm => perm.Name == "Sales_Orders_ChangeSalesPerson")
                break;
              case SOAndSQActions.EditQuotation:
              case SOAndSQActions.CreateQuotation:
                this.canViewThePage = activePermissions.some(perm => perm.Name == "V_Quo");
                this.canChangeSalesPerson = activePermissions.some(perm => perm.Name == "Sales_Quotations_ChangeSalesPerson");
                break;
              case SOAndSQActions.CreateInvoice:
              case SOAndSQActions.CopyToInvoice:
                this.canViewThePage = activePermissions.some(perm => perm.Name == "V_Inv");
                this.canChangeSalesPerson = activePermissions.some(perm => perm.Name == "Sales_Invoices_ChangeSalesPerson");
                break;
            }

            this.isAllowedGoodReceipt = activePermissions.some(perm => perm.Name == "V_Inv_OIGN");

            this.isAllowedPriceListChange = activePermissions.some(perm => perm.Name == "V_LstChng");

            this.isAllowedWHChange = activePermissions.some(perm => perm.Name == "V_WHChng");

            this.isChangeTax = activePermissions.some(perm => perm.Name == "Perm_ChangeTax");

            this.isPermBonus = activePermissions.some(perm => perm.Name == "Perm_Bonus");

            this.isAllowedToEditCardNumber = activePermissions.some(perm => perm.Name == "W_ChangeCardNumber");

            this.canAllowDiscount = activePermissions.some(perm => perm.Name == "W_AllowDiscounts");

            this.isAllowEditLinePrice = activePermissions.some(perm => perm.Name == "U_EditLinePrice");

            this.canEditPaymentTerms = activePermissions.some(perm => perm.Name == "Perm_PaymentTerms");
          }
          else
          {
            this.canViewThePage = false;
          }

          return response;
        }),
        finalize(() => this.ApplyPermissionsRestrictions()),
        catchError(error => {
          this.canViewThePage = false;

          this.alertService.ShowAlert("error",{ text: error});

          return EMPTY;
        })
      )
  }


  /**
   * Validate the permissions states and apply the required restrictions
   * @constructor
   */
  ApplyPermissionsRestrictions(): void
  {
    if(!this.canChangeSalesPerson)
    {
      this.documentForm.get("SlpList").disable();
    }
  }

  getCustomers() {
    this.blockUI.start('Obteniendo clientes, espere por favor...');
    this.bpService.GetMinifiedCustomers()
      .subscribe((response) => {
        this.blockUI.stop();
        if (response && response.Result && response.Data) {
          this.bpList = [];
          this.bpCodeList = [];
          this.bpNameList = [];
          this.patientCodes = [];

          this.bpList = response.Data;

          for (let customer of response.Data) {
            this.patientCodes.push(customer.Code);
            this.bpCodeList.push(customer.CardCode);
            this.bpNameList.push(customer.CardName);
          }

          this.GetCurrencyType();

          this.GetPayTerms();

          this.GetPriceList();
        } else {
          this.alertService.errorAlert(`Error: Código: ${response.Error.Code}, mensaje: ${response.Error.Message}`);
        }

      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
      });
  }

  /**
   * Return the customers request as observable with mapping
   * @constructor
   * @private
   */
  private GetCustomersObservable(): Observable<IResponse<IMinifiedCustomer[]>>
  {
    return this.bpService.GetMinifiedCustomers()
      .pipe(
        map(response => {
          if (response && response.Result && response.Data)
          {
            this.bpList = [];

            this.bpCodeList = [];

            this.bpNameList = [];

            this.patientCodes = [];

            this.bpList = response.Data;

            for (let customer of response.Data)
            {
              this.patientCodes.push(customer.Code);

              this.bpCodeList.push(customer.CardCode);

              this.bpNameList.push(customer.CardName);
            }
          }
          else
          {
            this.alertService.ShowAlert("error", {text: `Error: Código: ${response.Error.Code}, mensaje: ${response.Error.Message}`});
          }

          return response;
        }),
        concatMap(response => {
          return forkJoin([
            this.GetCurrencyTypesObservable(),
            this.GetPaymentTermsObservable(),
            this.GetPricesListObservable()
          ]);
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      )
  }

  GetCurrencyType() {
    this.blockUI.start('Obteniendo los tipos de monedas...');
    this.paramsService.GetCurrencyType().subscribe(data => {
      if (data.Data.length > 0) {
        this.currencyList = data.Data;
        this.currencyList.sort();
        this.allCurrencyList = data.Data;
        this.currencyPayment = 'COL';
        this.Funcion();
        this.blockUI.stop();
      } else {
        this.blockUI.stop();
        this.alertService.errorAlert('Error al cargar las monedas - ' + data.Error.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
    });
  }

  /**
   * Return the currency types request as observable with mapping
   * @constructor
   * @private
   */
  private GetCurrencyTypesObservable(): Observable<IResponse<Currency[]>>
  {
    return this.paramsService.GetCurrencyType()
      .pipe(
        map(response => {
          if (response.Data.length > 0)
          {
            this.currencyList = response.Data;

            this.currencyList.sort();

            this.allCurrencyList = response.Data;

            this.currencyPayment = 'COL';
          }
          else
          {
            this.alertService.ShowAlert("error", {text: 'Error al cargar las monedas - ' + response.Error.Message});
          }

          return response;
        }),
        concatMap(response => this.GetCustomerDetailsObservable()),
        catchError(error => {
          this.alertService.ShowAlert("error", {text:error});

          return of(null);
        })
      )
  }

  Funcion(): void {

    const PATIENT: IPatient = this.storageService.GetPreloadPatient();

    if (PATIENT)
    {
      this.DEFAULT_BUSINESS_PARTNER = PATIENT.Code.toString();
    }

    this.bpService.GetCustomerDetails(this.DEFAULT_BUSINESS_PARTNER.toString(), '')
      .subscribe(next => {
        let listNumToUse: number = Number(next.Data.ListNum);

        if (this.currentMemoryInvoice && this.currentMemoryInvoice.InvForm && this.currentMemoryInvoice.InvForm.PriceList)
        {
          listNumToUse = Number(this.currentMemoryInvoice.InvForm.PriceList);
        }

        this.userCurrency = this.PriceList.find(x => x.ListNum == listNumToUse).PrimCurr;

        this.GetCurrencyByUser();

        if (PATIENT && next.Data)
        {
          this.documentForm.patchValue({PayTerms: next.Data.GroupNum});
          this.documentForm.patchValue({PriceList: next.Data.ListNum});
        }

        this.currencyPayment = this.userCurrency;

        const DOC_ENTRY = this.storage.GetDocEntry();

        let MOBJECT;

        if (DOC_ENTRY > 0)
        {
          MOBJECT = this.saleDocumentModel;
        }
        else
        {
          this.priceList = +next.Data.ListNum;
        }

        this.documentForm.get("cardName").patchValue(MOBJECT ? MOBJECT.CardName : next.Data.CardName);

        this.documentForm.get("cardCode").patchValue(MOBJECT ? MOBJECT.cardCode : next.Data.CardCode);
        //(<HTMLInputElement>document.getElementById(`customerCode`)).value = MOBJECT ? MOBJECT.cardCode : next.Data.CardCode;

        this.documentForm.get("Code").patchValue(this.DEFAULT_BUSINESS_PARTNER)
        //(<HTMLInputElement>document.getElementById(`clienteId`)).value = this.DEFAULT_BUSINESS_PARTNER;

        this.feForm.patchValue({
          IdType: MOBJECT ? MOBJECT.IdType : next.Data.IdType,
          Identification: MOBJECT ? MOBJECT.U_Identificacion : next.Data.U_Identificacion,
          Email: MOBJECT ? MOBJECT.U_Email : next.Data.U_Email
        });

        this.SetValueToUDFCedulaPaciente(MOBJECT ? MOBJECT.U_Identificacion : next.Data.U_Identificacion);
    }, error => {
      console.log(error);
    })
  }

  /**
   * Return the customer details request as observable with mapping
   * @constructor
   * @private
   */
  private GetCustomerDetailsObservable(): Observable<IResponse<BusinessPartnerModel>>
  {
    const PATIENT: IPatient = this.storageService.GetPreloadPatient();

    if (PATIENT)
    {
      this.DEFAULT_BUSINESS_PARTNER = PATIENT.Code.toString();
    }

    return this.bpService.GetCustomerDetails(this.DEFAULT_BUSINESS_PARTNER.toString(), '')
      .pipe(
        map(response => {
          let listNumToUse: number = Number(response.Data.ListNum);

          if (this.currentMemoryInvoice && this.currentMemoryInvoice.InvForm && this.currentMemoryInvoice.InvForm.PriceList)
          {
            listNumToUse = Number(this.currentMemoryInvoice.InvForm.PriceList);
          }

          this.userCurrency = this.PriceList.find(x => x.ListNum == listNumToUse).PrimCurr;

          this.GetCurrencyByUser();

          if (PATIENT && response.Data)
          {
            this.documentForm.patchValue({PayTerms: response.Data.GroupNum});
            this.documentForm.patchValue({PriceList: response.Data.ListNum});
          }

          this.currencyPayment = this.userCurrency;

          const DOC_ENTRY = this.storage.GetDocEntry();

          let MOBJECT;

          if (DOC_ENTRY > 0)
          {
            MOBJECT = this.saleDocumentModel;
          }
          else
          {
            this.priceList = +response.Data.ListNum;
          }

          this.documentForm.get("cardName").patchValue(MOBJECT ? MOBJECT.CardName : response.Data.CardName);

          this.documentForm.get("cardCode").patchValue(MOBJECT ? MOBJECT.cardCode : response.Data.CardCode);

          this.documentForm.get("Code").patchValue(this.DEFAULT_BUSINESS_PARTNER)

          this.feForm.patchValue({
            IdType: MOBJECT ? MOBJECT.IdType : response.Data.IdType,
            Identification: MOBJECT ? MOBJECT.U_Identificacion : response.Data.U_Identificacion,
            Email: MOBJECT ? MOBJECT.U_Email : response.Data.U_Email
          });

          /**
           * This validation works because this method is called in the initialization of the component, please check
           * again if will be called in another place.
           *
           * Best regards,
           * Isaac Herrera
           */
          if(!this.invoicesInMemoryService.HasInvoicesInMemory() || this.invoicesInMemoryService.LastInvoiceInMemoryIsEmpty())
          {
            this.SetValueToUDFCedulaPaciente(MOBJECT ? MOBJECT.U_Identificacion : response.Data.U_Identificacion);
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      )
  }

  /**
   * Funcion que permite llenar el select de moneda segun las monedas que posee el cliente
   * @param {string} currency Variable que contiene el tipo de moneda del cliente
   */
  GetCurrencyByUser() {
    this.currencyList = this.allCurrencyList;

    this.currencyPayment = this.userCurrency;

    this.SetCurr();
  }

  SetCurr(): void {
    let cur = this.currencyList.find(curr => curr.Id === this.userCurrency);

    if (this.storage.GetDocEntry() > 0) {
      cur = this.currencyList.find(curr => curr.Id === JSON.parse(this.storage.GetCustomerData()).Currency);
    }

    if (!cur) return;

    this.setCurr = cur.Symbol;
  }

  GetPayTerms() {
    this.blockUI.start('Obteniendo términos de pago, espere por favor...');
    this.itemService.GetPayTerms().pipe(
      tap((data: any) => {
        if (data.Result) {
          this.PayTermsList = data.payTermsList;
        } else {
          this.alertService.errorAlert(`Error: código: ${data.Error.Code}, mensaje: ${data.Error.Message}`);
        }
      }),
      concatMap(next => this.bpService.GetCustomerDetails(this.DEFAULT_BUSINESS_PARTNER.toString(), '').pipe(tap(bps => {
        const DOC_ENTRY = this.storage.GetDocEntry();
        if (DOC_ENTRY <= 0) {
          this.documentForm.patchValue({PayTerms: bps.Data.GroupNum});
        }
      })))).subscribe((next: any) => {
          this.blockUI.stop();
        },
        (error: any) => {
          this.blockUI.stop();
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
        });
  }

  /**
   * Return the payment terms request as observable with mapping
   * @constructor
   * @private
   */
  private GetPaymentTermsObservable(): Observable<any>
  {
    return this.itemService.GetPayTerms()
      .pipe(
        map((response: any) => {
          if (response.Result)
          {
            this.PayTermsList = response.payTermsList;
          }
          else
          {
            this.alertService.ShowAlert("error", {text: `Error: código: ${response.Error.Code}, mensaje: ${response.Error.Message}`});
          }

          return response;
        }),
        concatMap(response => this.bpService.GetCustomerDetails(this.DEFAULT_BUSINESS_PARTNER.toString(), '')
          .pipe(
            map(customerDetailsResponse => {
              const DOC_ENTRY = this.storage.GetDocEntry();

              if (DOC_ENTRY <= 0)
              {
                this.documentForm.patchValue({PayTerms: customerDetailsResponse.Data.GroupNum});
              }

              return customerDetailsResponse;
            })
          )
        ),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  CreateFEForm() {
    this.feForm = this.fb.group({
      IdType: [''],
      Identification: [''],
      Email: [''],
      Provincia: [''],
      Canton: [''],
      Distrito: [''],
      Barrio: [''],
      Direccion: ['']
    });

    this.feForm.patchValue({IdType: this.identificationTypeList[0].Id});
  }

  // obtiene el maximo descuento posible
  getMaxDiscout() {
    this.documentService.getMaxDiscout().subscribe((data: any) => {
      if (data.Result) {
        this.currentRequest++;
        this.maxDiscuont = data.discount;
        this.$requestViewer.next(this.currentRequest);
      } else {
        this.alertService.errorAlert(`Error: código: ${data.Error.Code}, mensaje: ${data.Error.Message}`);
      }
    }, (error: any) => {
      this.alertService.errorInfoAlert(`Error getCustomers!!!, error: ${error.error.Message}`);
    });

  }

  /**
   * Return the max discount request as observable with mapping
   * @constructor
   * @private
   */
  private GetMaxDiscountObservable(): Observable<any>
  {
    return this.documentService.getMaxDiscout()
      .pipe(
        map((response: any) => {
          if (response.Result)
          {
            this.currentRequest++;

            this.maxDiscuont = response.discount;

            this.$requestViewer.next(this.currentRequest);
          }
          else
          {
            console.error(`Error: código: ${response.Error.Code}, mensaje: ${response.Error.Message}`);
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  // llena los campos de la tabla de items con los campos parametriados
  GetParamsViewList() {
    this.blockUI.start('Obteniendo parámetros...');
    this.paramsService.getParasmView()
      .subscribe((data: any) => {
        this.blockUI.stop();
        if (data.Result) {
          this.viewParamList = data.Params.filter(param => {
            if (this.storageService.GetDocumentType() == SOAndSQActions.CreateInvoice || this.storageService.GetDocumentType() == SOAndSQActions.CopyToInvoice) {
              return param.type === 1 && param.Visibility;
            } else {
              return param.type === 1 && param.Visibility && param.Name != 'TaxOnly';
            }

          });
          this.tableLength = 0;
          for (var i = 0; i < this.viewParamList.length; i++) {
            this.tableLength += this.MapWidth[this.viewParamList[i].Name];
          }
          this.viewParamListHeader = data.Params.filter(param => {

            return param.type === 2;
          });
          this.viewParamListTotales = data.Params.filter(param => {
            return param.type === 3;
          });
          this.viewParamTitles = data.Params.filter(param => {
            return param.type === 6;
          });
          this.ChargeParamstoView();
        } else {
          this.alertService.errorAlert('Error al cargar los parámetros de la página - ' + data.Error.Message);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
      });
  }

  /**
   * Return the view parameters request as observable with mapping
   * @constructor
   * @private
   */
  private GetViewParamatersObservable(): Observable<any>
  {
    return this.paramsService.getParasmView()
      .pipe(
        map((response: any) => {
          if (response.Result)
          {
            this.viewParamList = response.Params.filter(param =>
            {
              let documentType = this.storageService.GetDocumentType();

              if (documentType == SOAndSQActions.CreateInvoice || documentType == SOAndSQActions.CopyToInvoice) {
                return param.type === 1 && param.Visibility;
              } else {
                return param.type === 1 && param.Visibility && param.Name != 'TaxOnly';
              }

            });

            this.tableLength = 0;

            for (var i = 0; i < this.viewParamList.length; i++)
            {
              this.tableLength += this.MapWidth[this.viewParamList[i].Name];
            }

            this.viewParamListHeader = response.Params.filter(param => {

              return param.type === 2;
            });

            this.viewParamListTotales = response.Params.filter(param => {
              return param.type === 3;
            });

            this.viewParamTitles = response.Params.filter(param => {
              return param.type === 6;
            });

            this.ChargeParamstoView();
          }
          else
          {
            console.error('Error al cargar los parámetros de la página - ' + response.Error.Message);
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  // Carga los datos parametrizados en las variables
  ChargeParamstoView() {
    // parametrizaciones para dtos de cabezera
    this.viewParamListHeader.forEach((element) => {
      if (element.Name === "lbCardCode") {
        this.lbCardCode = element;
      }
      if (element.Name === "txtCardCode") {
        this.txtCardCode = element;
      }
      if (element.Name === "lbCardName") {
        this.lbCardName = element;
      }
      if (element.Name === "txtCardName") {
        this.txtCardName = element;
      }
      if (element.Name === "lbCurrency") {
        this.lbCurrency = element;
      }
      if (element.Name === "txtCurrency") {
        this.txtCurrency = element;
      }
      if (element.Name === "txtPayTerms") {
        this.txtPayTerms = element;
      }
      if (element.Name === "lbPayTerms") {
        this.lbPayTerms = element;
      }
      if (element.Name === "txtPriceList") {
        this.txtPriceList = element;
      }
      if (element.Name === "lbPriceList") {
        this.lbPriceList = element;
      }
      if (element.Name === "txtComments") {
        this.txtComments = element;
      }
      if (element.Name === "lbComments") {
        this.lbComments = element;
      }
      if (element.Name === "txtSLP") {
        this.txtSLP = element;
      }
      if (element.Name === "lbSLP") {
        this.lbSLP = element;
      }
    });
    // parametrizaciones datos de totales
    this.viewParamListTotales.forEach((element) => {
      if (element.Name === "lbTotalExe") {
        this.lbTotalExe = element;
      }
      if (element.Name === "txtTotalExe") {
        this.txtTotalExe = element;
      }
      if (element.Name === "lbDiscount") {
        this.lbDiscount = element;
      }
      if (element.Name === "txtDiscount") {
        this.txtDiscount = element;
      }
      if (element.Name === "lbTaxes") {
        this.lbTaxes = element;
      }
      if (element.Name === "txtTaxes") {
        this.txtTaxes = element;
      }
      if (element.Name === "lbTotal") {
        this.lbTotal = element;
      }
      if (element.Name === "txtTotal") {
        this.txtTotal = element;
      }
    });
    // parametrizacion del titulo
    // let obj = this.viewParamTitles.filter(param => {
    //   return param.Name === 'T_Inv';
    // });
    // this.title = obj[0].Text;
  }


  // obtiene el tipo de cambio
  getExRate() {
    this.blockUI.start('Obteniendo tipos de cambios, espere Por Favor...');
    this.exrate.getExchangeRate().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.Result) {
        this.currencyChange = data.exRate;
        this.DailyExRate = data.exRate
        this.currentRequest++;
        this.$requestViewer.next(this.currentRequest);
        //this.alertService.errorAlert(`Error: Código: ${data.errorInfo.Code}, Mensaje: ${data.errorInfo.Message}`);
      } else {
        this.alertService.errorAlert(`Error: código: ${data.Error.Code}, mensaje: ${data.Error.Message}`);
      }

    }, (error: any) => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error getCustomers!!!, error: ${error.error.Message}`);

    });
  }

  /**
   * Return the exchange rate request as observable with mapping
   * @constructor
   * @private
   */
  private GetExchangeRateObservable(): Observable<any>
  {
    return this.exrate.getExchangeRate()
      .pipe(
        map(response => {
          if (response.Result)
          {
            this.currencyChange = response.exRate;
            this.DailyExRate = response.exRate
            this.currentRequest++;
            this.$requestViewer.next(this.currentRequest);
          }
          else
          {
            console.error(`Error: código: ${response.Error.Code}, mensaje: ${response.Error.Message}`);
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      )
  }

  GetPriceList() {
    this.blockUI.start('Obteniendo listas de precios, espere por favor...');
    this.itemService.GetPriceList()
      .subscribe((data: any) => {
        this.blockUI.stop();
        if (data.Result) {
          this.PriceList = data.priceList;


          let listNumToUse: number = this.PriceList[0].ListNum;

          if (this.currentMemoryInvoice && this.currentMemoryInvoice.InvForm && this.currentMemoryInvoice.InvForm.PriceList) {
            listNumToUse = this.currentMemoryInvoice.InvForm.PriceList;
          }

          this.documentForm.patchValue({PriceList: this.storage.GetDocEntry() > 0 || this.priceList ? this.priceList : listNumToUse});
        } else {
          this.alertService.errorAlert(`Error: código: ${data.Error.Code}, mensaje: ${data.Error.Message}`);
        }
        this.SetDefaultList(this.DEFAULT_BUSINESS_PARTNER);
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
      });
  }

  /**
   * Return prices list request as observable with mapping
   * @constructor
   * @private
   */
  private GetPricesListObservable(): Observable<any>
  {
    return this.itemService.GetPriceList()
      .pipe(
        map((response: any) => {
          if (response.Result)
          {
            this.PriceList = response.priceList;

            let listNumToUse: number = this.PriceList[0].ListNum;

            if (this.currentMemoryInvoice && this.currentMemoryInvoice.InvForm && this.currentMemoryInvoice.InvForm.PriceList)
            {
              listNumToUse = this.currentMemoryInvoice.InvForm.PriceList;
            }

            this.documentForm.patchValue({PriceList: this.storage.GetDocEntry() > 0 || this.priceList ? this.priceList : listNumToUse});
          }
          else
          {
            this.alertService.ShowAlert("error", {text: `Error: código: ${response.Error.Code}, mensaje: ${response.Error.Message}`});
          }

          return response;
        }),
        concatMap(response => this.GetDefaultPriceListObservable()),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      )
  }

  SetDefaultList(_cardCode: string) {
    this.itemService.GetPriceListDefault(_cardCode).subscribe(next => {
      if (next.Result) {


        let listNumToUse: number = next.PriceList.ListNum;

        if (this.currentMemoryInvoice && this.currentMemoryInvoice.InvForm && this.currentMemoryInvoice.InvForm.PriceList) {
          listNumToUse = this.currentMemoryInvoice.InvForm.PriceList;
        }

        this.documentForm.patchValue({
          PriceList: this.storage.GetDocEntry() > 0 || this.itemsList.length ? this.priceList : listNumToUse
        });
      }
    });
  }

  /**
   * Return the default price list request as observable with mapping
   * @constructor
   * @private
   */
  private GetDefaultPriceListObservable(): Observable<IIPriceResponse>
  {
    return this.itemService.GetPriceListDefault(this.DEFAULT_BUSINESS_PARTNER)
      .pipe(
        map(response => {
          if (response.Result)
          {
            let listNumToUse: number = response.PriceList.ListNum;

            if (this.currentMemoryInvoice && this.currentMemoryInvoice.InvForm && this.currentMemoryInvoice.InvForm.PriceList) {
              listNumToUse = this.currentMemoryInvoice.InvForm.PriceList;
            }

            this.documentForm.patchValue({
              PriceList: this.storage.GetDocEntry() > 0 || this.itemsList.length ? this.priceList : listNumToUse
            });
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      )
  }

  /**
   * Send a request to retrieve the items filtered by specialty and category
   * @param pSpecialty Specialty that items must have.
   * @param pCategory Category that items must have
   * @constructor
   */
  GetItems(pSpecialty: string) {
    this.blockUI.start();

    this.GetItemsObservable(pSpecialty)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe();
  }

  /**
   * Get the item request as observable with mapping
   * @param pSpecialty Specialty that must have the item
   * @constructor
   * @private
   */
  private GetItemsObservable(pSpecialty: string): Observable<IItemsNamesResponse>
  {
    return this.itemService.GetItems(pSpecialty)
      .pipe(
        map((response) => {
          if (response.Result)
          {
            this.itemsTypeaheadList = response.ItemList.ItemCompleteName;
            this.itemsCodeList = response.ItemList.ItemCode;
            this.itemsNameList = response.ItemList.ItemName;
          }
          else
          {
            console.error(`Error: código: ${response.Error.Code}, mensaje: ${response.Error.Message}`);
          }

          return response;
        }),
        catchError((error) => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      )
  }

  // funcion para obtener los impuestos desde SAP
  // no recibe parametros
  GetTaxes() {
    this.blockUI.start('Obteniendo impuestos, espere por favor...');
    this.taxService.GetTaxes()
      .subscribe((data: any) => {
        if (data.Result) {
          this.taxesList.length = 0;
          this.taxesList = data.Taxes;
        } else {
        }
        this.blockUI.stop();
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error getTaxes!!!, error: ${error.error.Message}`);
      });
  }

  /**
   * Return the taxes request as observable with mapping
   * @constructor
   */
  GetTaxesObservable(): Observable<any>
  {
    return this.taxService.GetTaxes()
      .pipe(
        map(response => {
          if (response.Result)
          {
            this.taxesList.length = 0;
            this.taxesList = response.Taxes;
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  GetCompanies() {
    this.blockUI.start('Obteniendo compañías, espere por favor...');
    this.companyService.GetCompanies().subscribe((data: any) => {
      if (data.Result) {
        this.companiesList.length = 0;
        this.companiesList = data.companiesList;
        this.companiesList.forEach(comp => {
          // this.pesoBolsa = comp.ScaleWeightToSubstract;49
          this.maxWeightTo0 = comp.ScaleMaxWeightToTreatAsZero;
        });
      } else {
        this.alertService.errorAlert('Error al cargar compañías - error: ' + data.Error.Message);
      }
      this.blockUI.stop();
    }, (error: any) => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
    });
  }

  /**
   * Return the companies request as observable with mapping
   * @constructor
   * @private
   */
  private GetCompaniesObservable(): Observable<any>
  {
    return this.companyService.GetCompanies()
      .pipe(
        map((response: any) => {
          if (response.Result)
          {
            this.companiesList.length = 0;
            this.companiesList = response.companiesList;
            this.companiesList.forEach(comp => {
              this.maxWeightTo0 = comp.ScaleMaxWeightToTreatAsZero;
            });
          }
          else
          {
            console.error('Error al cargar compañías - error: ' + response.Error.Message);
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text:error});

          return of(null);
        })
      );
  }

  // funcion para obtener una lista de cuentas segun la compañía seleccionada
  GetAccount() {
    this.blockUI.start('Obteniendo cuentas, espere por favor...');
    this.accountService.getAccount()
      .subscribe((data: any) => {
        if (data.Result) {
          this.accountList = data.Data;
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert('Error al cargar Cuentas - error: ' + data.Error.Message);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);

      });
  }

  /**
   * Return the accounts request as observable with mapping
   * @constructor
   * @private
   */
  private GetAccountsObservable(): Observable<IResponse<IContableAccounts>>
  {
    return this.accountService.getAccount()
      .pipe(
        map(response => {
          if (response.Result)
          {
            this.accountList = response.Data;
          }
          else
          {
            console.error('Error al cargar Cuentas - error: ' + response.Error.Message);
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      )
  }

  GetCards() {
    this.blockUI.start('Obteniendo tarjetas, espere por favor...');
    this.cardService.getCards()
      .subscribe((data: any) => {
        if (data.Result) {
          this.cardsList = data.cardsList;

          this.CardName = data.cardsList[0].CardName;
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert('error al cargar tarjetas de credito - error: ' + data.Error.Message);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
      });
  }

  /**
   * Return the cards request as observable with mapping
   * @constructor
   * @private
   */
  private GetCardsObservable(): Observable<any>
  {
    return this.cardService.getCards()
      .pipe(
        map((response: any) => {
          if (response.Result)
          {
            this.cardsList = response.cardsList;

            this.CardName = response.cardsList[0].CardName;
          }
          else
          {
            console.error('Error al cargar tarjetas de credito - error: ' + response.Error.Message);
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(error);
        })
      );
  }


  GetAccountsBank() {
    this.blockUI.start('Obteniendo bancos, espere por favor...');
    this.bankService.getAccountsBank()
      .subscribe((data: any) => {
        if (data.Result) {
          this.banksList = data.banksList;
          // this.checkForm.patchValue({ BankNames: data.banksList[0].BankCode });
          this.blockUI.stop();
        } else {
          this.blockUI.stop();
          this.alertService.errorAlert('error al obtener información de los Bancos - error: ' + data.Error.Message);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
      });
  }

  /**
   * Return the banks accounts request as observable with mapping
   * @constructor
   * @private
   */
  private GetBanksAccountsObservable(): Observable<any>
  {
    return this.bankService.getAccountsBank()
      .pipe(
        map((response: any) => {
          if (response.Result)
          {
            this.banksList = response.banksList;
          }
          else
          {
            this.alertService.errorAlert('error al obtener información de los Bancos - error: ' + response.Error.Message);
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text:error});

          return of(null);
        })
      );
  }

  // Genera el id para la creacion de la factura usanda en sap y el pin pad
  generateUniqueInvoiceId(): void {
    this.uniqueInvCode = this.commonService.GenerateDocumentUniqueID(this.storage.GetDocumentType());
  }

  // establece la persona que es el vendedor
  GetSalesPerson() {
    this.blockUI.start('Cargando listas de usuarios...');
    this.uService.getUserList().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.Result) {
        this.userAssignsList = data.Users;
        this.userAssignsList.forEach(user => {
          if (this.currentUser.userId.toString() === user.UserId.toString()) {
            this.defaultSlpCode = user.SlpCode;
          }
        });
        this.GetSalesPersonList();
      } else {
        this.blockUI.stop();
        this.alertService.errorAlert('Error al cargar la lista de usuarios - ' + data.Error.Message);
      }
    }, error => {
      console.log(error);
      this.alertService.errorAlert('Error al cargar la lista de usuarios - ' + error);
    });
  }

  /**
   * Return the user default sales person request as observable with mapping
   * @constructor
   * @private
   */
  private GetDefaultSalesPersonCodeObservable(): Observable<any>
  {
    return this.uService.getUserList()
      .pipe(
        map((response: any) => {
          if (response.Result)
          {
            this.userAssignsList = response.Users;

            this.userAssignsList.forEach(user => {
              if (this.currentUser.userId.toString() === user.UserId.toString())
              {
                this.defaultSlpCode = user.SlpCode;
              }
            });
          }
          else
          {
            console.error('Error al cargar la lista de usuarios - ' + response.Error.Message);
          }

          return response;
        }),
        concatMap(response => this.GetSalesPersonsObservable()),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  GetSalesPersonList() {
    this.blockUI.start('Obteniendo vendedores, espere por favor...');

    this.smService.GetSalesPersons(true)
      .subscribe((data: any) => {
        this.blockUI.stop();
        if (data.Result) {
          this.salesPersons = data.salesManList;
          const DOC_ENTRY = this.storage.GetDocEntry();
          if (DOC_ENTRY > 0) {
            const CUSTOMER_DATA = JSON.parse(this.storage.GetCustomerData());
            this.documentForm.patchValue({SlpList: CUSTOMER_DATA.SlpCode});
          } else {
            this.documentForm.patchValue({SlpList: this.defaultSlpCode.toString()});
          }

        } else {

          this.alertService.errorAlert(`Error: código: ${data.Error.Code}, mensaje: ${data.Error.Message}`);
        }
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
      });
  }

  /**
   * Return the sales persons request as observable with mapping
   * @constructor
   * @private
   */
  private GetSalesPersonsObservable(): Observable<ISalesPersonResponse>
  {
    return this.smService.GetSalesPersons(true)
      .pipe(
        map(response => {
          if (response.Result)
          {
            this.salesPersons = response.salesManList;

            const DOC_ENTRY = this.storage.GetDocEntry();

            if (DOC_ENTRY > 0)
            {
              const CUSTOMER_DATA = JSON.parse(this.storage.GetCustomerData());

              this.documentForm.patchValue({SlpList: CUSTOMER_DATA.SlpCode});
            }
            else
            {
              this.documentForm.patchValue({SlpList: this.defaultSlpCode.toString()});
            }

          }
          else
          {
            console.error(`Error: código: ${response.Error.Code}, mensaje: ${response.Error.Message}`);
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text:error});

          return of(null);
        })
      );
  }
  GetWarehouseInfo() {
    const HEADQUARTER = this.storage.GetCurrentHeadquarter();

    const SPECIALITY = this.documentForm.controls['Speciality'].value;

    this.blockUI.start("Obteniendo almacenes...");

    this.itemService.GetWHAvailable((HEADQUARTER ? HEADQUARTER.Id : 0), SPECIALITY).pipe(finalize(() => this.blockUI.stop())).subscribe(callBack => {
      if (callBack.Result) {
        this.warehouses = callBack.Data || [];
        this.whCode = '';
        this.whName = '';
      } else {
        this.alertService.errorAlert(`Error: código: ${callBack.Error.Code}, mensaje: ${callBack.Error.Message}`);
      }
    }, error => {
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
    });

  }

  /**
   * Return the warehouse info request as observable with mapping
   * @constructor
   */
  GetWarehouseInformationObservable(): Observable<IResponse<IMinifiedWarehouse[]>>
  {
    const HEADQUARTER = this.storage.GetCurrentHeadquarter();

    const SPECIALITY = this.documentForm.controls['Speciality'].value;

    return this.itemService.GetWHAvailable((HEADQUARTER ? HEADQUARTER.Id : 0), SPECIALITY)
      .pipe(
        map(response => {
          if (response.Result)
          {
            this.warehouses = response.Data || [];
            this.whCode = '';
            this.whName = '';
          }
          else
          {
            console.error(`Error: código: ${response.Error.Code}, mensaje: ${response.Error.Message}`);
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", { text: error });

          return of(null);
        })
      )
  }

  //#endregion

  //#region config form cabecera documentos

  searchBPCode = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.bpCodeList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  searchBPName = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.bpNameList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  searchItemCode = this.IS();

  IS() {
    return (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(15),
        distinctUntilChanged(),
        map(term => term.length < 1 ? []
          : this.itemsTypeaheadList.filter(v => {

            let a = v.toLowerCase();

            const stringSize = a.length;

            const t = term.toLowerCase();

            if (this.itemsTypeaheadList.find(r => r === t)) return true;

            const b = t.split('*').filter(x => x !== '');

            const size = b.length;

            let isSorted = true;

            if (size > 1) {

              let indexes = [];

              for (let c = 0; c < size; c++) {
                b[c] = b[c].replace(' ', '');
                let ii = a.indexOf(b[c]);
                if (ii > -1) {
                  ii++;
                  a = a.slice(ii, stringSize);
                  if (indexes.length > 0) indexes.push(indexes[indexes.length - 1] + ii);
                  else indexes.push(ii);
                }
              }

              let sizeIndexes = indexes.length;

              if (sizeIndexes === size) {
                for (let c = 0; c < sizeIndexes - 1; c++) {
                  if (indexes[c] > indexes[c + 1]) {
                    isSorted = false;
                    c = sizeIndexes;
                  }
                }
                return isSorted;
              }
            }
            return v.toLowerCase().indexOf(term.toLowerCase()) > -1;
          }).sort((x, y) => x.toLowerCase().indexOf(term.toLowerCase()) - y.toLowerCase().indexOf(term.toLowerCase())).slice(0, 50))
      );
  }

  cantChange() {
    if (this.Cant.value < 1) {
      this.Cant.setValue(1);
    }
  }

  public model: any;

  searchPatients = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map((term) =>
        term === ''
          ? []
          : this.bpList
            .filter((v) => {
              return (
                v.CardName.toLowerCase().indexOf(term.toLowerCase()) > -1
                ||
                v.Cedula.toLowerCase().indexOf(term.toLowerCase()) > -1
              );
            })
            .slice(0, 10)
      )
    );

  ValidateCardName(_code: number, _cardCode: string): string {
    if (!_cardCode) return this.documentForm.get("cardName").value;

    const DOC_ENTRY = this.storage.GetDocEntry();

    let MOBJECT: IDocument;

    if (DOC_ENTRY > 0) {
      MOBJECT = this.saleDocumentModel;
    }

    this.bpService.GetCustomerDetails(`${_code}`, _cardCode).toPromise().then(next => {
      if (!this.documentForm.get("cardCode").value)//(<HTMLInputElement>document.getElementById('customerCode')) && !(<HTMLInputElement>document.getElementById('customerCode')).value)
      {
        this.documentForm.get("cardCode").patchValue(MOBJECT ? MOBJECT.CardCode : next.Data.CardCode);
        //(<HTMLInputElement>document.getElementById('customerCode')).value = MOBJECT ? MOBJECT.CardCode : next.Data.CardCode;
        if (next.Data)
        {
          this.documentForm.patchValue({PayTerms: next.Data.GroupNum});
          this.documentForm.patchValue({PriceList: next.Data.ListNum});
          this.userCurrency = this.PriceList.find(x => x.ListNum == next.Data.ListNum).PrimCurr;
          this.currencyPayment = this.userCurrency;
        }
      }
      return MOBJECT ? MOBJECT.CardName : next.Data.CardName;
    }).catch(error => {
      console.error(error);
      return;
    });
  }

  // formatter = (x: { Cedula: string; CardName: string }) => this.ValidateCardName(x.CardName);
  formatter = (x: { Code: number; CardCode: string }) => this.ValidateCardName(x.Code, x.CardCode);

  CheckIfPatientExists(_event): void {
    let cardNameInputValue = this.documentForm.get("cardName").value;

    if (cardNameInputValue && cardNameInputValue != "" && !this.bpNameList.find(x => x.toUpperCase().includes(cardNameInputValue.toUpperCase()))) {
      this.alertService.PromptedTitleddAlert(`No se ha encontrado el paciente`, `¿ Desea crearlo ?`, `question`).then(result => {
        if (result) {

          this.RaisePatientModal();
        }
      });
    }
  }

  // funcion para detectar el cambio en el input de descripcion
  // recibe como parametro la descripcion del item
  ChangeDescriptionCustomer(_event) : void
  {
    const SELECTED_PATIENT: { CardName: string; CardCode: string; Cedula: string; Code: string } = _event;

    try
    {
      _event.preventDefault();
    }
    catch (error) {}

    if (SELECTED_PATIENT && SELECTED_PATIENT.CardName)
    {
      this.invalidPatient = false;

      this.documentForm.get("cardName").patchValue(SELECTED_PATIENT.CardName);

      this.documentForm.get("Code").patchValue(SELECTED_PATIENT.Code);

      this.documentForm.get("cardCode").patchValue(SELECTED_PATIENT.CardCode);

      let cusCardName = SELECTED_PATIENT.CardName;

      if (cusCardName != null)
      {
        let cusCNameFiltered = this.bpNameList.filter(
          (bp) => bp.toLowerCase().includes(cusCardName.toLowerCase())
        );

        if (cusCNameFiltered.length > 0)
        {
          this.bpService.GetCustomerDetails(SELECTED_PATIENT.Code, SELECTED_PATIENT.CardCode.toString())
            .subscribe({
              next: (next) => {
                if(next.Result)
                {
                  this.feForm.patchValue({
                    IdType: next.Data.IdType,
                    Identification: next.Data.U_Identificacion,
                    Email: next.Data.U_Email
                  });

                  this.SetValueToUDFCedulaPaciente(next.Data.U_Identificacion);

                  this.GetCurrencyByUser();

                  if (this.PriceList && this.PriceList.length > 0)
                  {
                    this.userCurrency = this.PriceList.find(x => x.ListNum == next.Data.ListNum)!.PrimCurr;

                    this.currencyPayment = this.userCurrency;
                  }

                  // Valida que solo si no esta seleccionado otro medio de pago
                  // se seleccionen los terminos de pago del cliente
                  if (this.documentForm.controls['AnotherPayment'].value == -1)
                  {
                    if (this.storage.GetDocEntry() <= 0)
                    {
                      this.documentForm.patchValue({PayTerms: next.Data.GroupNum});
                    }
                  }

                  this.userCurrency = this.PriceList.find(x => x.ListNum == next.Data.ListNum)!.PrimCurr;

                  this.currencyPayment = this.userCurrency;

                  if (this.itemsList.length <= 0) this.documentForm.patchValue({PriceList: next.Data.ListNum});

                  let nombre = "Contado";

                  this.defaultContado = "Contado";

                  if (next.Data.ClienteContado === false)
                  {
                    nombre = "A30Dias";

                    this.defaultContado = "A30Dias";
                  }

                  for (let item of this.paymentTypes)
                  {
                    if (nombre === item.name.toString())
                    {
                      this.documentForm.patchValue({paymentType: item.id});
                    }
                  }
                }
                else
                {
                  console.log(`${next.Error.Code} - ${next.Error.Message}`);
                }
              },
              error: (error) => {
                console.error(error);
              }
            });
        }
      }
      else
      {
        this.invalidPatient = true;

        if (this.bpList && this.bpList.length > 0 && this.documentForm.get("cardName").value)
        {
          let cardNameInputValue = this.documentForm.get("cardName").value;

          if (cardNameInputValue && cardNameInputValue != "" && !this.bpNameList.find(x => x.toUpperCase().includes(cardNameInputValue.toUpperCase())))
          {
            this.alertService.PromptedTitleddAlert(`No se ha encontrado el paciente`, `¿ Desea crearlo ?`, `question`)
            .then(result => {
              if (result) {

                this.RaisePatientModal();
              }
            });
          }
        }
      }
    }
    else
    {
      if (SELECTED_PATIENT)
      {
        this.invalidPatient = !this.bpNameList.some((bp) => bp == (SELECTED_PATIENT.CardName || SELECTED_PATIENT));
      }
      else
      {
        this.invalidPatient = true;
      }
    }
  }


  /**
   * Set the selected customer identification to the UDF Cedula_Paciente
   * @param pCustomerIdentification Identification of the selected customer
   * @constructor
   */
  SetValueToUDFCedulaPaciente(pCustomerIdentification: string): void
  {
    this.SetValueToUDF("U_Cedula_Paciente", pCustomerIdentification, "String");
  }

  // funcion para detectar el cambio en el input de Código
  // recibe como parametro el Código del cliente
  ChangeCode(_code) { // Lo dejo con el return para elimar la ejecucion de cual quier evento
    return;
  }

  SetupRecalculate(): void {
    this.conta = 0;
    this.total = 0;
    this.totalUSD = 0;
    this.tax = 0;
    this.discount = 0;
    this.totalWithoutTax = 0;
    let itemsToCalculate: any[] = []; // lista de items

    this.itemsList.forEach(x => itemsToCalculate.push(x));
    this.blockUI.start('Recalculando precios, espere por favor');
    setTimeout(() => {
      this.PollItemsData(itemsToCalculate);
    }, 1000);
  }

  PollItemsData(_itemsList: any[]): void {
    if (_itemsList.length === 0 || this.itemsList.length === 0) {
      this.blockUI.stop();
      return;
    }
    const ITEM_INDEX = _itemsList.length - 1;
    const ITEM = _itemsList[ITEM_INDEX];
    const PRICE_LIST = this.documentForm.get('PriceList').value;

    // Get default warehouse to find item info
    let warehouse = this.warehouses.find(x => x.Speciality === this.documentForm.controls['Speciality'].value);

    this.itemService.GetItemByItemCode(ITEM.Item, PRICE_LIST, (warehouse ? warehouse.WhsCode : ''), this.documentForm.controls.cardCode.value)
      .subscribe((data: any) => {
        if (data.Result) {
          this.total += ITEM.UnitPrice;
          this.itemsList[ITEM_INDEX].DiscountPercent = data.Item.Discount;
          this.itemsList[ITEM_INDEX].DiscountG = data.Item.DiscountG;
          this.LineTotalCalculateExt(_itemsList.length - 1);
          this.UpdateDocumentAmounts();
          _itemsList.pop();
          this.PollItemsData(_itemsList);
        } else {
          this.PollItemsData([]);
          this.alertService.errorAlert(`Error no se pudo recalcular los precios, motivo: código: ${data.Error.Code}, mensaje: ${data.Error.Message}`);
        }
      }, (error: any) => {
        this.PollItemsData([]);
        this.alertService.errorInfoAlert(`Error no se pudo recalcular los precios, motivo: ${error}`);
      });
  }


  GetCustomerUpdate() {
    this.blockUI.start('Obteniendo clientes, espere por favor...');
    this.bpService.GetCustomers()
      .subscribe((data: any) => {
        this.blockUI.stop();
        // console.log(data.Code != null && data.Code == '404');
        if (data && data.Code === undefined && data.BPS != null) {
          this.bpList.length = 0;
          this.bpCodeList.length = 0;
          this.bpNameList.length = 0;
          this.patientCodes = [];
          this.bpList = data.BPS;
          for (let item of data.BPS) {
            this.bpCodeList.push(item.CardCode);
            this.bpNameList.push(item.CardName);
            this.patientCodes.push(item.Code);
          }

        } else {
          this.alertService.errorAlert(`Error: código: ${data.Error.Code}, mensaje: ${data.Error.Message}`);
        }

      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
      });
  }

  //Calculo de totales basado en ERA
  UpdateDocumentAmounts(): void {
    this.total = 0;
    this.discount = 0;
    this.tax = 0;
    this.totalWithoutTax = 0;
    const DECS = (() => +`1${`0`.repeat(this.COMPANY.DecimalAmountTotalDocument)}`)();

    // Recorre toda la lista de items agregados a facturar.
    this.itemsList.forEach(x => {

      // const FIRST_SUBTOTAL = (x.Quantity * x.UnitPrice);
      const FIRST_SUBTOTAL = Math.round((x.Quantity * x.UnitPrice) * DECS) / DECS;

      /**
       * Segunda versión del cálculo para decimales
       * En esta implementación se contemplan decimales dinámicos,
       * pero a pesar de esto es una versión de pruebas
       */
      const LINE_DISCOUNT = ((Math.round(FIRST_SUBTOTAL * DECS) / DECS) * (x.DiscountPercent / 100));

      const SUBTOTAL_WITH_LINE_DISCOUNT = (Math.round(((FIRST_SUBTOTAL - LINE_DISCOUNT)) * DECS) / DECS);

      const HEADER_DISCOUNT = 0; // Este campo lo deja eaguilar para que si en futuro se decide implementar descuento de cabecera en el proyecto solo sea mapear la variable y no alterar la formula del cálculo

      const TOTAL_HEADER_DISCOUNT = (SUBTOTAL_WITH_LINE_DISCOUNT * HEADER_DISCOUNT);

      const SUBTOTAL_WITH_HEADER_DISCOUNT = SUBTOTAL_WITH_LINE_DISCOUNT - TOTAL_HEADER_DISCOUNT;

      const CURRENT_TAX_RATE = x.TaxRate / 100;

      const TOTAL_TAX = SUBTOTAL_WITH_HEADER_DISCOUNT * CURRENT_TAX_RATE;
      //Math.round(((SUBTOTAL_WITH_HEADER_DISCOUNT * CURRENT_TAX_RATE) + Number.EPSILON) * 100) / 100;

      this.totalWithoutTax += SUBTOTAL_WITH_HEADER_DISCOUNT * +!x.TaxOnly;
      //Math.round((SUBTOTAL_WITH_HEADER_DISCOUNT * (+!x.TaxOnly) + Number.EPSILON) * 100) / 100;

      this.discount += LINE_DISCOUNT;

      this.tax += TOTAL_TAX;
    });

    this.tax = Math.round((this.tax) * DECS) / DECS;

    this.total = Math.round((Math.round(this.totalWithoutTax * DECS) / DECS + Math.round(this.tax * DECS) / DECS + Number.EPSILON) * DECS) / DECS;
  }

  //#endregion


  /**
   * Return the observable flow for get the configured UDFs
   * @param pDocumentAlias Document type alias
   * @constructor
   */
  GetConfiguredUDFsObservable(pDocumentAlias: DOCUMENT_ALIAS): Observable<IUdfsByCategoryResponse>
  {
    return this.udfService.GetConfiguredUdfsByCategory(pDocumentAlias)
      .pipe(
        map((response) => {
          if(response.Result)
          {
            response.Udfs.filter(x => x.Values !== '')
              .forEach(x => x.MappedValues = (JSON.parse(x.Values) as IudfValue[]));

            this.GenerateUDFsControls(response.Udfs);

            this.SetUDFsDefaultValues();

            this.ListenCategoryUdfChanges();

            this.ListenPatientIdentificationNumberChanges();
          }
          else
          {
            console.info(response);
          }

          return response;
        }),
        concatMap(response => {
          const DOC_ENTRY = this.storage.GetDocEntry();

          return forkJoin([
            DOC_ENTRY > 0 && this.udfs.length > 0 ? this.GetDocumentUDFsInformationObservable(this.udfs, pDocumentAlias, DOC_ENTRY) : of(null),
            this.GetUDFsDevelopmentObservable()
          ]);
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  /**
   * Generate form controls to the UDFs form based on the configured UDFs
   * @param pConfiguredUDFs List of configured UDFs
   * @constructor
   * @private
   */
  private GenerateUDFsControls(pConfiguredUDFs: IUdf[]): void
  {
    this.udfs = [];

    for(let udf of pConfiguredUDFs)
    {
      if(!this.udfsForm.contains(udf.Name))
      {
        this.udfsForm.addControl(udf.Name, new FormControl(undefined, udf.IsRequired ? Validators.required : undefined));
      }

      this.udfs.push(udf);
    }
  }

  /**
   * Set values to the UDFs
   * @constructor
   * @private
   */
  private SetUDFsDefaultValues(): void
  {
    if(this.invoicesInMemoryService.HasInvoicesInMemory()) return;

    this.SetValueToUDF("U_Categoria", "00", "String");
  }
  GetUdfsData(_udfs: IUdf[], _category: string, _docEntry): void {
    this.blockUI.start(`Obteniendo datos de udfs, espere por favor`);
    this.udfService.GetUdfsData(_udfs, _docEntry, _category).subscribe(next => {
      this.blockUI.stop();
      if (next.Result)
      {
        next.UdfsTarget.forEach(x => {
          this.SetValueToUDF(x.Name, x.Value, x.FieldType);
        });
      }
      else
      {
        console.log(next);
        this.alertService.errorAlert(`Error ${next.Error ? next.Error.Message : JSON.stringify(next)}`);
      }
    }, error => {
      this.blockUI.stop();
      console.log(error);
    });
  }

  /**
   * Return the document UDFs information request as observable with mapping
   * @param pUDFs List of UDFs to retrieve the information
   * @param pCategory Document type alias
   * @param pDocEntry Document entry
   * @constructor
   */
  private GetDocumentUDFsInformationObservable(pUDFs: IUdf[], pCategory: string, pDocEntry: number): Observable<IUdfsTargetResponse>
  {
    return this.udfService.GetUdfsData(pUDFs, pDocEntry.toString(), pCategory)
      .pipe(
        map(response => {
          if (response.Result)
          {
            response.UdfsTarget.forEach(x => {
              this.SetValueToUDF(x.Name, x.Value, x.FieldType);
            });
          }
          else
          {
            console.error(response);
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      )
  }

  /**
   * Set a value to a UDF
   * @param pName Name of the UDF where the value will be applied
   * @param pValue Value that will be applied
   * @param pFieldType Data type of the UDF value
   * @param pEmitEvent Indicates if should emit the event of changes when set the value to the form
   * @constructor
   * @private
   */
  private SetValueToUDF(pName: string, pValue: string, pFieldType: string, pEmitEvent: boolean = true): void
  {
    let control = this.udfsForm.get(pName);

    if(control)
    {
      if (pFieldType === 'DateTime')
      {
        if(pValue !== '')
        {
          control.patchValue(formatDate(pValue, 'yyyy-MM-dd', 'en'), {emitEvent: pEmitEvent});
        }
      }
      else
      {
        control.patchValue(pValue, {emitEvent: pEmitEvent});
      }
    }
  }
  MapDataType(_dataType): string {
    let mappedDataType = 'No definido';

    switch (_dataType) {
      case 'String':
        mappedDataType = "text";
        break;
      case 'Int32':
        mappedDataType = "number";
        break;
      case 'DateTime':
        mappedDataType = "date";
        break;
    }
    return mappedDataType;
  }

  GetUdfDevelopment(): void {
    this.udfService.GetUdfDevelopment().subscribe(next => {
      if (next.Result) {
        next.UdfCategories.filter(x => x.Name === DOCUMENT_ALIAS.INC_PAYMENT_CARDS).forEach(x => {
          this.udfTargets.push({
            Key: x.Description,
            Value: ''
          });
        });
        this.IsUdfIntegrityValid();
      }
    });
  }

  /**
   * Return the UDFs development request as observable with mapping
   * @constructor
   */
  private GetUDFsDevelopmentObservable(): Observable<IUdfCategoryResponse>
  {
    return this.udfService.GetUdfDevelopment()
      .pipe(
        map(response => {
          if (response.Result)
          {
            response.UdfCategories
              .filter(x => x.Name === DOCUMENT_ALIAS.INC_PAYMENT_CARDS)
              .forEach(x => {
                this.udfTargets.push({
                  Key: x.Description,
                  Value: ''
                });
              });

            this.IsUdfIntegrityValid();
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  UdfSetter(_udfTargets: IKValue[]): void {
    _udfTargets.forEach(x => {
      const NOT_REDERED_UDF = this.udfsForm.get(x.Key);
      if (!NOT_REDERED_UDF) throw new Error(`El udf ${x.Key} es requerido para completar el documento pero está eliminado de la configuración de udfs, por favor
      agreguelo en la configuración`);
    });
  }

  // Verifica si los udfs a usar por desarrollo no han sido eliminados de la configuracion general
  IsUdfIntegrityValid(): boolean {
    let isValid = true;
    this.udfTargets.forEach(x => {
      if (!this.udfs.find(y => y.Name === x.Key)) {
        this.alertService.errorAlert(`El udf ${x.Key} es requerido para completar el documento pero está eliminado de la configuración de udfs, por favor
        agreguelo en la configuración`);
        isValid = false;
        return;
      }
    });

    return isValid;
  }

  // Se ejecta luego de completar la data de pago
  UdfPaymentValidation(): boolean {
    try {
      if (!this.IsUdfIntegrityValid()) return false;

      this.UdfSetter(this.udfTargets);

      this.mappedUdfs = [];

      this.udfs
        .forEach(x => {
          let control = this.udfsForm.get(x.Name);

          if(control)
          {
            this.mappedUdfs.push({
              Name: x.Name,
              Value: control.value,
              FieldType: x.FieldType
            } as IUdfTarget);
          }
      });


      let udfName = '';

      let isValid = true;

      this.udfs
        .forEach(x => {
          let control = this.udfsForm.get(x.Name);

          if(control)
          {
            if (x.IsRequired && !control.value)
            {
              udfName = x.IsRendered ? x.Description : `${x.Description}, no está renderizado pero `;

              isValid = false;

              return;
            }
          }
      });

      if (!isValid)
      {
        this.alertService.infoInfoAlert(`El campo ${udfName} es requerido`);

        return false;
      }

      return true;

    }
    catch (error)
    {
      this.alertService.errorAlert(error);

      return false;
    }
    finally
    {
      for(let control in this.udfsForm.controls)
      {
        this.udfsForm.get(control).markAsTouched();
      }
    }
  }

  //#endregion

  //#region AJUSTE INVENTARIO
  GetAvailableItemInventory(code: string, cant: number) {
    this.itemService.GetWHAvailableItem(code)
      .subscribe((data: any) => {
        if (data.Result) {
          let available: boolean = false;
          data.whInfo.forEach(wh => {
            if (wh.Disponible < cant && wh.InvntItem === "Y") {
              available = true;
            }
          });
          if (data.whInfo.length > 0) {
            if (available) {
              this.alertService.infoInfoAlert(`Sin stock, solicitud de ${cant}, disponible:${data.whInfo[0].Disponible} `);
            }
          } else {
            this.alertService.infoInfoAlert('Este artículo no posee disponibles en ningún almacén.');
          }
        } else {
          this.alertService.errorAlert('Error al Obtener disponibilidad el artículo - ' + data.Error.Message);
        }

      }, (error: any) => {
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
      });
  }


  GetSettings(): void {
    this.companyService.GetSettingsbyId(CONFIG_VIEW.OIGN).subscribe(response => {
      if (response.Result) {
        let result = JSON.parse(response.Data.Json);
        ;
        this.CommentInv = result.Comments;
        this.GoodsReceiptAccount = result.GoodsReceiptAccount;
      } else {
        this.alertService.errorAlert('Ócurrio un error obteniendo configuración de ajuste de inventario ' + response.Error.Message);
      }
    }, err => {
      this.alertService.errorAlert('Ócurrio un error obteniendo configuración de ajuste de inventario ' + err);
    });
  }

  // Levanta la modal de ajuste de inventario.

  InventorySettings(): void {

    this.GetSettings();

    this.blockUI.start('Obteniendo información');
    // Se filtra las lines que no tienen en stock la cantidad solicitada, seran las que se usen para el ajuste del inventario.
    let filterLines = this.itemsList.filter(x => +x.available < (this.itemsList.filter(y => y.ItemCode == x.ItemCode && y.WarehouseCode == x.WarehouseCode).reduce((p, c) => {
      return p + c.Quantity
    }, 0)) && x.InvntItem === "Y");

    let filterLinesGroup = filterLines.filter((item, index) => {
      return filterLines.findIndex(x => x.ItemCode == item.ItemCode && x.WarehouseCode == item.WarehouseCode) == index;
    });


    // Resive una lista de itemCodes de los items que se les quiere realizar el ajuste, este metodo valida si el item puede ser enviado a ajuste
    // Retorna un array de objetos formados por, itemCde, ultimo precio de compra, precio promedio y si se puede o no enviar a ajuste.
    this.itemService.GetDataForGoodReceiptInvocie(filterLinesGroup.map(i => i.Item), this.whCode).subscribe(response => {
      this.blockUI.stop();
      if (response.Result) {
        // Seteamos el mensaje que se ve en el popover de los items con desviacion
        this.DeviationMessage = response.Data[0].Message;

        filterLinesGroup.forEach(x => {

          // se busca la data obtenida del item que se esta iterando
          let value = response.Data.find(i => i.ItemCode === x.Item);
          let cantidad = this.itemsList.filter(y => y.ItemCode == x.ItemCode && y.WarehouseCode == x.WarehouseCode).reduce((p, c) => {
            return p + c.Quantity
          }, 0);
          const LINE = {
            // BarCode: x.BarCode,50
            ItemCode: x.Item,
            ItemName: `${x.Item} - ${x.ItemName}`,
            Quantity: +cantidad - x.available,
            Available: x.available,
            Missing: +cantidad,
            TaxCode: x.TaxCode,
            UnitPrice: value.LastPrice,
            Discount: x.DiscountPercent,
            DiscountG: x.DiscountG,
            Tax_Rate: x.TaxRate,
            TaxRate: x.TaxRate,
            TotalLine: value.LastPrice * (cantidad - x.available),
            WareHouse: x.WarehouseCode,
            AVGPrice: value.AVGPrice,
            LastPurchasePrice: value.LastPrice,
            DeviationStatus: value.DeviationStatus
          }

          this.lines.push(LINE);

        });


        this.TotalCalculateInventorySetting();

      } else {
        // Error
      }
    }, err => {
      //msj Error
      this.blockUI.stop();
    });
    (<HTMLInputElement>document.getElementById('raiseModalInventorySettings')).click();
  }


  //#001  Retorna el mensaje que se ve en el popover cuando un item cuenta con desviacion en su precio.
  ItemForAdjustment(index: number): string {
    if (this.lines[index] && this.lines[index].DeviationStatus === 0) {
      return this.DeviationMessage;
    }
    return '';
  }

  //#region UDFSOIGN
  GetUdfDevelopmentOIGN(): void {
    this.udfService.GetUdfDevelopment().subscribe(next => {
      if (next.Result) {
        next.UdfCategories.filter(x => x.Name === DOCUMENT_ALIAS.GOODSRECEIPT).forEach(x => {
          this.udfTargetsOIGN.push({
            Key: x.Description,
            Value: ''
          });
        });
        this.IsUdfIntegrityValidOIGN();
      }
    });
  }

  /**
   * Returns the development UDFs for OIGN request as observable with mapping
   * @constructor
   * @private
   */
  private GetDevelopmentUDFsOIGNObservable(): Observable<IUdfCategoryResponse>
  {
    if(![SOAndSQActions.CreateInvoice, SOAndSQActions.CopyToInvoice].includes(this.storage.GetDocumentType()))
    {
      return of(null);
    }

    return this.udfService.GetUdfDevelopment()
      .pipe(
        map(response => {
          if (response.Result)
          {
            response.UdfCategories.filter(x => x.Name === DOCUMENT_ALIAS.GOODSRECEIPT)
              .forEach(x => {
                this.udfTargetsOIGN.push({
                  Key: x.Description,
                  Value: ''
                });
              });

            this.IsUdfIntegrityValidOIGN();
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  GetConfiguredUdfsOIGN(_documentAlias: string): void {
    this.blockUI.start(`Obteniendo datos, espere por favor`);
    this.udfService.GetConfiguredUdfsByCategory(_documentAlias).subscribe(next => {
      this.blockUI.stop();
      if (next.Result) {
        this.udfsOIGN = next.Udfs;
        this.udfsOIGN.filter(x => x.Values !== '').forEach(x => x.MappedValues = (JSON.parse(x.Values) as IudfValue[]));

        this.GetUdfDevelopmentOIGN();

      } else {
        console.log(next);
      }
    }, error => {
      this.blockUI.stop();
      console.log(error);
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
    });


  }

  /**
   * Return the configured UDFs request for OIGN as observable with mapping
   * @constructor
   * @private
   */
  private GetConfiguredUDFsOIGNObservable(): Observable<IUdfsByCategoryResponse>
  {
    if(![SOAndSQActions.CreateInvoice, SOAndSQActions.CopyToInvoice].includes(this.storage.GetDocumentType()))
    {
      return of(null);
    }

    return this.udfService.GetConfiguredUdfsByCategory(DOCUMENT_ALIAS.GOODSRECEIPT)
      .pipe(
        map(response => {
          if (response.Result)
          {
            this.udfsOIGN = response.Udfs;

            this.udfsOIGN.filter(x => x.Values !== '').forEach(x => x.MappedValues = (JSON.parse(x.Values) as IudfValue[]));
          }
          else
          {
            console.log(response);
          }

          return response;
        }),
        concatMap(response => this.GetDevelopmentUDFsOIGNObservable()),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      )
  }

  // Verifica si los udfs a usar por desarrollo no han sido eliminados de la configuracion general
  IsUdfIntegrityValidOIGN(): boolean {
    let isValid = true;
    this.udfTargetsOIGN.forEach(x => {
      if (!this.udfsOIGN.find(y => y.Name === x.Key)) {
        this.alertService.errorAlert(`El udf ${x.Key} es requerido para completar el documento pero está eliminado de la configuración de udfs, por favor
      agreguelo en la configuración`);
        isValid = false;
        return;
      }
    });

    return isValid;
  }

  // CONFIG OIGN UDFS
  UdfOIGNValidation(): boolean {
    try {
      if (!this.IsUdfIntegrityValidOIGN()) return false;

      this.UdfSetter(this.udfTargetsOIGN);

      this.mappedUdfsOIGN = [];

      this.udfsOIGN
        .forEach(x => {
          let control = this.udfsForm.get(x.Name);

          if(control)
          {
            this.mappedUdfsOIGN.push({
              Name: x.Name,
              Value: control.value,
              FieldType: x.FieldType
            } as IUdfTarget);
          }
      });


      let udfName = '';

      let isValid = true;

      this.udfsOIGN
        .forEach(x => {
          let control = this.udfsForm.get(x.Name);

          if(control)
          {
            if (x.IsRequired && control.value == '')
            {
              udfName = x.IsRendered ? x.Description : `${x.Description}, no está renderizado pero `;

              isValid = false;

              return;
            }
          }
      });

      if (!isValid)
      {
        this.alertService.infoInfoAlert(`El campo ${udfName} es requerido`);

        return;
      }

      return true;
    }
    catch (error)
    {
      this.alertService.errorAlert(error);

      return false;
    }
  }

  //#endregion

  // Generar ajuste de inventario
  SaveInventorySettings() {
    if (!this.UdfOIGNValidation()) {
      this.blockUI.stop();

      return;
    }
    if (this.GoodsReceiptAccount == '') {
      this.alertService.infoAlert('Cuenta no configurada para ajuste de inventario');
      return;
    }

    const CORRUPTED_QUANTITY = this.lines.find(x => x.Quantity == 0);
    if (CORRUPTED_QUANTITY) {
      this.alertService.errorAlert(`Cantidad del producto  ${CORRUPTED_QUANTITY.ItemCode}  - ${CORRUPTED_QUANTITY.ItemName}, no puede ir en  0`);
      return;
    }
    const CORRUPTED_PRICE = this.lines.find(x => x.UnitPrice == 0);
    if (CORRUPTED_PRICE) {
      this.alertService.errorAlert(`El precio del producto  ${CORRUPTED_PRICE.ItemCode}  - ${CORRUPTED_PRICE.ItemName}, no puede ir en  0`);
      return;
    }

    const CORRUPTED_LINES = this.lines.some(i => i.DeviationStatus == 0);

    if (CORRUPTED_LINES) {
      this.alertService.infoAlert('Existen artículos que no pueden ser procesados ya que cuentan con una desviación')
      return;
    }


    // Validacion por si se requiere mostrar con los nombres o codigos de los articulos que no pueden ser procesados por tener desviacion
    // const CORRUPTED_LINES = this.lines.filter(i => i.hasDeviation);

    // if (CORRUPTED_LINES && CORRUPTED_LINES.length > 0) {
    //   let htmlMessage: string = '<lu style="text-align: left">?</lu>';
    //   let corruptedLines: string = '';

    //   CORRUPTED_LINES.forEach(i => {
    //     corruptedLines += '<li>' + i.ItemName + '</li>'
    //   });

    //   this.alertService.InfoAlertHtml(htmlMessage.replace('?', corruptedLines), 'Los siguientes articulos no pueden ser procesados ya que cuentan con una desviacion del ' + this.deviationPercent + '%');
    //   return;
    // }


    swal({
      type: 'warning',
      title: 'Se realizará ajuste de inventario',
      text: '¿ Desea continuar ?',
      showCancelButton: true,
      confirmButtonColor: '#049F0C',
      cancelButtonColor: '#ff0000',
      confirmButtonText: 'Continuar',
      cancelButtonText: 'No'
    }).then(next => {
      if (!(Object.keys(next)[0] === 'dismiss')) {
        this.blockUI.start('Realizando ajuste de inventario');

        this.goodsReciptStockService.CreateGoodsReceiptStock(
          this.lines, this.documentForm.controls.PriceList.value, this.CommentInv,
          this.usersCredentials, this.GoodsReceiptAccount, this.mappedUdfsOIGN, this.uniqueInvCode
        ).subscribe(response => {
          this.blockUI.stop();
          if (response.Result) {
            this.returnedDocNum = response.DocNum;
            this.closebuttonInvSetting.nativeElement.click();
            (<HTMLButtonElement>document.getElementById('triggerAfterInvModal')).click();
            this.lines.forEach(x => {
              this.itemService.GetWHAvailableItem(x.ItemCode)
                .subscribe((data: any) => {
                  if (data.Result) {
                    let available: boolean = false;
                    data.whInfo.forEach(wh => {
                      if (wh.InvntItem === "N") {
                        available = true;
                      }
                    });
                    if (!available) {
                      var index: number = this.itemsList.indexOf(this.itemsList.find(y => y.Item === x.ItemCode));
                      if (index != -1) {
                        this.itemsList[index].available = data.whInfo[0].Disponible;
                      }
                    }

                  } else {
                    this.blockUI.stop();
                    this.alertService.errorAlert('Error al obtener disponibilidad el artículo - ' + data.Error.Message);
                  }

                }, (error: any) => {

                  this.blockUI.stop();
                  this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
                });
            });


            this.lines = [];

            (<HTMLInputElement>document.getElementById('CreateInvo')).click();

          } else {
            console.log(response);
            this.alertService.errorAlert('Error' + response.Error.Message);
          }
        }, error => {
          console.log(error);
          this.blockUI.stop();
          if (error.error && error.error.errorInfo) {
            this.alertService.errorAlert(`Error: código ${error.error.Error.Code}. Detalle: ${error.error.Error.Message}`);
          } else {
            this.alertService.errorAlert(`Error: ${error}`);
          }
        });

      }
    });

  }

  OnCloseModalInventorySettings() {

    // (<HTMLInputElement>document.getElementById('CreateInvo')).click();
    swal({
      type: 'warning',
      title: 'No se ha realizado el ajuste de inventario',
      text: '¿ Desea continuar ?',
      showCancelButton: true,
      confirmButtonColor: '#049F0C',
      cancelButtonColor: '#ff0000',
      confirmButtonText: 'Continuar',
      cancelButtonText: 'No'
    }).then(next => {
      if (!(Object.keys(next)[0] === 'dismiss')) {
        this.lines.forEach(x => {

          var index: number = this.itemsList.indexOf(this.itemsList.find(y => y.Item === x.ItemCode));
          if (x.Available == 0) {
            if (index != -1) {
              this.itemsList.splice(index, 1);
            }
            this.UpdateDocumentAmounts();
          } else {
            if (index != -1) {
              this.itemsList[index].Quantity = x.Available;
              this.LineTotalCalculateExt(index);
            }
          }

        });

        this.lines = [];
        this.closebuttonInvSetting.nativeElement.click();
      }
    });


  }

  closeAfterInvModal() {
    this.lines = [];
    this.udfsOIGN = [];
    this.GetConfiguredUdfsOIGN(DOCUMENT_ALIAS.GOODSRECEIPT);

    (<HTMLInputElement>document.getElementById('CreateInvo')).click();


  }

  LineTotalCalculateInventorySetting(i: number, field: string) {
    switch (+field) {
      case 1:
        this.lines[i].Quantity = +(parseFloat((<HTMLInputElement>document.getElementById('quantity_' + i)).value).toFixed(2));
        this.lines[i].TotalLine = this.lines[i].Quantity * this.lines[i].LastPurchasePrice;
        this.lines[i].TotalLine = +(this.lines[i].Quantity * this.lines[i].LastPurchasePrice).toFixed(2);
        break;
      case 2:
        this.lines[i].LastPurchasePrice = +(parseFloat((<HTMLInputElement>document.getElementById('lastPrice_' + i)).value).toFixed(2));
        this.lines[i].TotalLine = +(this.lines[i].Quantity * this.lines[i].LastPurchasePrice).toFixed(2);
        this.lines[i].UnitPrice = this.lines[i].LastPurchasePrice;
        break;

      case 3:
        this.lines[i].LastPurchasePrice = +(parseFloat((<HTMLInputElement>document.getElementById('totalLine_' + i)).value).toFixed(2)) / this.lines[i].Quantity;
        this.lines[i].LastPurchasePrice = +this.lines[i].LastPurchasePrice.toFixed(2);
        this.lines[i].TotalLine = +(parseFloat((<HTMLInputElement>document.getElementById('totalLine_' + i)).value).toFixed(2));
        this.lines[i].UnitPrice = this.lines[i].LastPurchasePrice;
        break;
    }

    this.TotalCalculateInventorySetting();
  }

  TotalCalculateInventorySetting() {
    this.CRCTotal = +this.lines.reduce((a, b) => a +
      (((b.LastPurchasePrice * b.Quantity) - ((b.LastPurchasePrice * b.Quantity) * (b.Discount / 100))) +
        (((b.LastPurchasePrice * b.Quantity) - ((b.LastPurchasePrice * b.Quantity) * (b.Discount / 100))) * (b.Tax_Rate / 100)) || 0), 0).toFixed(2);

  }

  //#endregion

  //#region configuracion lineas sin stock,precio

  LineStatus(item: any, index: number): string {

    if (+item.UnitPrice <= +item.LastPurchasePrice && +item.UnitPrice !== 0) {
      return `Costo del artículo es mayor o igual al precio de venta. Precio venta: ${+item.UnitPrice} Precio costo: ${+item.LastPurchasePrice}`;
    } else if (+item.UnitPrice == 0) {
      return "No tiene precio";
    }
    if (+item.Quantity === 0 && item.ItemClass != ItemsClass.Service) {
      return "Sin stock";
    }

    // let cantsingroupLine: number = 0;
    // const INDEX = this.itemsList.findIndex(x => x.available !== 0 && x.Item === this.itemsList[index].Item);

    // this.itemsList.forEach(x => {
    //   if (x.Item === this.itemsList[index].Item) {
    //     cantsingroupLine += x.Quantity;

    //     if (INDEX !== -1) {
    //       x.available = this.itemsList[INDEX].available
    //       x.InvntItem = this.itemsList[INDEX].InvntItem
    //     }
    //   }
    // });

    const QUANTITYTOTAL = this.itemsList.filter(y => y.Item == this.itemsList[index].Item && this.itemsList[index].InvntItem === "Y" && y.WarehouseCode == this.itemsList[index].WarehouseCode && this.itemsList[index].ItemClass != ItemsClass.Service).reduce((p, c) => {
      return p + c.Quantity
    }, 0);

    if (this.itemsList[index].available < +QUANTITYTOTAL && this.itemsList[index].InvntItem === "Y" && this.itemsList[index].ItemClass != ItemsClass.Service) {
      return `Sin stock almacén ${item.WhsName}, solicitado: ${+QUANTITYTOTAL}, disponible: ${this.itemsList[index].available}`;
    }

  }

  AvailableItemColor(item: any): boolean {
    let cantsingroupLine: number = 0;

    this.itemsList.forEach(x => {
      if (x.Item === item.Item) {
        cantsingroupLine += x.Quantity;
      }
    });

    if (+item.available < +cantsingroupLine && item.InvntItem === "Y" && item.ItemClass != ItemsClass.Service) {
      return true;
    }
  }

  LineColor(item: any): string {

    if (+item.UnitPrice <= +item.LastPurchasePrice || +item.UnitPrice == 0) {
      return "mOrange";
    }
  }

  GetCurrencySymbol(): string {
    switch (this.userCurrency) {
      case 'COL':
      case 'CRC':
        return 'CRC';
      case 'DOL':
      case 'USD':
        return 'USD';
      default:
        return ' ';
    }
  }

  GetSymbol(): string {
    switch (this.userCurrency) {
      case 'COL':
      case 'CRC':
        return '₡';
      case 'DOL':
      case 'USD':
        return '$';
      default:
        return '';
    }
  }

  //#endregion
  RaisePatientModal(): void {
    let modalOption: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'Modal-Xl'
    };

    this.modalPay = this.modalService.open(PatientComponent, modalOption);

    this.modalPay.componentInstance.modalStatus = {
      HeaderText: `Crear paciente`,
      IPatient: null,
      IsCreating: true
    } as IModalStatus<IPatient>;

    this.modalPay.result.then((next: IModalStatus<IPatient>) => {
    }, (next: IModalStatus<IPatient>) => {
      if (next.IsCompleted)
      {
        if (next.Data.U_Est_Paciente === '01')
        {
          this.documentForm.patchValue({
            cardCode: next.Data.U_CardCode,
            Code: next.Data.Code,
            cardName: next.Data.U_Nombre + ' ' + next.Data.U_Apellido1 + ' ' + next.Data.U_Apellido2
          });

          this.feForm.patchValue({
            IdType: next.Data.U_TipoIdentificacion,
            Identification: next.Data.U_Identificacion,
            Email: next.Data.U_Email
          });

          this.SetValueToUDFCedulaPaciente(next.Data.U_Identificacion);

          this.documentForm.get("cardName").patchValue(next.Data.U_Nombre + ' ' + next.Data.U_Apellido1 + ' ' + next.Data.U_Apellido2);

          this.documentForm.get("cardCode").patchValue(next.Data.U_CardCode);
          //(<HTMLInputElement>document.getElementById(`customerCode`)).value = next.Data.U_CardCode;

          this.documentForm.get("Code").patchValue(next.Data.Code.toString());
          //(<HTMLInputElement>document.getElementById(`clienteId`)).value = next.Data.Code.toString();

          this.bpService.GetCustomerDetails('-1', next.Data.U_CardCode).subscribe(bps => {
            if (bps.Data) {
              this.documentForm.patchValue({PayTerms: bps.Data.GroupNum});
              this.documentForm.patchValue({PriceList: bps.Data.ListNum});
              this.userCurrency = this.PriceList.find(x => x.ListNum == bps.Data.ListNum).PrimCurr;
              this.currencyPayment = this.userCurrency;
              this.bpCodeList.push(next.Data.U_CardCode);
              this.bpNameList.push(next.Data.U_Nombre + ' ' + next.Data.U_Apellido1 + ' ' + next.Data.U_Apellido2);
              this.patientCodes.push(next.Data.Code.toString());
              this.invalidPatient = false;
            }
          }, error => {
            console.log(error);
          });
        }
        else
        {
          this.alertService.infoAlert(`El paciente fue creado inactivo, no será cargado en la factura`);
        }
      }
    });
  }

  GetPatientFullName(_patient: IPatient): string {
    try {
      let fullName = ``;

      if (_patient) {
        if (_patient.U_Nombre) {
          fullName = `${_patient.U_Nombre}`;
        }

        if (_patient.U_Apellido1) {
          fullName += ` ${_patient.U_Apellido1}`;
        }

        if (_patient.U_Apellido2) {
          fullName += ` ${_patient.U_Apellido2}`;
        }
      }
      return fullName;
    } catch (error) {
      return '';
    }
  }

  GetMappedPayments(): void {
    try {
      this.mappedPayments = [];
      this.blockUI.start(`Cargando, espere por favor`);
      this.paymentService.GetMappedPayments().pipe(finalize(() => this.blockUI.stop())).subscribe({
        next: (callback: IResponse<IMappedPayment[]>) => {
          if (callback && callback.Result) {
            if (callback.Error) {

            } else {
              this.mappedPayments = callback.Data;
              if (this.mappedPayments && this.mappedPayments.length > 0) {
                this.documentForm.patchValue({
                  AnotherPayment: this.mappedPayments[0].FldValue
                });
              }
            }
          } else {

          }
        },
        error: error => {
          console.info(error);
        }
      });
    } catch (error) {
      console.info(error);
    }
  }

  /**
   * Return the mapped payments request as observable with mapping
   * @constructor
   * @private
   */
  private GetMappedPaymentsObservable(): Observable<IResponse<IMappedPayment[]>>
  {
    return this.paymentService.GetMappedPayments()
      .pipe(
        map(response => {
          if (response && response.Result)
          {
            if (!response.Error)
            {
              this.mappedPayments = response.Data;

              if (this.mappedPayments && this.mappedPayments.length > 0)
              {
                this.documentForm.patchValue({
                  AnotherPayment: this.mappedPayments[0].FldValue
                });
              }
            }
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  GetSpecialities() {
    try {
      this.specialities = [];
      this.blockUI.start(`Cargando, espere por favor`);
      this.itemService.GetSpecialities().pipe(finalize(() => this.blockUI.stop())).subscribe({
        next: (callback: IResponse<ISpeciality[]>) => {
          if (callback && callback.Result) {
            this.specialities = callback.Data;
          } else {
            this.alertService.infoAlert(`${callback.Error.Code}-${callback.Error.Message}`);
          }
        },
        error: error => {
          console.info(error);
        }
      });
    } catch (error) {
      console.info(error);
    }
  }

  /**
   * Return the specialties request observable with mapping
   * @constructor
   */
  GetSpecialtiesObservable(): Observable<IResponse<ISpeciality[]>>
  {
    return this.itemService.GetSpecialities()
      .pipe(
        map(response => {
          if (response && response.Result)
          {
            this.specialities = response.Data;
          }
          else
          {
            console.error(`${response.Error.Code}-${response.Error.Message}`)
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  GetDoctors() {
    try {
      this.doctors = [];

      this.blockUI.start(`Cargando, espere por favor`);

      this.doctorService.GetDoctors()
        .pipe(
          finalize(() => this.blockUI.stop())
        )
        .subscribe({
          next: (callback: IResponse<IDoctor[]>) => {
            if (callback && callback.Result)
            {
              this.doctors = callback.Data;
            }
            else
            {
              this.alertService.infoAlert(`${callback.Error.Code}-${callback.Error.Message}`);
            }
          },
          error: error => {
            console.info(error);
          }
      });
    } catch (error) {
      console.info(error);
    }
  }

  /**
   * Returns the doctors request as observable with mapping
   * @constructor
   */
  GetDoctorsObservable(): Observable<IResponse<IDoctor[]>>
  {
    return this.doctorService.GetDoctors()
      .pipe(
        map(response => {
          if (response && response.Result)
          {
            this.doctors = response.Data;
          }
          else
          {
            console.info(`${response.Error.Code}-${response.Error.Message}`);
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  RaiseAutenticationModal(_content) {
    this.userCredentialsForm.patchValue({
      Email: [''],
      Password: ['']
    });
    this.modalService.open(_content, {centered: true, backdrop: "static"});
  }

  ValidateCredentials(): void {
    try {
      const EMAIL = this.userCredentialsForm.controls.Email.value;
      const PASSWORD = this.userCredentialsForm.controls.Password.value;

      this.authorizatorPermissions = [];

      this.blockUI.start(`Procesando, espere por favor`);

      this.sPerm.AuthorizerUser(EMAIL, PASSWORD).pipe(finalize(() => this.blockUI.stop())).subscribe({
        next: (callback: IResponse<IPermission[]>) => {

          if (!callback || !callback.Result) {
            this.alertService.errorAlert(`${callback.Error.Code} - ${callback.Error.Message}`);
            return of(callback || []);
          }

          this.authorizatorPermissions = callback.Data || [];

          this.modalService.dismissAll();

          this.onSubmit(this.radcot);

        },
        error: error => {
          if (error && error.Error) {
            this.alertService.errorAlert(error.Error.Message);
          } else {
            this.alertService.errorAlert(JSON.stringify(error));
          }
        }
      });
    } catch (error) {
      console.info(error);
    }
  }

  GetMinCreditPayment() {
    try {
      this.blockUI.start('Obteniendo el porcentaje mínimo de pago, espere por favor...');
      this.documentService.GetMinCreditPayment().pipe(finalize(() => this.blockUI.stop())).subscribe({
        next: (callback: IResponse<number>) => {
          this.minCreditPayment = callback.Data || 0;

          if (!callback.Result) {
            this.alertService.infoAlert(`${callback.Error.Code}-${callback.Error.Message}`);
          }
        },
        error: error => {
          console.info(error);
        }
      });
    } catch (error) {
      console.info(error);
    }
  }

  /**
   * Return the minimum credit payment request as observable with mapping
   * @constructor
   * @private
   */
  private GetMinimumCreditPaymentObservable(): Observable<IResponse<number>>
  {
    return this.documentService.GetMinCreditPayment()
      .pipe(
        map(response => {
          this.minCreditPayment = response.Data || 0;

          if (!response.Result)
          {
            this.alertService.ShowAlert("info", {text: `${response.Error.Code}-${response.Error.Message}`});
          }

          return response;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      )
  }

  ItemsInList(): boolean {
    return this.itemsList.length > 0 ? true : null
  }

  GetRequiredFildsAndRules(): void {
    try {
      forkJoin(
        this.fieldService.GetRequiredFields(),
        this.fieldService.GetFieldRules()
      ).subscribe(callbacks => {
        if (callbacks[0].Result) {
          this.requiredFields = callbacks[0].Data;
        } else {
          this.alertService.errorAlert(callbacks[0].Error.Message);
        }
        if (callbacks[1].Result) {
          this.fieldRules = callbacks[1].Data;
        } else {
          this.alertService.errorAlert(callbacks[1].Error.Message);
        }
      }, error => {
        console.error(error);
      });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Return the fields and rules request as observable with mappings
   * @constructor
   * @private
   */
  private GetRequiredFieldsAndRulesObservable(): Observable<[IResponse<IRequiredField[]>, IResponse<IFieldRule[]>]>
  {
    return forkJoin(
      this.fieldService.GetRequiredFields(),
      this.fieldService.GetFieldRules()
    )
      .pipe(
        map(responses => {
          if (responses[0].Result)
          {
            this.requiredFields = responses[0].Data;
          }
          else
          {
            this.alertService.ShowAlert("error", {text: responses[0].Error.Message});
          }

          if (responses[1].Result)
          {
            this.fieldRules = responses[1].Data;
          }
          else
          {
            this.alertService.ShowAlert("error", {text: responses[1].Error.Message});
          }

          return responses;
        }),
        catchError(error => {
          this.alertService.ShowAlert("error", {text: error});

          return of(null);
        })
      );
  }

  ValidateRequiredFields(_document: CreateInvoice): boolean
  {

    // Validación dinámica de campos mediante reglas
    for (let field of this.requiredFields)
    {
      let rules = true;

      for (let rule of this.fieldRules.filter(rule => rule.RequiredFieldId === field.Id))
      {
        // Valida los campos del encabezado
        rules = ((this.documentForm.controls[rule.Name] ? this.documentForm.controls[rule.Name].value : undefined) === rule.Value);
        // Valida los campos de las lineas
        if (!rules)
        {
          rules = _document.Invoice.DocumentLines.some(line => line[rule.Name] === rule.Value);
        }
        // Si aplica la regla avanzamos de una a validar las reglas
        if (rules)
        {
          break;
        }
      }

      if (rules)
      {
        if (
          (!this.documentForm.controls[field.Name] || this.documentForm.controls[field.Name].value == field.Default) &&
          (_document.Invoice.DocumentLines.some(line => !line[field.Name] || line[field.Name] == field.Default)))
        {
          this.alertService.infoAlert(`Debe completar el campo ${field.DisplayName}`);
          return true;
        }
      }
    }

    return false;
  }

  // Selecciona los terminos de pago, respecto de otros medios de pago
  ChangeAnotherPayment() {
    let anotherPayment = this.mappedPayments.find(x => x.FldValue == this.documentForm.controls["AnotherPayment"].value);

    if (anotherPayment) this.documentForm.controls["PayTerms"].setValue(anotherPayment.GroupNum);
  }

  //#region TAPP

  FilterRewards(_rewards: any[], _points: number): any[] {
    if (_rewards) {
      return _rewards.filter(r => r.product_ids[0] === '' || this.itemsList.some(p => r.product_ids.includes(p.Item)));
    } else {
      return [];
    }
  }

  public SelectReward(_reward: any): void {

    _reward.selected = !_reward.selected;

    try {

      if (_reward.selected) {
        if (_reward.reward_type === 4) {
          _reward.product_ids.forEach(element => {
            let index = this.itemsList.findIndex(x => x.Item === element && !x.HasDiscount);
            if (index >= 0) {
              this.itemsList[index].DiscountPercent = _reward.real_cost
              this.itemsList[index].IsDiscountByTapp = true;
              this.itemsList[index].real_cost = _reward.real_cost;
              this.itemsList[index].reward_type = _reward.reward_type;
              this.LineTotalCalculateExt(index);
            }
          });
        } else if (_reward.reward_type === 1 || _reward.reward_type === 2 || _reward.reward_type === 3) {
          _reward.product_ids.forEach(element => {
            let index = this.itemsList.findIndex(x => x.Item === element && !x.HasDiscount);
            if (index >= 0) {
              this.itemsList[index].DiscountPercent = Number((_reward.real_cost / this.itemsList[index].UnitPrice) * 100);

              this.itemsList[index].IsDiscountByTapp = true;
              this.itemsList[index].real_cost = _reward.real_cost;
              this.itemsList[index].reward_type = _reward.reward_type;
              this.LineTotalCalculateExt(index);
            }
          });
        }

        this.redeemedRewards.push(_reward);

      } else {
        _reward.product_ids.forEach(element => {
          let index = this.itemsList.findIndex(x => x.Item === element && x.IsDiscountByTapp);
          if (index >= 0) {
            this.itemsList[index].DiscountPercent = 0;
            this.itemsList[index].IsDiscountByTapp = false;
            this.itemsList[index].real_cost = 0;
            this.itemsList[index].reward_type = 0;
            this.LineTotalCalculateExt(index);
          }
        });

        let index = this.redeemedRewards.findIndex(element => element.id === _reward.id);
        if (index >= 0) {
          this.redeemedRewards.splice(index, 1);
        }
      }

      // Limpiar descuento de lines cuando se selecciona cupones
      let hasReward = this.itemsList.some(item => item.IsDiscountByTapp);

      this.itemsList.forEach(item => {
        if(hasReward && !item.HasDiscount && !item.IsDiscountByTapp){
          item.DiscountPercent = 0;
        }
      });
    } catch (ex) {
      console.log(ex)
    }
  }

  FilterInflateRewards(_rewards: any[], _points: number, _total: number): any[] {
    return this.FilterRewards(_rewards, _points).map(r => {
      switch (r.reward_type) {
        case 1:
        case 2:
        case 3:
          if (r.product_ids[0] !== '') {
            let appliedItemsCount = this.itemsList.filter(p => r.product_ids.includes(p.Item)).reduce((acc, cur) => acc + cur.Quantity, 0);
            r.applied_cost = r.real_cost * appliedItemsCount;
          } else {
            r.applied_cost = r.real_cost;
          }
          break;
        case 4:
          if (r.product_ids[0] !== '') {
            let appliedItemsSum = this.itemsList.filter(p => r.product_ids.includes(p.Item)).reduce((acc, cur) => acc + (cur.UnitPrice * cur.Quantity), 0);
            r.applied_cost = appliedItemsSum * (r.real_cost / 100);
          } else {
            r.applied_cost = _total * (r.real_cost / 100);
          }
          break;
        default:
          console.warn("This type of reward can't be apllied");
          console.log(r);
          r.applied_cost = 0;
      }
      return r;
    });
  }

  showPointsModal() {
    this.pointsForm.controls['pointsSearch'].setValue('');

    this.modalTapp = this.modalService.open(this.modalTappConsultPoints, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'Modal-lg',
      backdrop: "static"
    });

    this.modalTapp.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
    });

  }

  closeAceptModalTapp(): void {
    if (this.openTappInvoiceResponse && this.openTappInvoiceResponse.error_code === 200) {
      this.modalTapp.close();
    } else {
      this.alertService.errorInfoAlert('El número de cédula buscado no es cliente TAPP');
    }
  }

  openTappInvoice(identification: string = null) {
    try {

      if (!identification || identification === '') {
        this.alertService.infoInfoAlert('Ingrese la identificación');
        return;
      }

      this.blockUI.start(`Consultando datos...`);

      let date = new Date();
      let dateFormat = `${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}-${date.getFullYear()}`;
      let currentCardCode = this.documentForm.controls['cardCode'].value;
      let currentSlpCode = this.documentForm.controls['SlpList'].value;
      let currentCustomer = this.bpList.find(customer => customer.CardCode === currentCardCode);

      if (currentCustomer || identification) {
        identification = (identification) ? identification : currentCustomer.Cedula;

        this.tappService.Get(this.uniqueInvCode, '0', dateFormat, identification, currentSlpCode)
          .pipe(
            filter((res: IResponse<string>) => {
              if (res.Result) {
                return true;
              } else {
                this.alertService.errorAlert(`Error consultando datos de tap, detalle: ${res.Error.Code}-${res.Error.Message}`);
                return false;
              }
            }),
            finalize(() => this.blockUI.stop())
          ).subscribe(next => {

          this.openTappInvoiceResponse = JSON.parse(next.Data);

          if (this.openTappInvoiceResponse && this.openTappInvoiceResponse.error_code !== 200) {
            console.warn(this.openTappInvoiceResponse.tapp_screen_messages);
          } else if (!this.openTappInvoiceResponse) {
            console.error(`Code: ${next.Error.Code}, Message: ${next.Error.Message}`);
          }
        }, (error: any) => {
          this.openTappInvoiceResponse = null;
          console.error(error);
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  PointsTapp(_docEntry: number, _dataInvoice: CreateInvoice): Observable<any | null> {

    let date = new Date(_dataInvoice.Invoice.DocDate);
    let dateFormat = `${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}-${date.getFullYear()}`;

    // Define products for points
    let products: any[] = _dataInvoice.Invoice.DocumentLines.map(line => ({
      product_code: line.ItemCode,
      product_description: line.ItemName,
      quantity: line.Quantity,
      subtotal: Number(((line.UnitPrice - (line.UnitPrice * (line.DiscountPercent / 100)))).toFixed(this.COMPANY.DecimalAmountPrice))
    }));

    let rewardIds: number[] = this.redeemedRewards.map(element => {
      return element.id
    });

    return this.tappService.Post
    (
      this.openTappInvoiceResponse.tapp_customer.identification,
      this.uniqueInvCode,
      _docEntry.toString(),
      dateFormat,
      _dataInvoice.Invoice.SalesPersonCode.toString(),
      _dataInvoice.Payment.PointsInvoiceAmount,
      _dataInvoice.Payment['PointsSum'],
      products,
      rewardIds
    ).pipe(
      tap((next: any) => {
        if (next.Result) {
          this.closeTappInvoiceResponse = JSON.parse(next.Data);
        } else {
          // Hay que validar como se controla en caso de error si se va a reintentar
          // o se pasaria por alto, puesto que para este punto la factura ya esta creada
          // de momento solo se loguean y muestran los errores
          this.closeTappInvoiceResponse = null;
          let parseResponse = JSON.parse(next.Data);
          if (parseResponse) {
            this.alertService.warningInfoAlert(`${parseResponse.tapp_screen_messages}`);
          }
          console.error(`Code: ${next.Error.Code}, Message: ${next.Error.Message}`);
          console.error(`Data: ${next.Data}`);
        }
      }),
      catchError(error => {
        console.log(error);
        this.alertService.errorInfoAlert(`Lo sentimos ocurrio un error al redimir puntos en tapp`);
        return of(null)
      })
    );
  }

  //#endregion
  closeCancelModalTapp() {
    this.modalTapp.close();
    this.openTappInvoiceResponse = null;
    this.closeTappInvoiceResponse = null;

    this.itemsList.forEach(item => {
      item.IsDiscountByTapp = false;
      if(!item.HasDiscount){
        item.DiscountPercent = 0;
      }
    });
  }

  validateTappDiscount(){
    return this.itemsList.some(item => item.IsDiscountByTapp);
  }

  /**
   * Filter the sales persons typeahead input options
   * @param pText$ Value that will be used to filter the options
   * @constructor
   */
  SearchSalesPersons: OperatorFunction<string, ISalesPerson[]> = (pText$: Observable<string>) =>
  {
    const debouncedText$ = pText$.pipe(debounceTime(200), distinctUntilChanged());

    const clicksWithClosedPopup$ = this.clickSalesPersonsInput$.pipe(filter(() => !this.salesPersonsTypeahead.isPopupOpen()));

    const inputFocus$ = this.focusSalesPersonsInput$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)
      .pipe(
        map((term) =>
          (term === '' ? this.salesPersons : this.salesPersons.filter((slp) => `${slp.SlpCode} - ${slp.SlpName}`.toLowerCase().includes(term.toLowerCase()))).slice(0, 10),
        ),
      );
  };

  /**
   * Format the result of sales persons typeahead
   * @param pResult Result of the filter
   * @constructor
   */
  SalesPersonTypeaheadFormatter = (pResult: ISalesPerson | string) => {
    if(typeof pResult === "object")
    {
      return `${pResult.SlpCode} - ${pResult.SlpName}`;
    }

    let salesPerson = this.salesPersons.find((slp) => slp.SlpCode === pResult)

    if(salesPerson)
    {
      return `${salesPerson.SlpCode} - ${salesPerson.SlpName}`;
    }

    return '';
  }

  /**
   * Handle the select event of the sales person typeahead
   * @param pEvent Event data
   * @constructor
   */
  OnSelectSalesPerson(pEvent: any): void
  {
    setTimeout(() => {
      this.documentForm.get("SlpList").setValue(pEvent.item.SlpCode, {emitEvent: false});
    }, 0);
  }

  /**
   * Handle the spcialty change event
   * @constructor
   */
  OnSpecialtyChange(): void
  {
    this.GetItems(this.documentForm.value.Speciality);

    this.GetWarehouseInfo();

    this.GetSpecialtyCategories();
  }

  private GetSpecialtyCategories(): void
  {
    //The specialty would have the empty value when it is All
    if(this.documentForm.value.Speciality){
      this.blockUI.start();

      this.itemService.GetSpecialtyCategories(this.documentForm.value.Speciality)
        .pipe(
          finalize(() => this.blockUI.stop())
        )
        .subscribe({
          next: (response) => {
            this.RefreshCategoryUdfValidValues(response.Data);
          },
          error: (error) => {
            console.error(error);

            this.alertService.errorInfoAlert(error);
          }
        })
    }else{
      this.RefreshCategoryUdfValidValues(null);
    }

  }

  /**
   * Refresh the category UDF valid values
   * @param pValidValues New valid values to show
   * @constructor
   * @private
   */
  private RefreshCategoryUdfValidValues(pValidValues: KeyValuePair<string, string>[] | null)
  {
      const categoryUdf = this.udfs.find(udf => udf.Name == "U_Categoria");

      if(categoryUdf)
      {
        //It would be null when filtering by specialty "All"
        if(pValidValues !== null){
          categoryUdf.MappedValues = pValidValues.map(vv => ({
            Value: vv.Key,
            Description: vv.Value,
            IsActive: true
          } as IudfValue));
        }else{
          //Only active values loaded
          categoryUdf.MappedValues = (JSON.parse(categoryUdf.Values) as IudfValue[])
        }

      }
  }

  /**
   * Get the selected category
   * @constructor
   * @private
   */
  private GetSelectedCategory(): string
  {
    let control = this.udfsForm.get("U_Categoria");

    if(control)
    {
      return control.value || "";
    }

    return '';
  }

  /**
   * Listen changes in the FE identification number field
   * @constructor
   * @private
   */
  private ListenPatientIdentificationNumberChanges(): void
  {
    let control = this.udfsForm.get("U_Cedula_Paciente");

    if(control)
    {
      this.formsSubscriptions$.add(
        control
          .valueChanges
          .pipe(
            debounceTime(500),
            distinctUntilChanged(),
            filter(identificationNumber => identificationNumber && identificationNumber.length >= 9)
          )
          .subscribe({
            next: (identificationNumber) => {
              this.RequestSalesForceCustomerInformation(identificationNumber);
            }
          })
      );
    }
  }

  /**
   * Send a request to retrieve the customer Sales Force information
   * @param pCustomerIdentification Identification number of the selected customer
   * @constructor
   * @private
   */
  private RequestSalesForceCustomerInformation(pCustomerIdentification: string): void
  {
    this.blockUI.start("Consultando información de Sales Force...");

    const {Code, cardCode} = this.documentForm.getRawValue();

    const {IdType} = this.feForm.getRawValue();

    this.bpService.GetSalesForceInformation(pCustomerIdentification, IdType, cardCode, Code)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (response) => {
          this.businessPartnerSalesForceInformation = response.Data;

          this.businessPartnerIsAnActiveMemberOfMedismart = this.CustomerIsAnActiveMemberOfMedismart();

          if(this.businessPartnerIsAnActiveMemberOfMedismart)
          {
            this.alertService.infoInfoAlert("El socio es afiliado activo de Medismart");

            this.OnCustomerMemberOfMedismart();
          }
          else
          {
            this.OnCustomerNotAMemberOfMedismart();
          }
        },
        error: (error) => {
          this.businessPartnerSalesForceInformation = null;

          this.businessPartnerIsAnActiveMemberOfMedismart = false;

          this.OnCustomerNotAMemberOfMedismart();

          this.alertService.errorInfoAlert(`Hubo un error al consultar la informacion de Sales Force. Details: ${error}`);
        }
      });
  }

  /**
   * Check with if the customer is a member of Medismart based on the Sales Force information requested
   * @constructor
   * @private
   */
  private CustomerIsAnActiveMemberOfMedismart(): boolean
  {
    const customerIdentificationNumber: string = this.udfsForm.get("U_Cedula_Paciente").value;

    const isABeneficiary = this.businessPartnerSalesForceInformation.benResults.some(ben => ben.cedula === customerIdentificationNumber && ben.estado == 'Activo');

    const isAMember = this.businessPartnerSalesForceInformation.accountResults.some(cli => cli.cedula === customerIdentificationNumber && cli.estado == 'Activo');

    return isAMember || isABeneficiary;
  }

  /**
   * Do the required things if the selected customer is not a Medismart member
   * @param pSetUDFsValues Indicates if the should set the respective values to the UDFs
   * @constructor
   * @private
   */
  private OnCustomerNotAMemberOfMedismart(pSetUDFsValues: boolean = true): void
  {
    if(this.businessPartnerIsAnActiveMemberOfMedismart) return;

    if(pSetUDFsValues)
    {
      this.documentForm.get("IsMedismartCustomer").patchValue(false);

      this.FillMedismartUDFsWithSalesForceInformation();

      this.SetValueToUDF("U_Categoria", '00', "String");

      this.SetValueToUDF("U_ValMedAppCoupQr", null, "String");
    }

    this.DoUDFControlRequired("U_Categoria");

    this.RemoveValidatorsToUdf("U_ValMedAppCoupQr");

    this.HideMedismartUDFs();
  }

  /**
   * Do the required things if the selected customer is a Medismart member
   * @param pSetUDFsValues Indicates if the should set the respective values to the UDFs
   * @constructor
   * @private
   */
  private OnCustomerMemberOfMedismart(pSetUDFsValues: boolean = true): void
  {
    if(!this.businessPartnerIsAnActiveMemberOfMedismart) return;

    this.ShowMedismartUDFs();

    if(pSetUDFsValues)
    {
      this.documentForm.get("IsMedismartCustomer").patchValue(true);

      this.FillMedismartUDFsWithSalesForceInformation();

      this.SetValueToUDF("U_Categoria", '00', "String");
    }

    this.DoUDFControlRequired("U_ValMedAppCoupQr");

    this.RemoveValidatorsToUdf("U_Categoria");
  }

  /**
   * Hide the UDFs related to the sales force information
   * @constructor
   * @private
   */
  private HideMedismartUDFs(): void
  {
    this.RemoveControlFromUDFsForm('U_Num_Cli_Medismart');

    this.RemoveControlFromUDFsForm('U_ID_Medismart_Nuevo');
  }

  /**
   * Remove a form control from the UDFs form
   * @param pUdfName Form control name
   * @constructor
   * @private
   */
  private RemoveControlFromUDFsForm(pUdfName: string): void
  {
    let udf = this.udfs.find(udf => udf.Name === pUdfName);

    if(udf)
    {
      udf.IsRendered = false;

      udf.IsActive = false;

      if(this.udfsForm.contains(udf.Name))
      {
        this.udfsForm.removeControl(udf.Name);
      }
    }
  }

  /**
   * Show the UDFs related to the sales force information
   * @constructor
   * @private
   */
  private ShowMedismartUDFs():void
  {
    this.AddControlToUDFsForm("U_Num_Cli_Medismart");

    this.AddControlToUDFsForm("U_ID_Medismart_Nuevo");
  }

  /**
   * Add a control to the UDFs form
   * @param pUdfName Form control name
   * @constructor
   * @private
   */
  private AddControlToUDFsForm(pUdfName: string): void
  {
    let udf = this.udfs.find(udf => udf.Name === pUdfName);

    if(udf)
    {
      udf.IsRendered = true;

      udf.IsActive = true;

      if(!this.udfsForm.contains(udf.Name))
      {
        this.udfsForm.addControl(udf.Name, new FormControl(undefined, udf.IsRequired ? Validators.required : undefined));
      }
    }
  }

  /**
   * Fill the Medismart UDFs if there are configured with the Sales Force information
   * @constructor
   * @private
   */
  private FillMedismartUDFsWithSalesForceInformation(): void
  {
    const customerIdentificationNumber: string = this.udfsForm.get("U_Cedula_Paciente").value;

    const salesForceInformation = [...this.businessPartnerSalesForceInformation.accountResults, ...this.businessPartnerSalesForceInformation.benResults]
      .map(sfInfo => ({IdentificationNumber: sfInfo.cedula, ClientNumber: sfInfo["numeroBen"] ? sfInfo["numeroBen"] : sfInfo["numeroCliente"], Status: sfInfo.estado}))
      .find(sfInfo => sfInfo.IdentificationNumber == customerIdentificationNumber);

    this.hasSalesForceInformation = !!salesForceInformation;

    if(this.hasSalesForceInformation && salesForceInformation.Status == 'Activo')
    {
      this.SetValueToUDF("U_Num_Cli_Medismart", salesForceInformation.ClientNumber, 'String');
    }
    else
    {
      this.SetValueToUDF("U_Num_Cli_Medismart", '', 'String');
    }
  }

  /**
   * Reset the value and validator of the specified udf form control
   * @param pUdfName Name of the udf form control to reset
   * @constructor
   * @private
   */
  private RemoveValidatorsToUdf(pUdfName:string): void
  {
    let udfControl = this.udfsForm.get(pUdfName);

    if(udfControl)
    {
      udfControl.clearValidators();

      udfControl.updateValueAndValidity();

      this.udfs.find(udf => udf.Name === pUdfName).IsRequired = false;
    }
  }

  /**
   * Do required the specified udf control
   * @param pUdfName Udf control name
   * @constructor
   * @private
   */
  private DoUDFControlRequired(pUdfName: string): void
  {
    let udfControl = this.udfsForm.get(pUdfName);

    if(udfControl)
    {
      udfControl.setValidators(Validators.required);

      udfControl.updateValueAndValidity();

      this.udfs.find(udf => udf.Name === pUdfName).IsRequired = true;
    }
  }

  /**
   * Open the customer sales force information modal
   * @constructor
   */
  OpenCustomerSalesForceInformationModal(): void
  {
    if(!this.businessPartnerSalesForceInformation) return;

    const customerSalesForceModalRef = this.modalService.open(CustomerSalesForceInfoModalComponent, {
      size: 'lg'
    });

    customerSalesForceModalRef.componentInstance.customerSalesForceInformation = this.businessPartnerSalesForceInformation;
    customerSalesForceModalRef.componentInstance.customerIdentificationNumber = this.udfsForm.get("U_Cedula_Paciente").value;
  }

  /**
   * Build an object with the required information for a memory invoice
   * @constructor
   * @private
   */
  private BuildInvoiceInMemory(): IInvoiceInMemory
  {
    let {IdType, Identification, Email} = this.feForm.getRawValue();

    let udfsInformation = this.MapUDFsInformationForInvoiceInMemory();

    let {DocumentType,Code,Speciality,cardCode,cardName,PayTerms,PriceList,SlpList,Comment,AnotherPayment,IsMedismartCustomer} = this.documentForm.getRawValue();

    return {
      DocumentKey: this.uniqueInvCode,
      DocumentLines: [...this.itemsList],
      ElectronicInvoiceFormInformation: {
        Email,
        IdentificationNumber: Identification,
        IdentificationType: IdType
      },
      UDFsInformation: udfsInformation,
      DocumentFormInformation: {
        Comments: Comment,
        CustomerCode: cardCode,
        IsMedismartCustomer,
        PatientCode: Code,
        Specialty: Speciality,
        CustomerName: cardName,
        OtherPaymentMethod: AnotherPayment,
        SalesPersonCode: SlpList,
        PaymentTerm: PayTerms,
        TypeOfElectronicInvoice: DocumentType,
        PriceList
      },
      SalesForceInformation: this.businessPartnerSalesForceInformation,
      TAPPInformation: {
        OpenTAPPInvoiceResponse: this.openTappInvoiceResponse,
        RedeemedRewards: this.redeemedRewards
      },
      UDFsList: this.udfs
    } as IInvoiceInMemory;
  }

  /**
   * Map the current UDFs values and names to be stored in a memory invoice
   * @constructor
   * @private
   */
  private MapUDFsInformationForInvoiceInMemory(): IUdfTarget[]
  {
    let udfsInformation: IUdfTarget[] = [];

    for(let udf of this.udfs)
    {
      let control = this.udfsForm.get(udf.Name);

      udfsInformation.push({
        Name: udf.Name,
        Value: control ? control.value : null,
        FieldType: udf.FieldType
      } as IUdfTarget);
    }

    return udfsInformation;
  }

  /**
   * Load the information of a memory invoice and set the forms
   * @param pDocumentKey Represent the document key of the memory invoice that will be loaded
   * @param pShouldShowAlert Indicates if should show and alert to indicates to the user if a problem happened
   * @param pManuallyLoad Indicates if the invoice in memory that will be load is for an action of the user
   * @constructor
   * @private
   */
  private LoadInvoiceInMemory(pDocumentKey: string = '', pShouldShowAlert: boolean = false, pManuallyLoad: boolean = false): void
  {
    try {
      if(this.GetDocumentAlias() !== 'OINV')
      {
        return;
      }

      let invoiceInMemory = this.invoicesInMemoryService.GetInvoiceInMemory(pDocumentKey);

      if(!invoiceInMemory)
      {
        return;
      }

      this.itemsList = [];

      if(invoiceInMemory.DocumentFormInformation)
      {
        this.documentForm.patchValue({
          DocumentType: invoiceInMemory.DocumentFormInformation.TypeOfElectronicInvoice,
          Code: invoiceInMemory.DocumentFormInformation.PatientCode,
          Speciality: invoiceInMemory.DocumentFormInformation.Specialty,
          cardCode: invoiceInMemory.DocumentFormInformation.CustomerCode,
          cardName: invoiceInMemory.DocumentFormInformation.CustomerName,
          PayTerms: invoiceInMemory.DocumentFormInformation.PaymentTerm,
          PriceList: invoiceInMemory.DocumentFormInformation.PriceList,
          SlpList: invoiceInMemory.DocumentFormInformation.SalesPersonCode,
          Comment: invoiceInMemory.DocumentFormInformation.Comments,
          AnotherPayment: invoiceInMemory.DocumentFormInformation.OtherPaymentMethod,
          IsMedismartCustomer: invoiceInMemory.DocumentFormInformation.IsMedismartCustomer
        }, {emitEvent: false});

        this.priceList = invoiceInMemory.DocumentFormInformation.PriceList;

        let priceList = this.PriceList.find(x => x.ListNum == this.priceList);

        if(priceList)
        {
          this.userCurrency = this.currencyPayment = priceList.PrimCurr;
        }
      }

      if(invoiceInMemory.ElectronicInvoiceFormInformation)
      {
        this.feForm.patchValue({
          IdType: invoiceInMemory.ElectronicInvoiceFormInformation.IdentificationType,
          Identification: invoiceInMemory.ElectronicInvoiceFormInformation.IdentificationNumber,
          Email: invoiceInMemory.ElectronicInvoiceFormInformation.Email
        }, {emitEvent: false});
      }

      if(invoiceInMemory.UDFsList && invoiceInMemory.UDFsList.length)
      {
        this.GenerateUDFsControls(invoiceInMemory.UDFsList);
      }

      if(invoiceInMemory.UDFsInformation && invoiceInMemory.UDFsInformation.length)
      {
        for(let udfValue of invoiceInMemory.UDFsInformation)
        {
          this.SetValueToUDF(udfValue.Name, udfValue.Value, udfValue.FieldType, false);
        }
      }

      if(invoiceInMemory.SalesForceInformation)
      {
        this.businessPartnerSalesForceInformation = invoiceInMemory.SalesForceInformation;

        this.businessPartnerIsAnActiveMemberOfMedismart = this.CustomerIsAnActiveMemberOfMedismart();

        this.hasSalesForceInformation = this.businessPartnerIsAnActiveMemberOfMedismart;

        if(this.businessPartnerIsAnActiveMemberOfMedismart)
        {
          this.alertService.infoInfoAlert("El socio es afiliado activo de Medismart");

          this.OnCustomerMemberOfMedismart(false);
        }
        else
        {
          this.OnCustomerNotAMemberOfMedismart(false);
        }
      }

      if(invoiceInMemory.TAPPInformation)
      {
        this.openTappInvoiceResponse = invoiceInMemory.TAPPInformation.OpenTAPPInvoiceResponse;
        this.redeemedRewards = invoiceInMemory.TAPPInformation.RedeemedRewards;
      }

      this.itemsList = invoiceInMemory.DocumentLines;

      this.hasLines = !!this.itemsList.length;

      this.DoRequiredUDFOrdenLaboratorio();

      this.uniqueInvCode = invoiceInMemory.DocumentKey;

      this.UpdateDocumentAmounts();

      this.invoicesInMemoryService.SetCurrentlyInvoiceInMemoryLoaded(invoiceInMemory.DocumentKey, !pManuallyLoad);
    }
    catch (e) {
      console.error("An error occurs trying to load the memory invoice. Details: ", e);

      if(pShouldShowAlert)
      {
        this.alertService.ShowAlert("error", {
          title: 'Loading memory invoice',
          text: `An error occurs trying to load the memory invoice. Details: ${JSON.stringify(e)}`
        });
      }
    }
  }

  /**
   * Listen events of manually invoices in memory addition
   * @constructor
   * @private
   */
  private ListenManuallyAddOfInvoicesInMemory(): void
  {
    if(this.GetDocumentAlias() === 'OINV')
    {
      this.subscriptions$.add(
        this.invoicesInMemoryService.onManuallyInvoiceInMemoryAdded$
          .subscribe({
            next: (documentKey) => {
              this.invoicesInMemoryService.SaveInvoiceInMemory(this.BuildInvoiceInMemory());

              this.CreateNew(false);
            }
          })
      );
    }
  }

  /**
   * Listen events of manually loading of invoices in memory
   * @constructor
   * @private
   */
  private ListenManuallyLoadOfInvoiceInMemory(): void
  {
    if(this.GetDocumentAlias() === 'OINV')
    {
      this.subscriptions$.add(
        this.invoicesInMemoryService.onRequestLoadInvoiceInMemory$.subscribe({
          next: (data) => {
            if(data.ShouldSaveCurrentInvoice)
            {
              this.invoicesInMemoryService.SaveInvoiceInMemory(this.BuildInvoiceInMemory());
            }

            this.LoadInvoiceInMemory(data.DocumentKey, false, true);
          }
        })
      );
    }
  }

  /**
   * Sent a request to retrieve the triple warranty categories
   * @constructor
   * @private
   */
  private GetTripleWarrantyCategoriesCodesObservable(): Observable<IResponse<string[]>|null>
  {
    return this.documentService.GetTripleWarrantyCategoriesCodes()
      .pipe(
        map(response => {
          this.tripleWarrantyCategoriesCodes = response.Data;

          return response;
        }),
        catchError(e => {
          console.error("An error occurs trying to retrieve the triple warranty categories codes. Details: ", e);

          return of(null);
        })
      );
  }

  /**
   * Sent a request to retrieve the farma value category
   * @constructor
   * @private
   */
   private GetFarmaValueCategoryCodeObservable(): Observable<IResponse<string>|null>
   {
     return this.documentService.GetFarmaValueCategoryCode()
       .pipe(
         map(response => {
           this.farmaValueCategoryCode = response.Data;
 
           return response;
         }),
         catchError(e => {
           console.error("An error occurs trying to retrieve the Farma Value categorty code. Details: ", e);
 
           return of(null);
         })
       );
   }

  /**
   * Listen changes in the isMedismartCustomer checkbox
   * @constructor
   * @private
   */
  private ListenIsMedismartCustomerCheckboxChanges(): void
  {
    this.documentForm.get("IsMedismartCustomer")
      .valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe({
        next: (value) => {
          this.documentForm.get("IsMedismartCustomer").patchValue(this.businessPartnerIsAnActiveMemberOfMedismart);
        }
      })
  }

  /**
   * Checks if the specified category is a FarmaValue category and then show the Farma Value cupon fields
   * @param pCategoryCode Code of the selected category to check
   * @constructor
   * @private
   */
   private OnSelectFarmaValueCategory(pCategoryCode: string)
   {
     if(this.farmaValueCategoryCode == pCategoryCode)
     {

      this.AddControlToUDFsForm("U_Cupon_FarmaV");
      this.AddControlToUDFsForm("U_Cupon_FarmaV2");
       
       return;
     }
 
     this.RemoveControlFromUDFsForm("U_Cupon_FarmaV");
     this.RemoveControlFromUDFsForm("U_Cupon_FarmaV2");
 
   }
  
  /**
   * Generate a farma value setting to setup on payment modal
   * @returns complete objetc of farma value settings
   */
  GenerateFarmaValueSettings(): IFarmaValue {
    if((this.udfsForm.get("U_Categoria").value == this.farmaValueCategoryCode)){
      return {
        Cupon1: this.udfsForm.get("U_Cupon_FarmaV").value,	
        Cupon2: this.udfsForm.get("U_Cupon_FarmaV2").value,
        Monto1: this.farmaValueSetting.Monto1,
        Monto2: this.farmaValueSetting.Monto2,
        Valid1: this.farmaValueSetting.Valid1,
        Valid2: this.farmaValueSetting.Valid2,
        Card: {
          Id: this.farmaValueSetting.Card.Id,
          Acct: this.farmaValueSetting.Card.Acct,
          Name: this.farmaValueSetting.Card.Name,
          Number: this.farmaValueSetting.Card.Number, 
          Owner: this.farmaValueSetting.Card.Owner, 
          Valid: this.farmaValueSetting.Card.Valid, 
          Voucher: this.farmaValueSetting.Card.Voucher 
        } as ICard,
        Active: true
      } as IFarmaValue
    } else {
      return {
        Cupon1: '',	
        Cupon2: '',
        Monto1: 0,
        Monto2: 0,
        Valid1: false,
        Valid2: false,
        Card: {
          Id: this.farmaValueSetting.Card.Id,
          Acct: this.farmaValueSetting.Card.Acct,
          Name: this.farmaValueSetting.Card.Name,
          Number: this.farmaValueSetting.Card.Number, 
          Owner: this.farmaValueSetting.Card.Owner, 
          Valid: this.farmaValueSetting.Card.Valid, 
          Voucher: this.farmaValueSetting.Card.Voucher 
        } as ICard,
        Active: false
      } as IFarmaValue
    }
   }
}
