import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  AlertService,
  AuthenticationService,
  ItemService,
  PermsService,
  ReportsService,
  SalesManService,
  StorageService, UserService
} from "../../../services";
import {WarehouseService} from "../../../services/warehouse.service";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {IMinifiedWarehouse} from "../../../models/i-minified-warehouse";
import {ISalesPerson} from "../../../models/i-sales-person";
import {IMinifiedItem} from "../../../models/i-item";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SelectionModalComponent} from "../../../components/selection-modal/selection-modal.component";
import {ISelectionModalData} from "../../../models/i-selection-modal-data";
import {
  IInventoryTransferRequest,
  IInventoryTransferRequestLine,
  IInventoryTransferRequestStates, IMinifiedInventoryTransferRequest
} from "../../../models/i-inventory-transfer-request";
import {InventoryTransferRequestsService} from "../../../services/inventory-transfer-requests.service";
import {catchError, concatMap, finalize, map, retry, tap} from "rxjs/operators";
import {formatDate} from "@angular/common";
import {forkJoin, from, of, Subject, throwError} from "rxjs";
import {HeadquarterService} from "../../../services/headquarter.service";
import {IHeadquarter} from "../../../models/i-headquarter";
import {IudfValue} from "../../../models/iudf-value";
import {UdfsService} from "../../../services/udfs.service";
import {DOCUMENT_ALIAS} from "../../../models/constantes";
import {IToken, IUdf, IUdfTarget} from "../../../models";
import {
  EditInventoryTransferRequestComponent
} from "./edit-inventory-transfer-request/edit-inventory-transfer-request.component";
import {IEditInventoryTransferRequestModalData} from "../../../models/i-edit-inventory-transfer-request-modal-data";
import {ISerialNumber} from "../../../models/i-serial-number";
import {SerialNumbersService} from "../../../services/serial-numbers.service";
import {Globals} from "../../../globals";
import {ISession} from "../../../models/i-token";
import { ReportType } from 'src/app/enum/enum';
import * as printJS from 'print-js';
import {LocalStorageVariables} from "../../../enum/local-storage-variables";
import {StockTransferListComponent} from "./stock-transfer-list/stock-transfer-list.component";

@Component({
  selector: 'app-inventory-transfer-request',
  templateUrl: './inventory-transfer-request.component.html',
  styleUrls: ['./inventory-transfer-request.component.scss']
})
export class InventoryTransferRequestComponent implements OnInit, AfterViewInit {
  currentTap: 'manual' | 'automatic' = 'manual';

  documentForm: FormGroup;

  @BlockUI() blockUI: NgBlockUI;

  originWarehouseSelected: IMinifiedWarehouse | undefined;

  destinationWarehouseSelected: IMinifiedWarehouse | undefined;

  salesPersonSelected: ISalesPerson;

  documentLines: IInventoryTransferRequestLine[];

  onTabChange$: Subject<'manual' | 'automatic'>;

  searchDocumentsForm: FormGroup;

  transferRequestStates: IInventoryTransferRequestStates[];

  transferRequests: IMinifiedInventoryTransferRequest[];

  transferRequestsCount: number;

  headquarters: IHeadquarter[];

  transferRequestsTablePage: number = 1;

  searchFormWasChanged: boolean = false;

  documentUdfs: IUdf[];

  udfsForm: FormGroup;

  canCreateTransferRequestManually: boolean = false;

  canViewAutomaticTransferRequests: boolean = false;

  currentUser: ISession;

  canCreateInventoryTransfer: boolean = false;

  /**
   * Indicates if the user can change the sales person of the document
   */
  canChangeSalesPerson: boolean = false;
  constructor(private formBuilder: FormBuilder,
              private inventoryTransferService: InventoryTransferRequestsService,
              private headquarterService: HeadquarterService,
              private localStorageService: StorageService,
              private alertService: AlertService,
              private udfsService: UdfsService,
              private globals: Globals,
              private permissionService: PermsService,
              private authenticationService: AuthenticationService,
              private reportsService: ReportsService,
              private modalController: NgbModal) {
    this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.InitVariables();

  }

  ngAfterViewInit(): void {
    const linesFieldSet: HTMLElement = document.getElementById("linesFieldSet");

    if(linesFieldSet)
    {
      linesFieldSet.setAttribute('style', `max-height: ${((linesFieldSet.offsetParent.clientHeight - (linesFieldSet.offsetHeight + linesFieldSet.offsetTop)) + linesFieldSet.offsetHeight) - 28}px`);
    }
  }

  /**
   * Initialize the variables of the component
   * @constructor
   */
  InitVariables(): void
  {
    this.onTabChange$ = new Subject<'manual' | 'automatic'>();

    this.ListenTabChanges();

    let userSalesPerson = this.localStorageService.GetLocalStorageVariable<ISalesPerson>(LocalStorageVariables.CurrentUserSalesPerson);

    this.salesPersonSelected = userSalesPerson ? userSalesPerson : {
      SlpName: '-Ningún empleado del departamento de ventas-',
      SlpCode: '-1'
    };

    this.documentForm = this.formBuilder.group({
      OriginWarehouse: [''],
      DestinationWarehouse: [''],
      Quantity: [1],
      SalesPerson: [`${this.salesPersonSelected.SlpCode} - ${this.salesPersonSelected.SlpName}`, [Validators.required]],
      Comments: [null],
      Item: ['']
    });

    let date = new Date();

    this.searchDocumentsForm = this.formBuilder.group({
      DocNum: [null],
      DateFrom: [{ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }],
      DateTo: [{ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() }],
      Headquarter: [''],
      Status: [''],
      CreationType: ['']
    });

    this.documentUdfs = [];

    this.headquarters = [];

    this.documentLines = [];

    this.transferRequestStates = [];

    this.transferRequests = [];

    this.transferRequestsCount = 0;

    this.ListenSearchFormChanges();

    this.SendInitialRequests();
  }

  /**
   * Send the initial request to set up the component
   * @constructor
   */
  SendInitialRequests(): void
  {
    this.blockUI.start("Cargando...");

    forkJoin([
      this.udfsService.GetConfiguredUdfsByCategory(DOCUMENT_ALIAS.INVENTORY_TRANSFER_REQUEST),
      this.permissionService.getPerms(this.currentUser.userId)
    ])
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe(responses => {
      if (responses[0].Result)
      {
        this.documentUdfs = responses[0].Udfs || [];

        this.documentUdfs.filter(x => x.Values !== '').forEach(x => x.MappedValues = (JSON.parse(x.Values) as IudfValue[]));

        const udfFormControlConfig: {[key:string]: any} = {};

        this.documentUdfs.forEach(udf => {
          if(udf.IsRendered)
          {
            if(udf.IsRequired)
            {
              udfFormControlConfig[udf.Name] = [null, Validators.required];
            }
            else
            {
              udfFormControlConfig[udf.Name] = null;
            }
          }
        });

        this.udfsForm = this.formBuilder.group(udfFormControlConfig);
      }

      if(responses[1].Result)
      {
        this.canCreateTransferRequestManually = responses[1].perms.some(p => p.Active && p.Name == 'Inventory_TransferRequest_CreateManually');

        this.canViewAutomaticTransferRequests = responses[1].perms.some(p => p.Active && p.Name == 'Inventory_TransferRequest_ViewAutomaticTransferRequest');

        this.canChangeSalesPerson = responses[1].perms.some(p => p.Active && p.Name === 'Inventory_TransferRequest_ChangeSalesPerson');

        this.ApplyPermissionsRestrictions();
      }
    }, error => {
        this.alertService.ShowAlert("error", {
          text: error
        });

        console.error(error);
    });

  }


  /**
   * Apply the restrictions of permission in the page
   * @constructor
   */
  ApplyPermissionsRestrictions(): void
  {
    if(!this.canChangeSalesPerson)
    {
      this.documentForm.get("SalesPerson").disable();
    }

    if(!this.canCreateTransferRequestManually && this.canViewAutomaticTransferRequests)
    {
      this.onTabChange$.next("automatic");
    }
  }

  /**
   * Set up the items selection modal
   * @constructor
   */
  ShowItemSelectionModal(): void
  {
    const modal = this.modalController.open(SelectionModalComponent, {
      windowClass: 'width-fit-content',
      backdrop: 'static',
      size: 'lg'
    });

    modal.componentInstance.data = {
      ServiceToInject: ItemService,
      MethodToExecute: 'GetMinifiedItems',
      TableColumns: {
        ItemCode: "Código de ítem",
        ItemName: "Nombre de ítem"
      },
      ShowNumeration: true,
      RequestExtraParameters: ['inventory'],
      PredefinedParametersToSend: ['all']
    } as ISelectionModalData;

    modal.result.then((result: IMinifiedItem) => {

      let {Quantity} = this.documentForm.getRawValue();

      this.documentLines.push({
        ItemCode: result.ItemCode,
        ItemDescription: result.ItemName,
        Quantity,
        U_ATF_REQUEST_QUANTITY: Quantity,
        FromWarehouseCode: this.originWarehouseSelected.WhsCode,
        WarehouseCode: this.destinationWarehouseSelected.WhsCode,
        // ManSerNum: result.ManagedBySerialNumber
      } as IInventoryTransferRequestLine);


      // this.hasLinesWithSeries = this.documentLines.some(ln => ln.ManSerNum == 'Y');
    }, (reason) => {});
  }

  /**
   * Set up the warehouse selection modal
   * @param _formControlName Form field to set the selected value
   * @constructor
   */
  ShowWarehouseSelectionModal(_formControlName: 'OriginWarehouse' | 'DestinationWarehouse'): void
  {
    const modal = this.modalController.open(SelectionModalComponent, {
      windowClass: 'width-fit-content',
      backdrop: 'static',
      size: 'lg'
    });

    modal.componentInstance.data = {
      ServiceToInject: WarehouseService,
      MethodToExecute: 'GetWarehouseForInventoryTransfer',
      TableColumns: {
        WhsCode: "Código de almacén",
        WhsName: "Nombre de almacén"
      },
      UIPagination: true,
      PropsToMatchWithSearchCriteria: ["WhsCode", "WhsName"],
      ShowNumeration: true,
      PredefinedParametersToSend: []
    } as ISelectionModalData;


    modal.result.then((result: IMinifiedWarehouse) => {
      const selectWarehouse = () => {
        if(_formControlName == 'OriginWarehouse')
        {
          this.originWarehouseSelected = result;
        }
        else
        {
          this.destinationWarehouseSelected = result;
        }

        this.documentForm.get(_formControlName).patchValue(`${result.WhsCode} - ${result.WhsName}`);
      }

      if(this.documentLines.length && ((_formControlName == 'OriginWarehouse' && this.originWarehouseSelected) || (_formControlName == 'DestinationWarehouse' && this.destinationWarehouseSelected)))
      {
        this.alertService.PromptedAlert(`¿Desea actualizar el valor "${ _formControlName == 'OriginWarehouse' ? 'Almacén origen' : 'Almacén destino' }" para las líneas existentes con este nuevo valor?`, "question")
          .then(questionResult => {
            if(questionResult)
            {
              let linePropToSet = _formControlName == "OriginWarehouse" ? 'FromWarehouseCode' : 'WarehouseCode';

              this.documentLines.forEach(line => {
                if(_formControlName == 'OriginWarehouse' && line.SerialNumber)
                {
                  line.SerialNumber = null;
                }

                line[linePropToSet] = result.WhsCode;
              });
            }

            selectWarehouse();
          });
      }
      else
      {
        selectWarehouse();
      }
    }, (reason) => {});
  }

  /**
   * Set up the sales person selection modal
   * @constructor
   */
  ShowSalesPersonSelectionModal(): void
  {
    const modal = this.modalController.open(SelectionModalComponent, {
      windowClass: 'width-fit-content',
      backdrop: 'static',
      size: 'lg'
    });

    modal.componentInstance.data = {
      ServiceToInject: SalesManService,
      MethodToExecute: 'Get',
      TableColumns: {
        SlpCode: "Código de vendedor",
        SlpName: "Nombre de vendedor"
      },
      UIPagination: true,
      PropsToMatchWithSearchCriteria: ["SlpCode", "SlpName"],
      ShowNumeration: true,
      PredefinedParametersToSend: []
    } as ISelectionModalData;


    modal.result.then((result: ISalesPerson) => {
      this.salesPersonSelected = result;
      this.documentForm.get("SalesPerson").patchValue(`${result.SlpCode} - ${result.SlpName}`);
    }, (reason) => {});
  }

  /**
   * Set up the warehouse selection modal
   * @param _lineProp Property of the document line where the result will be set
   * @param _line Line that will be updated
   * @constructor
   */
  ShowItemWarehouseSelectionModal(_lineProp: 'FromWarehouseCode' | 'WarehouseCode', _line: IInventoryTransferRequestLine): void
  {
    const modal = this.modalController.open(SelectionModalComponent, {
      windowClass: 'width-fit-content',
      backdrop: 'static',
      size: 'lg'
    });

    modal.componentInstance.data = {
      ServiceToInject: WarehouseService,
      MethodToExecute: 'GetWarehouseForInventoryTransfer',
      TableColumns: {
        WhsCode: "Código de almacén",
        WhsName: "Nombre de almacén"
      },
      UIPagination: true,
      PropsToMatchWithSearchCriteria: ["WhsCode", "WhsName"],
      ShowNumeration: true,
      PredefinedParametersToSend: []
    } as ISelectionModalData;


    modal.result.then((result: IMinifiedWarehouse) => {
      if(_lineProp == 'FromWarehouseCode' && _line.SerialNumber)
      {
        _line.SerialNumber = null;
      }

      _line[_lineProp] = result.WhsCode;
    }, (reason) => {});
  }

  /**
   * Clean the document fields
   * @param _ask Indicates if should ask before clean
   * @constructor
   */
  CleanFields(_ask: boolean): void
  {
    const cleanManualFields = () => {
      this.salesPersonSelected = {
        SlpName: '-Ningún empleado del departamento de ventas-',
        SlpCode: '-1'
      };

      this.originWarehouseSelected = undefined;

      this.destinationWarehouseSelected = undefined;

      this.documentForm.patchValue({
        OriginWarehouse: '',
        DestinationWarehouse: '',
        Quantity: 1,
        SalesPerson: `${this.salesPersonSelected.SlpCode} - ${this.salesPersonSelected.SlpName}`,
        Comments: ''
      });

      this.documentLines = [];

      for(const control in this.udfsForm.controls)
      {
        this.udfsForm.get(control).patchValue(null);
      }

      // this.hasLinesWithSeries = false;
    }

    const cleanAutomaticFields = () => {
      let date = new Date();

      this.searchDocumentsForm.patchValue({
        DocNum: null,
        DateFrom: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() },
        DateTo: { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() },
        Headquarter: '',
        Status: ''
      });

      this.SearchDocuments();
    }

    if(_ask)
    {
      this.alertService.PromptedAlert('¿Desea limpiar los campos?', "question")
        .then(result => {
          if(result)
          {
            this.currentTap == 'manual' ? cleanManualFields() : cleanAutomaticFields();
          }
        });
    }
    else
    {
      this.currentTap == 'manual' ? cleanManualFields() : cleanAutomaticFields();
    }
  }

  /**
   * Remove a document line
   * @param _lineIndex Index of the document line to remove
   * @constructor
   */
  RemoveDocumentLine(_lineIndex: number): void
  {
    this.alertService.PromptedAlert('¿Desea eliminar esta línea del documento?', "question")
      .then(result => {
        if(result)
        {
          this.documentLines.splice(_lineIndex, 1);

          // this.hasLinesWithSeries = this.documentLines.some(ln => ln.ManSerNum == 'Y');
        }
      });
  }

  /**
   * Send a request to create the current document
   * @constructor
   */
  OnClickSaveDocument(): void
  {
      if(!this.DocumentValidations()) return;

      let {Comments} = this.documentForm.getRawValue();

      let headquarter = this.localStorageService.GetCurrentHeadquarter();

      let UDFs: IUdfTarget[] = [];

      if(this.udfsForm)
      {
        for(const key in this.udfsForm.value)
        {
          UDFs.push({
            Name: key,
            Value: this.udfsForm.get(key).value,
            FieldType: this.documentUdfs.find(udf => udf.Name == key).FieldType
          } as IUdfTarget);
        }
      }

      let document = {
        Comments,
        FromWarehouse: this.originWarehouseSelected.WhsCode,
        ToWarehouse: this.destinationWarehouseSelected.WhsCode,
        StockTransferLines: this.documentLines.map(ln => ({...ln, U_ATF_REQUEST_QUANTITY: ln.Quantity})),
        SalesPersonCode: +this.salesPersonSelected.SlpCode,
        UDFs,
        U_ATF_VERIFICATION_CODE: formatDate(new Date(), 'yyyyMMddHHmmss', 'en'),
        U_ATF_HEADQUARTER: headquarter.Code,
        U_ATF_STATUS: 'Created',
        U_ATF_CREATION_TYPE: 'M'
      } as IInventoryTransferRequest;

      this.blockUI.start("Procesando...");

      this.inventoryTransferService.Post(document)
        .pipe(
          concatMap(response => {

            this.blockUI.stop();

            return from(this.alertService.ShowAlert("success", {
              title: 'Creación de documento',
              text: 'Documento creado éxitosamente',
              confirmButtonText: 'Imprimir',
              cancelButtonText: 'Aceptar',
              cancelButtonColor: '#1DA0CE',
              confirmButtonColor:'#38BE5D'
            }, {
              "Número de documento": response.Data.DocNum.toString()
            }))
            .pipe(
              concatMap(result => {
                if(result.value)
                {
                  this.blockUI.start();

                  return this.reportsService.printReport(response.Data.DocEntry, ReportType.StockTransferRequest);
                }

                return of(null);
              }),
              catchError(error => {
                this.blockUI.stop();

                return from(this.alertService.ShowAlert("error", {
                  title: 'Impresión de reporte',
                  text: error,
                  cancelButtonText: 'Cancelar',
                  cancelButtonColor: '#E55151',
                  confirmButtonText: 'Reintentar',
                  confirmButtonColor:'#38BE5D'
                }))
                .pipe(
                  concatMap(result => {
                    if(result.value)
                    {
                      this.blockUI.start();

                      return this.reportsService.printReport(response.Data.DocEntry, ReportType.StockTransferRequest);
                    }

                    return of(null);
                  })
                );
              })
            );

          }),
          finalize(() => this.blockUI.stop())
        )
        .subscribe({
          next: (response) => {
            if(response)
            {
              printJS({
                printable: response.Data,
                type: 'pdf',
                base64: true
              });
            }

            this.CleanFields(false);
          },
          error: (error) => {
            this.alertService.ShowAlert('error', {
              text: error,
            });
            console.error(error);
          }
        });
  }

  /**
   * Validate information before send the document. If all is ok return `true` otherwise return `false`
   * @constructor
   */
  DocumentValidations(): boolean
  {
    if(!this.originWarehouseSelected || !this.destinationWarehouseSelected)
    {
      this.alertService.infoInfoAlert("Debe seleccionar almacén de origen y almacén de destino.");
      return;
    }

    if(!this.documentLines.length)
    {
      this.alertService.infoInfoAlert('Debe agregar almenos una línea al documento.');
      return false;
    }

    if(this.documentLines.some(ln => ln.Quantity <= 0))
    {
      this.alertService.infoInfoAlert('No se permiten líneas con cantidad menor o igual a cero.');
      return false;
    }

    if(this.documentUdfs.some(udf => udf.IsRendered && udf.IsRequired && !this.udfsForm.get(udf.Name).value))
    {
      this.alertService.infoInfoAlert('Hay UDFs requeridos sin completar.');
      return false;
    }

    return true;
  }


  /**
   * Listen when the tab was change
   * @constructor
   */
  ListenTabChanges(): void
  {
    this.onTabChange$
      .pipe(
        concatMap(tab => {
          if(tab == "automatic")
          {
            this.blockUI.start("Cargando...");

            return forkJoin([
              this.inventoryTransferService.GetStates(),
              this.inventoryTransferService.Get(-1, new Date(), new Date(), '', '', 10, 0, ''),
              this.headquarterService.GetCurrentUserHeadquarters()
            ])
              .pipe(
                map(response => {
                  this.blockUI.stop();

                  this.transferRequestStates = response[0].Data || [];

                  this.headquarters = response[2].Data;

                  this.transferRequests = (response[1].Data.Data || []).map(tr => ({
                    ...tr,
                    Headquarter: `${tr.Headquarter} - ${this.headquarters.find(h => h.Code == tr.Headquarter) ? this.headquarters.find(h => h.Code == tr.Headquarter).Name : ''}`,
                    StatusName: tr.Status ? this.transferRequestStates.find(trs => trs.State == tr.Status).StateName : '-'
                  }));

                  this.transferRequestsCount = response[1].Data.RecordsCount;

                  return tab;
                }),
                catchError(error => {
                  this.blockUI.stop();

                  this.alertService.ShowAlert("error", {
                    text: error
                  });

                  console.error(error);

                  return of(tab);
                })
              );
          }

          return of(tab)
        })
      )
      .subscribe({
        next: (tab) => {
          this.currentTap = tab;
        }
      });
  }

  /**
   * Send a request with the specified filters to retrieve the inventory transfer requests
   * @constructor
   */
  SearchDocuments(): void
  {
    this.blockUI.start("Cargando...");

    let {DocNum, DateFrom, DateTo, Headquarter, Status, CreationType} = this.searchDocumentsForm.getRawValue();

    let dateFrom = new Date(DateFrom.year, DateFrom.month - 1, DateFrom.day);

    let dateTo = new Date(DateTo.year, DateTo.month - 1, DateTo.day);

    this.inventoryTransferService.Get(DocNum || -1, dateFrom, dateTo, Headquarter, Status, 10, 0, CreationType)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (response) => {
          this.transferRequests = (response.Data.Data || []).map(tr => ({
            ...tr,
            Headquarter: `${tr.Headquarter} - ${this.headquarters.find(h => h.Code == tr.Headquarter) ? this.headquarters.find(h => h.Code == tr.Headquarter).Name : ''}`,
            StatusName: tr.Status ? this.transferRequestStates.find(trs => trs.State == tr.Status).StateName : '-'
          }));

          this.transferRequestsCount = response.Data.RecordsCount;

          this.transferRequestsTablePage = 1;

          this.searchFormWasChanged = false;
        },
        error: (error) => {
          this.alertService.ShowAlert("error", {
            text: error
          });

          console.error(error);
        }
      });
  }

  /**
   * Send a request with the pagination data to retrieve the inventory transfer requests
   * @constructor
   */
  OnTransferRequestsTablePageChange(): void
  {
    this.blockUI.start("Cargando...");

    let {DocNum, DateFrom, DateTo, Headquarter, Status, CreationType} = this.searchDocumentsForm.getRawValue();

    let dateFrom = new Date(DateFrom.year, DateFrom.month - 1, DateFrom.day);

    let dateTo = new Date(DateTo.year, DateTo.month - 1, DateTo.day);

    if(this.searchFormWasChanged) this.transferRequestsTablePage = 1;

    this.inventoryTransferService.Get(DocNum || -1, dateFrom, dateTo, Headquarter, Status, 10, 10 * (this.transferRequestsTablePage - 1), CreationType)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (response) => {
          this.transferRequests = (response.Data.Data || []).map(tr => ({
            ...tr,
            Headquarter: `${tr.Headquarter} - ${this.headquarters.find(h => h.Code == tr.Headquarter) ? this.headquarters.find(h => h.Code == tr.Headquarter).Name : ''}`,
            StatusName: tr.Status ? this.transferRequestStates.find(trs => trs.State == tr.Status).StateName : '-'
          }));

          this.transferRequestsCount = response.Data.RecordsCount;
        },
        error: (error) => {
          this.alertService.ShowAlert("error", {
            text: error
          });

          console.error(error);
        }
      });
  }

  /**
   * Listen changes in the search form
   * @constructor
   */
  ListenSearchFormChanges(): void
  {
    this.searchDocumentsForm.valueChanges
      .subscribe({
        next: (value) => {
          this.searchFormWasChanged = true;
        }
      })
  }

  /**
   * Get the type of the UDF input based on udf type
   * @param _dataType
   * @constructor
   */
  GetUdfInputType(_dataType: string): string {
    switch (_dataType) {
      case 'String':
        return "text";
      case 'Int32':
        return "number";
      case 'DateTime':
        return "date";
      default:
        return 'text';
    }
  }

  /**
   * Show the edition modal with the information of the selected document
   * @param _documentEntry Unique identifier of the document
   * @constructor
   */
  OnClickEditDocument(_documentEntry: number): void
  {
    const modal = this.modalController.open(EditInventoryTransferRequestComponent, {
      backdrop: 'static',
      windowClass: 'custom-transfer-modal',
    });

    modal.componentInstance.data = {
      TransferRequestStates: this.transferRequestStates,
      Headquarters: this.headquarters,
      DocumentEntry: _documentEntry
    } as IEditInventoryTransferRequestModalData;

    modal.result.then((result) => {
      this.SearchDocuments();
    });
  }

  /**
   * Validate the status of the transfer request to know if the user has permission to edit in that state
   * @param _status Status of the transfer request
   * @constructor
   */
  CanEditTransferRequest(_status: string): boolean
  {
    return this.globals.permList.some(p => p.Active && p.Name == `Inventory_TransferRequest_EditWith${_status}State`);
  }

  /**
   * Send a request to retrieve the filled report file to print
   * @param _docEntry Doc entry of the stock transfer request
   * @param _reportType Print format type
   */
  PrintReport(_docEntry: number, _reportType: ReportType): void
  {
    this.blockUI.start();

    this.reportsService.printReport(_docEntry, _reportType)
    .pipe(
      finalize(() => this.blockUI.stop())
    )
    .subscribe({
      next: (response) => {
        printJS({
          printable: response.Data,
          type: 'pdf',
          base64: true
        });
      },
      error: (error) => {
        this.alertService.ShowAlert("error", {
          text: error,
          title: 'Impresión de reportes'
        });

        console.error(error);
      }
    })
  }


  /**
   * Set up a modal and show the stock transfer related to the transfer request
   * @param pTransferRequestEntry Transfer request unique identifier
   * @constructor
   */
  ViewStockTransfers(pTransferRequestEntry: number): void
  {
    const modal = this.modalController.open(StockTransferListComponent, {
      windowClass: 'width-fit-content',
      backdrop: 'static',
      size: 'lg'
    });

    modal.componentInstance.transferRequestDocEntry = pTransferRequestEntry;
  }
}
