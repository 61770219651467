import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {debounceTime, distinctUntilChanged, filter, map, startWith} from "rxjs/operators";
import {merge, Observable, OperatorFunction, Subject} from "rxjs";
import {FormControl} from "@angular/forms";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {IDoctor} from "../../models/i-doctor";
import {IHeadquarter} from "../../models/i-headquarter";
import {LocalStorageVariables} from "../../enum/local-storage-variables";
import {AlertService, StorageService} from "../../services";

@Component({
  selector: 'app-document-line-doctors-typeahead',
  templateUrl: './document-line-doctors-typeahead.component.html',
  styleUrls: ['./document-line-doctors-typeahead.component.scss']
})
export class DocumentLineDoctorsTypeaheadComponent implements OnInit {
  @Input() doctors: IDoctor[];

  @Input() isDisabled: boolean;

  @Input() isDanger: boolean;

  @Input() isOrange: boolean;

  @Input() lineDoctorCode: string;

  @Input() lineSpecialty: string;

  @Output() selectedDoctor = new EventEmitter<any>();

  doctorControl = new FormControl();

  clickInput$ = new Subject<string>();

  focusInput$ = new Subject<string>();

  @ViewChild('salesPersonsTypeahead') salesPersonsTypeahead: NgbTypeahead;

  constructor(private storageService: StorageService,
              private alertService: AlertService) { }

  ngOnInit() {
    setTimeout(() => {
      this.doctorControl.setValue(this.PreselectedDoctorExist() ? this.lineDoctorCode : '');
    }, 0);
  }

  /**
   * Check if preselected doctor exist in the current filtered doctors
   * @constructor
   * @private
   */
  private PreselectedDoctorExist(): boolean
  {
    const filteredDoctors = this.FilterDoctors('');

    return filteredDoctors.some(d => d.Code === this.lineDoctorCode);
  }
  /**
   * Filter the doctors typeahead input options
   * @param pText$ Value that will be used to filter the options
   * @constructor
   */
  SearchDoctors: OperatorFunction<string, IDoctor[]> = (pText$: Observable<string>) =>
  {
    const debouncedText$ = pText$.pipe(debounceTime(200), distinctUntilChanged());

    const clicksWithClosedPopup$ = this.clickInput$.pipe(filter(() => !this.salesPersonsTypeahead.isPopupOpen()));

    const inputFocus$ = this.focusInput$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)
      .pipe(
        map((term) => this.FilterDoctors(term)),
      );
  };

  /**
   * Filter doctor array
   * @param pSearchCriteria Search criteria filter
   * @constructor
   * @private
   */
  private FilterDoctors(pSearchCriteria: string): IDoctor[]
  {
    let selectedHeadquarter = this.storageService.GetLocalStorageVariable<IHeadquarter>(LocalStorageVariables.CurrentHeadquarter);

    if(!selectedHeadquarter)
    {
      return [];
    }

    return this.doctors
      .filter((doc) => (doc.Headquarter === 'NONE' || doc.Headquarter === selectedHeadquarter.Code) && (doc.Speciality === 'NONE' || doc.Speciality === this.lineSpecialty || !this.lineSpecialty) &&
        `${doc.Code} - ${doc.Name}`.toLowerCase().includes(pSearchCriteria.toLowerCase()))
      .slice(0, 10);
  }

  /**
   * Format the result of sales persons typeahead
   * @param pResult Result of the filter
   * @constructor
   */
  DoctorTypeaheadFormatter = (pResult: IDoctor | string) => {
    if(typeof pResult === "object")
    {
      return pResult.Code === '' ? pResult.Name : `${pResult.Code} - ${pResult.Name}`;
    }

    let doctor = this.doctors.find((doc) => doc.Code === pResult)

    if(doctor)
    {
      return doctor.Code === '' ? doctor.Name : `${doctor.Code} - ${doctor.Name}`;
    }

    return '';
  }

  /**
   * Handle the select event of the doctors typeahead
   * @param pEvent Event data
   * @constructor
   */
  OnSelectDoctor(pEvent: any): void
  {
    setTimeout(() => {
      this.doctorControl.setValue(pEvent.item.Code, {emitEvent: false});

      this.selectedDoctor.emit(pEvent.item.Code);
    }, 0);
  }
}
