import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, finalize, first, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import swal from 'sweetalert2';
// import { sweetalert2 } from 'sweetalert2/dist/sweetalert2'


// MODELOS
import { Company, PasswordValidation, User, IPrinter, CONFIG_VIEW, AppConstants } from '../../../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import {
  AuthenticationService,
  AlertService,
  StorageService,
  CompanyService,
  ExRateService,
  CommonService,
  UserService
} from '../../../services/index';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { NgbModal, NgbModalOptions, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { columnsTotalWidth } from '@swimlane/ngx-datatable/release/utils';
import { HeadquarterService } from 'src/app/services/headquarter.service';
import { HeadquarterSelectorComponent } from 'src/app/components/headquarter-selector/headquarter-selector.component';
import { IHeadquarter } from 'src/app/models/i-headquarter';
import {LocalStorageVariables} from "../../../enum/local-storage-variables";
import {IBaseResponse} from "../../../models/responses";

// PIPES

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  usersCredentials: User[];
  model: User;
  Pinpad: boolean;
  @ViewChild('instance') instance: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(100), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.usersCredentials
        : this.usersCredentials.filter(v => v.Email.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  formatter = (result: User) => result.Email;

  annio: number;
  /**
   * variable del formulario, tanto de login como de signin
   */
  loginForm: FormGroup;
  /**
   * variable que comprueba si esta cargando o no
   */
  loading = false;
  /**
   * variable para saber si esta activo el form de login (true) o de signin (false)
  */
  isLogin: boolean;
  /**
   * variable para saber si esta recuperando la contrasenna, activo el form de forgot (true) o se oculta (false)
  */
  forgotPwsd: boolean;
  /**
   * variable para reconcer si se hizo el envio del formulario o no
   */
  submitted = false;
  /**
   * variable que contiene la url de retorno para ver a donde desplazarse
   */
  returnUrl: string;



  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private storage: StorageService,
    private auth: AuthenticationService,
    private alertService: AlertService,
    private companyService: CompanyService
    , private exRateService: ExRateService
    , private commonService: CommonService
    , private headquarterService: HeadquarterService,
    private userService: UserService,
    private modalService: NgbModal
  ) {
    if (this.auth.currentUserValue) {
      this.router.navigate(['/']);
    }
    this.annio = new Date().getFullYear();
  }

  ngOnInit() {
    this.Pinpad = false;
    this.isLogin = true;
    this.forgotPwsd = false;
    this.loginForm = this.fb.group({
      // email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      email: ['', [Validators.required, Validators.minLength(2)]],
      password: ['', Validators.required],
      hasOfflineMode: [this.storage.getConnectionType()]
    });
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    this.usersCredentials = this.storage.getUserCredentials();
  }

  GetSettings(): void {
    this.companyService.GetSettingsbyId(CONFIG_VIEW.Payment).subscribe(response => {
      if (response.Result) {
        let result = JSON.parse(response.Data.Json);
        this.Pinpad = result.Pinpad;
      } else {
        this.alertService.errorAlert('Ocurrió un error obteniendo configuracion compañía uso de pinpad ' + response.Error.Message);
      }
    }, err => {
      this.alertService.errorAlert('Ocurrió un error obteniendo configuracion compañía uso de pinpad ' + err);
    });
  }
  GetSettingUrlOffline(): void {
    this.companyService.GetSettingsbyId(CONFIG_VIEW.OFFLINE_PP).subscribe(response => {
      if (response.Result) {
        this.storage.SetUrlOffilePP(JSON.parse(response.Data.Json));
      } else {
        this.alertService.errorAlert('Ocurrió un error obteniendo configuracion compañía uso de pinpad ' + response.Error.Message);
      }
    }, err => {
      this.alertService.errorAlert('Ocurrió un error obteniendo configuracion compañía uso de pinpad ' + err);
    });
  }
  /**
   * Funcion para tener mejor acceso a los campos del form
   */
  get f() { return this.loginForm.controls; }

  /**
   * Funcion para el envio del formulario, de login o de registro
   */
  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid)
    {
      if (this.loginForm.controls.email.invalid)
      {
        this.alertService.warningInfoAlert('El formato del usuario debe ser ejemplo@ejemplo.com')

        return;
      }

      if (this.loginForm.controls.confirmPassword.invalid)
      {
        this.alertService.warningInfoAlert('Datos Incorrectos, Las contraseñas no coinciden!');

        return;
      }

      this.alertService.warningInfoAlert('Contraseña no es valida!');

      return;
    }

    this.loading = true;

    if (this.isLogin && !this.forgotPwsd)
    {
        this.storage.setOnline();

        this.storage.setConnectionType(this.loginForm.value.hasOfflineMode);

        const USERNAME = this.f.email.value.Email || this.f.email.value;

        const PASSWORD = this.f.password.value.Password || this.f.password.value;

        this.auth.Login(USERNAME, PASSWORD)
          .subscribe({
            next: (loginResponse) => {
              this.storage.addUserCredentials(USERNAME, ""); // Se guardan en blanco las claves hasta que se defina un flujo para guardar estas

              this.storage.setCurrentSession(loginResponse);

              this.auth.NotifyLoginSuccess();

              this.userService.GetCurrentUserSalesPerson()
                .subscribe({
                  next: (userSalesPersonResponse) => {
                    this.storage.SetLocalStorageVariable(LocalStorageVariables.CurrentUserSalesPerson, userSalesPersonResponse.Data);
                  },
                  error: (error) => {
                    console.error("An error occurs trying to retrieve the user sales person: ", error);
                  }
                });

              this.companyService.GetDefaultCompany()
                .subscribe({
                  next: (defaultCompanyResponse) => {
                    if (defaultCompanyResponse.Result)
                    {
                      this.GetSettings();

                      this.GetSettingUrlOffline();

                      this.companyService.GetCompanyById(defaultCompanyResponse.Company.Id)
                        .subscribe({
                          next: (companyResponse: any) => {
                            if (companyResponse.Result)
                            {
                              const COMPANY = companyResponse.companyAndMail.company as Company;

                              this.storage.setCompanyConfiguration(
                                COMPANY.DecimalAmountPrice,
                                COMPANY.DecimalAmountTotalLine,
                                COMPANY.DecimalAmountTotalDocument,
                                null,
                                COMPANY.HasZeroBilling
                                , COMPANY.LineMode,
                                COMPANY.AcceptedMargins,
                                COMPANY.DBCode);

                              this.commonService.bdCode.next(COMPANY.DBCode);

                              this.exRateService.getExchangeRate()
                                .subscribe({
                                  next: (next) => {
                                    if (next.Result) {
                                      this.commonService.exchangeRate.next(next.exRate);
                                    }
                                    else {
                                      console.log(`GetExrateError ${next}`);
                                    }
                                  },
                                  error: (error) => {
                                    console.error("An error occurs trying to retrieve the exchange rate: ", error);
                                  }
                                });

                              this.storage.setHasOfflineMode(companyResponse.companyAndMail.company.HasOfflineMode);

                              this.headquarterService.GetHeadquarter(Number(loginResponse.HeadquarterId)).pipe(finalize(()=>{
                                this.auth.currentUserSubject.next(loginResponse);
                                const returnUrl = this.route.snapshot.params['returnUrl'];
                                this.router.navigate([ decodeURIComponent(returnUrl) ]);
                              }))
                                .subscribe({
                                  next: (callBack) => {
                                    if(callBack.Result)
                                    {
                                      // Set default user headquarter
                                      this.storage.SetCurrentHeadquarter(callBack.Data || null);

                                      this.headquarterService.currentHeadquarterSubject.next(callBack.Data || null);

                                      this.OpenHeadquarterSelector();
                                    }
                                    else
                                    {
                                      this.alertService.errorInfoAlert('Error al cargar la información de la sede - Error: ' + loginResponse.Error.Message);
                                    }
                                  },
                                  error: (error) => {
                                    this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);

                                  }
                                });
                            }
                            else
                            {
                              this.alertService.errorInfoAlert('Error al cargar la información de las compañías - Error: ' + loginResponse.Error.Message);
                            }
                          },
                          error: (error) => {
                            this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
                          }
                        });
                    }
                    else
                    {
                      this.alertService.errorInfoAlert(`Error al obtener la información de la compañia, Error: ${defaultCompanyResponse.Error.Message}`);
                    }
                  },
                  error: (error) => {
                    this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error.error}`);
                  }
                });
            },
            error: (error) => {
              this.alertService.errorAlert(error);

              this.loading = false;
            }
          });
    }
    else if (!this.isLogin && !this.forgotPwsd)
    {
      if (this.checkPasswords(this.loginForm))
      {
        this.auth.register(this.loginForm)
          .subscribe({
            next: (data: IBaseResponse) => {
              this.loading = false;

              if (data.Result)
              {
                this.alertService.successInfoAlert('Usuario Registrado Correctamente!!!');
              }
              else
              {
                this.alertService.errorInfoAlert(`No se Pudo Registrar el Usuario Correctamente!!!, Codigo: ${data.Error.Code}, Mensaje: ${data.Error.Message}`)
              }
            },
            error: (error) => {
              this.loading = false;

              this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
            }
          });

      }
      else
      {
        this.loading = false;

        this.alertService.warningInfoAlert('Datos Incorrectos, Las contraseñas no coinciden!!!');
      }
    }
    else if (this.forgotPwsd)
    {
      this.auth.sendRecoverPswdEmail(this.loginForm)
        .subscribe({
          next: (data: IBaseResponse) => {
            this.loading = false;

            if (data.Result)
            {
              this.alertService.successInfoAlert('Usuario Registrado Correctamente!!!');
            }
            else
            {
              this.alertService.errorInfoAlert(`No se Pudo Registrar el Usuario Correctamente!!!, Codigo: ${data.Error.Code}, Mensaje: ${data.Error.Message}`)
            }
          },
          error: (error) => {
            this.loading = false;

            this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
          }
        });
    }
  }

  /**
   * Funcion para cambiar entre el formulario de login y de sigin
   */
  clickEvent(islogin: boolean, forgotPwsd: boolean) {
    this.isLogin = islogin;
    this.forgotPwsd = forgotPwsd;
    // se activa el form de login
    if (this.isLogin && !this.forgotPwsd) {
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
        password: ['', Validators.required]
      });
      // se activa el form de registrarse
    } else if (!this.isLogin && !this.forgotPwsd) {
      this.loginForm = this.fb.group({
        fullName: ['', Validators.required],
        email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required]
      }, {
        validator: PasswordValidation.MatchPassword // your validation method
      });
      // se activa el form de recuperar la contrasenna
    } else if (this.forgotPwsd) {
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]]
      });
    }
    // get return url from route parameters or default to '/'
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';

  }

  /**
   * Funcion para validar el que las contraseñas sean iguales al registrar un usuario nuevo
   *
   * @param {FormGroup} group Formulario de registro
   */
  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? true : false;
  }

  OpenHeadquarterSelector(): void {
    let modalOption: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title'
    };

    this.modalService.dismissAll('abort');

    let modalHeadquarter = this.modalService.open(HeadquarterSelectorComponent, modalOption);

    modalHeadquarter.result.then((result: IHeadquarter) => {
      this.headquarterService.currentHeadquarterSubject.next(result);
      this.alertService.successInfoAlert(`${result.Code} - ${result.Name} seleccionada!`);
    }, (reason: IHeadquarter) => {
      console.log(reason);
    });
  }
}
