import {DatePipe, formatDate} from '@angular/common';
import {THIS_EXPR} from '@angular/compiler/src/output/output_ast';
import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {finalize} from 'rxjs/operators';
import {ElectronRendererService} from 'src/app/electronrenderer.service';
import {BACErrorCodes, BoRcptTypes, PaymentResults} from 'src/app/enum/enum';
import {AppConstants, Company, CONFIG_VIEW, IPPTransaction, IPrinter, ITerminal, ITerminalByUser} from 'src/app/models';
import {IContableAccounts} from 'src/app/models/i-contableaccounts';
import {IInvoiceInfoForPayment, IOnPaymentFail} from 'src/app/models/i-payment-data';
import {BasePayment, CreditCards} from 'src/app/models/i-payment-document';
import {
  AlertService,
  AuthenticationService,
  CommonService, CompanyService,
  DocumentService,
  PaymentService,
  ReportsService,
  StorageService
} from 'src/app/services';
import {ITapp} from "../../models/i-tapp-config";
import {ITransaction, IVoidedTransaction} from "../../models/i-pp-transaction";

const VAT: number = 0.04;
// CONSTANTS
const printJS = require("print-js");

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [DatePipe]
})
export class PaymentComponent implements OnInit {

  // Points
  availableTappRewards: any[] = [];
  pointsSettings: ITapp = null;
  pointsForm: FormGroup;
  openTappInvoiceResponse: any = null;
  TotalPoints: number;
  TotalRewards: number;
  IspaymentRecived:boolean = false;

  //varbox
  amountAppliedManually: boolean = false;
  requiredTransferRef: boolean;
  isPaymentPercent: boolean = false;
  minCreditPayment: number;
  requiredCardAccount: string;
  requiredCashAccount: string;
  requiredTransferAccount: string;
  defaultCardNumber: string;
  isAllowedToEditCardNumber: boolean;
  maxAmountToCharge: number;
  maxVatToRefunded: number;
  lockedTotalUnvatted: number;
  amountToCharge: number;
  amountToCommit: number;
  vatToCommit: number;
  theoricalAmount: number;
  theoricalVAT: number;

  unvattedAmount: number;
  // vatRefunded: number;
  hasVatApplied: boolean;
  isRefundedValid: boolean;
  terminal: ITerminal;
  userCurrency: string;
  exRate: number;


  @BlockUI() blockUI: NgBlockUI;

  @Input() public requiredData: any;

  TO_FIXED_TOTALDOCUMENT: string; // Contiene la cantidad de decimales a usar en el total del documento

  //Forms
  cashForm: FormGroup;
  transferForm: FormGroup;
  checkForm: FormGroup;
  creditCardForm: FormGroup;
  //Variables pp
  pinPadCard: IPPTransaction;
  pinPadCards: IPPTransaction[];
  terminals: ITerminal[];
  pPTransaction: IPPTransaction;
  isRequestinBacPayment: boolean;


  preDocument: any;

  //Totales
  TotalG: number; // monto total del pago
  ReceivedG: number; // monto total recibido del pago
  DifferenceG: number; // monto total de la diferencia, o salto restante
  ChangeG: number; // guarda el vuelto
  TotalCash: number; // monto total del pago en efectivo
  TotalCards: number; // monto total del pago en tarjetas
  TotalTransfer: number; // monto total del pago en transferencia
  TotalCheck: number; // monto total del pago en cheque
  changeColones: number; // vuelto en colones
  changeDolares: number; // vuelto en dolares
  currencyChange: number; // monto del tipo de cambio
  totalUSD: number;
  total: number;


  //REVISAR ------------------------------------------------------
  isBilling: boolean;//Validacion flag para evitar reenvio de solicitudes al mismo tiempo

  currencyPayment: string;

  COMPANY: Company;

  currencyList: any[]; // lista de tipos de cambio
  accountList: IContableAccounts;
  V_CreditCards: CreditCards[]; // lista de pagos registrados con tarjetas de credito
  cardsList: any[]; // lista de tarjetas
  V_Checks: any[]; // Lista de pagos registrados con cheques
  banksList: any[]; // lista de bancos

  invoiceInfo: IInvoiceInfoForPayment;


  isFromFail: boolean;
  DefaultCardValid: string;//Fecha valido hasta configurado desde companias

  flagForm: boolean;  //Validacion flag para evitar reenvio de solicitudes al mismo tiempo
  isPartialPayment: boolean;
  /**
   * List of PinPad transactions
   */
  transactions: ITransaction[] = [];
  /**
   * Indicates if the current company use PINPAD feature
   */
  companyUsePinPad: boolean = false;
  constructor(private fb: FormBuilder
    , private alertService: AlertService
    , private modalService: NgbModal
    , private datePipe: DatePipe
    , private storageService: StorageService
    , private authenticationService: AuthenticationService
    , private paymentService: PaymentService
    , private reportsService: ReportsService
    , private electronRendererService: ElectronRendererService
    , private companyService: CompanyService) {
  }

  ngOnInit() {
    this.initData();
    this.LoadData();
    this.resetModalData();
    this.TO_FIXED_TOTALDOCUMENT = `1.${this.COMPANY.DecimalAmountTotalDocument}-${this.COMPANY.DecimalAmountTotalDocument}`;
  }

  theoricalDocumentAmount: number;
  theoricalDocumentVAT: number;

  theoricalDocumentAmountConverted: number;
  theoricalDocumentVatConverted: number;

  LoadData(): void {
    if (this.requiredData) {
      this.isPaymentPercent = this.requiredData.IsPaymentPercent;
      this.minCreditPayment = this.requiredData.MinCreditPayment;

      this.isAllowedToEditCardNumber = this.requiredData.IsAllowedToEditCardNumber;
      this.requiredCashAccount = this.requiredData.RequiredCashAccount;
      this.requiredTransferAccount = this.requiredData.RequiredTransferAccount;
      this.requiredTransferRef = this.requiredData.RequiredTransferRef;
      this.requiredCardAccount = this.requiredData.RequiredCardAccount;

      this.amountToCharge = this.requiredData.DocumentTotals.TotalUnvatted; // Contiene el monto a cobrar sin importa el medio de pago
      this.theoricalVAT = this.requiredData.DocumentTotals.VatRefunded; // contiene solo el impuesto 4iva
      this.theoricalAmount = this.requiredData.DocumentTotals.UnVattedAmount; // contiene el monto a cobrar del 4iva

      // TAPP Info
      this.availableTappRewards = this.requiredData.AvailableTappRewards || [];
      this.openTappInvoiceResponse = this.requiredData.OpenTappInvoiceResponse;
      this.pointsSettings = this.requiredData.TappSettings;
      this.IspaymentRecived = this.requiredData.IspaymentRecived;

      this.userCurrency = this.requiredData.UserCurrency;
      this.exRate = +this.requiredData.ExRate;

      this.isFromFail = false;

      this.invoiceInfo = this.requiredData.InvoiceInfo;

      this.accountList = this.requiredData.lists.accountList;

      this.currencyList = this.requiredData.lists.currencyList;

      this.cardsList = this.requiredData.lists.cardsList;

      this.banksList = this.requiredData.lists.banksList;

      this.V_Checks = this.requiredData.lists.V_Checks;

      //Tipo cambio expresado en COL/USD/EUR
      this.currencyPayment = this.requiredData.Currency.currencyPayment;

      //Totals
      this.currencyChange = this.requiredData.DocumentTotals.currencyChange;

      this.totalUSD = this.requiredData.DocumentTotals.totalUSD;

      this.TotalG = this.total = this.requiredData.DocumentTotals.total;

      if (this.currencyPayment !== 'COL')
      {
        this.theoricalDocumentAmount = this.requiredData.DocumentTotals.UnVattedAmount;

        this.theoricalDocumentAmountConverted = this.requiredData.DocumentTotals.UnVattedAmount * this.currencyChange;

        this.theoricalDocumentVAT = this.requiredData.DocumentTotals.VatRefunded;

        this.theoricalDocumentVatConverted = this.requiredData.DocumentTotals.VatRefunded * this.currencyChange;
      }
      else
      {
        this.theoricalDocumentAmount = this.requiredData.DocumentTotals.UnVattedAmount;

        this.theoricalDocumentAmountConverted = this.requiredData.DocumentTotals.UnVattedAmount / this.currencyChange;

        this.theoricalDocumentVAT = this.requiredData.DocumentTotals.VatRefunded;

        this.theoricalDocumentVatConverted = this.requiredData.DocumentTotals.VatRefunded / this.currencyChange;
      }

      this.terminal = this.terminals.find(x => x.Currency == this.currencyPayment);

      if(this.terminal)
      {
        this.creditCardForm.patchValue({ Terminal: this.terminal.TerminalId});
      }

      // PP, documento requerido para almacenarlo cuando se haga un pago por PP
      this.preDocument = this.requiredData.PinpadInfo.PreDocument;

      this.DefaultCardValid = this.requiredData.CardValid;

      this.defaultCardNumber = this.requiredData.DefaultCardNumber;

      // OnFail, si algo salio mal creando el documento al cual se le hizo el pago
      if (this.requiredData.OnFail.IsFail)
      {
        this.isFromFail = true;

        let value: IOnPaymentFail = this.requiredData.OnFail.DataForFail;

        // this.pPTransaction = value.pPTransaction;
        this.pinPadCards = value.pinPadCards;

        this.cashForm.setValue(value.cashForm.value);

        this.creditCardForm.setValue(value.creditCardForm.value);

        this.transferForm.setValue(value.transferForm.value);

        this.pointsForm.setValue({TotalPoints: value.TotalPoints});

        this.V_CreditCards = [...value.cards.filter(c => c.CreditCardNumber !== this.pointsSettings.Card.Number)];

        this.transactions = [...value.Transactions];

        this.DifferenceG = value.DifferenceG;

        this.ReceivedG = value.ReceivedG;

        this.TotalCards = value.TotalCards;

        this.TotalCash = value.TotalCash;

        this.TotalCheck = value.TotalCheck;

        this.TotalTransfer = value.TotalTransfer;

        this.TotalPoints = value.TotalPoints;

        this.setTotalAmount();

        this.changeCurrency(true);
      }
    }

    this.CheckIfCompanyUsePinPad();
  }

  /**
   * Send a request to retrieve the company invoice configuration to check if the company use PinPad
   * @constructor
   * @private
   */
  private CheckIfCompanyUsePinPad(): void
  {
    this.companyService.GetSettingsbyId(CONFIG_VIEW.Payment)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (response) => {
          if(response.Result && response.Data.Json)
          {
            let configuration = JSON.parse(response.Data.Json);

            this.companyUsePinPad = configuration.Pinpad;
          }
        },
        error: (error) => {
          console.error(error);

          this.alertService.errorInfoAlert(`An error occurs trying to check if company use PinPad. Details: ${error}`)
        }
      })
  }


  // funcion para cambiar el tipo de moneda en la modal de pagos
  changeCurrency(_isOnLoad = false): void {

    const AMOUNT_APPLIED = 1

    if (!_isOnLoad) {
      this.creditCardForm.patchValue({CreditSum: 0});
      this.cashForm.patchValue({TotalCash: 0});
      this.transferForm.patchValue({TransSum: 0});
      this.alertService.infoInfoAlert(`Se ha restablecido el monto recibido del cliente`);

      this.terminal = this.terminals.find(x => x.Currency == this.currencyPayment);
      this.creditCardForm.patchValue({
        Terminal: this.terminal.TerminalId
      });
    }

    if (this.currencyPayment !== 'COL') {
      if (this.userCurrency === 'USD') {
        this.TotalG = this.total;
        this.ReceivedG = this.total;

        this.theoricalAmount = this.theoricalDocumentAmount;
        this.theoricalVAT = this.theoricalDocumentVAT;
      } else {
        this.theoricalAmount = this.theoricalDocumentAmountConverted;
        this.theoricalVAT = this.theoricalDocumentVatConverted;
        this.TotalG = Number((this.total / this.currencyChange).toFixed(2));
        this.ReceivedG = this.TotalG;
      }
    } else {
      if (this.userCurrency === 'COL') {
        this.TotalG = this.total;
        this.ReceivedG = this.TotalG;

        this.theoricalAmount = this.theoricalDocumentAmount;
        this.theoricalVAT = this.theoricalDocumentVAT;
      } else {
        this.TotalG = Number((this.total * this.currencyChange).toFixed(2));
        this.ReceivedG = this.TotalG;

        this.theoricalAmount = this.theoricalDocumentAmountConverted;
        this.theoricalVAT = this.theoricalDocumentVatConverted;
      }
    }

    this.ReceivedG = 0;
    this.setTotalAmount();
  }

  onCtrlBCash(): void {
    this.cashForm.patchValue({TotalCash: this.DifferenceG.toFixed(this.COMPANY.DecimalAmountTotalDocument)});
    this.addAmountCash();
  }

  addAmountCash(): void {
    this.ReceivedG = +this.ReceivedG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalG = +this.TotalG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalCash = parseFloat(this.cashForm.value.TotalCash);

    if (this.TotalCash > 0) {
      this.setTotalAmount();
    } else {
      this.cashForm.patchValue({TotalCash: 0});
      this.TotalCash = 0;
      this.setTotalAmount();
    }
  }

  // funcion para calcular el total de recibido y de diferencia para el pago
  setTotalAmount(): void { //decimalPipe

    this.TotalG = +this.TotalG.toFixed(this.COMPANY.DecimalAmountTotalDocument);

    this.ReceivedG = Number((this.TotalCash + this.TotalCards + this.TotalCheck + this.TotalTransfer + this.TotalPoints).toString());
    this.ReceivedG = +this.ReceivedG.toFixed(this.COMPANY.DecimalAmountTotalDocument);

    let diff = Number((this.TotalG - this.ReceivedG).toString());

    this.DifferenceG = Math.max(diff, 0.0);
    this.ChangeG = Math.max(0, -1 * diff);

    if (this.currencyPayment !== 'COL') {
      this.changeDolares = this.ChangeG;
      this.changeColones = Number((this.ChangeG * this.currencyChange).toString());
    } else {
      this.changeDolares = Number((this.ChangeG / this.currencyChange).toString());

      this.changeColones = this.ChangeG;
    }
  }

  // funcion para colocar el monto a pagar en tranferencia
  addAmountTransfer(): void {
    this.TotalTransfer = parseFloat(this.transferForm.value.TransSum);
    this.TotalTransfer = +this.TotalTransfer.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalCards = +this.TotalCards.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalCheck = +this.TotalCheck.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalG = +this.TotalG.toFixed(this.COMPANY.DecimalAmountTotalDocument);

    if (this.TotalTransfer > 0) {
      if ((this.TotalCards + this.TotalTransfer + this.TotalCheck) <= this.TotalG) {
        this.setTotalAmount();
      } else {
        this.alertService.infoInfoAlert('El monto ingresado en transferencia supera el total de la factura.');
        this.transferForm.patchValue({TransSum: 0});
        this.TotalTransfer = 0;
        this.setTotalAmount();
      }
    } else {
      this.transferForm.patchValue({TransSum: 0});
      this.TotalTransfer = 0;
      this.setTotalAmount();
    }
  }

  /**
   * Add the provided information of the credit card to the table
   * @constructor
   */
  AddCreditCardAmount() : void
  {
    if (this.requiredCardAccount && +this.creditCardForm.value.CreditSum > 0 && this.creditCardForm.value.CreditCard == '')
    {
      this.alertService.infoAlert(`Por favor seleccione el nombre de la tarjeta antes de enviar el pago`);

      return;
    }

    if (!this.creditCardForm.value.CardNum)
    {
      this.alertService.infoAlert(`Por favor digite un número de tarjeta válido`);

      return;
    }

    let creditCardTotal = 0;

    if (this.creditCardForm.valid)
    {
      if (!this.creditCardForm.controls.VoucherNum.value || this.creditCardForm.controls.VoucherNum.value == '0' || this.creditCardForm.controls.VoucherNum.value == '')
      {
        this.alertService.infoAlert(`Digite el número de vourcher, por favor`);

        return;
      }

      if (parseFloat(this.creditCardForm.value.CreditSum) > 0)
      {
        if (this.V_CreditCards.length > 0)
        {
          this.V_CreditCards.forEach((vcc) => {
            creditCardTotal += parseFloat((vcc.CreditSum + (vcc.RefundedVAT || 0)).toString());
          });
        }

        const AVAILABLE_THEORICAL_AMOUNT = this.theoricalAmount - this.V_CreditCards.reduce((acc, card) => acc + card.CreditSum, 0);

        const AMOUNT_TO_CHARGE_ON_CARD = this.creditCardForm.value.CreditSum <= AVAILABLE_THEORICAL_AMOUNT ? this.creditCardForm.value.CreditSum : AVAILABLE_THEORICAL_AMOUNT;

        let percetageUsedFromMaxAmount = AMOUNT_TO_CHARGE_ON_CARD / this.theoricalAmount;

        if (percetageUsedFromMaxAmount > 1)
        {
          percetageUsedFromMaxAmount = (this.creditCardForm.value.CreditSum + this.V_CreditCards.reduce((acc, card) => acc + card.CreditSum, 0)) / this.theoricalAmount;

          this.alertService.infoAlert(`El monto es superior al total del documento`);

          return;
        }

        const VAT_TO_REFUND = Number((this.theoricalVAT * percetageUsedFromMaxAmount).toFixed(this.COMPANY.DecimalAmountTotalDocument));

        if ((creditCardTotal + VAT_TO_REFUND + this.creditCardForm.value.CreditSum + this.TotalCash + this.TotalTransfer) > this.TotalG)
        {
          this.alertService.infoInfoAlert("El el monto a cobrar con tarjeta supera el total de la factura. Monto a cobrar actualizado.");

          return;
        }

        creditCardTotal += this.creditCardForm.value.CreditSum;

        creditCardTotal = Number(creditCardTotal.toFixed(this.COMPANY.DecimalAmountTotalDocument));

        if (creditCardTotal > 0)
        {
          if ((creditCardTotal + this.TotalTransfer + this.TotalCheck) <= this.TotalG)
          {
            const CREDIT_CARD = this.cardsList.filter(x => x.CreditCard === this.creditCardForm.value.CreditCard)[0];

            let temp = this.creditCardForm.value.CardValid.split('-');

            let aux = temp[1] + '/' + temp[0][2] + temp[0][3];

            this.V_CreditCards.push({
              CardValid: aux,
              CreditCardNumber: this.creditCardForm.value.CardNum,
              CreditCard: CREDIT_CARD.CardName.split(' ')[0],
              CreditSum: this.creditCardForm.value.CreditSum,
              U_ManualEntry: this.creditCardForm.value.IsManualEntry ? '1' : '0',
              U_NumTarjeta: this.creditCardForm.value.CardNum,
              FormatCode: CREDIT_CARD.CardName,
              OwnerIdNum: this.creditCardForm.value.IsManualEntry ? this.creditCardForm.value.VoucherNum : this.creditCardForm.value.OwnerIdNum,
              VoucherNum: this.creditCardForm.value.VoucherNum,
              IsManualEntry: this.creditCardForm.value.IsManualEntry,
              CreditAcct: CREDIT_CARD.CreditCard,
              RefundedVAT: VAT_TO_REFUND
            } as CreditCards);

            this.TotalCards = creditCardTotal;

            this.setTotalAmount();

            creditCardTotal += (this.V_CreditCards[this.V_CreditCards.length - 1].RefundedVAT || 0);

            this.TotalCards = creditCardTotal;

            this.setTotalAmount();

            // proceso que se ejecuta condicionalmente es para ocultar el input de iva devuelto
            if (this.theoricalVAT > 0) this.CheckVatApplied();

            this.creditCardForm.patchValue({OwnerIdNum: '0'});
            this.creditCardForm.patchValue({VoucherNum: '0'});
            this.creditCardForm.patchValue({CardValid: this.DefaultCardValid});
            this.creditCardForm.patchValue({CardNum: this.defaultCardNumber});
            this.creditCardForm.patchValue({CreditSum: '0'});
            this.creditCardForm.patchValue({IsManualEntry: true});

            this.onCtrlBTarjeta();

          }
          else
          {
            this.creditCardForm.patchValue({CreditSum: '0'});

            this.alertService.infoInfoAlert('El monto ingresado en la tarjeta de crédito supera el total de la factura.');
          }
        }
        else
        {
          this.alertService.infoInfoAlert(
            "El monto ingresado en Total es incorrecto."
          );

          this.creditCardForm.patchValue({CreditSum: 0});
        }
      }
      else
      {
        this.creditCardForm.patchValue({CreditSum: 0});
      }
    }
    else
    {
      this.alertService.infoInfoAlert("Campos inválidos");
    }
  }

  removeCreditCard(_index, _voucherNumber: string,  _ownerIdNum: string = ``) {
    let transaction: ITransaction = null;

    (this.transactions || []).forEach((x, index) => {
      if (index === _index && x.AuthorizationNumber === _ownerIdNum) transaction = x
    });

    if (transaction)
    {
      this.blockUI.start(`Procesando, espere por favor`);

      let committedVoidedTransaction: IVoidedTransaction = null;

      let serializedTransaction = ``;

      this.paymentService.CancelCommittedTransaction(transaction).pipe(
        finalize(() => {
          this.blockUI.stop();
          this.CommitVoidedTransaction(committedVoidedTransaction, serializedTransaction);
        })
      ).subscribe({
        next: (response) => {
          try
          {
            if (response.Result)
            {
              this.TotalCards -= (this.V_CreditCards[_index].CreditSum + (this.V_CreditCards[_index].RefundedVAT || 0));

              this.setTotalAmount();

              if (this.theoricalAmount > 0) this.CheckVatApplied();

              if (this.V_CreditCards.length === 0)
              {
                this.isRefundedValid = false;
              }

              this.creditCardForm.patchValue({
                'CardValid': this.DefaultCardValid,
                'CardNum': this.defaultCardNumber,
                'OwnerIdNum': '0',
                'VoucherNum': '0',
                'IsManualEntry': true
              });

              this.pPTransaction = null;

              const CREDIT_SUM = this.V_CreditCards[_index].CreditSum;

              if (this.theoricalAmount > 0)
              {
                if (this.theoricalAmount >= (this.maxAmountToCharge + CREDIT_SUM))
                {
                  this.maxAmountToCharge += CREDIT_SUM
                }

                this.onCtrlBTarjeta();
              }

              this.V_CreditCards.splice(_index, 1);

              const INDEX_TRANSACTION = this.transactions.findIndex(x => x.SystemTrace === transaction.SystemTrace)

              const EMVS_STREAM = JSON.parse(response.Data)['EMVStreamResponse'];

              this.transactions.splice(INDEX_TRANSACTION, 1);

              this.setTotalAmount();

              serializedTransaction = response.Data;

              committedVoidedTransaction = {
                InvoiceNumber: transaction.InvoiceNumber,
                SerializedTransaction: response.Data,
                TerminalId: transaction.TerminalId,
                TransactionId: EMVS_STREAM.transactionId,
                CreationDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en'),
                DocumentKey: this.invoiceInfo.uniqueInvCode
              } as IVoidedTransaction;

            }
            else
            {
              this.alertService.errorAlert(`${response.Error.Code} - ${response.Error.Message}`);
            }
          }
          catch (error)
          {
            this.alertService.errorAlert(AppConstants.GetError(error));
          }
        }
      });
    }
    else
    {

      const CREDIT_SUM = this.V_CreditCards[_index].CreditSum;
      this.TotalCards -= (this.V_CreditCards[_index].CreditSum + (this.V_CreditCards[_index].RefundedVAT || 0));
      this.V_CreditCards.splice(_index, 1);
      this.setTotalAmount();

      if (this.theoricalAmount > 0) {
        if (this.theoricalAmount >= (this.maxAmountToCharge + CREDIT_SUM)) {
          this.maxAmountToCharge += CREDIT_SUM
        }
        this.onCtrlBTarjeta();
      }

      if (this.theoricalAmount > 0) this.CheckVatApplied();

      if (this.V_CreditCards.length === 0) {
        this.isRefundedValid = false;
      }
    }
  }

  private CommitVoidedTransaction(pVoidedTransaction: IVoidedTransaction, pSerializedTransaction: string): void {

    if (!pVoidedTransaction) return; // Its no required to notify to the user an uncommited transaction

    this.blockUI.start(`Procesando, espere por favor`);

    let committedVoidedTransaction: IVoidedTransaction = null

    this.paymentService.CommitCanceledCard2(pVoidedTransaction)
      .pipe(
        finalize(() => {
          this.blockUI.stop();

          this.PrintVoid(pSerializedTransaction, committedVoidedTransaction.InvoiceNumber, committedVoidedTransaction.TerminalId, committedVoidedTransaction.CreationDate);
        })
    ).subscribe({
      next: (callback) => {
        if (callback.Result)
        {
          committedVoidedTransaction = callback.Data;

          committedVoidedTransaction.CreationDate = (new Date()).toLocaleString();

          this.alertService.successInfoAlert(`Transacción completada`);
        }
        else
        {
          this.alertService.infoAlert(`Transacción anulada pero no se pudo respaldar en base de datos. ${AppConstants.GetError(callback)}`);
        }
      },
      error: error => {
        console.info(error);

        this.alertService.errorAlert(AppConstants.GetError(error));
      }
    });
  }

  PrintVoid(_serializedTransaction: string, _invoiceNumber: string, _terminal: string, _creationDate: string) {
    const EMVSTREAM = JSON.parse(_serializedTransaction)['EMVStreamResponse'];

    const TRANSACTION = {
      AuthorizationNumber: EMVSTREAM['authorizationNumber'],
      InvoiceNumber: _invoiceNumber,
      ReferenceNumber: EMVSTREAM['referenceNumber'],
      SystemTrace: EMVSTREAM['systemTraceNumber'],
      TerminalId: _terminal
    } as ITransaction;

    // Se usa esta forma de parsear el monto debido que para el bac 1000, representa 10.00 colones
    const AMOUNT = +EMVSTREAM.salesAmount.slice(0, EMVSTREAM.salesAmount.length - 2) + "." + EMVSTREAM.salesAmount.slice(-2);

    const TERMINAL:ITerminal = this.terminals.find(x => x.TerminalId == _terminal) || {} as ITerminal;

    // make some mappings
    let docEntry = -1; // i set this value to -1 because is used to ignore some fields in the report
    let rawData = `>ct:1>ct_end>tr1:${_terminal}>tr_end1>am1:${AMOUNT}>am_end1>ti1:${_invoiceNumber}`;
    rawData += `>ti_end1>st1:${EMVSTREAM['systemTraceNumber']}>st_end1>rn1:${EMVSTREAM['referenceNumber']}`;
    rawData += `>rn_end1>na1:${EMVSTREAM['authorizationNumber']}>na_end1>cu1:${TERMINAL.Currency}>cu_end1>fc1:${_creationDate}>fc_end1`;

    this.reportsService.PrintVoucher2(docEntry, rawData).pipe(finalize(() => this.blockUI.stop())).subscribe(data => {
      try {

        if (data.Result)
        {
          if (this.electronRendererService.CheckElectron())
          {
            let fileName = 'Invoice_' + TRANSACTION.InvoiceNumber + '.pdf';

            const PRINTERCONFIGURATION = JSON.parse(this.storageService.getCompanyConfiguration().PrinterConfiguration) as IPrinter;

            let file = {
              "fileName": fileName,
              "file": data.Data,
              "defaultPrinter": PRINTERCONFIGURATION.DisplayName
            };

            this.electronRendererService.send('Print', file);
          }
          else {
            printJS({
              printable: data.Data,
              type: 'pdf',
              base64: true
            });
          }
        }
        else
        {
          this.alertService.errorInfoAlert(`Error obteniendo reporte, error: ${data.Error.Code}-${data.Error.Message}`);
        }

      } catch (error) {
        console.info(error);
      }

    }, error => {

      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
    });
  }

  onCtrlBTransferencia(): void {
    this.transferForm.patchValue({TransSum: this.DifferenceG.toFixed(this.COMPANY.DecimalAmountTotalDocument)});
    this.addAmountTransfer();
  }

  onCtrlBCheque(): void {
    this.checkForm.patchValue({CheckSum: this.DifferenceG.toFixed(this.COMPANY.DecimalAmountTotalDocument)});
  }

  onCtrlBTarjeta(): void {
    this.creditCardForm.patchValue({CreditSum: 0});

    const DIFFERENCE_ON_CARDS = +(this.maxAmountToCharge - this.V_CreditCards.reduce((acc, card) => acc + card.CreditSum, 0)).toFixed(this.COMPANY.DecimalAmountTotalDocument);

    if (this.theoricalVAT > 0 && this.creditCardForm.value.CreditSum > 0 && DIFFERENCE_ON_CARDS > 0) {
      this.alertService.infoInfoAlert(`Monto restante actualizado`);
    }

    this.creditCardForm.patchValue({CreditSum: this.PreviewUnvattedAmount()});
  }

  CalculateManualAmountAppliedVAT(): void {
    const DECS = (() => +`1${`0`.repeat(this.COMPANY.DecimalAmountTotalDocument)}`)();

    let amountApplied: number = Number(this.creditCardForm.controls["CreditSum"].value);

    let vatAvailable: number = this.theoricalVAT - this.V_CreditCards.reduce((acc, card) => acc + card.RefundedVAT, 0);

    let totalVATAmount: number = Math.round(((amountApplied / 1.04) * 0.04) * DECS) / DECS;

    if (totalVATAmount > vatAvailable) {
      this.maxVatToRefunded = vatAvailable;
    } else {
      this.maxVatToRefunded = totalVATAmount;
    }

    this.amountAppliedManually = true;
  }

  PreviewUnvattedAmount(): number {
    try {
      this.maxAmountToCharge = 0;

      this.maxVatToRefunded = 0;

      const OTHER_PAMENTS = Number((this.TotalTransfer + this.TotalCash + this.TotalCards).toFixed(this.COMPANY.DecimalAmountTotalDocument));

      const THEORICAL_VATTED_AMOUNT = this.theoricalAmount + this.theoricalVAT;

      const THEORICAL_TOTAL = this.amountToCharge + THEORICAL_VATTED_AMOUNT;

      let THEORICAL_CHARGE = THEORICAL_TOTAL - OTHER_PAMENTS;

      let theoricalLessThan = 0;
      let theoricalVATLessThan = 0;
      let theoricalEqualsTo = 0;
      let theoricalVATEqualsTo = 0;
      let theoricalGreaterThan = 0;
      let theoricalVATGreaterThan = 0;

      if (THEORICAL_CHARGE < THEORICAL_VATTED_AMOUNT) {
        theoricalLessThan = THEORICAL_CHARGE / 1.04;//this.V_CreditCards.reduce((acc, card) => acc + card.RefundedVAT, 0) > 0 ? THEORICAL_CHARGE - this.V_CreditCards.reduce((acc, card) => acc + card.RefundedVAT, 0) : THEORICAL_CHARGE - THEORICAL_CHARGE * VAT;
        theoricalVATLessThan = theoricalLessThan * VAT;
      }

      if (THEORICAL_CHARGE === THEORICAL_VATTED_AMOUNT) {
        theoricalEqualsTo = this.theoricalAmount;
        theoricalVATEqualsTo = this.theoricalVAT;
      }

      if (THEORICAL_CHARGE > THEORICAL_VATTED_AMOUNT) {
        theoricalGreaterThan = (this.theoricalVAT + this.V_CreditCards.reduce((acc, card) => acc + card.RefundedVAT, 0)) > 0 ? THEORICAL_CHARGE - (this.theoricalVAT - this.V_CreditCards.reduce((acc, card) => acc + card.RefundedVAT, 0)) : THEORICAL_CHARGE;
        theoricalVATGreaterThan = (this.theoricalVAT - this.V_CreditCards.reduce((acc, card) => acc + card.RefundedVAT, 0));
      }

      const MAX_CHARGE_ON_CARDS = +(theoricalLessThan + theoricalEqualsTo + theoricalGreaterThan).toFixed(this.COMPANY.DecimalAmountTotalDocument);
      const MAX_VAT_REFUNDED = +(theoricalVATLessThan + theoricalVATEqualsTo + theoricalVATGreaterThan).toFixed(this.COMPANY.DecimalAmountTotalDocument);

      this.maxAmountToCharge = MAX_CHARGE_ON_CARDS;

      this.maxVatToRefunded = MAX_VAT_REFUNDED;

      const THEORICAL_TO_CHARGE = MAX_CHARGE_ON_CARDS >= 0 ? MAX_CHARGE_ON_CARDS : 0; //- ; +(MAX_CHARGE_ON_CARDS - this.V_CreditCards.reduce((acc, card) => acc + card.CreditSum, 0) > 0 ?  MAX_CHARGE_ON_CARDS : MAX_CHARGE_ON_CARDS).toFixed(this.COMPANY.DecimalAmountTotalDocument)  ;

      return THEORICAL_TO_CHARGE;
    }
    catch (error) {
      return 0;
    }
  }

  SubstractTaxFromAmount(_amount: number): number {
    try {
      const UNVATTED_AMOUNT_PERCENTAGE = _amount / this.theoricalAmount;

      const SUBSTRACTED_VAT = this.theoricalVAT * UNVATTED_AMOUNT_PERCENTAGE;

      return SUBSTRACTED_VAT;
    } catch (error) {
      return 0;
    }
  }

  closePayModal(): void {
    if (this.V_CreditCards.length > 0 && !this.isBilling) {
      this.alertService.infoInfoAlert(`Elimine las tarjetas agregadas`);
      return;
    }

    let closeDetail = {status: PaymentResults.CancelButton, DismissReason: 'Cancelar'};
    this.modalService.dismissAll(closeDetail);
    //this.isOnSubmit = false;
  }

  resetModalData(): void {
    if (this.isFromFail) return;
    this.isRefundedValid = false;
    this.TotalCash = 0;
    this.TotalCards = 0;
    this.TotalCheck = 0;
    this.TotalTransfer = 0;
    this.TotalPoints = 0;
    this.TotalRewards = 0;
    this.ReceivedG = 0;
    this.DifferenceG = this.TotalG;
    this.ChangeG = 0;

    this.cashForm.patchValue({AccountCash: this.accountList.CashAccounts[0].Account});
    this.cashForm.patchValue({TotalCash: 0});

    this.creditCardForm.patchValue({CreditCard: this.cardsList[0].CreditCard});
    this.creditCardForm.patchValue({CreditSum: 0});
    this.creditCardForm.patchValue({IsManualEntry: true});
    this.creditCardForm.patchValue({VoucherNum: '0'});
    this.creditCardForm.patchValue({CardValid: this.DefaultCardValid});
    this.creditCardForm.patchValue({CardNum: this.defaultCardNumber});
    this.creditCardForm.patchValue({OwnerIdNum: '0'});

    this.transferForm.patchValue({AccountTransf: this.accountList.TransferAccounts[0].Account});
    this.transferForm.patchValue({DateTrans: this.datePipe.transform(new Date(), 'y-MM-dd')});
    this.transferForm.patchValue({Ref: ''});
    this.transferForm.patchValue({TransSum: 0});

    this.checkForm.patchValue({AccountCheck: this.accountList.CheckAccounts[0].Account});
    this.checkForm.patchValue({BankNames: this.banksList[0].BankCode});
    this.checkForm.patchValue({DueDate: ''});
    this.checkForm.patchValue({CountryCod: 'CR'});
    this.checkForm.patchValue({AcctNum: ''});
    this.checkForm.patchValue({CheckNum: ''});
    this.checkForm.patchValue({CheckSum: 0});

    this.pointsForm.patchValue({TotalPoints: 0});
  }


  initData(): void {
    this.isPartialPayment = false;
    this.maxAmountToCharge = 0;
    this.maxVatToRefunded = 0;
    this.vatToCommit = 0;
    this.amountToCommit = 0;
    this.hasVatApplied = false;
    this.isRefundedValid = false;
    this.flagForm = false;
    this.pinPadCards = [];
    this.pinPadCard = null;
    this.currencyList = []; // lista de tipos de cambio

    this.V_CreditCards = []; // lista de pagos registrados con tarjetas de credito
    this.cardsList = []; // lista de tarjetas
    this.V_Checks = []; // Lista de pagos registrados con cheques
    this.banksList = []; // lista de bancos

    this.CheckVatApplied();
    this.terminals = this.storageService.GetPPTerminalsbyUser();
    this.COMPANY = this.storageService.getCompanyConfiguration();

    let mDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.cashForm = this.fb.group({
      AccountCash: ["", Validators.required],
      TotalCash: [0, Validators.required],
    });

    this.transferForm = this.fb.group({
      AccountTransf: ["", Validators.required],
      DateTrans: [mDate, Validators.required],
      Ref: ["", Validators.required],
      TransSum: [0, Validators.required],
    });

    this.checkForm = this.fb.group({
      AccountCheck: ['', Validators.required],
      BankNames: ['', Validators.required],
      DueDate: ['', Validators.required],
      CountryCod: [{value: 'CR', disabled: true}, Validators.required],
      AcctNum: ['', Validators.required],
      CheckNum: ['', Validators.required],
      CheckSum: [0, Validators.required]
    });

    this.creditCardForm = this.fb.group({
      CreditCard: ["", Validators.required],
      CardNum: [""], // , [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      OwnerIdNum: ["", Validators.required],
      CardValid: [""], //, [Validators.required, Validators.minLength(3), Validators.maxLength(6)]
      CreditSum: [0, Validators.required],
      VoucherNum: ["", Validators.required],
      IsManualEntry: [true, Validators.required],
      Terminal: [""]
    });

    this.pointsForm = this.fb.group({
      TotalPoints: [0, Validators.required],
    });

    this.TotalRewards = 0;
  }


  OnPaymentCreated(payment: any): void {
    let closeDetail = {
      status: PaymentResults.Created,
      Payment: payment,
      Changes: {
        USD: this.changeDolares,
        COL: this.changeColones,
        Recived: this.ReceivedG
      },
      PinpadInfo: {
        pinPadCards: this.transactions,
        terminals: this.terminals,
        pPTransaction: this.pPTransaction
      },
      OnFail: this.CreateOnFailObject(),
      DismissReason: 'Ok'
    }


    this.modalService.dismissAll(closeDetail);
  }


  // Hace la peticion de pago al api local para uso del pin pad
  requestPinPadPayment(): void {
    if (this.flagForm)
    {
      console.log('Intento duplicación pago pinpad');

      return;
    }

    if (!this.creditCardForm.controls.Terminal.value) {
      this.alertService.infoInfoAlert(`Seleccione un terminal por favor`);
      return;
    }

    this.terminal = this.terminals.find(x => x.TerminalId === this.creditCardForm.controls.Terminal.value);

    if (!this.terminal)
    {
      this.alertService.infoInfoAlert(`No se puedo obtener el terminal, recargue la página por favor`);

      return;
    }

    if (!this.terminal || Object.keys(this.terminal).length === 0)
    {
      this.alertService.warningInfoAlert(`No existe un terminal configurado para el usuario`);

      this.isRequestinBacPayment = false;

      return;
    }

    const AMOUNT = +this.creditCardForm.value.CreditSum;

    if (AMOUNT <= 0)
    {
      this.alertService.infoInfoAlert(`El monto a cobrar debe ser superior a 0`);

      return;
    }

    let totalcard = 0;

    if (parseFloat(this.creditCardForm.value.CreditSum) <= 0)
    {
      this.creditCardForm.patchValue({CreditSum: 0});

      this.alertService.infoInfoAlert(
        "El monto total debe ser superior a cero."
      );

      return;
    }

    const PERCENTAGE_USED_FROM_MAX_AMOUNT = (this.creditCardForm.value.CreditSum) / this.maxAmountToCharge;

    if (PERCENTAGE_USED_FROM_MAX_AMOUNT > 1) {
      this.alertService.infoAlert(`El monto es superior al total del documento`);
      return;
    }

    const VAT_TO_REFUND = this.maxVatToRefunded * PERCENTAGE_USED_FROM_MAX_AMOUNT;

    if ((totalcard + VAT_TO_REFUND + this.creditCardForm.value.CreditSum + this.TotalCash + this.TotalTransfer) > this.TotalG) {
      this.alertService.infoInfoAlert("El el monto a cobrar con tarjeta supera el total de la factura. Monto a cobrar actualizado.");
      return;
    }

    if (this.V_CreditCards.length > 0)
    {
      this.V_CreditCards.forEach((vcc) => {
        totalcard += parseFloat(vcc.CreditSum.toString());
      });

      totalcard += parseFloat(this.creditCardForm.value.CreditSum);
    } else {
      totalcard = parseFloat(this.creditCardForm.value.CreditSum);
    }

    totalcard = Number(totalcard.toFixed(this.COMPANY.DecimalAmountTotalDocument));

    if (totalcard <= 0)
    {
      this.alertService.infoInfoAlert(
        "El monto ingresado en Total es incorrecto."
      );

      return;
    }

    if (!((totalcard + this.TotalTransfer + this.TotalCheck) <= this.TotalG))
    {
      this.creditCardForm.patchValue({ CreditSum: 0 });
      this.alertService.infoInfoAlert('El monto ingresado en la tarjeta de crédito supera el total de la factura.');
      return;
    }

    this.isRequestinBacPayment = true;

    if (this.currencyPayment !== this.terminal.Currency)
    {
      this.alertService.infoAlert(`El terminal sólo permite cobrar en ${this.terminal.Currency}`);
      return;
    }

    this.blockUI.start(`Solicitando pago`);

    this.flagForm = true;

    const CREDIT_CARD = this.cardsList.filter(x => x.CreditCard === this.creditCardForm.value.CreditCard)[0];

    let temp = this.creditCardForm.value.CardValid.split('-');

    let aux = temp[0][2] + temp[0][3] + temp[1];

    const INVOICE_NUMBER = this.BacIdGenerator();

    this.paymentService.requestPinPadPayment(AMOUNT,
      this.invoiceInfo.uniqueInvCode,
      this.currencyPayment,
      this.GeneratePreDocument(),
      INVOICE_NUMBER,
      JSON.parse(this.storageService.getCurrentSession()).UserName,
      this.terminal.TerminalId,
      CREDIT_CARD.CardName.slice(2),
      aux,
      this.terminal)
      .pipe(
        finalize(() => {
          this.flagForm = false;
          this.isRequestinBacPayment = false;
          this.blockUI.stop();
        })
      )
      .subscribe(next => {
        this.flagForm = false;
        try
        {
          if(next.Result)
          {
            const EMVS_STREAM = JSON.parse(next.Data)['EMVStreamResponse'];
            this.creditCardForm.patchValue({ 'CardNum': EMVS_STREAM['maskedCardNumber'].slice(-4) });
            this.creditCardForm.patchValue({ 'OwnerIdNum': EMVS_STREAM['authorizationNumber'] });
            this.creditCardForm.patchValue({ 'VoucherNum': EMVS_STREAM['referenceNumber'] });
            this.creditCardForm.patchValue({ 'IsManualEntry': false });

            this.setTotalAmount();

            const TRANSACTION = {
              InvoiceNumber: INVOICE_NUMBER,
              TerminalId: this.terminal.TerminalId,
              SerializedTransaction: next.Data,
              AuthorizationNumber: EMVS_STREAM['authorizationNumber'],
              ReferenceNumber: EMVS_STREAM['referenceNumber'],
              SystemTrace: EMVS_STREAM['systemTraceNumber'],
              TransactionId: EMVS_STREAM['transactionId'],
              CreationDate: formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en')
            } as ITransaction;

            this.transactions.push(TRANSACTION);

            this.TotalCards = totalcard;

            this.setTotalAmount();

            if (this.theoricalVAT > 0) this.CheckVatApplied();

            this.AddCreditCardAmount();

            this.alertService.successInfoAlert(`Transacción completada`);
          }
          else {
            this.creditCardForm.patchValue({ CreditSum: 0 });
            this.alertService.errorAlert(`${next.Error.Code} ${next.Error.Message}`);
          }
        }
        catch (error)
        {
          this.alertService.errorAlert(`${AppConstants.GetError(error)}`);
        }
      }, error => {
        this.alertService.errorAlert(`${AppConstants.GetError(error)}`);
      });
  }

  CreatePaymentObject() {

    let total = 0;
    total = this.total;

    const VAT_RETURNED = +(this.V_CreditCards.reduce((acc, creditCard) => {
      return acc + (creditCard.RefundedVAT || 0)
    }, 0)).toFixed(this.COMPANY.DecimalAmountTotalDocument);

    if (this.theoricalVAT > 0 && VAT_RETURNED > 0) {

      const VAT_CARD: CreditCards = this.storageService.GetVATCard();

      this.V_CreditCards.push({
        CardValid: VAT_CARD.CardValid,
        CreditAcct: VAT_CARD.CreditAcct,
        CreditCard: VAT_CARD.CreditCard,
        CreditCardNumber: VAT_CARD.CreditCardNumber,
        CreditSum: VAT_RETURNED,
        IsManualEntry: VAT_CARD.IsManualEntry,
        OwnerIdNum: VAT_CARD.OwnerIdNum,
        U_ManualEntry: VAT_CARD.U_ManualEntry,
        U_NumTarjeta: VAT_CARD.CreditCardNumber,
        VoucherNum: VAT_CARD.VoucherNum,
        RefundedVAT: VAT_CARD.RefundedVAT,
        FormatCode: VAT_CARD.FormatCode
      } as CreditCards);

    }

    // Agregar tarjeta de puntos
    if ((this.TotalPoints + this.TotalRewards) > 0) {
      this.V_CreditCards.push({
        CardValid: this.pointsSettings.Card.Valid,
        CreditCardNumber: this.pointsSettings.Card.Number,
        CreditCard: this.pointsSettings.Card.Id,
        CreditSum: (this.TotalPoints + this.TotalRewards),
        U_ManualEntry: '1',
        FormatCode: `${this.pointsSettings.Card.Id} ${this.pointsSettings.Card.Number}`,
        OwnerIdNum: this.pointsSettings.Card.Voucher,
        VoucherNum: this.pointsSettings.Card.Voucher,
        IsManualEntry: true,
        CreditAcct: this.pointsSettings.Card.Acct
      } as CreditCards);
    }


    let Payments: BasePayment = {
      CardCode: this.invoiceInfo.CardCode,
      CashAccount: this.cashForm.value.AccountCash,
      CashSum: Number(this.cashForm.value.TotalCash - this.ChangeG),
      Remarks: this.invoiceInfo.Comment,
      DocCurrency: this.currencyPayment,
      DocRate: this.currencyChange,
      Total: total,
      PaymentCreditCards: this.V_CreditCards,
      PaymentInvoices: [],
      TransferSum: Number(this.transferForm.value.TransSum),
      TransferAccount: this.transferForm.value.AccountTransf,
      TransferDate: this.transferForm.value.DateTrans,
      TransferReference: this.transferForm.value.Ref,
      accountPayment: this.invoiceInfo.accountPayment,
      DocDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      DueDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      Series: -1,
      DocType: BoRcptTypes.rCustomer,
      UDFs: [],
      CheckAccount: '',
      CounterReference: '',
      U_MontoRecibido: this.ReceivedG,
      PointsInvoiceAmount: (this.TotalPoints + this.TotalRewards) > 0 ? +(total - (this.TotalPoints + this.TotalRewards)) : total,
    };

    Payments['Transactions'] = this.transactions;

    Payments['PointsSum'] = this.pointsForm.value.TotalPoints + this.TotalRewards;

    if (this.availableTappRewards && this.availableTappRewards.length > 0) {
      Payments['SelectedRewards'] = this.availableTappRewards.filter(r => r.selected).map(r => r.id);
    }

    return Payments;
  }


  CreateOnFailObject(): IOnPaymentFail {

    return {
      cashForm: this.cashForm,
      checkForm: this.checkForm,
      creditCardForm: this.creditCardForm,
      transferForm: this.transferForm,
      Transactions: [...this.transactions],
      pinPadCards: this.pinPadCards,
      cards: this.V_CreditCards,
      ChangeG: this.ChangeG,
      DifferenceG: this.DifferenceG,
      ReceivedG: this.ReceivedG,
      TotalCards: this.TotalCards,
      TotalCash: this.TotalCash,
      TotalCheck: this.TotalCheck,
      TotalPoints: this.TotalPoints,
      TotalTransfer: this.TotalTransfer
    } as IOnPaymentFail;
  }

  //#region TAPP
  onCtrlBPoints(): void {
    let totalPointsAmount = (this.openTappInvoiceResponse.tapp_customer_points - this.TotalRewards) * this.openTappInvoiceResponse.tapp_exchange_rate_redemption.amount_destiny;
    let totalPointsApplied = (totalPointsAmount <= +this.DifferenceG.toFixed(this.COMPANY.DecimalAmountTotalDocument)) ? totalPointsAmount : this.DifferenceG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    // this.patchZeroes();
    this.pointsForm.patchValue({TotalPoints: totalPointsApplied});
    this.addAmountPoints();
  }

  addAmountPoints(): void {
    this.ReceivedG = +this.ReceivedG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalG = +this.TotalG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalPoints = parseFloat(this.pointsForm.value.TotalPoints);

    if (this.TotalPoints > 0 && this.TotalPoints <= ((this.openTappInvoiceResponse.tapp_customer_points - this.TotalRewards) * this.openTappInvoiceResponse.tapp_exchange_rate_redemption.amount_destiny) && this.TotalPoints <= this.TotalG) {
      this.setTotalAmount();
    } else {
      this.pointsForm.patchValue({TotalPoints: 0});
      this.TotalPoints = 0;
      this.setTotalAmount();
    }
  }
  //#endregion

  GeneratePreDocument(): string {

    const Payments = this.CreatePaymentObject();

    const JSONDocument = {
      'Invoice': this.preDocument,
      'Payment': {...Payments, V_PaymentLines: this.requiredData.PinpadInfo.PrePaymentLines}
    }

    return JSON.stringify(JSONDocument);
  }


  BacIdGenerator(): string {
    const DATE = new Date();

    const DAYS = DATE.getDate() < 10 ? '0' + DATE.getDate() : DATE.getDate().toString();
    const MONTS = (DATE.getMonth() + 1) < 10 ? '0' + (DATE.getMonth() + 1) : (DATE.getMonth() + 1).toString();
    const YEAR = DATE.getFullYear();


    const HOURS = DATE.getHours() < 10 ? '0' + DATE.getHours() : DATE.getHours();
    const MINUTES = DATE.getMinutes() < 10 ? '0' + DATE.getMinutes() : DATE.getMinutes();
    const SECONDS = DATE.getSeconds() < 10 ? '0' + DATE.getSeconds() : DATE.getSeconds();

    const DATE_ = new Date(Date.UTC(YEAR, +DAYS, +MONTS, +HOURS, +MINUTES, +SECONDS));

    const PRE_FIX = DATE_.getTime() / 1000;

    const SUB_FIX = this.terminal.Id < 10 ? '0' + this.terminal.Id : this.terminal.Id;

    return SUB_FIX + '' + PRE_FIX;
  }

  async CreatePay() {

    if (this.requiredCashAccount && this.TotalCash > 0 && this.cashForm.value.AccountCash == '') {
      this.alertService.infoAlert(`Por favor seleccione una cuenta de efectivo antes de enviar el pago`);
      return;
    }

    if (this.requiredCardAccount && this.TotalCards > 0 && this.creditCardForm.value.CreditCard == '') {
      this.alertService.infoAlert(`Por favor seleccione el nombre de la tarjeta antes de enviar el pago`);
      return;
    }

    if (this.requiredTransferAccount && this.TotalTransfer > 0 && this.transferForm.value.AccountTransf == '') {
      this.alertService.infoAlert(`Por favor seleccione una cuenta de transferencia antes de enviar el pago`);
      return;
    }

    if (this.requiredTransferRef && this.TotalTransfer > 0 && this.transferForm.value.Ref == '') {
      this.alertService.infoAlert(`Por favor ingrese una referencia de transferencia antes de enviar el pago`);
      return;
    }

    this.isBilling = true;

    let payOk = true;

    if (this.ReceivedG < this.TotalG) {
      payOk = await this.partialPayValidator();
    }

    if (payOk) {
      this.isPartialPayment = true;

      const Payments = this.CreatePaymentObject();

      this.blockUI.stop();

      this.OnPaymentCreated(Payments);
    } else {
      this.isBilling = false;
    }
  }

  async partialPayValidator(): Promise<boolean> {
    return await this.alertService.ConfirmationAlert(
      "Creación de pago parcial",
      "El monto del pago es menor al monto total, ¿Desea realizar el pago parcial?",
      "Continuar"
    );
  }

  ChangeTab(event): void {
    if (event.nextId == '1') {
      this.creditCardForm.patchValue({CreditSum: this.PreviewUnvattedAmount()});
    }
  }

  CheckVatApplied(): void {
    this.hasVatApplied = this.V_CreditCards.reduce((acc, creditCard) => {
      return acc + creditCard.RefundedVAT
    }, 0) > 0;
  }

  GetVatApplied(): number {
    return this.V_CreditCards.reduce((acc, creditCard) => {
      return acc + creditCard.RefundedVAT
    }, 0);
  }

  GetRefundeVATByCard(_vatRefunded: number): number {
    try {
      if (!_vatRefunded) return 0;

      return +_vatRefunded;
    } catch (error) {
      return 0;
    }
  }

  GetMinPaymentAmount(): number {
    return +(this.TotalG * (this.minCreditPayment / 100)).toFixed(this.COMPANY.DecimalAmountTotalDocument);
  }

  /**
   * Return the terminals of the user that match with the payment currency
   * @constructor
   */
  GetTerminalsByCurrency(): ITerminal[]
  {
    return this.terminals.filter(x => x.Currency == this.currencyPayment);
  }
}
