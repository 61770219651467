import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal, NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {IDelivery, IDeliveryEditLabOrderNum} from "../../../../models/i-delivery";
import {finalize} from "rxjs/operators";
import {IResponse} from "../../../../models/i-api-response";
import {IPermission} from "../../../../models";
import {of} from "rxjs";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {AlertService, PermsService} from "../../../../services";
import {DeliveryService} from "../../../../services/delivery.service";
import {DeliveryAuthorization, DeliveryErrorType} from "../../../../enum/enum";

@Component({
  selector: 'app-deliveries-edit',
  templateUrl: './deliveries-edit.component.html',
  styleUrls: ['./deliveries-edit.component.scss']
})
export class DeliveriesEditComponent implements OnInit {

  /**
   * Represents the data of the delivery that is being edited
   */
  @Input() deliveryDataToEdit: IDeliveryEditLabOrderNum;

  /**
   * Represents the data of the delivery that is being edited
   */
  @Input() authorizatorPermissions: IPermission[] = [];

  /**
   *Referencia a la modal de autenticacion
   */
  @ViewChild('authModal') authModal: ElementRef<HTMLInputElement>;
  authModalRef: NgbModalRef;

  @BlockUI() blockUI: NgBlockUI;

  /**
   * Represent the form in which the fields of the delivery in edition
   */
  deliveryEditForm: FormGroup;

  /**
   * Represents the form for the authorization credentials
   */
  userCredentialsForm: FormGroup;

  /**
   * Represents the reasons for editing the delivery
   */
  reasonsToEdit: {value: string; label: string}[] = [
    { value: 'ERR', label: 'Número erróneo' }, // ERR para 'error'
    { value: 'NEW', label: 'Nueva orden de laboratorio' } // NEW para 'nueva'
  ];

  /**
   * Represents the message that will be displayed in the authorization modal
   */
  authorizationModalMessage: string;

  constructor(private formBuilder: FormBuilder,
              private activeModal: NgbActiveModal,
              private modalService: NgbModal,
              private permissionService: PermsService,
              private alertService: AlertService,
              private deliveryService: DeliveryService,) { }

  ngOnInit() {
    this.deliveryDataToEdit.OldOrigLabOrderNumber = this.deliveryDataToEdit.U_OrigLabOrderNumber;
    this.CreateDeliveryEditForm();
    this.CreateUserCredentialsForm();
    this.LoadDeliveryData();
  }

  /**
   * The form is created with the fields to edit the delivery
   * @constructor
   */
  CreateDeliveryEditForm(){
    this.deliveryEditForm = this.formBuilder.group({
      U_DocNum: [{value: '', disabled: true}],
      BusinessPartner : [{value: '', disabled: true}],
      ReasonToEdit: ['', Validators.required],
      U_OrigLabOrderNumber: ['', Validators.required],
    });
  }

  /**
   * The form is created with the fields to perform the authentication
   * @constructor
   */
  CreateUserCredentialsForm(){
    this.userCredentialsForm = this.formBuilder.group({
      Email: [''],
      Password: ['']
    });
  }


  /**
   * Loads the data of the delivery to be edited in the form
   * @constructor
   */
  LoadDeliveryData(){
    //Se realiza esto devido a que hay registro en los que le U_CardName trae el cardCode
    const name = this.deliveryDataToEdit.U_CardName.includes(this.deliveryDataToEdit.U_CardCode) ?
                  this.deliveryDataToEdit.U_CardName : `${this.deliveryDataToEdit.U_CardCode} - ${this.deliveryDataToEdit.U_CardName}`;

    this.deliveryEditForm.patchValue({
      ...this.deliveryDataToEdit,
      BusinessPartner: name
    });
  }

  /**
   * Start the authorization process
   * @param _content
   * @constructor
   */
  RaiseAutenticationModal(_content): void {
    this.userCredentialsForm.patchValue({
      Email: [''],
      Password: ['']
    });

    this.authModalRef = this.modalService.open(_content, { centered: true, backdrop: "static" });
  }

  /**
   * Se valida si el usuario actual tiene el permiso para actualizar
   * @param _firstValidation
   * @constructor
   */
  ChangeAuthorization(_firstValidation = true): void {
      if(_firstValidation){
        this.authorizationModalMessage = `Es requerido un usuario autorizador para este proceso`;
      }else{
        this.authorizationModalMessage = `El usuario autorizador no tiene permiso para editar el número de orden de laboratorio`;
      }
      const permissionAssign = this.authorizatorPermissions.find(x => x.Name == 'Sales_Delivery_EditLabOrderNum');

      if(!permissionAssign){
        this.RaiseAutenticationModal(this.authModal);
      } else {
        this.UpdateLabOrderNum()
      }
  }

  /**
   * The user credentials are validated, and if they are correct, the update proceeds.
   * @constructor
   */
  ValidateCredentials(): void {
    const EMAIL = this.userCredentialsForm.controls.Email.value;
    const PASSWORD = this.userCredentialsForm.controls.Password.value;

    this.authorizatorPermissions = [];

    this.blockUI.start(`Procesando, espere por favor`);

    this.permissionService.AuthDeliveryToEditLabOrderNum(EMAIL, PASSWORD)
      .pipe(finalize(() => this.blockUI.stop()))
      .subscribe({
        next: (callback: IResponse<IPermission[]>) => {

          if (!callback || !callback.Result) {
            this.alertService.errorAlert(`${callback.Error.Code} - ${callback.Error.Message}`);
            return of(callback || []);
          }
          this.authorizatorPermissions = callback.Data || [];
          
          this.authModalRef.close();

          this.ChangeAuthorization(false)
        },
        error: error => {
          if (error && error.Error) {
            this.alertService.errorAlert(error.Error.Message);
          }
          else {
            this.alertService.errorAlert(JSON.stringify(error));
          }
        }
    });

  }

  /**
   * Envia a actualizar el numero de orden de laboratorio
   * @constructor
   */
  UpdateLabOrderNum(){
    this.blockUI.start(`Procesando, espere por favor`);
        const deliveryEditValues : IDeliveryEditLabOrderNum = {
      ...this.deliveryDataToEdit,
      ...this.deliveryEditForm.getRawValue(),
      AuthorizedUser: this.userCredentialsForm.controls['Email'].value,
    }

    this.deliveryService.UpdateDeliveryLabOrderNum(deliveryEditValues)
      .pipe(finalize(()=> this.blockUI.stop()))
      .subscribe({
        next: ((callback)=>{
          if (callback.Result) {
            this.Dismiss(true);
            this.alertService.infoAlert("Entrega actualizada");
          } else {
            this.alertService.errorInfoAlert('Error al actualizar la entrada - ' + callback.Error.Message);
          }
        }),
        error: ((err) =>{
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${err}`);
        })
      })
  }

  /**
   * Dismiss the current modal
   * @constructor
   */
  Dismiss(_searchDeliveries: boolean = false): void
  {
    this.activeModal.dismiss(_searchDeliveries);
  }

  /**
   * Close the authorization modal
   * @constructor
   */
  CloseAuthorizationModal(): void{
    this.authModalRef.dismiss('Cross click');
  }

}
