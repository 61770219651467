import {Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {formatDate} from "@angular/common";
import {IInventoryCounting, IInventoryCountingLine} from "../../../models/i-inventory-counting";
import {SelectionModalComponent} from "../../../components/selection-modal/selection-modal.component";
import {WarehouseService} from "../../../services/warehouse.service";
import {ISelectionModalData} from "../../../models/i-selection-modal-data";
import {IMinifiedWarehouse} from "../../../models/i-minified-warehouse";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AlertService, AuthenticationService, ItemService, PermsService} from "../../../services";
import {concatMap, finalize, map} from "rxjs/operators";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {IDocumentLine, IPermission} from "../../../models";
import {IBinLocation} from "../../../models/i-bin-location";
import {BinLocationsService} from "../../../services/bin-locations.service";
import {IItemWarehouseInfo, IMinifiedItemForInventoryCounting} from "../../../models/i-item";
import {EMPTY, forkJoin, from, of, Subject} from "rxjs";
import {MeasurementUnitsService} from "../../../services/measurement-units.service";
import {IBarcode} from "../../../models/i-barcode";
import {IMeasurementUnit} from "../../../models/i-measurement-unit";
import {InventoryCountingService} from "../../../services/inventory-counting.service";
import {ISession} from "../../../models/i-token";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-inventory-countings',
  templateUrl: './inventory-countings.component.html',
  styleUrls: ['./inventory-countings.component.scss']
})
export class InventoryCountingsComponent implements OnInit {

  @BlockUI() private blockUI: NgBlockUI;

  /**
   * Indicates what type is the search criteria of the items
   */
  searchItemsBy: 'barcode' | 'codeAndName' = 'codeAndName';

  /**
   * Reference to the fields in of the view
   */
  documentForm!: FormGroup;

  /**
   * Represent the lines that have the current document
   */
  documentLines: IInventoryCountingLine[];

  /**
   * Represent the warehouse selected in the document form
   */
  selectedWarehouse: IMinifiedWarehouse;

  /**
   * Indicates if some of the document lines was marked as counted and has variance
   */
  someDocumentLineIsCountedAndHasVariance: boolean = false;

  /**
   * Indicates if the application is requesting an item information
   */
  private isRequestingItem: boolean = false;

  /**
   * Emits a value when a item is scanned
   * @private
   */
  private onScanItem$: Subject<string>;

  /**
   * Represent the scanned code
   * @private
   */
  private scannedCode: string;

  /**
   * Current user session information
   */
  currentUser: ISession;

  /**
   * Indicates if the user has permission to access to this page
   */
  canAccessToView: boolean;

  /**
   * Represent the document that will be edited
   */
  preloadedDocument: IInventoryCounting | undefined;

  /**
   * Represent the preloaded document entry passed by params
   */
  preloadedDocumentEntry: number | undefined;

  /**
   * Indicates if the user is editing or creating a document
   */
  transactionMode: 'edit' | 'create' = 'create';

  /**
   * Indicates if the current document is closed
   */
  documentIsClosed: boolean = false;

  /**
   * Indicates if the user has permissions to edit/create a document
   */
  hasPermissionSaveDocument: boolean = false;

  /**
   * Indicates if the user can edit the document count date
   */
  hasPermissionsForEditCountDate: boolean = false;

  /**
   * Indicates if the user has permission to edit the document year end date
   */
  hasPermissionsForEditYearEndDate: boolean = false;

  /**
   * Represent the user actual permissions
   */
  userPermissions: IPermission[];

  constructor(private formBuilder: FormBuilder,
              private inventoryCountingService: InventoryCountingService,
              private alertService: AlertService,
              private measurementUnitService: MeasurementUnitsService,
              private modalController: NgbModal,
              private permissionService: PermsService,
              private authenticationService: AuthenticationService,
              private activatedRoute: ActivatedRoute,
              private warehouseService: WarehouseService,
              private itemService: ItemService,
              private binLocationsService: BinLocationsService) {
    this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.InitVariables();
    this.SendInitialRequests();
  }

  /**
   * Initialize the component variables
   * @constructor
   */
  private InitVariables(): void
  {
    this.canAccessToView = false;

    if(!this.activatedRoute.snapshot.paramMap.has('id'))
    {
      this.transactionMode = 'create';
    }
    else
    {
      this.transactionMode = 'edit';
      this.preloadedDocumentEntry = +this.activatedRoute.snapshot.paramMap.get('id');
    }

    this.scannedCode = '';

    this.onScanItem$ = new Subject<string>();

    let date = new Date();

    this.documentForm = this.formBuilder.group({
      Warehouse: [null],
      Reference2: [null],
      YearEndDate: [null],
      CountDate: [{year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}],
      CountDateTime: [formatDate(date, 'HH:mm', 'en')],
      Remarks: [null]
    });

    this.documentLines = [];

    this.ListenScan();
  }

  /**
   * Send the initial request to set up the component
   * @constructor
   */
  private SendInitialRequests(): void {
    this.blockUI.start("Cargando...");

    forkJoin([
      this.permissionService.getPerms(this.currentUser.userId),
      this.preloadedDocumentEntry ? this.inventoryCountingService.GetInventoryCounting(this.preloadedDocumentEntry) : of(null),
      this.preloadedDocumentEntry ? this.warehouseService.GetWarehousesForInventoryCounting('') : of(null),
    ])
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe(responses => {
        if (responses[0].Result) {
          this.userPermissions = responses[0].perms.filter(p => p.Active);

          this.canAccessToView = this.userPermissions.some(p => p.Name == 'Inventory_Countings_Access');

          this.hasPermissionSaveDocument = this.userPermissions.some(perm => (this.transactionMode == 'create' && perm.Name == 'Inventory_Countings_Create') || (this.transactionMode == 'edit' && perm.Name == 'Inventory_Countings_Edit'));

          this.hasPermissionsForEditYearEndDate = this.userPermissions.some(perm => perm.Name == 'Inventory_Countings_EditYearEndDate');

          this.hasPermissionsForEditCountDate = this.userPermissions.some(perm =>  perm.Name == 'Inventory_Countings_EditCountDate');

          this.ApplyPermissionsRestrictions();
        }

        if(responses[1])
        {
          this.preloadedDocument = responses[1].Data;
          this.MapPreloadedDocument(responses[2].Data.Data);
        }
      }, error => {
        this.alertService.ShowAlert("error", {
          text: error
        });

        console.error(error);
      });

  }

  /**
   * Apply the validations and restriction of the permissions
   * @constructor
   */
  ApplyPermissionsRestrictions(): void
  {
    if(!this.hasPermissionsForEditCountDate)
    {
      this.documentForm.get("CountDate").disable();
      this.documentForm.get("CountDateTime").disable();
    }

    if(!this.hasPermissionsForEditYearEndDate) this.documentForm.get("YearEndDate").disable();

    if(!this.hasPermissionSaveDocument)
    {
      this.documentForm.get("Reference2").disable();
      this.documentForm.get("Remarks").disable();
      this.documentForm.get("CountDate").disable();
      this.documentForm.get("CountDateTime").disable();
      this.documentForm.get("YearEndDate").disable();
    }
  }

  /**
   * Show the modal to select a warehouse
   * @param pDocumentLine Represent the line where the selected warehouse should be set, and indicates if the selection is for a line
   * @param pDocumentLineIndex Represent the line index in the array
   * @constructor
   */
  ShowWarehouseSelectionModal(pDocumentLine?: IInventoryCountingLine, pDocumentLineIndex?: number): void {
    const modal = this.modalController.open(SelectionModalComponent, {
      windowClass: 'width-fit-content',
      backdrop: 'static',
      size: 'lg'
    });

    modal.componentInstance.data = {
      ServiceToInject: WarehouseService,
      MethodToExecute: 'GetWarehousesForInventoryCounting',
      TableColumns: {
        WhsCode: "Código de almacén",
        WhsName: "Nombre de almacén"
      },
      UIPagination: true,
      PropsToMatchWithSearchCriteria: ["WhsCode", "WhsName"],
      ShowNumeration: true,
      PredefinedParametersToSend: ["searchCriteria"]
    } as ISelectionModalData;

    modal.result.then((selectedWarehouse: IMinifiedWarehouse) => {
      if (pDocumentLine) {
        this.ChangeDocumentLineWarehouse(pDocumentLine, pDocumentLineIndex, selectedWarehouse)
      } else {
        this.ChangeFormWarehouse(selectedWarehouse);
      }
    }, (reason) => {
    });
  }

  /**
   * Change the warehouse of the document form
   * @param pWarehouse Selected warehouse information
   * @constructor
   * @private
   */
  private ChangeFormWarehouse(pWarehouse: IMinifiedWarehouse): void {
    this.selectedWarehouse = pWarehouse;

    this.documentForm.get("Warehouse").patchValue(`${pWarehouse.WhsCode} - ${pWarehouse.WhsName}`);
  }

  /**
   * Change the warehouse of the document line
   * @param pDocumentLine Document line to be changed
   * @param pDocumentLineIndex Index of document line to be changed in the document lines array
   * @param pWarehouse Selected warehouse information
   * @constructor
   * @private
   */
  private ChangeDocumentLineWarehouse(pDocumentLine: IInventoryCountingLine, pDocumentLineIndex: number, pWarehouse: IMinifiedWarehouse): void {
    if (this.documentLines.some((line, index) => !pWarehouse.UseBinLocations && index != pDocumentLineIndex && line.ItemCode == pDocumentLine.ItemCode && line.WarehouseCode == pWarehouse.WhsCode)) {
      this.alertService.ShowAlert("info", {
        text: 'Ya existe un ítem con el mismo almacén en este documento.',
        confirmButtonText: 'Aceptar'
      });

      return;
    }
    const changeLineWarehouse = (pResetDocumentQuantities: boolean) => {
      pDocumentLine.SelectedWarehouse = pWarehouse;

      pDocumentLine.WarehouseCode = pWarehouse.WhsCode;

      pDocumentLine.SelectedBinLocation = undefined;

      pDocumentLine.BinEntry = undefined;

      if (!pWarehouse.UseBinLocations) {
        this.blockUI.start("Consultando información del almacén");

        this.itemService.GetItemWarehouseInfo(pDocumentLine.ItemCode, pWarehouse.WhsCode)
          .pipe(
            finalize(() => this.blockUI.stop())
          )
          .subscribe({
            next: (response) => {
              pDocumentLine.InWarehouseQuantity = response.Data.QuantityInStock;

              if (pResetDocumentQuantities) {
                this.ResetDocumentLineQuantities(pDocumentLine);
              } else {
                this.CalculateDocumentLineVariance(pDocumentLine);
              }
            },
            error: (error) => {
              this.alertService.errorInfoAlert(error);

              console.error(`An error occurs requesting the warehouse information. References Warehouse(${pWarehouse.WhsCode}), Item(${pDocumentLine.ItemCode})`);
            }
          });
      } else {
        if (pResetDocumentQuantities) {
          this.ResetDocumentLineQuantities(pDocumentLine);
        } else {
          this.CalculateDocumentLineVariance(pDocumentLine);
        }
      }
    }

    if (pDocumentLine.SelectedWarehouse && ((pDocumentLine.SelectedWarehouse.UseBinLocations && pDocumentLine.SelectedBinLocation) || !pDocumentLine.SelectedWarehouse.UseBinLocations)) {
      this.alertService.ShowAlert("question", {
        title: '¿Está seguro de que desea cambiar el almacén?',
        text: 'Al cambiar el almacén, todas las cantidades se restablecerán.',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      })
        .then(questionResult => {
          if (questionResult.value) {
            changeLineWarehouse(true);
          }
        });
    } else {
      changeLineWarehouse(false);
    }
  }

  /**
   * Set up the items selection modal
   * @constructor
   */
  ShowItemSelectionModal(): void {
    const modal = this.modalController.open(SelectionModalComponent, {
      windowClass: 'width-fit-content',
      backdrop: 'static',
      size: 'lg'
    });

    modal.componentInstance.data = {
      ServiceToInject: ItemService,
      MethodToExecute: 'GetItemsForInventoryCounting',
      TableColumns: {
        ItemCode: "Código de ítem",
        ItemName: "Nombre de ítem"
      },
      ShowNumeration: true,
      RequestExtraParameters: [this.searchItemsBy == 'barcode'],
      PredefinedParametersToSend: ['all']
    } as ISelectionModalData;

    modal.result.then((selectedItem: IMinifiedItemForInventoryCounting) => {
      this.InsertSelectedItemToDocumentLines(selectedItem);
    }, (reason) => {
    });
  }

  /**
   * Send the required request to retrieve the necessary information to add the item to document lines
   * @param pSelectedItem Information about selected item
   * @constructor
   * @private
   */
  private InsertSelectedItemToDocumentLines(pSelectedItem: IMinifiedItemForInventoryCounting): void {
    if (this.documentLines.some((line, index) => this.selectedWarehouse && !this.selectedWarehouse.UseBinLocations && line.ItemCode == pSelectedItem.ItemCode && line.WarehouseCode == this.selectedWarehouse.WhsCode)) {
      this.alertService.ShowAlert("info", {
        text: 'Ya existe un ítem con el mismo almacén en este documento.',
        confirmButtonText: 'Aceptar'
      });

      return;
    }

    this.blockUI.start();

    forkJoin([
      this.selectedWarehouse && !this.selectedWarehouse.UseBinLocations ? this.itemService.GetItemWarehouseInfo(pSelectedItem.ItemCode, this.selectedWarehouse.WhsCode) : of(null),
      this.itemService.GetItemMeasurementUnits(pSelectedItem.ItemCode),
      this.itemService.GetBarcodes(pSelectedItem.ItemCode)
    ])
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (responses) => {
          this.MapSelectedItemToDocumentLine(pSelectedItem, responses[0] ? responses[0].Data : null, responses[2].Data, responses[1].Data, false);
        },
        error: (error) => {
          this.alertService.errorInfoAlert(error);

          console.error(`An error occurs trying to retrieve the required information for the document line. References: Item(${pSelectedItem.ItemCode}), Warehouse(${this.selectedWarehouse.WhsCode})`, error);
        }
      });
  }

  /**
   * Show the modal to select a bin location
   * @param pDocumentLine Represent the line where the selected warehouse should be set
   * @param pDocumentLineIndex Represent the line index in the array
   * @constructor
   */
  ShowBinLocationModal(pDocumentLine: IInventoryCountingLine, pDocumentLineIndex: number): void {
    const modal = this.modalController.open(SelectionModalComponent, {
      windowClass: 'width-fit-content',
      backdrop: 'static',
      size: 'lg'
    });

    modal.componentInstance.data = {
      ServiceToInject: BinLocationsService,
      MethodToExecute: 'Get',
      TableColumns: {
        AbsEntry: "ID",
        BinCode: "Ubicación",
        Quantity: 'Cantidad'
      },
      UIPagination: true,
      PropsToMatchWithSearchCriteria: ["BinCode", "AbsEntry"],
      ShowNumeration: true,
      RequestExtraParameters: [pDocumentLine.ItemCode, pDocumentLine.WarehouseCode],
      PredefinedParametersToSend: []
    } as ISelectionModalData;

    modal.result.then((selectedBinLocation: IBinLocation) => {
      this.ChangeDocumentLineBinLocation(pDocumentLine, pDocumentLineIndex, selectedBinLocation);
    }, (reason) => {
    });
  }

  /**
   * Change the bin location of the specified document line
   * @param pDocumentLine Document line to be changed
   * @param pDocumentLineIndex Index of the document line to be changed in the document lines array
   * @param pSelectedBinLocation Selected bin location
   * @constructor
   * @private
   */
  private ChangeDocumentLineBinLocation(pDocumentLine: IInventoryCountingLine, pDocumentLineIndex: number, pSelectedBinLocation: IBinLocation): void {
    if (this.documentLines.some((line, index) => index != pDocumentLineIndex && pDocumentLine.ItemCode == line.ItemCode && line.WarehouseCode == pDocumentLine.WarehouseCode && line.BinEntry == pSelectedBinLocation.AbsEntry)) {
      this.alertService.ShowAlert("info", {
        text: 'Ya existe una línea en este documento que utiliza la misma ubicación.',
        confirmButtonText: 'Aceptar'
      });

      return;
    }
    const changeLineBinLocation = (pResetQuantities: boolean) => {
      pDocumentLine.SelectedBinLocation = pSelectedBinLocation;
      pDocumentLine.BinEntry = pSelectedBinLocation.AbsEntry;
      pDocumentLine.InWarehouseQuantity = pSelectedBinLocation.Quantity;

      if (pResetQuantities) {
        this.ResetDocumentLineQuantities(pDocumentLine);
      } else {
        this.CalculateDocumentLineVariance(pDocumentLine);
      }
    };

    if (pDocumentLine.SelectedBinLocation) {
      this.alertService.ShowAlert("question", {
        title: '¿Está seguro de que desea cambiar la ubicación?',
        text: 'Al cambiar la ubicación, todas las cantidades se restablecerán.',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      })
        .then((questionResult) => {
          if (questionResult.value) {
            changeLineBinLocation(true);
          }
        });
    } else {
      changeLineBinLocation(false);
    }
  }

  /**
   * Reset the quantities of the document line specified
   * @param pDocumentLine Document line to be changed
   * @constructor
   * @private
   */
  private ResetDocumentLineQuantities(pDocumentLine: IInventoryCountingLine): void {
    pDocumentLine.CountedQuantity = 0;
    pDocumentLine.UoMCountedQuantity = 0;
    pDocumentLine.Counted = 'tNO';
    this.CalculateDocumentLineVariance(pDocumentLine);
  }

  /**
   * Calculate the variance of the document line
   * @param pDocumentLine Information about the document line to be changed
   * @constructor
   * @private
   */
  private CalculateDocumentLineVariance(pDocumentLine: IInventoryCountingLine): void {
    if (!pDocumentLine.SelectedWarehouse) {
      this.alertService.infoInfoAlert('Debe seleccionar un almacén.');

      return;
    }

    if (pDocumentLine.SelectedWarehouse.UseBinLocations && !pDocumentLine.SelectedBinLocation) {
      this.alertService.infoInfoAlert('Debe seleccionar una ubicación.');

      return;
    }

    if (pDocumentLine.Counted == 'tYES')
    {
      pDocumentLine.Variance = -(pDocumentLine.InWarehouseQuantity - pDocumentLine.CountedQuantity);
    }
    else
    {
      pDocumentLine.Variance = 0;
    }

    this.someDocumentLineIsCountedAndHasVariance = this.documentLines.some(l => l.Variance != 0 && l.Counted === 'tYES');
  }

  /**
   * Show the modal to select a measurement units
   * @param pDocumentLine Represent the line where the selected warehouse should be set
   * @constructor
   */
  ShowMeasurementUnitsModal(pDocumentLine: IInventoryCountingLine): void {
    const modal = this.modalController.open(SelectionModalComponent, {
      windowClass: 'width-fit-content',
      backdrop: 'static',
      size: 'lg'
    });

    modal.componentInstance.data = {
      ServiceToInject: MeasurementUnitsService,
      MethodToExecute: 'Get',
      TableColumns: {
        UoMGroupName: "Grupo",
        MUnitName: "Unidad de medida"
      },
      UIPagination: true,
      PropsToMatchWithSearchCriteria: ["UoMGroupName", "MUnitName", "MUnitCode", "MUnitEntry"],
      ShowNumeration: true,
      RequestExtraParameters: [pDocumentLine.ItemCode],
      PredefinedParametersToSend: []
    } as ISelectionModalData;


    modal.result.then((selectedMeasurementUnit: IMeasurementUnit) => {
      this.ChangeDocumentLineMeasurementUnit(pDocumentLine, selectedMeasurementUnit);
    }, (reason) => {
    });
  }

  /**
   * Change the measurement unit of the document line and calculate the quantities
   * @param pDocumentLine Document line to be changed
   * @param pSelectedMeasurementUnit New measurement unit of the document line
   * @constructor
   * @private
   */
  private ChangeDocumentLineMeasurementUnit(pDocumentLine: IInventoryCountingLine, pSelectedMeasurementUnit: IMeasurementUnit): void {
    pDocumentLine.UoMCode = pSelectedMeasurementUnit.MUnitCode;

    pDocumentLine.SelectedMeasureUnit = pSelectedMeasurementUnit;
  }

  /**
   * Calculates the quantities of the document line based on measurement unit
   * @param pDocumentLine Document line to be changed
   * @constructor
   * @private
   */
  private CalculateDocumentLineMeasurementUnitQuantities(pDocumentLine: IInventoryCountingLine): void {
    pDocumentLine.CountedQuantity = (pDocumentLine.UoMCountedQuantity / pDocumentLine.SelectedMeasureUnit.AltQuantity) * pDocumentLine.SelectedMeasureUnit.BaseQuantity;

    this.CalculateDocumentLineVariance(pDocumentLine);
  }

  /**
   * Map the values required for the document line to a document line
   * @param pSelectedItemInfo Information about the selected item
   * @param pWarehouseInfo Information about quantities of the warehouse
   * @param pBarcodes Barcodes of the item
   * @param pMeasurementUnits Measurement units of the item
   * @param pWasScanned Indicates if the item was scanned
   * @constructor
   */
  private MapSelectedItemToDocumentLine(pSelectedItemInfo: IMinifiedItemForInventoryCounting, pWarehouseInfo: IItemWarehouseInfo, pBarcodes: IBarcode[], pMeasurementUnits: IMeasurementUnit[], pWasScanned: boolean): void {
    let documentLine = {} as IInventoryCountingLine;

    documentLine.WarehouseCode = "";

    if (this.selectedWarehouse) {
      documentLine.SelectedWarehouse = {...this.selectedWarehouse};

      documentLine.WarehouseCode = this.selectedWarehouse.WhsCode;
    }

    documentLine.UoMCode = '';

    documentLine.LineNumber = Math.max(...[...this.documentLines.map(ln => ln.LineNumber), 0]) + 1;

    documentLine.LineStatus = 'clsOpen';

    documentLine.ItemCode = pSelectedItemInfo.ItemCode;

    documentLine.ItemDescription = pSelectedItemInfo.ItemName;

    documentLine.CountedQuantity = 0;

    documentLine.BarCode = pSelectedItemInfo.BarCode;

    documentLine.UoMCountedQuantity = 0;

    documentLine.InWarehouseQuantity = 0;

    documentLine.Variance = 0;

    documentLine.Counted = 'tNO';

    if (pWarehouseInfo) {
      documentLine.InWarehouseQuantity = pWarehouseInfo.QuantityInStock;
    }

    documentLine.HasMeasurementUnits =  pMeasurementUnits && (pMeasurementUnits.length > 1 || pMeasurementUnits[0].MUnitEntry != -1);

    if (pMeasurementUnits && pMeasurementUnits.length == 1 && pMeasurementUnits[0].UoMGroupEntry == -1)
    {
      this.ChangeDocumentLineMeasurementUnit(documentLine, pMeasurementUnits[0]);
    }
    else if(pSelectedItemInfo.UomEntry)
    {
      let measurementUnit = pMeasurementUnits.find(uom => uom.MUnitEntry == pSelectedItemInfo.UomEntry);

      this.ChangeDocumentLineMeasurementUnit(documentLine, measurementUnit);
    }

    if(pWasScanned)
    {
      if (pMeasurementUnits && pSelectedItemInfo.UomEntry)
      {
        let measurementUnit = pMeasurementUnits.find(uom => uom.MUnitEntry == pSelectedItemInfo.UomEntry);

        if (measurementUnit && measurementUnit.MUnitEntry != -1)
        {
          this.ChangeDocumentLineMeasurementUnit(documentLine, measurementUnit);

          documentLine.UoMCountedQuantity = 1;

          documentLine.Counted = 'tYES';

          this.CalculateDocumentLineMeasurementUnitQuantities(documentLine);
        }
        else
        {
          documentLine.CountedQuantity += 1;
        }
      }
      else
      {
        documentLine.CountedQuantity += 1;
      }
    }

    this.documentLines.push(documentLine);
  }

  /**
   * Toggle the document line counted property
   * @param pDocumentLine Document line to be change
   * @constructor
   */
  OnMarkDocumentLineAsCounted(pDocumentLine: IInventoryCountingLine): void {
    pDocumentLine.Counted = pDocumentLine.Counted == 'tYES' ? 'tNO' : 'tYES';

    this.CalculateDocumentLineVariance(pDocumentLine);
  }

  /**
   * Change event handler for the input counted quantity
   * @param pDocumentLine Document line to be change
   * @param pEvent Change event information
   * @constructor
   */
  OnChangeCountedQuantity(pEvent: any, pDocumentLine: IInventoryCountingLine): void {
    pDocumentLine.CountedQuantity = pEvent.target.value;

    pDocumentLine.Counted = 'tYES';

    this.CalculateDocumentLineVariance(pDocumentLine);
  }

  /**
   * Change event handler for the input UoM counted quantity
   * @param pDocumentLine Document line to be change
   * @param pEvent Change event information
   * @constructor
   */
  OnChangeUoMCountedQuantity(pEvent: any, pDocumentLine: IInventoryCountingLine): void {
    pDocumentLine.UoMCountedQuantity = pEvent.target.value;

    pDocumentLine.Counted = 'tYES';

    this.CalculateDocumentLineMeasurementUnitQuantities(pDocumentLine)
  }

  /**
   * Remove the specified document line from the document lines array
   * @param pDocumentLineIndex Index of the document line to remove
   * @constructor
   */
  RemoveDocumentLine(pDocumentLineIndex: number): void {
    if (pDocumentLineIndex < 0) return;

    this.alertService.ShowAlert("question", {
      title: '¿Está seguro de que desea eliminar la línea?',
      text: 'Si elimina la línea, tendrá que configurarla nuevamente, ya que la información se perderá.',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar'
    })
      .then(questionResult => {
        if (questionResult.value) {
          this.documentLines.splice(pDocumentLineIndex, 1);

          this.someDocumentLineIsCountedAndHasVariance = this.documentLines.some(l => l.Variance != 0 && l.Counted === 'tYES');
        }
      });
  }

  /**
   * Build the inventory counting document and send a request to create it
   * @constructor
   */
  SaveDocument(): void {
    if (!this.DocumentLinesValidations())
    {
      return;
    }

    let inventoryCountingDocument = this.BuildInventoryCountingDocument();

    this.blockUI.start("Procesando...");

    if(this.transactionMode === 'create')
    {
      this.inventoryCountingService.Post(inventoryCountingDocument)
        .pipe(
          finalize(() => this.blockUI.stop())
        )
        .subscribe({
          next: (response) => {
            this.alertService.ShowAlert("success", {
              title: 'Creación de documento',
              text: 'Documento creado exitosamente'
            }, {
              'Número de documento': response.Data ? response.Data.DocumentNumber.toString() : undefined
            });

            this.CleanFields(false);
          },
          error: (error) => {
            this.alertService.ShowAlert("error", {
              title: 'Creación de documento',
              text: error
            });
          }
        });
    }
    else
    {
      this.inventoryCountingService.Patch(inventoryCountingDocument)
        .pipe(
          finalize(() => this.blockUI.stop())
        )
        .subscribe({
          next: (response) => {
            this.alertService.ShowAlert("success", {
              title: 'Actualización de documento',
              text: 'Documento actualizado exitosamente'
            }, {
              'Número de documento': this.preloadedDocument!.DocumentNumber.toString()
            });

            this.CleanFields(false);
          },
          error: (error) => {
            this.alertService.ShowAlert("error", {
              title: 'Actualización de documento',
              text: error
            });
          }
        })
    }
  }

  /**
   * Build the inventory document taking the form values and the lines
   * @constructor
   * @private
   */
  private BuildInventoryCountingDocument(): IInventoryCounting {
    let {Reference2, YearEndDate, CountDate, Remarks, CountDateTime} = this.documentForm.getRawValue();

    let countDate = new Date(CountDate.year, CountDate.month - 1, CountDate.day);

    let yearEndDate = YearEndDate ? new Date(YearEndDate.year, YearEndDate.month - 1, YearEndDate.day) : undefined;

    if(this.transactionMode == 'create')
    {
      return {
        CountTime: CountDateTime,
        Remarks,
        YearEndDate: yearEndDate ? formatDate(yearEndDate, 'yyyy-MM-dd', 'en') : undefined,
        Reference2,
        CountDate: formatDate(countDate, 'yyyy-MM-dd', 'en'),
        InventoryCountingLines: [...this.documentLines]
      } as IInventoryCounting;
    }

    return {
      DocumentNumber: this.preloadedDocument!.DocumentNumber,
      DocumentEntry: this.preloadedDocument!.DocumentEntry,
      Series: this.preloadedDocument!.Series,
      CountDate: formatDate(countDate, 'yyyy-MM-dd', 'en'),
      CountTime: CountDateTime,
      YearEndDate: yearEndDate ? formatDate(yearEndDate, 'yyyy-MM-dd', 'en') : undefined,
      Reference2,
      Remarks,
      InventoryCountingLines: this.documentLines
    } as IInventoryCounting;
  }

  /**
   * Set up an action sheet with the lines that don't have all information
   * @constructor
   */
  private DocumentLinesValidations(): boolean {
    if (!this.documentLines.length) {
      this.alertService.infoInfoAlert("Debe agregar almenos una línea al documento.");

      return false;
    }

    if (!this.documentLines.some(ln => ln.LineStatus == 'clsOpen')) {
      this.alertService.infoInfoAlert('Todas las lineas del documento estan cerradas');

      return false;
    }

    let isAllCorrect = true;

    for (let documentLine of this.documentLines) {
      if (documentLine.LineStatus == 'clsClosed') continue;

      let pendingInformation: string[] = [];

      if ((!documentLine.IsPreloaded && !documentLine.SelectedWarehouse) || (documentLine.IsPreloaded && !documentLine.WarehouseCode)) {
        pendingInformation.push('almacén');
      } else if (!documentLine.IsPreloaded && (documentLine.SelectedWarehouse && documentLine.SelectedWarehouse.UseBinLocations) && !documentLine.BinEntry) {
        pendingInformation.push('ubicación');
      }

      if (documentLine.HasMeasurementUnits && !documentLine.UoMCode) {
        pendingInformation.push('unidad de medida');
      }

      if (pendingInformation.length) {
        this.alertService.ShowAlert("info", {
          title: 'Líneas con información pendiente',
          text: `La línea #${documentLine.LineNumber} no posee la siguiente información: ${pendingInformation.join(', ')}`
        });

        isAllCorrect = false;

        break;
      }
    }

    return isAllCorrect;
  }

  /**
   * Send a request to retrieve the item by it's barcode, if the items is already added try to increment the quantity
   * @constructor
   */
  ListenScan(): void {
    this.onScanItem$
      .pipe(
        map(scannedCode => {
          this.isRequestingItem = true;

          this.blockUI.start();

          return scannedCode;
        }),
        concatMap(scannedCode => {
          if(this.SumIfItemExistInDocumentLines(scannedCode))
          {
            this.isRequestingItem = false;

            this.blockUI.stop();

            return EMPTY;
          }

          return of(scannedCode);
        }),
        concatMap(scannedCode => {
          return this.itemService.GetItemByBarcode(scannedCode);
        }),
        concatMap(itemResponse => {
          if (!itemResponse.Data) {
            return of(null);
          }

          return forkJoin([
            this.itemService.GetItemMeasurementUnits(itemResponse.Data.ItemCode),
            this.itemService.GetBarcodes(itemResponse.Data.ItemCode),
            this.selectedWarehouse && !this.selectedWarehouse.UseBinLocations ? this.itemService.GetItemWarehouseInfo(itemResponse.Data.ItemCode, this.selectedWarehouse.WhsCode) : of(null)
          ]).pipe(
            map(requiredInformationResponses => ({
              MeasurementUnits: requiredInformationResponses[0].Data,
              Barcodes: requiredInformationResponses[1].Data,
              WarehouseInformation: requiredInformationResponses[2] ? requiredInformationResponses[2].Data : null,
              Item: itemResponse.Data,
              WasScanned: true
            })),
            finalize(() => {
              this.blockUI.stop();
              this.isRequestingItem = false;
              this.scannedCode = '';
            })
          )
        })
      )
      .subscribe({
        next: (requiredInformation) => {
          if (!requiredInformation)
          {
            this.alertService.ShowAlert("info", {
              text: "No se encontró el artículo."
            });
          }
          else
          {
            this.MapSelectedItemToDocumentLine(requiredInformation.Item, requiredInformation.WarehouseInformation, requiredInformation.Barcodes, requiredInformation.MeasurementUnits, requiredInformation.WasScanned);
          }

          this.isRequestingItem = false;

          this.blockUI.stop();

        },
        error: (err) => {
          this.alertService.ShowAlert("error", {
            title: err
          });

          this.isRequestingItem = false;

          this.blockUI.stop();
        }
      });
  }

  /**
   * Sum one unit if the item already exist in the document lines
   * @constructor
   */
  SumIfItemExistInDocumentLines(pScannedCode: string): boolean {
    const incrementQuantities = (pDocumentLine: IInventoryCountingLine) => {
      if(pDocumentLine.HasMeasurementUnits)
      {
        if(!pDocumentLine.UoMCode)
        {
          setTimeout(() => {
            this.alertService.ShowAlert("info", {
              text: "Por favor, agregue una unidad de medida a la línea del artículo que ha ingresado manualmente antes de escanearlo.",
              title: 'Linea sin unidad de medida'
            });
          }, 0)
        }
        else
        {
          if (pDocumentLine.SelectedMeasureUnit && pDocumentLine.SelectedMeasureUnit.MUnitEntry != -1)
          {
            pDocumentLine.UoMCountedQuantity! += 1;

            this.CalculateDocumentLineMeasurementUnitQuantities(pDocumentLine)
          }
          else
          {
            pDocumentLine.CountedQuantity += 1;
          }
        }
      }
      else
      {
        pDocumentLine.CountedQuantity += 1;
      }

      pDocumentLine.WasScanned = true;

      this.scannedCode = '';

      return true;
    }

    let documentLineByItemCode = this.documentLines.slice().reverse().find(dl => dl.ItemCode == pScannedCode);

    if (documentLineByItemCode) {
      return incrementQuantities(documentLineByItemCode);
    }

    let documentLineByBarCode = this.documentLines.slice().reverse().find(dl => dl.BarCode == pScannedCode);

    if (documentLineByBarCode) {
      return incrementQuantities(documentLineByBarCode);
    }

    return false;
  }

  /**
   * Listen the window keydown event to get the inputs of the scanner
   * @param _event The keyboard events that scanner emits
   * @constructor
   */
  @HostListener('window:keydown', ['$event'])
  async HandleScannerInput(_event: KeyboardEvent): Promise<void>
  {
    if (this.isRequestingItem || event.target instanceof HTMLInputElement) return;

    if (_event.key == 'Enter')
    {
      this.onScanItem$.next(this.scannedCode);

      this.scannedCode = '';
    }
    else if (_event.key.length == 1)
    {
      this.scannedCode += _event.key;
    }

    // If the user write and don't press enter
    //
    setTimeout(() => {
      this.scannedCode = '';
    }, 1500);
  }

  /**
   * Reset the fields and lines
   * @constructor
   */
  CleanFields(_ask: boolean = true): void {
    const cleanFields = () => {
      this.someDocumentLineIsCountedAndHasVariance = false;

      this.documentLines = [];

      let date = new Date();

      this.documentForm = this.formBuilder.group({
        Warehouse: [null],
        Reference2: [null],
        YearEndDate: [null],
        CountDate: [{year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()}],
        CountDateTime: [formatDate(date, 'HH:mm', 'en')],
        Remarks: [null]
      });

      this.preloadedDocument = undefined;

      this.selectedWarehouse = undefined;

      this.transactionMode = 'create';

      this.hasPermissionSaveDocument = this.userPermissions.some(perm => (this.transactionMode == 'create' && perm.Name == 'Inventory_Countings_Create') || (this.transactionMode == 'edit' && perm.Name == 'Inventory_Countings_Edit'));

      this.documentIsClosed = false;

      if (this.hasPermissionsForEditCountDate) this.documentForm.get("CountDate").enable();

      if (this.hasPermissionsForEditYearEndDate) this.documentForm.get("YearEndDate").enable();

      if (this.hasPermissionSaveDocument) {
        this.documentForm.get("Reference2").enable();
        this.documentForm.get("Remarks").enable();
        this.documentForm.get("Warehouse").enable();
      }
    };

    if (_ask && this.hasPermissionSaveDocument && !this.documentIsClosed) {
      this.alertService.ShowAlert("question", {
        title: '¿Desea cancelar el documento?',
        text: 'Al cancelar el documento, todos los campos se restablecerán.',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar'
      })
        .then(questionResponse => {
          if (questionResponse.value) {
            cleanFields();
          }
        });
    } else {
      cleanFields();
    }
  }

  /**
   * Map the preloaded document to the used variables
   * @param pWarehouseInformation Information of the warehouses
   * @constructor
   */
  MapPreloadedDocument(pWarehouseInformation: IMinifiedWarehouse[]): void
  {
    if(!this.preloadedDocument) return;

    this.documentIsClosed = this.preloadedDocument.DocumentStatus == 'cdsClosed';

    if(this.documentIsClosed)
    {
      this.documentForm.get("CountDate").disable();
      this.documentForm.get("YearEndDate").disable();
      this.documentForm.get("Reference2").disable();
      this.documentForm.get("Remarks").disable();
      this.documentForm.get("Warehouse").disable();
    }

    let countDate = new Date(this.preloadedDocument.CountDate);

    let formCountDate = {
      year: countDate.getFullYear(),
      month: countDate.getMonth() + 1,
      day: countDate.getDate()
    };

    if(this.preloadedDocument.YearEndDate)
    {
      let yearEndDate = new Date(this.preloadedDocument.YearEndDate);

      let formYearEndDate = {
        year: countDate.getFullYear(),
        month: countDate.getMonth() + 1,
        day: countDate.getDate()
      };

      this.documentForm.get("YearEndDate").patchValue(formYearEndDate);
    }

    this.documentForm.patchValue({
      Reference2: this.preloadedDocument.Reference2,
      CountDate: formCountDate,
      CountDateTime: this.preloadedDocument.CountTime,
      Remarks: this.preloadedDocument.Remarks,
      Warehouse: null
    });

    this.documentLines = this.preloadedDocument.InventoryCountingLines.map(line => ({
      ...line,
      SelectedWarehouse: pWarehouseInformation.find(w => w.WhsCode === line.WarehouseCode),
      SelectedMeasureUnit: {
        MUnitName: line.UoMName,
        MUnitCode: line.UoMCode
      } as IMeasurementUnit,
      SelectedBinLocation: {
        BinCode: line.BinCode,
        Quantity: line.InWarehouseQuantity,
        AbsEntry: line.BinEntry
      },
      IsPreloaded: true,
      WasScanned: false,
      Variance: line.Counted == 'tYES' ? -(line.InWarehouseQuantity - line.CountedQuantity) : 0
    }));
  }
}
