import {Component, Input, OnInit} from '@angular/core';
import {InventoryTransferRequestsService} from "../../../../services/inventory-transfer-requests.service";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {finalize} from "rxjs/operators";
import {AlertService, ReportsService} from "../../../../services";
import * as printJS from "print-js";
import {ReportType} from "../../../../enum/enum";
import {IMinifiedStockTransfer} from "../../../../models/i-inventory-transfer-request";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-stock-transfer-list',
  templateUrl: './stock-transfer-list.component.html',
  styleUrls: ['./stock-transfer-list.component.scss']
})
export class StockTransferListComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  @Input() transferRequestDocEntry: number;

  stockTransfers: IMinifiedStockTransfer[] = [];

  filteredStockTransfers: IMinifiedStockTransfer[] = [];

  stockTransferTablePage: number = 1;
  constructor(private inventoryTransferRequestService: InventoryTransferRequestsService,
              private alertService: AlertService,
              private activeModal: NgbActiveModal,
              private reportsService: ReportsService) { }

  ngOnInit() {
    this.SendInitialRequest();
  }

  /**
   * Send the initial requests to set up the component
   * @constructor
   */
  SendInitialRequest(): void
  {
    this.blockUI.start();

    this.inventoryTransferRequestService.GetStockTransfersOfTransferRequest(this.transferRequestDocEntry)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (response) => {
          this.stockTransfers = response.Data;
          this.filteredStockTransfers = this.PaginateStockTransfers();
        },
        error: (error) => {
          console.error(error);

          this.alertService.ShowAlert("error", {
            text: error
          });
        }
      });
  }

  /**
   * Handle the page change event
   * @constructor
   */
  HandleTablePageChange(): void
  {
    this.filteredStockTransfers = this.PaginateStockTransfers();
  }

  private PaginateStockTransfers(): IMinifiedStockTransfer[]
  {
    let skip = 10 * (this.stockTransferTablePage - 1);

    return this.stockTransfers.slice(skip, skip + 10);
  }

  /**
   * Print the specified stock transfer
   * @param pDocEntry Unique identifier of the stock transfer
   * @constructor
   */
  PrintStockTransfer(pDocEntry: number): void
  {
    this.blockUI.start();

    this.reportsService.printReport(pDocEntry, ReportType.StockTransfer)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (response) => {
          printJS({
            printable: response.Data,
            type: 'pdf',
            base64: true
          });
        },
        error: (error) => {
          this.alertService.ShowAlert("error", {
            text: error,
            title: 'Impresión de reportes'
          });

          console.error(error);
        }
      })
  }

  /**
   * Dismiss the modal without any result
   * @constructor
   */
  DismissModal(): void
  {
    this.activeModal.dismiss();
  }
}
