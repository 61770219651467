import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {formatDate} from "@angular/common";
import {IInventoryCounting} from "../models/i-inventory-counting";
import {IResponse} from "../models/i-api-response";
import {IBaseResponse} from "../models/responses";

@Injectable({
  providedIn: 'root'
})
export class InventoryCountingService {

  constructor(private http: HttpClient) { }

  /**
   * Send a request to create the inventory counting document
   * @param _document The document information
   * @constructor
   */
  Post(_document: IInventoryCounting): Observable<IResponse<IInventoryCounting>>
  {
    return this.http.post<IResponse<IInventoryCounting>>('api/InventoryCountings', _document);
  }

  /**
   * Send a request to update the inventory counting document
   * @param _document The document information
   * @constructor
   */
  Patch(_document: IInventoryCounting): Observable<IBaseResponse>
  {
    return this.http.patch<IBaseResponse>('api/InventoryCountings', _document);
  }

  /**
   * Send a request to retrieve the minified inventory countings in a date range or by document number
   * @param pDocNum Document number (Priority)
   * @param pDateFrom Start date for documents creation
   * @param pDateTo End date for documents creation
   * @param pDocumentStatus Represent the status of the documents that will be retrieved
   * @constructor
   */
  GetInventoryCountings(pDocNum: number, pDateFrom: Date, pDateTo: Date, pDocumentStatus: 'all' | 'cdsOpen' | 'cdsClosed'): Observable<IResponse<IInventoryCounting[]>>
  {
    return this.http.get<IResponse<IInventoryCounting[]>>('api/InventoryCountings', {
      params: {
        docNum: pDocNum.toString(),
        dateFrom: formatDate(pDateFrom, 'yyyy-MM-dd', 'en'),
        dateTo: formatDate(pDateTo, 'yyyy-MM-dd', 'en'),
        documentStatus: pDocumentStatus
      }
    });
  }

  /**
   * Retrieve an inventory counting document by its document entry
   * @param _docEntry Document entry to search the inventory counting
   * @constructor
   */
  GetInventoryCounting(_docEntry: number): Observable<IResponse<IInventoryCounting>>
  {
    return this.http.get<IResponse<IInventoryCounting>>('api/InventoryCountings', {
      params: {
        docEntry: _docEntry.toString()
      }
    })
  }
}
