import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IHeadquarter } from 'src/app/models/i-headquarter';
import { AlertService, StorageService } from 'src/app/services';
import { HeadquarterService } from 'src/app/services/headquarter.service';

@Component({
  selector: 'app-headquarter-selector',
  templateUrl: './headquarter-selector.component.html',
  styleUrls: ['./headquarter-selector.component.scss']
})
export class HeadquarterSelectorComponent implements OnInit {
  //VARBOX
  currentHeadquarter: IHeadquarter;
  headquarters$: Observable<IHeadquarter[]> = of([]);
  @BlockUI() blockUI: NgBlockUI;
  constructor(
    private alertService: AlertService,
    private modalActiveService: NgbActiveModal,
    private formBuilder: FormBuilder,
    private storage: StorageService,
    private headquarterService: HeadquarterService
  ) { }

  ngOnInit() {
    this.currentHeadquarter = this.storage.GetCurrentHeadquarter();
    this.LoadHeadquarter();
  }

  LoadHeadquarter(){
    this.blockUI.start("Obteniendo sedes...");

    this.headquarterService.GetCurrentUserHeadquarters().pipe(finalize(() => this.blockUI.stop()))
    .subscribe(callBack => {
      if(callBack.Result){
        this.headquarters$ = of(callBack.Data.filter(x => x.Status === 'A') || []);
      } else {
        this.alertService.errorAlert(`${callBack.Error.Code} - ${callBack.Error.Message}`);
      }
    }, error => {
      this.alertService.errorAlert(error);
    });
  }

  SelectHeadquarter(_headquarter: IHeadquarter): void{
    this.storage.SetCurrentHeadquarter(_headquarter);
    this.modalActiveService.close(_headquarter);
  }

  Dismiss(reason: string): void{
    this.modalActiveService.dismiss(reason);
  }

}
