import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';

// MODELOS
import { AppConstants, IPrinter, settings } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';
import { AlertService } from './alert.service';
import { Observable } from 'rxjs';
import { IBaseResponse, ICompanySapResponse } from '../models/responses';
import { IResponse } from '../models/i-api-response';
import { CompanyMargins } from '../models/company';
import { Settings } from 'http2';
import { DBObjectName } from '../models/i-dbObjects';


// PIPES

@Injectable({
  providedIn: 'root'
})
export class CompanyService {



  @BlockUI() blockUI: NgBlockUI;

  constructor(private http: HttpClient,
    private storage: StorageService,
    private router: Router) {

  }



  // 001 - Metodo para actualizar los margenes de las vistas.
  UpdateMargins(companyId: number, Margins: any[]) {
    let str = JSON.stringify(Margins)
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/UpdateCompanyMargins?IdCompany=${companyId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url,Margins, { headers });
  }

  GetMargins():Observable<IResponse<CompanyMargins[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/GetViewMargins`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<CompanyMargins[]>>(url, { headers });
  }


  // funcion para obtener las compañias de la DBLocal
  // no recibe parametros
  GetCompanies() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/GetCompanies`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  // funcion para obtener las informacion de la compañia de la DBLocal
  // recibe como parametro el id de la compannia
  GetCompanyById(companyId: number) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/GetCompanyById?companyId=${companyId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }
  // Servicio para obtener la información de la compania por defecto
  GetDefaultCompany(): Observable<ICompanySapResponse> {

    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/GetDefaultCompany`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<ICompanySapResponse>(url, { headers });
  }

  /**
   * Send a request to create the company information
   * @param companyMailForm General information about the company
   * @param companyId Id of the company that will be saved
   * @param mailDataId Information about emails configuration
   * @param selectedFile Logo of the company
   * @param PrintInvFile Invoice print format file
   * @param PrintQuoFile Quotation print format file
   * @param PrintSOFile Sale orders print format file
   * @param PrintCopyFile
   * @param fileInvenFile Inventory print format file
   * @param _fileOinvPP PinPad invoices print format file
   * @param _fileOinvCopy Copy invoices print format file
   * @param _printRecivedPaidFile Incoming payment print format file
   * @param r Router service
   * @param alertService Alert service
   * @param decimalsForm Information about company decimals
   * @param printerConfiguration Printers configuration
   * @param _fileCreditNote Credit note print format file
   * @param _stockTransferFile Stock transfer print format file
   * @param _requetedReceivedStockTransferRequest Stock transfer request print format file to Requested/Received differences
   * @param _stockTransferRequestFile Stock transfer request print format file
   * @param _alertServiceMinStockFile Alert service min stock print format file
   * @constructor
   */
  CreateCompanyAndMail(companyMailForm: FormGroup,
            companyId: number,
            mailDataId: number,
            selectedFile: File,
            PrintInvFile: File,
            PrintQuoFile: File,
            PrintSOFile: File,
            PrintCopyFile: File,
            fileInvenFile: File,
            _fileOinvPP: File,
            _fileOinvCopy: File,
            _printRecivedPaidFile: File,
            r: Router,
            alertService: AlertService,
            decimalsForm: FormGroup,
            printerConfiguration: IPrinter,
            _fileCreditNote: File,
            _stockTransferFile: File,
            _requetedReceivedStockTransferRequest: File,
            _stockTransferRequestFile: File,
            _alertServiceMinStockFile: File,
                       pCanceledCardFile: File) {
    const companyAndMail = this.CreateCompanyMailModel(companyMailForm, companyId, mailDataId, decimalsForm, printerConfiguration);

    const token = JSON.parse(this.storage.getCurrentSession());

    const url = `${AppConstants.apiUrl}api/Company/CreateCompany`;

    let XHR = new XMLHttpRequest();

    const FD = new FormData();



    // Adding the request files
    FD.append('LogoPath', selectedFile);

    FD.append('ReportPath', PrintInvFile);

    FD.append('ReportPathQuotation', PrintQuoFile);

    FD.append('ReportPathSO', PrintSOFile);

    // FD.append('fileCopy', PrintCopyFile);

    FD.append('ReportPathInventory', fileInvenFile);

    FD.append('ReportPathPP', _fileOinvPP);

    FD.append('ReportPathCopy', _fileOinvCopy);

    FD.append('ReportCreditNote', _fileCreditNote);

    FD.append('ReportRecivedPaid', _printRecivedPaidFile);

    FD.append('StockTransferFile', _stockTransferFile);

    FD.append('RequestedReceivedStockTransferRequest', _requetedReceivedStockTransferRequest);

    FD.append('StockTransferRequestFile', _stockTransferRequestFile);

    FD.append('AlertServiceMinStockFile', _alertServiceMinStockFile);

    FD.append('companyAndMail', JSON.stringify(companyAndMail));
    FD.append("CanceledCardFile", pCanceledCardFile);

    XHR.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200)
      {
        let response = JSON.parse(XHR.responseText);

        if (response.Result)
        {
          alertService.successInfoAlert('Compañía Creada con éxito');

          r.navigate(['/companies']);
        }
        else
        {
          alertService.errorAlert('Error al cargar la información de las compañias - Error: ' + response.errorInfo.Message);
        }
      }
    };

    this.blockUI.stop(); // Stop blocking


    XHR.open('POST', url, true);

    XHR.setRequestHeader('Authorization', `Bearer ${token.access_token}`);

    // Send our FormData object; HTTP headers are set automatically
    XHR.send(FD);
  }


  /**
   * Send a request to update the company information
   * @param companyMailForm General information about the company
   * @param companyId Id of the company that will be saved
   * @param mailDataId Information about emails configuration
   * @param selectedFile Logo of the company
   * @param PrintInvFile Invoice print format file
   * @param PrintQuoFile Quotation print format file
   * @param PrintSOFile Sale orders print format file
   * @param PrintBalanceFile Balance print format file
   * @param fileInvenFile Inventory print format file
   * @param _fileOinvPP PinPad invoices print format file
   * @param _fileOinvCopy Copy invoices print format file
   * @param _printRecivedPaidFile Incoming payment print format file
   * @param r Router service
   * @param alertService Alert service
   * @param decimalsForm Information about company decimals
   * @param printerConfiguration Printers configuration
   * @param _fileCreditNote Credit note print format file
   * @param _stockTransferFile Stock transfer print format file
   * @param _requetedReceivedStockTransferRequest Stock transfer request print format file
   * @param _stockTransferRequestFile Stock transfer request print format file
   * @param _alertServiceMinStockFile Alert service min stock print format file
   * @constructor
   */
  UpdateCompanyAndMail(companyMailForm: FormGroup,
                       companyId: number,
                       mailDataId: number,
                       selectedFile: File,
                       PrintInvFile: File,
                       PrintQuoFile: File,
                       PrintSOFile: File,
                       PrintBalanceFile: File,
                       fileInvenFile: File,
                       _fileOinvPP: File,
                       _fileOinvCopy: File,
                       _printRecivedPaidFile: File,
                       r: Router,
                       alertService: AlertService,
                       decimalsForm: FormGroup,
                       printerConfiguration: IPrinter,
                       _fileCreditNote: File,
                       _stockTransferFile: File,
                       _requetedReceivedStockTransferRequest: File,
                       _stockTransferRequestFile: File,
                       _alertServiceMinStockFile: File,
                       pCanceledCardFile: File) {
    const companyAndMail = this.CreateCompanyMailModel(companyMailForm, companyId, mailDataId, decimalsForm, printerConfiguration);

    const token = JSON.parse(this.storage.getCurrentSession());

    const url = `${AppConstants.apiUrl}api/Company/UpdateCompany`;

    let XHR = new XMLHttpRequest();

    const FD = new FormData();

    // Adding the request files
    FD.append('LogoPath', selectedFile);

    FD.append('ReportPath', PrintInvFile);

    FD.append('ReportPathQuotation', PrintQuoFile);

    FD.append('ReportPathSO', PrintSOFile);

    FD.append('ReportBalance', PrintBalanceFile);

    FD.append('ReportPathInventory', fileInvenFile);

    FD.append('ReportPathPP', _fileOinvPP);

    FD.append('ReportPathCopy', _fileOinvCopy);

    FD.append('ReportCreditNote', _fileCreditNote);

    FD.append('ReportRecivedPaid', _printRecivedPaidFile);

    FD.append('StockTransferFile', _stockTransferFile);

    FD.append('RequestedReceivedStockTransferRequest', _requetedReceivedStockTransferRequest);

    FD.append('StockTransferRequestFile', _stockTransferRequestFile);

    FD.append('AlertServiceMinStockFile', _alertServiceMinStockFile);

    FD.append('companyAndMail', JSON.stringify(companyAndMail));
    FD.append("CanceledCardFile", pCanceledCardFile);

    XHR.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200)
      {
        let response = JSON.parse(XHR.responseText);

        if (response.Result)
        {
          alertService.successInfoAlert('Compañía actualizada con éxito');

          r.navigate(['/companies']);
        }
        else
        {
          alertService.errorAlert('Error al cargar la información de las compañias - Error: ' + response.errorInfo.Message);
        }
      }
    };

    this.blockUI.stop(); // Stop blocking

    XHR.open('POST', url, true);

    XHR.setRequestHeader('Authorization', `Bearer ${token.access_token}`);

    // Send our FormData object; HTTP headers are set automatically
    XHR.send(FD);
  }

  // metodo para la creacion del objeto compannia y mailDataId
  // recibe como parametro el formulario de la compannia y correo, ademas del id de la compannia y el del correo
  CreateCompanyMailModel(companyMailForm: FormGroup, companyId: number, mailDataId: number, decimalsForm: FormGroup, printerConfiguration: IPrinter) {
    return {
      'company': {
        'Id': companyId,
        'DBName': companyMailForm.value.DBName,
        'DBCode': companyMailForm.value.DBCode,
        'SAPConnectionId': companyMailForm.value.SAPConnectionId || 0,
        'Active': !!companyMailForm.value.Active,
        'ExchangeRate': companyMailForm.value.ExchangeRate || 0,
        'ExchangeRateValue': companyMailForm.value.ExchangeRateValue || 0,
        'HandleItem': companyMailForm.value.HandleItem || 0,
        'BillItem': companyMailForm.value.BillItem || 0,
        'ScaleWeightToSubstract': companyMailForm.value.ignoreWeight || 0,
        'ScaleMaxWeightToTreatAsZero': companyMailForm.value.maxAs0 || 0,
        'DecimalAmountPrice': decimalsForm.value.price || 0,
        'DecimalAmountTotalLine': decimalsForm.value.totalLine || 0,
        'DecimalAmountTotalDocument': decimalsForm.value.totalDocument || 0,
        'HasOfflineMode': !!companyMailForm.value.hasOfflineMode,
        'PrinterConfiguration': JSON.stringify(printerConfiguration),
        'HasZeroBilling': !!companyMailForm.value.hasZeroBilling,
        'LineMode': !!companyMailForm.value.LineMode,
        'MailDataId': companyMailForm.value.MailDataId
      },
      'mail': {
        'Id': companyMailForm.value.MailDataId !== null ? companyMailForm.value.MailDataId : 0,
        'Host': companyMailForm.value.Host,
        'from': companyMailForm.value.from,
        'pass': companyMailForm.value.pass,
        'SSL': !!companyMailForm.value.SSL,
        'port': companyMailForm.value.port || 0,
        'subject': companyMailForm.value.subject,
        'user': companyMailForm.value.user
      }
    };
  }

  // funcion para obtener el logo de la compañia de la DBLocal
  // no recibe parametros
  GetCompanyLogo() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/GetCompanyLogo`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  retornar() {
    this.router.navigate(['/companies']);
  }
  GetViewGroupList() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/GetViewGroupList`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<any>(url, { headers });
  }
  UpdateViewLineAgrupation(ViewGroupList: any): Observable<IBaseResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/UpdateViewLineAgrupation`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IBaseResponse>(url,
      ViewGroupList,
      { headers }
    );
  }

  GetSettings(): Observable<IResponse<settings[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Settings/GetViewSettings`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<settings[]>>(url, { headers });
  }
  GetSettingsbyId(Code: number): Observable<IResponse<settings>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Settings/GetViewSettingbyId?Code=${Code}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<settings>>(url, { headers });
  }

  SaveSettings(Settings: settings): Observable<IBaseResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Settings/SaveSettings`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IBaseResponse>(url,
      Settings,
      { headers }
    );
  }
  GetDbObjectNames():Observable<IResponse<DBObjectName[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/GetDbObjectName`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<DBObjectName[]>>(url, { headers });
  }

  UpdateDbObjectNames(DBObjectNameList: DBObjectName[]): Observable<IBaseResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Company/UpdateDbObjectNames`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IBaseResponse>(url,
      DBObjectNameList,
      { headers }
    );
  }

}
