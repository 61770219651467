import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../models';
import { IResponse } from '../models/i-api-response';
import { IFieldRule } from '../models/i-field-rule';
import { IRequiredField } from '../models/i-required-field';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class FieldService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  GetRequiredFields(): Observable<IResponse<IRequiredField[]>>{
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    
    const URL = `${AppConstants.apiUrl}api/Fields/GetRequiredFields`;
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<IResponse<IRequiredField[]>>(URL, { headers });
  }

  GetFieldRules(): Observable<IResponse<IFieldRule[]>>{
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    
    const URL = `${AppConstants.apiUrl}api/Fields/GetFieldRules`;
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<IResponse<IFieldRule[]>>(URL, { headers });
  }
}
