import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FormGroup} from '@angular/forms';

// MODELOS
import {AppConstants, IPPTransaction, IUdfTarget, ITerminal, IMappedPayment} from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import {StorageService} from './storage.service';
import {Observable} from 'rxjs';
import {IBaseResponse, IPPTransactionResponse, IInvoicePaymentDetailResponse} from '../models/responses';
import {IInvoicePaymentDetail} from '../models/i-invoice-payment-detail';
import {CurrencyPP} from '../models/constantes';
import {IResponse} from '../models/i-api-response';
import {ITransaction, IVoidedTransaction} from "../models/i-pp-transaction";

// PIPES

@Injectable({
  providedIn: 'root'
})
export class PaymentService {


  constructor(private http: HttpClient,
              private storage: StorageService) {
  }

  // funcion para obtener los parametros de la vista de facturacion
  // no recibe parametros
  getPayInvoices(searchForm: FormGroup) {

    const code = searchForm.value.customer.split(' - ')[0];

    const card = searchForm.value.card;
    let name = ``;

    if (searchForm.value.customer.split(' - ').length > 1) {
      name = searchForm.value.customer.split(' - ')[1];
    }
    const phone = searchForm.value.phone;
    const slpCode = searchForm.value.SlpCode;
    const comisionable = searchForm.value.comisionable;
    const numOrdLab = searchForm.value.numOrdLab;
    const numOrdOpt = searchForm.value.numOrdOpt;

    const view = 1;
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(`${AppConstants.apiUrl}api/Payment/GetPayInvoices?cardCode=${code}&card=${card}&name=${name}&phone=${phone}&comisionable=${comisionable}&numOrdLab=${numOrdLab}&numOrdOpt=${numOrdOpt}&currency=${searchForm.value.currency}&slpCode=${slpCode}`,
      {headers});
  }

  // funcion para obtener los parametros de la vista de facturacion
  // no recibe parametros
  getPayApInvoices(searchForm: FormGroup) {
    const code = searchForm.value.supplier.split(' - ')[0];
    const view = 1;
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(`${AppConstants.apiUrl}api/Payment/GetPayApInvoices?CardCode=${code}&Sede=${searchForm.value.sede}&Currency=${searchForm.value.currency}`,
      {headers});
  }

  // funcion para crear un pago
  // parametros el modelo de pago
  createPayInvoices(Payments: any, pagocuenta: boolean, _udfs: IUdfTarget[], _IsWithTransaction: boolean = false) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `${AppConstants.apiUrl}api/Documents/CreatePaymentRecived`;

    return this.http.post(url,
      {
        ...Payments,
        'isPayAccount': pagocuenta,
        'UdfTarget': _udfs,
        'IsWithTransaction': _IsWithTransaction

      },
      {headers}
    );
  }

  // funcion para crear un pago
  // parametros el modelo de pago
  createPayApInvoices(Payments: any, _udfs: IUdfTarget[]) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    const url = `${AppConstants.apiUrl}api/Documents/CreatePayApInvoices`;
    return this.http.post(url,
      {
        ...Payments,
        'UdfTarget': _udfs
      },
      {headers});
  }

  // obtiene la lista de facturas
  // parametros modelo con al informacion que se necesita para buscar los documentos
  getInvList(InfoSearch: any) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}api/Payment/GetPaymentList`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url,
      InfoSearch,
      {headers});
  }

  // envia el pago a anular
  // parametros modelo de pago con la informacion de la factura a cancelar
  CancelPayment(Payments: any) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    const url = `${AppConstants.apiUrl}api/Payment/CancelPayment`;

    return this.http.post(url,
      Payments,
      {headers}
    );
  }

  requestPinPadPayment(_amount: number, _uniqueInvCode: string, _currencyPayment: string, invoiceDocument: string, _bacId: string, _userPrefix: string
    , _terminalId: string, CardName: string, ExpirationDate: string, _terminal: ITerminal): Observable<IResponse<string>> {

    const ppURL = this.storage.GetUrlPinpad();

    const url = `${ppURL}api/Payment/RequestPinPadPayment`;

    return this.http.post<IResponse<string>>(url, {
      'Amount': _amount,
      'InvoiceNumber': _bacId,
      'DocumentKey': _uniqueInvCode,
      'Currency': _currencyPayment == 'COL' ? CurrencyPP.LOCAL_CURRENCY : CurrencyPP.USD,
      'Document': invoiceDocument,
      'TerminalId': _terminalId,
    }, {});
  }

  updatePinPadPayment(_pinPadCards: IPPTransaction[]): Observable<IPPTransactionResponse> {
    const ppURL = this.storage.GetUrlPinpad();

    const url = `${ppURL}api/Payment/UpdatePinPadPayment`;

    return this.http.post<IPPTransactionResponse>(url,
      _pinPadCards,
      {}
    );
  }

  GetCommitedPPCards(_documentKey: string): Observable<IResponse<IPPTransaction[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `${AppConstants.apiUrl}api/Payment/GetPPTransactionByDocumentKey?_documentKey=${_documentKey}`;

    return this.http.get<IResponse<IPPTransaction[]>>(url, {headers});
  }

  /**
   * Send a request to cancel committed transactions
   * @param _transaction
   * @constructor
   */
  CancelCommittedTransaction(_transaction: ITransaction): Observable<IResponse<string>> {
    const ppURL = this.storage.GetUrlPinpad();

    const url = `${ppURL}api/Payment/CancelCard`;

    return this.http.post<IResponse<string>>(url, _transaction);
  }

  cancelPinPadCard(_pPTransaction: IPPTransaction): Observable<IResponse<IPPTransaction>> {
    // const token = JSON.parse(this.storage.getCurrentSessionOffline());
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': `Bearer ${token.access_token}`
    // });

    const ppURL = this.storage.GetUrlPinpad();

    const url = `${ppURL}api/Payment/CancelCard`;

    return this.http.post<IResponse<IPPTransaction>>(url, _pPTransaction, {}
    );
  }

  CommitCanceledCard(_pPTransaction: IPPTransaction, _terminal: ITerminal, _rawData: string) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `${AppConstants.apiUrl}api/Payment/CommitCanceledCard`;

    _pPTransaction.Terminal = _terminal;

    _pPTransaction.TerminalId = _terminal.Id;

    const M_OBJECT = {
      Transaction: _pPTransaction,
      Terminal: _terminal,
      RawData: _rawData,
      UserPrefix: token.UserName
    };

    return this.http.post<IResponse<IPPTransaction>>(url, M_OBJECT, {headers});
  }

  /**
   * Send a request to create a voided transaction
   * @param pVoidedTransaction Information of the voided transaction
   * @constructor
   */
  CommitCanceledCard2(pVoidedTransaction: IVoidedTransaction): Observable<IResponse<IVoidedTransaction>> {

    const token = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.post<IResponse<IVoidedTransaction>>(`${AppConstants.apiUrl}api/Payment/CommitCanceledCard`, pVoidedTransaction, { headers });
  }

  getPaymentDetail(_docNum: number): Observable<IInvoicePaymentDetailResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `${AppConstants.apiUrl}api/Payment/GetInvoicePaymentDetail?_docEntry=${_docNum}`;

    return this.http.get<IInvoicePaymentDetailResponse>(url, {headers});
  }

  /**
   * Send a request to retrieve the transactions details of a document
   * @param _documentKey Generated key by the application to identify the document
   * @constructor
   */
  GetTransactionDetails(_documentKey: string): Observable<IResponse<ITransaction[]>>
  {
    const token = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `${AppConstants.apiUrl}api/Payment/GetTransactionDetails`;

    return this.http.get<IResponse<ITransaction[]>>(url, {
      headers,
      params: {
        _documentKey: _documentKey
      }
    });
  }

  GetMappedPayments(): Observable<IResponse<IMappedPayment[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `${AppConstants.apiUrl}api/Payment/GetOtherPayments`;

    return this.http.get<IResponse<IMappedPayment[]>>(url, {headers});
  }
}
