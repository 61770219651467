import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

// MODELOS
import {AppConstants} from './../models/index';

// RUTAS
// COMPONENTES
// SERVICIOS
import {StorageService} from './storage.service';
import {IService} from "../models/i-service";
import {Observable} from "rxjs";
import {IResponse} from "../models/i-api-response";
import {IPagedRequestResponse, ISalesPersonResponse} from "../models/responses";
import {ISalesPerson} from "../models/i-sales-person";
import {map} from "rxjs/operators";
import {LocalStorageVariables} from "../enum/local-storage-variables";
import {IHeadquarter} from "../models/i-headquarter";

// PIPES

@Injectable({
  providedIn: 'root'
})
export class SalesManService implements IService {

  constructor( private http: HttpClient,
    private storage: StorageService ) {
  }

  /**
   * Send a request to retrieve the sales persons
   * @param pForDocuments Indicates is should query the sales persons that will be appear in documents
   * @param pApplyHeadquarterFilter Indicates if should apply the conditions for filter by headquarter
   * @constructor
   */
  GetSalesPersons(pForDocuments: boolean = false, pApplyHeadquarterFilter: boolean = true): Observable<ISalesPersonResponse>
  {
    return this.http.get<ISalesPersonResponse>(`api/SalesMan/GetSalesMan`,{
      params: {
        forDocuments: pForDocuments.toString(),
        headquarter: pApplyHeadquarterFilter ? this.GetHeadquarterCodeForSalesPersonsFilter() : 'all'
      }
    });
  }

  Get(): Observable<IResponse<IPagedRequestResponse<ISalesPerson[]>>> {
    return this.http.get<ISalesPersonResponse>('api/SalesMan/GetSalesMan', {
      params: {
        forDocuments: 'true',
        headquarter: this.GetHeadquarterCodeForSalesPersonsFilter()
      }
    })
      .pipe(
        map(response => ({
          Result: true,
          Data: {
            Data: response.salesManList,
            RecordsCount: response.salesManList.length
          }
        } as IResponse<IPagedRequestResponse<ISalesPerson[]>>))
      );
  }


  /**
   * Get the headquarter code based on conditions to filter the sales person request
   * @constructor
   * @private
   */
  private GetHeadquarterCodeForSalesPersonsFilter(): string
  {
    let currentUserSalesPerson = this.storage.GetLocalStorageVariable<ISalesPerson>(LocalStorageVariables.CurrentUserSalesPerson);

    if(currentUserSalesPerson.SlpCode == "0")
    {
      let headquarter: IHeadquarter | undefined = this.storage.GetLocalStorageVariable<IHeadquarter>(LocalStorageVariables.CurrentHeadquarter);

      if(headquarter)
      {
        return headquarter.Code;
      }
      else
      {
        return "";
      }
    }

    return "all";
  }
}
