import { formatDate } from "@angular/common";
import { environment } from "src/environments/environment";


export class AppConstants {

    static GetTime(): string {
        /* let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let dateTime = date + ' ' + time;*/
        let dateTime: string = formatDate(new Date(), "yyyy-MM-dd HH:mm:ss", "en");
        return dateTime;
    }
    static GetError(_error: any) {
        let joined_error;

        if(typeof _error.error == 'string')
        {
          return _error.error;
        }

        if (_error.error && _error.error.errorInfo) {
            joined_error = _error.error.errorInfo.Message;

        } else if (_error.error && _error.error.errorInfo && _error.error.errorInfo.Message) {
            joined_error = _error.error.errorInfo.Message;

        } else if (_error.Error && _error.Error.Message) {
            joined_error = _error.Error.Message;

        } else if (_error.error && _error.error.Error && _error.error.Error.Message) {
            joined_error = _error.error.Error.Message;

        }else if (_error.error && _error.error.error_description) {
            joined_error = _error.error.error_description;

        } else if (_error.error && _error.error.Message) {
            joined_error = _error.error.Message;

        } else if (_error.message) {
            joined_error = _error.message;

        } else if (_error.errorInfo && _error.errorInfo.Message) {
            joined_error = _error.errorInfo.Message;

        } else if (_error.error) {
            joined_error = _error.error;

        } else {
            joined_error = _error;
        }

        if (typeof joined_error === 'string') return joined_error;

        return JSON.stringify(_error);
    }

    public static get padronInfoURl(): string { return environment.padronUrl }

    // PRUEBAS DEBUG
    // public static get modalAnswer(): string { return 'https://npstest.clavisco.com/#/'; }
    // PRUEBAS TEST
    // public static get apiAnswer(): string { return 'https://npsapitest.clavisco.com/'; }
    public static get RPTMANAGER_URL(): string { return environment.reportManagerUrl; }

    public static comoesta: boolean = true;

    public static get sOnline(): string {
        this.comoesta = true;
        return 'a';
    }

    public static get sOffline(): string {
        this.comoesta = false;
        return "a";
    }

    public static get apiUrl(): string { return navigator.onLine ? this.getOnLine() : this.getOffLine(); }

    public static get onlineUrl(): string { return this.getOnLine(); }

    public static get offlineUrl(): string { return this.getOffLine(); }

    public static get TokenPadron(): string { return this.getTokenPadron(); }

    static getOffLine(): string {
        return 'http://localhost:42222/';
    }

    static getOnLine(): string { return environment.apiUrl }

    static getTokenPadron(): string { return 'https://padronapi.clavisco.com/'; }

    public static get AppKey(): string { return 'AppSuper'; }
}

export const ExchangeRate = [
    {
        'Id': '1',
        'Name': 'SAP',
    },
    {
        'Id': '2',
        'Name': 'DB',
    }
];

export const HandleItem = [
    {
        'Id': '1',
        'Name': 'Código',
    },
    {
        'Id': '2',
        'Name': 'Barras',
    }
];

export const BillItem = [
    {
        'Id': '1',
        'Name': 'Código',
    },
    {
        'Id': '2',
        'Name': 'Series',
    }
];

export const ReportTypeList = [
    {
        'Id': '1',
        'Name': 'Factura',
    },
    {
        'Id': '5',
        'Name': 'Órden de Venta',
    },
    {
        'Id': '2',
        'Name': 'Cotizacion',
    },
    {
        'Id': '9',
        'Name': 'Pagos recibidos',
    }
]

export const IdentificationType = [
    {
        'Id': '00',
        'Name': '',
    },
    {
        'Id': '01',
        'Name': 'Cedula Física',
    },
    {
        'Id': '02',
        'Name': 'Cedula Jurídica',
    },
    {
        'Id': '03',
        'Name': 'DIMEX',
    },
    {
        'Id': '04',
        'Name': 'NITE',
    }

];

export const IdentificationBusinessPartner = [
    {
        'Id': '1',
        'Name': 'Cliente',
        'SapType': 'C',

    },
    {
        'Id': '2',
        'Name': 'Proveedor',
        'SapType': 'S',
    },
];

export enum PayTermsEnum {
    Credito = 1,
    Contado = 2
}

export enum UDF_CATEGORIES {
    OINV = 1,
    ORCT,
    ORDR,
    OQUT,
    OCRD
}

export enum RequestDocumentType { // Usado para definir el tipo de peticion que se hara al banco. Por ejemplo cierre de tarjetas o precierre de tarjetas
    BALANCE,
    PRE_BALANCE
}

export enum CurrencyPP {
    LOCAL_CURRENCY = 1,
    USD = 2
}

export enum DocumentStatus {
    ERROR_ON_SYNCUP = 'ERROR',
    PENDING_TO_SYNUP = 'PEDIENTE_SINCRONIZAR',
    SYNCHRONIZED = 'SINCRONIZADO'
}

export const TypeInvoice = [
    {

        'Name': '',
        'Description': ''
    },
    {

        'Name': 'FE',
        'Description': 'Factura Electrónica'
    },
    {

        'Name': 'TE',
        'Description': 'Tiquete Electrónico'
    }
];

export enum DOCUMENT_ALIAS {
    INVOICE = 'OINV',
    SALE_ORDER = 'ORDR',
    SALE_QUOTATION = 'OQUT',
    INC_PAYMENT_CARDS = 'RCT3',
    BUSINESS_PARTNER = 'OCRD',
    ITEMS = 'OITM',
    GOODSRECEIPT = 'OIGN',
    GOODSISSUE = 'OIGE',
    GOODSRECEIPTPO = 'OPDN',
    GOODSRETURN = 'ORPD',
    PURCHASEORDER = 'OPOR',
    APINVOICE = 'OPCH',
    CREDITMEMO = 'ORIN',
    OUTGOINGPAYMENTS = 'OVPM',
    INCOMINGPAYMENTS = 'ORCT',
    INVENTORY_TRANSFER_REQUEST = 'OWTQ'
}

export enum CONFIG_VIEW {
    OIGN = 1,
    Invoice = 2,
    Payment = 4,
    BussinesPartner = 5,
    OFFLINE_PP = 6,
    POINTS = 9
}

export enum PATIENT_LOG_ACTION {
    C = "CREAR",
    U = "ACTUALIZAR",
    A = ""
}

export enum PATIENT_LOG_STATUS {
    C = "COMPLETADO",
    E = "ERROR"
}

export enum DocumentType {
    Unassign = 0,
    Payment = 3,
    CreditMemo = 4,
    Quotation = 5,
    SaleOrder = 6,
    Invoice = 7,
    ApInvoice = 8,
    ApPayment = 9,
    InventoryEntry = 10,
    InventoryReturn = 11,
    PurchaseOrder = 12,
    StockGoodIssue = 13,
    StockGoodRecipe = 14
}

// export enum DR_MAX_API {
//     PRODUCTION = 'https://emadrmxapi.clavisco.com/',
//     TESTING = 'https://emadrmxapitest.clavisco.com/',
//     DEVELOPMENT = 'https://emadrmxapidev.clavisco.com/',
//     DEBUG = 'http://localhost:50050/'
// }

