import {AfterViewInit, Component, Inject, inject, Injector, Input, OnDestroy, OnInit, Type} from '@angular/core';
import {FormControl} from "@angular/forms";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {debounceTime, delay, distinctUntilChanged, filter, finalize, startWith, switchMap} from "rxjs/operators";
import {ISelectionModalData} from "../../models/i-selection-modal-data";
import {IService} from "../../models/i-service";
import {AlertService} from "../../services";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DOCUMENT} from "@angular/common";
import {Subscription} from "rxjs";
import {IResponse} from "../../models/i-api-response";
import {IPagedRequestResponse} from "../../models/responses";

@Component({
  selector: 'app-selection-modal',
  templateUrl: './selection-modal.component.html',
  styleUrls: ['./selection-modal.component.scss']
})
export class SelectionModalComponent implements OnInit, OnDestroy, AfterViewInit {

  @BlockUI() blockUI: NgBlockUI;

  searchCriteria: FormControl;

  @Input() data!: ISelectionModalData;

  service: IService;

  tableColumns: string[] = [];

  objectProps: string[] = [];

  records: any[] = [];

  filteredRecords: any[] = [];

  tablePage: number = 1;

  recordsCount: number = 10;

  firstRequestSent: boolean = false;

  subscriptions$: Subscription = new Subscription();
  constructor(private alertService: AlertService,
              private injector: Injector,
              @Inject(DOCUMENT) private domDocument: Document,
              private activeModal: NgbActiveModal) {

  }

  ngOnInit() {
    this.service = this.injector.get(this.data.ServiceToInject);

    this.domDocument.querySelectorAll('.modal-content')
      .forEach(el => {
        el.setAttribute('style', 'min-width: fit-content; width: 80%; margin-left:auto; margin-right: auto;');
      });

    this.searchCriteria = new FormControl('');
  }

  ngAfterViewInit(): void {
    this.objectProps = Object.keys(this.data.TableColumns);

    this.GetTableColumns();

    this.ListenSearchCriteriaChanges();
  }

  /**
   * Map the table columns
   * @constructor
   */
  GetTableColumns(): void
  {
    for (const key in this.data.TableColumns) {
      this.tableColumns.push(this.data.TableColumns[key]);
    }
  }

  /**
   * Listen changes in the search criteria form control
   * @constructor
   */
  ListenSearchCriteriaChanges(): void
  {
    this.subscriptions$.add(
      this.searchCriteria.valueChanges
        .pipe(
          startWith(''),
          filter(value => this.data && (!this.data.UIPagination || (!this.firstRequestSent && this.data.UIPagination)) && typeof value == 'string'),
          debounceTime(350),
          switchMap(value => {
            this.firstRequestSent = true;

            this.blockUI.stop();

            this.blockUI.start('Cargando...');

            return this.service[this.data.MethodToExecute](...this.GetParametersToSend());
          })
        )
        .subscribe({
          next: (response: IResponse<IPagedRequestResponse<any>>) => {
            this.blockUI.stop();
            this.records = response.Data.Data || [];
            this.recordsCount = response.Data.RecordsCount;
            this.filteredRecords = [...this.records].slice(0, 10);
            this.tablePage = 1;
          },
          error: (error) => {
            this.blockUI.stop();
            this.alertService.errorAlert(error);
            console.error(error);
          }
        })
    );


    if(this.data.UIPagination && this.data.PropsToMatchWithSearchCriteria && this.data.PropsToMatchWithSearchCriteria.length)
    {
      this.subscriptions$.add(
        this.searchCriteria.valueChanges
          .pipe(
            filter(value => this.data && typeof value == 'string'),
          )
          .subscribe({
            next: (value) => {
              let skip = 10 * (this.tablePage - 1);

              this.filteredRecords = this.records.filter(record => {
                let joinedPropsValues = '';

                for (const prop of this.data.PropsToMatchWithSearchCriteria!) {
                  joinedPropsValues += record[prop];
                }

                return joinedPropsValues.toLowerCase().includes(value.toLowerCase());
              });

              this.recordsCount = this.filteredRecords.length;

              this.filteredRecords = this.filteredRecords.slice(skip, skip + 10);

              this.tablePage = 1;
            }
          })
      );
    }
  }

  private GetParametersToSend(): any[]
  {
    let take = 10;

    let skip = 10 * (this.tablePage - 1)

    let searchCriteria = this.searchCriteria.value;

    if(this.data.PredefinedParametersToSend.includes('all'))
    {
      return [take, skip, searchCriteria].concat(this.data.RequestExtraParameters || []);
    }

    let paramsToReturn = [];

    for (let param of this.data.PredefinedParametersToSend)
    {
      switch (param)
      {
        case "take":
          paramsToReturn.push(take);
          break;
        case "skip":
          paramsToReturn.push(skip);
          break;
        case "searchCriteria":
          paramsToReturn.push(searchCriteria);
          break;
      }
    }

    return paramsToReturn.concat(this.data.RequestExtraParameters || []);
  }

  /**
   * Send a request to retrieve the records in by page
   * @constructor
   */
  OnPageChange(): void
  {
    if(this.data.UIPagination)
    {
      let searchCriteria = this.searchCriteria.value;

      let skip = 10 * (this.tablePage - 1);

      this.filteredRecords = this.records.filter(record => {
        let joinedPropsValues = '';

        for (const prop of this.data.PropsToMatchWithSearchCriteria!) {
          joinedPropsValues += record[prop];
        }

        return joinedPropsValues.toLowerCase().includes(searchCriteria.toLowerCase());
      }).slice(skip, skip + 10);
    }
    else
    {
      this.blockUI.start("Cargando...");

      this.subscriptions$.add(
        this.service[this.data.MethodToExecute](...this.GetParametersToSend())
          .pipe(
            finalize(() => this.blockUI.stop())
          )
          .subscribe({
            next: (response: IResponse<IPagedRequestResponse<any>>) => {
              this.records = response.Data.Data || [];
              this.recordsCount = response.Data.RecordsCount;
              this.filteredRecords = [...this.records];
            },
            error: (error) => {
              this.alertService.errorInfoAlert(error);
              console.error(error);
            }
          })
      );
    }
  }

  /**
   * Dismiss the modal with the selected record
   * @param _record The selected record
   * @constructor
   */
  OnSelectRecord(_record: any): void
  {
    this.activeModal.close(_record);
  }

  /**
   * Dismiss the modal without any result
   * @constructor
   */
  DismissModal(): void
  {
    this.activeModal.dismiss();
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
