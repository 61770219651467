import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {StorageService} from "../services";

@Injectable()
export class HeadersInterceptor implements HttpInterceptor
{
  constructor(private storageService: StorageService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    if(req.url.includes("token"))
    {
      return next.handle(req);
    }

    let clonedRequest = req.clone();

    if(!req.headers.has("Authorization"))
    {
      const token = JSON.parse(this.storageService.getCurrentSession());

      let headers = req.headers.append("Authorization", `Bearer ${token.access_token}`);

      clonedRequest = req.clone({headers})
    }

    if(!req.headers.has("Content-Type"))
    {
      let headers = clonedRequest.headers.append("Content-Type", "application/json");

      clonedRequest = clonedRequest.clone({headers})
    }

    return next.handle(clonedRequest);
  }

}
