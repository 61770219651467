import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { IHeadquarter } from 'src/app/models/i-headquarter';
import { IMinifiedWarehouse } from 'src/app/models/i-minified-warehouse';
import { AlertService, AuthenticationService, PermsService } from 'src/app/services';
import { HeadquarterService } from 'src/app/services/headquarter.service';
import { WarehouseService } from 'src/app/services/warehouse.service';

@Component({
  selector: 'app-warehouses-by-headquarter',
  templateUrl: './warehouses-by-headquarter.component.html',
  styleUrls: ['./warehouses-by-headquarter.component.scss']
})
export class WarehousesByHeadquarterComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  headquarterList: IHeadquarter[];
  warehousesAssign: IMinifiedWarehouse[]
  warehousesAvailable: IMinifiedWarehouse[];
  rdButons: FormGroup;
  headquarter: number;
  currentUser: any;
  currentUserSubscription: Subscription;
  permisos: boolean  = true;

  constructor( 
    private permsService: PermsService,
    private headquarterService: HeadquarterService, 
    private warehouseService: WarehouseService,
    private formBuilder: FormBuilder, 
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
        this.currentUser = user;
      }
    );
  }

  ngOnInit(): void {
    this.headquarterList = [];
    this.warehousesAssign = [];
    this.warehousesAvailable = [];
	  this.CheckPermits();
    this.LoadHeadquarters();
    this.rdButons = this.formBuilder.group({
      rControlQuitar: ['']
    });
  }
  CheckPermits(): void{
	  this.permsService.getPerms(this.currentUser.userId).pipe(finalize(()=>this.blockUI.stop())).subscribe((data: any) => {
		  if (data.Result) {
        data.perms.forEach(Perm => {
          if(Perm.Name === "V_Warehouses_Assign"){
            this.permisos = Perm.Active;
          }
        });
		  } else {
        this.alertService.errorAlert(`Error al cargar los permisos, Error: ${data.errorInfo.Message}`);
			  this.permisos = false;
		  }
		}, error => {
      this.alertService.errorAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
		  this.permisos = false;
		});
  }

  LoadHeadquarters(): void {
    this.blockUI.start("Cargando lista de sedes...");
    this.headquarterService.GetHeadquarters().pipe(finalize(()=>this.blockUI.stop())).subscribe(callBack => {
      if(callBack.Result){
        this.headquarterList = callBack.Data;
        if(this.headquarterList && this.headquarterList.length > 0){
          this.LoadWarehouses(this.headquarterList[0].Id);
        }
      } else {
        this.alertService.errorInfoAlert('Error al cargar la lista de sedes - ' + callBack.Error.Message);
      }
    }, error => {
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error}`);
    });
  }

  LoadWarehouses(_headquarterId: number): void {
    this.headquarter = _headquarterId;
    this.warehousesAssign = [];
    this.warehousesAvailable = [];
    this.blockUI.start('Cargando listas de almacenes...');
    this.warehouseService.GetWarehouses().pipe(finalize(()=>this.blockUI.stop())).subscribe(callBackFirst => {
      if (callBackFirst.Result) {
        this.warehousesAvailable = [];
        this.warehouseService.GetWarehousesByHeadquarter(_headquarterId).pipe(finalize(()=>this.blockUI.stop())).subscribe(callBackSecod => {
          if (callBackSecod.Result) {
            this.warehousesAssign = [];
            this.warehousesAssign = callBackSecod.Data;
            this.warehousesAvailable = callBackFirst.Data.filter(x => !this.warehousesAssign.some(y => x.WhsCode === y.WhsCode));
          } else {
            this.warehousesAssign = [];
            this.alertService.errorInfoAlert('Error al cargar la lista de almacenes - ' + callBackSecod.Error.Message);
          }
        }, errorSecod => {
          this.warehousesAssign = [];
          this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${errorSecod}`);
        });
      } else {
        this.warehousesAvailable = [];
        this.alertService.errorInfoAlert('Error al cargar la lista de almacenes - ' + callBackFirst.Error.Message);
      }
    }, errorFirst => {
      this.warehousesAvailable = [];
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${errorFirst}`);
    });
  }

  AddAll(): void {
    this.warehousesAssign = this.warehousesAssign.concat(this.warehousesAvailable);
    this.warehousesAvailable = [];
  }

  Add(_warehouse: IMinifiedWarehouse): void {
    let removed = this.warehousesAvailable.splice(this.warehousesAvailable.findIndex(x => x.WhsCode === _warehouse.WhsCode), 1);
    if(removed && removed.length > 0) {
      this.warehousesAssign.push(removed[0]);
    }
  }

  DeleteAll(): void {
    this.warehousesAvailable = this.warehousesAvailable.concat(this.warehousesAssign);
    this.warehousesAssign = [];
  }

  Delete(_warehouse: IMinifiedWarehouse): void {
    let removed = this.warehousesAssign.splice(this.warehousesAssign.findIndex(x => x.WhsCode === _warehouse.WhsCode), 1);
    if(removed && removed.length > 0) {
      this.warehousesAvailable.push(removed[0]);
    }
  }
  
  SaveChanges(): void {
    this.blockUI.start('Guardando cambios, por favor espere...');
    this.warehouseService.SaveWarehousesByHeadquarter(this.headquarter, this.warehousesAssign).subscribe( (data: any) => {
      this.blockUI.stop();
      if (data.Result) {
        this.alertService.successInfoAlert('Se guardaron correctamente los cambios');
      } else {
        this.alertService.errorAlert('Error al intentar guardar los cambios - error: ' + data. errorInfo.Message);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`); 
    });
    this.rdButons.controls.rControlQuitar.reset();
  }

}
