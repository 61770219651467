import {Component, HostListener, Input, OnInit} from '@angular/core';
import {LayoutService} from '../../shared/services/layout.service';
import {AuthenticationService, CommonService, EventService, StorageService} from '../../services/index';
import {Router} from '@angular/router';
import {IInvoiceInMemory} from 'src/app/models/i-invoice-in-memory';
import {InvoicesInMemoryService} from 'src/app/services/invoices-in-memory.service';
import {IUIEvent} from 'src/app/models';
import {filter} from 'rxjs/operators';
import {LocalStorageVariables} from "../../enum/local-storage-variables";


@Component({
  selector: 'app-content-section',
  templateUrl: './content-section.component.html',
  styleUrls: ['./content-section.component.scss']
})
export class ContentSectionComponent implements OnInit {
  //varbox
  patientsTitle: string;
  screenTitle = '';
  contentHeight: number;
  @Input() navLayout: string;
  @Input() defaultNavbar: string;
  @Input() toggleNavbar: string;
  @Input() toggleStatus: boolean;
  @Input() navbarEffect: string;
  @Input() deviceType: string;
  @Input() headerColorTheme: string;
  @Input() navbarColorTheme: string;
  @Input() activeNavColorTheme: string;

  currentUser: string;
  documentInformation: string;
  offlineInfo: string;

  invoicesInMemory: IInvoiceInMemory[] = [];

  ShowMemoryInvoices: boolean = true;

  /**
   * Represent the name which the invoices in memory will be rendered
   */
  invoicesInMemoryDisplayName: string;

  /**
   * Identifier of the current loaded invoice in memory
   */
  currentLoadedInvoiceInMemory: string;

  constructor(private layoutService: LayoutService, public router: Router,
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private commonService: CommonService,
    private invoicesInMemoryService: InvoicesInMemoryService
    , private eventService: EventService) {

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    this.invoicesInMemoryDisplayName = this.invoicesInMemoryService.InvoiceInMemoryDisplayName;

    this.ListenMemoryInvoiceEvents();
  }

  /**
   * Listen the events emitted by the invoice memory feature
   * @constructor
   * @private
   */
  private ListenMemoryInvoiceEvents(): void
  {
    this.invoicesInMemoryService.onInvoicesInMemoryChanged$
      .pipe(
        filter(changeType => changeType !== 'saved')
      )
      .subscribe({
        next: (changeType) => {
          this.invoicesInMemoryDisplayName = this.invoicesInMemoryService.InvoiceInMemoryDisplayName;

          this.invoicesInMemory = this.invoicesInMemoryService.GetInvoicesInMemory();
        }
      });

    this.invoicesInMemoryService.onRequestShowInvoiceInMemory$.subscribe({
      next: (shouldShowInvoicesInMemory) => {
        if(shouldShowInvoicesInMemory)
        {
          this.invoicesInMemoryDisplayName = this.invoicesInMemoryService.InvoiceInMemoryDisplayName;

          this.invoicesInMemory = this.invoicesInMemoryService.GetInvoicesInMemory();
        }
        else
        {
          this.invoicesInMemory = [];
        }
      }
    });

    this.invoicesInMemoryService.onLoadInvoiceInMemory$
      .subscribe({
        next: (documentKey) => {
          this.currentLoadedInvoiceInMemory = documentKey;
        }
      });
  }

  EVENTS: { [K: string]: Function } = {
    UpdatePatientsTitle: (_title: string) => this.UpdatePatientsTitle(_title)
  };

  ngOnInit() {
    this.layoutService.contentHeightCast.subscribe(setCtHeight => this.contentHeight = setCtHeight);

    this.eventService.Flow().pipe(filter(next => next.View === `CONTENT_SECTION`)).subscribe(next => this.EVENTS[next.Target](next.Data));

    this.commonService.hasDocument.subscribe(next => {
      this.documentInformation = next;

    });

    this.commonService.offlineInformation.subscribe(next => {
      this.documentInformation = next;

    });

    if (this.storageService.GetDocEntry() > 0) {
      this.commonService.hasDocument.next(this.storageService.GetBreadCrum());
    }

    this.invoicesInMemoryService.LoadInvoicesInMemoryConfiguration();
  }



  /**
   * Emits an event indicating that should load the specified invoice in memory
   * @param pDocumentKey Identifier of the invoice in memory that will be loaded
   * @constructor
   */
  LoadInvoiceInMemory(pDocumentKey: string): void {
    this.invoicesInMemoryService.LoadInvoiceInMemory(pDocumentKey);
  }
  /**
   * Add an empty invoice in memory
   * @constructor
   */
  AddEmptyInvoiceInMemory() {
    this.invoicesInMemoryService.AddEmptyInvoiceInMemory();
  }

  //-----------------------


  @HostListener('window:resize', ['$event'])
  onResizeHeight(event: any) {
    console.log(0);
    this.contentHeight = window.innerHeight - this.layoutService.headerHeight;
  }

  PublishEvent(_url: string, _target: string): void {

    const PATH = _url.split('/')[1];

    const EVENT = {
      Target: _target,
      View: PATH
    } as IUIEvent;

    this.eventService.PublishEvent(EVENT);
  }


  UpdatePatientsTitle(_title: string): void {
    setTimeout(_ => this.patientsTitle = _title );
  }
}
