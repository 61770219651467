import {AfterViewInit, Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {IMinifiedWarehouse} from "../../../../models/i-minified-warehouse";
import {ISalesPerson} from "../../../../models/i-sales-person";
import {
  IInventoryTransferRequest,
  IInventoryTransferRequestLine,
  IInventoryTransferRequestStates, IMinifiedInventoryTransferRequest
} from "../../../../models/i-inventory-transfer-request";
import {forkJoin, of, Subject} from "rxjs";
import {IHeadquarter} from "../../../../models/i-headquarter";
import {IUdf, IUdfTarget} from "../../../../models";
import {InventoryTransferRequestsService} from "../../../../services/inventory-transfer-requests.service";
import {HeadquarterService} from "../../../../services/headquarter.service";
import {
  AlertService,
  AuthenticationService,
  ItemService,
  PermsService,
  SalesManService,
  StorageService
} from "../../../../services";
import {UdfsService} from "../../../../services/udfs.service";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DOCUMENT_ALIAS} from "../../../../models/constantes";
import {catchError, concatMap, finalize, map} from "rxjs/operators";
import {IudfValue} from "../../../../models/iudf-value";
import {SelectionModalComponent} from "../../../../components/selection-modal/selection-modal.component";
import {ISelectionModalData} from "../../../../models/i-selection-modal-data";
import {IMinifiedItem} from "../../../../models/i-item";
import {WarehouseService} from "../../../../services/warehouse.service";
import {DOCUMENT, formatDate} from "@angular/common";
import {IEditInventoryTransferRequestModalData} from "../../../../models/i-edit-inventory-transfer-request-modal-data";
import {SerialNumbersService} from "../../../../services/serial-numbers.service";
import {ISerialNumber} from "../../../../models/i-serial-number";
import {IResponse} from "../../../../models/i-api-response";
import {IBaseResponse} from "../../../../models/responses";
import {ISession} from "../../../../models/i-token";

@Component({
  selector: 'app-edit-inventory-transfer-request',
  templateUrl: './edit-inventory-transfer-request.component.html',
  styleUrls: ['./edit-inventory-transfer-request.component.scss']
})
export class EditInventoryTransferRequestComponent implements OnInit, AfterViewInit {
  @Input() data: IEditInventoryTransferRequestModalData;

  documentForm: FormGroup;

  @BlockUI() blockUI: NgBlockUI;

  originWarehouseSelected: IMinifiedWarehouse | undefined;

  destinationWarehouseSelected: IMinifiedWarehouse | undefined;

  salesPersonSelected: ISalesPerson;

  documentLines: IInventoryTransferRequestLine[];

  documentUdfs: IUdf[];

  udfsForm: FormGroup;

  documentInEdition: IInventoryTransferRequest;

  headquarters: IHeadquarter[];

  transferRequestStates: IInventoryTransferRequestStates[];

  /**
   * Indicates if the user can edit the sales person of the document
   */
  canEditSalesPerson: boolean = false;

  /**
   * Information about the current user
   */
  currentUser: ISession;

  /**
   * Represent the actual state of the document
   */
  documentState: string = '';
  constructor(private formBuilder: FormBuilder,
              private inventoryTransferService: InventoryTransferRequestsService,
              private localStorageService: StorageService,
              private alertService: AlertService,
              private udfsService: UdfsService,
              private permissionService: PermsService,
              private activeModal: NgbActiveModal,
              @Inject(DOCUMENT) private domDocument: Document,
              private authenticationService: AuthenticationService,
              private modalController: NgbModal) {
    this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.InitVariables();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.domDocument.querySelectorAll('.modal-content')
        .forEach(el => {
          el.setAttribute('style', 'height: calc(100vh - 40px); overflow: auto; min-width: fit-content; width: 80%; margin-left:auto; margin-right: auto;');
        });
      const linesFieldSet: HTMLElement = document.getElementById("linesFieldSet");
      linesFieldSet.setAttribute('style', `max-height: ${((linesFieldSet.offsetParent.clientHeight - (linesFieldSet.offsetHeight + linesFieldSet.offsetTop)) + linesFieldSet.offsetHeight) - 28}px`);
    }, 0);
  }

  /**
   * Initialize the variables of the component
   * @constructor
   */
  InitVariables(): void
  {
    this.salesPersonSelected = {
      SlpName: '-Ningún empleado del departamento de ventas-',
      SlpCode: '-1'
    };

    this.documentForm = this.formBuilder.group({
      DocNum: [null],
      OriginWarehouse: [''],
      DestinationWarehouse: [''],
      U_ATF_HEADQUARTER: [null],
      U_ATF_STATUS: [null],
      Quantity: [1],
      SalesPerson: [`${this.salesPersonSelected.SlpCode} - ${this.salesPersonSelected.SlpName}`, [Validators.required]],
      U_ATF_COMMENTS: [null],
      Item: ['']
    });

    this.ListenDocumentStateChanges();

    this.documentUdfs = [];

    this.documentLines = [];

    this.headquarters = this.data.Headquarters;

    this.transferRequestStates = this.data.TransferRequestStates;

    this.SendInitialRequests();
  }

  /**
   * Listen changes in the field of document status
   * @constructor
   */
  ListenDocumentStateChanges(): void
  {
    this.documentForm.get("U_ATF_STATUS").valueChanges
      .subscribe({
        next: (value) => {
          this.documentState = value;
        }
      });
  }

  /**
   * Send the initial request to set up the component
   * @constructor
   */
  SendInitialRequests(): void
  {
    this.blockUI.start("Cargando...");

    forkJoin([
      this.udfsService.GetConfiguredUdfsByCategory(DOCUMENT_ALIAS.INVENTORY_TRANSFER_REQUEST),
      this.inventoryTransferService.GetInventoryTransferRequest(this.data.DocumentEntry),
      this.permissionService.getPerms(this.currentUser.userId)
    ])
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe(responses => {
        this.documentInEdition = responses[1].Data;

        this.originWarehouseSelected = {
          WhsCode: this.documentInEdition.FromWarehouse
        } as IMinifiedWarehouse;

        this.destinationWarehouseSelected = {
          WhsCode: this.documentInEdition.ToWarehouse
        } as IMinifiedWarehouse;

        this.documentLines = this.documentInEdition.StockTransferLines;

        this.salesPersonSelected = {
          SlpName: this.documentInEdition.SalesPersonName,
          SlpCode: this.documentInEdition.SalesPersonCode.toString()
        };

        this.documentForm.patchValue({
          OriginWarehouse: this.documentInEdition.FromWarehouse,
          DestinationWarehouse: this.documentInEdition.ToWarehouse,
          U_ATF_COMMENTS: this.documentInEdition.U_ATF_COMMENTS,
          SalesPerson: `${this.salesPersonSelected.SlpCode} - ${this.salesPersonSelected.SlpName}`,
          U_ATF_HEADQUARTER: this.documentInEdition.U_ATF_HEADQUARTER,
          U_ATF_STATUS: this.documentInEdition.U_ATF_STATUS,
          DocNum: this.documentInEdition.DocNum
        });

        if (responses[0].Result)
        {
          this.documentUdfs = responses[0].Udfs || [];

          this.documentUdfs.filter(x => x.Values !== '').forEach(x => x.MappedValues = (JSON.parse(x.Values) as IudfValue[]));

          const udfFormControlConfig: {[key:string]: any} = {};

          this.documentUdfs.forEach(udf => {
            if(udf.IsRendered)
            {
              let udfWithValue = this.documentInEdition.UDFs.find(_udf => _udf.Name == udf.Name);

              let value = null;

              if(udfWithValue)
              {
                value = udfWithValue.Value;
              }

              if(udf.IsRequired)
              {
                udfFormControlConfig[udf.Name] = [value, Validators.required];
              }
              else
              {
                udfFormControlConfig[udf.Name] = value;
              }
            }
          });

          this.udfsForm = this.formBuilder.group(udfFormControlConfig);
        }

        if(responses[2].Result)
        {
          this.canEditSalesPerson = responses[2].perms.some(p => p.Active && p.Name === 'Inventory_TransferRequest_EditSalesPerson');

          this.ApplyPermissionsRestrictions();
        }
      }, error => {
        this.alertService.ShowAlert("error", {
          text: error
        });

        console.error(error);
      });

  }

  /**
   * Apply the restrictions of permission in the page
   * @constructor
   */
  ApplyPermissionsRestrictions(): void
  {
    if(!this.canEditSalesPerson)
    {
      this.documentForm.get("SalesPerson").disable();
    }
  }

  /**
   * Set up the items selection modal
   * @constructor
   */
  ShowItemSelectionModal(): void
  {
    const modal = this.modalController.open(SelectionModalComponent, {
      windowClass: 'width-fit-content',
      backdrop: 'static',
      size: 'lg'
    });

    modal.componentInstance.data = {
      ServiceToInject: ItemService,
      MethodToExecute: 'GetMinifiedItems',
      TableColumns: {
        ItemCode: "Código de ítem",
        ItemName: "Nombre de ítem"
      },
      ShowNumeration: true,
      RequestExtraParameters: ['inventory'],
      PredefinedParametersToSend: ['all']
    } as ISelectionModalData;

    modal.result.then((result: IMinifiedItem) => {

      let {Quantity} = this.documentForm.value;

      this.documentLines.push({
        ItemCode: result.ItemCode,
        ItemDescription: result.ItemName,
        Quantity,
        U_ATF_REQUEST_QUANTITY: Quantity,
        FromWarehouseCode: this.originWarehouseSelected.WhsCode,
        WarehouseCode: this.destinationWarehouseSelected.WhsCode,
        LineNum: Math.max(...this.documentLines.map(ln => ln.LineNum), 0) + 1,
        LineStatus: 'bost_Open',
        // ManSerNum: result.ManagedBySerialNumber
      } as IInventoryTransferRequestLine);

      // this.hasLinesWithSeries = this.documentLines.some(ln => ln.ManSerNum == 'Y' || !!ln.SerialNumber);
    }, (reason) => {});
  }

  /**
   * Set up the warehouse selection modal
   * @param _formControlName Form field to set the selected value
   * @constructor
   */
  ShowWarehouseSelectionModal(_formControlName: 'OriginWarehouse' | 'DestinationWarehouse'): void
  {
    const modal = this.modalController.open(SelectionModalComponent, {
      windowClass: 'width-fit-content',
      backdrop: 'static',
      size: 'lg'
    });

    modal.componentInstance.data = {
      ServiceToInject: WarehouseService,
      MethodToExecute: 'GetWarehouseForInventoryTransfer',
      TableColumns: {
        WhsCode: "Código de almacén",
        WhsName: "Nombre de almacén"
      },
      UIPagination: true,
      PropsToMatchWithSearchCriteria: ["WhsCode", "WhsName"],
      ShowNumeration: true,
      PredefinedParametersToSend: []
    } as ISelectionModalData;


    modal.result.then((result: IMinifiedWarehouse) => {
      const selectWarehouse = () => {
        if(_formControlName == 'OriginWarehouse')
        {
          this.originWarehouseSelected = result;
        }
        else
        {
          this.destinationWarehouseSelected = result;
        }

        this.documentForm.get(_formControlName).patchValue(`${result.WhsCode} - ${result.WhsName}`);
      }

      if(this.documentLines.length && ((_formControlName == 'OriginWarehouse' && this.originWarehouseSelected) || (_formControlName == 'DestinationWarehouse' && this.destinationWarehouseSelected)))
      {
        this.alertService.PromptedAlert(`¿Desea actualizar el valor "${ _formControlName == 'OriginWarehouse' ? 'Almacén origen' : 'Almacén destino' }" para las líneas existentes con este nuevo valor?`, "question")
          .then(questionResult => {
            if(questionResult)
            {
              let linePropToSet = _formControlName == "OriginWarehouse" ? 'FromWarehouseCode' : 'WarehouseCode';

              this.documentLines.forEach(line => {

                if(_formControlName == 'OriginWarehouse' && line.SerialNumber)
                {
                  line.SerialNumber = null;
                }

                line[linePropToSet] = result.WhsCode;
              });
            }

            selectWarehouse();
          });
      }
      else
      {
        selectWarehouse();
      }
    }, (reason) => {});
  }

  /**
   * Set up the sales person selection modal
   * @constructor
   */
  ShowSalesPersonSelectionModal(): void
  {
    const modal = this.modalController.open(SelectionModalComponent, {
      windowClass: 'width-fit-content',
      backdrop: 'static',
      size: 'lg'
    });

    modal.componentInstance.data = {
      ServiceToInject: SalesManService,
      MethodToExecute: 'Get',
      TableColumns: {
        SlpCode: "Código de vendedor",
        SlpName: "Nombre de vendedor"
      },
      UIPagination: true,
      PropsToMatchWithSearchCriteria: ["SlpCode", "SlpName"],
      ShowNumeration: true,
      PredefinedParametersToSend: []
    } as ISelectionModalData;


    modal.result.then((result: ISalesPerson) => {
      this.salesPersonSelected = result;
      this.documentForm.get("SalesPerson").patchValue(`${result.SlpCode} - ${result.SlpName}`);
    }, (reason) => {});
  }

  /**
   * Set up the warehouse selection modal
   * @param _lineProp Property of the document line where the result will be set
   * @param _line Line that will be updated
   * @constructor
   */
  ShowItemWarehouseSelectionModal(_lineProp: 'FromWarehouseCode' | 'WarehouseCode', _line: IInventoryTransferRequestLine): void
  {
    const modal = this.modalController.open(SelectionModalComponent, {
      windowClass: 'width-fit-content',
      backdrop: 'static',
      size: 'lg'
    });

    modal.componentInstance.data = {
      ServiceToInject: WarehouseService,
      MethodToExecute: 'GetWarehouseForInventoryTransfer',
      TableColumns: {
        WhsCode: "Código de almacén",
        WhsName: "Nombre de almacén"
      },
      UIPagination: true,
      PropsToMatchWithSearchCriteria: ["WhsCode", "WhsName"],
      ShowNumeration: true,
      PredefinedParametersToSend: []
    } as ISelectionModalData;


    modal.result.then((result: IMinifiedWarehouse) => {
      if(_lineProp == 'FromWarehouseCode' && _line.SerialNumber)
      {
        _line.SerialNumber = null;
      }

      _line[_lineProp] = result.WhsCode;
    }, (reason) => {});
  }

  /**
   * Remove a document line
   * @param _lineIndex Index of the document line to remove
   * @constructor
   */
  RemoveDocumentLine(_lineIndex: number): void
  {
    this.alertService.PromptedAlert('¿Desea eliminar esta línea del documento?', "question")
      .then(result => {
        if(result)
        {
          this.documentLines.splice(_lineIndex, 1);

          // this.hasLinesWithSeries = this.documentLines.some(ln => ln.ManSerNum == 'Y' || !!ln.SerialNumber);
        }
      });
  }

  /**
   * Send a request to create the current document
   * @constructor
   */
  OnClickSaveDocument(): void
  {
    if(!this.DocumentValidations()) return;

    let {U_ATF_HEADQUARTER, U_ATF_STATUS, U_ATF_COMMENTS} = this.documentForm.getRawValue();

    let UDFs: IUdfTarget[] = [];

    if(this.udfsForm)
    {
      for(const key in this.udfsForm.value)
      {
        UDFs.push({
          Name: key,
          Value: this.udfsForm.get(key).value,
          FieldType: this.documentUdfs.find(udf => udf.Name == key).FieldType
        } as IUdfTarget);
      }
    }

    let document = {
      ...this.documentInEdition,
      U_ATF_COMMENTS,
      FromWarehouse: this.originWarehouseSelected.WhsCode,
      ToWarehouse: this.destinationWarehouseSelected.WhsCode,
      StockTransferLines: this.documentLines.map(ln => ({
        ...ln,
        TotalReceivedQuantity: ln.TotalReceivedQuantity + ln.U_ATF_RECEIVED_QUANTITY
      })),
      SalesPersonCode: +this.salesPersonSelected.SlpCode,
      UDFs,
      U_ATF_VERIFICATION_CODE: formatDate(new Date(), 'yyyyMMddHHmmss', 'en'),
      U_ATF_HEADQUARTER,
      U_ATF_STATUS,
    } as IInventoryTransferRequest;

    this.blockUI.start("Procesando...");

    this.inventoryTransferService.Patch(document)
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (response: IResponse<IInventoryTransferRequest>) => {
          this.alertService.ShowAlert("success", {
            title: 'Actualización de documento',
            text: 'Documento actualizado éxitosamente'
          }, {
            "Solicitud de traslado": this.documentInEdition.DocNum.toString(),
            "Transferencia de stock": response["Data"] ? response["Data"].DocNum.toString() : undefined
          });

          this.activeModal.close();
        },
        error: (error) => {
          this.alertService.ShowAlert('error', {
            text: error,
            title: 'Actualización de documento'
          });
          console.error(error);
        }
      });
  }

  /**
   * Validate information before send the document. If all is ok return `true` otherwise return `false`
   * @constructor
   */
  DocumentValidations(): boolean
  {
    if(!this.originWarehouseSelected || !this.destinationWarehouseSelected)
    {
      this.alertService.infoInfoAlert("Debe seleccionar almacén de origen y almacén de destino.");
      return;
    }

    if(!this.documentLines.length)
    {
      this.alertService.infoInfoAlert('Debe agregar almenos una línea al documento.');
      return false;
    }

    if(this.documentLines.some(ln => ln.Quantity <= 0))
    {
      this.alertService.infoInfoAlert('No se permiten líneas con cantidad menor o igual a cero.');
      return false;
    }

    if(this.documentUdfs.some(udf => udf.IsRendered && udf.IsRequired && !this.udfsForm.get(udf.Name).value))
    {
      this.alertService.infoInfoAlert('Hay UDFs requeridos sin completar.');
      return false;
    }

    return true;
  }

  /**
   * Get the type of the UDF input based on udf type
   * @param _dataType
   * @constructor
   */
  GetUdfInputType(_dataType: string): string {
    switch (_dataType) {
      case 'String':
        return "text";
      case 'Int32':
        return "number";
      case 'DateTime':
        return "date";
      default:
        return 'text';
    }
  }

  /**
   * Dismiss the current modal
   * @constructor
   */
  DismissModal(): void
  {
    this.activeModal.dismiss();
  }
}
