import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {forkJoin, merge, Observable, of, OperatorFunction, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, finalize, map} from "rxjs/operators";
import {IKValue} from "../../../../models";
import {NgbActiveModal, NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {CategoriesSpecialtyService} from "../../../../services/categories-specialty.service";
import {BlockUI, NgBlockUI} from "ng-block-ui";
import {AlertService} from "../../../../services";
import {IResponse} from "../../../../models/i-api-response";
import {ICategorySpecialty} from "../../../../models/i-category-specialty";
import {IBaseResponse} from "../../../../models/responses";

@Component({
  selector: 'app-category-specialty-edit',
  templateUrl: './category-specialty-edit.component.html',
  styleUrls: ['./category-specialty-edit.component.scss']
})
export class CategorySpecialtyEditComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  /**
   * Represent the code of the category specialty that is in edition
   */
  @Input() categoryBySpecialtyToEditCode: string | undefined;

  /**
   * Represent the category specialty that is in edition
   * @private
   */
  private categoryBySpecialtyInEdition: ICategorySpecialty | undefined;

  /**
   * Represent the form of a category by specialty
   */
  categoryBySpecialtyForm: FormGroup;

  /**
   * List of categories showed in the typeahead
   */
  categories: IKValue[] = [];

  /**
   * List of families showed in the typeahead
   */
  families: IKValue[] = [];

  /**
   * List of sub-families showed in the typeahead
   */
  subFamilies: IKValue[] = [];

  /**
   * Emits an event when some typeahead is clicked
   */
  clickInputTypeahead$: Subject<string> = new Subject<string>();

  /**
   * Emits an event when some typeahead is focused
   */
  focusInputTypeahead$: Subject<string> = new Subject<string>();

  /**
   * Represent the categories typeahead input element
   */
  @ViewChild('categoriesTypeahead') categoriesTypeahead: NgbTypeahead;

  /**
   * Represent the families typeahead input element
   */
  @ViewChild('familiesTypeahead') familiesTypeahead: NgbTypeahead;

  /**
   * Represent the sub-families typeahead input element
   */
  @ViewChild('subFamiliesTypeahead') subFamiliesTypeahead: NgbTypeahead;
  constructor(private formBuilder: FormBuilder,
              private activeModal: NgbActiveModal,
              private alertService: AlertService,
              private categoriesBySpecialtyService: CategoriesSpecialtyService) {

  }

  ngOnInit() {
    this.InitVariables();
    this.SendInitialRequest();
  }

  /**
   * Initialize the variables of the component
   * @constructor
   * @private
   */
  private InitVariables(): void
  {
    this.categoryBySpecialtyForm = this.formBuilder.group({
      Code: [''],
      Name: ['', Validators.required],
      U_MantCategoria: [null, Validators.required],
      U_MantFamilia: [null, Validators.required],
      U_MantSubFam: [null],
      U_MantAutRequerida: [false, Validators.required],
      U_MantDescuento: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(100)])],
      U_MantDescMDSM: [0, Validators.compose([Validators.required, Validators.min(0), Validators.max(100)])],
      U_MantEstado: [false, Validators.required]
    });
  }

  /**
   * Send the initial request to set up the component modal
   * @constructor
   * @private
   */
  private SendInitialRequest(): void
  {

    this.blockUI.start();

    forkJoin([
      this.categoriesBySpecialtyService.GetCategories(),
      this.categoriesBySpecialtyService.GetFamilies(),
      this.categoriesBySpecialtyService.GetSubFamilies(),
      this.categoryBySpecialtyToEditCode ? this.categoriesBySpecialtyService.GetCategorySpecialty(this.categoryBySpecialtyToEditCode) : of(null)
    ])
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (responses) => {
          this.MapCategories(responses[0]);
          this.MapFamilies(responses[1]);
          this.MapSubFamilies(responses[2]);
          this.MapCategoryBySpecialtyInEdition(responses[3]);
        },
        error: (error) => {
          console.error(error);

          this.alertService.ShowAlert("error", {text: error});
        }
      });
  }

  /**
   * Map the category specialty response to the category specialty variable
   * @param pCategoryBySpecialtyResponse Response with the information of the category specialty
   * @constructor
   * @private
   */
  private MapCategoryBySpecialtyInEdition(pCategoryBySpecialtyResponse: IResponse<ICategorySpecialty> | null): void
  {
    if(pCategoryBySpecialtyResponse === null)
    {
      return;
    }

    this.categoryBySpecialtyInEdition = pCategoryBySpecialtyResponse.Data;

    if(this.categoryBySpecialtyInEdition)
    {
      this.categoryBySpecialtyForm.setValue({
        ...this.categoryBySpecialtyInEdition,
        U_MantAutRequerida: this.categoryBySpecialtyInEdition.U_MantAutRequerida == 'Y',
        U_MantEstado: this.categoryBySpecialtyInEdition.U_MantEstado == 'Y'
      });
    }
  }

  /**
   * Map the categories response to the categories variable
   * @param pCategoriesResponse Response information
   * @constructor
   * @private
   */
  private MapCategories(pCategoriesResponse: IResponse<IKValue[]>): void
  {
    this.categories = pCategoriesResponse.Data ? pCategoriesResponse.Data : [];
  }

  /**
   * Map the families response to the families variable
   * @param pFamiliesResponse Response information
   * @constructor
   * @private
   */
  private MapFamilies(pFamiliesResponse: IResponse<IKValue[]>): void
  {
    this.families = pFamiliesResponse.Data ? pFamiliesResponse.Data : [];
  }

  /**
   * Map the sub-families response to the sub-families variable
   * @param pSubFamiliesResponse Response information
   * @constructor
   * @private
   */
  private MapSubFamilies(pSubFamiliesResponse: IResponse<IKValue[]>): void
  {
    this.subFamilies = pSubFamiliesResponse.Data ? pSubFamiliesResponse.Data : [];
  }

  /**
   * Filter the categories typeahead input options
   * @param pText$ Value that will be used to filter the options
   * @constructor
   */
  SearchCategories: OperatorFunction<string, IKValue[]> = (pText$: Observable<string>) =>
  {
    const debouncedText$ = pText$.pipe(debounceTime(200), distinctUntilChanged());

    const clicksWithClosedPopup$ = this.clickInputTypeahead$
      .pipe(
        filter((value) => value === 'category' && !this.categoriesTypeahead.isPopupOpen()),
        map(() => '')
      );

    const inputFocus$ = this.focusInputTypeahead$
      .pipe(
        filter((value) => value === 'category'),
        map(() => '')
      );

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)
      .pipe(
        map((term) =>
          (term === '' ? this.categories : this.categories.filter((kv) => `${kv.Key} - ${kv.Value}`.toLowerCase().includes(term.toLowerCase()))).slice(0, 10),
        ),
      );
  };

  /**
   * Format the result of categories typeahead
   * @param pResult Result of the filter
   * @constructor
   */
  CategoryTypeaheadFormatter = (pResult: IKValue | string) => {
    if(typeof pResult === "object")
    {
      return `${pResult.Key} - ${pResult.Value}`;
    }

    let category = this.categories.find((kv) => kv.Key === pResult)

    if(category)
    {
      return `${category.Key} - ${category.Value}`;
    }

    return '';
  }

  /**
   * Handle the select event of the categories typeahead
   * @param pEvent Event data
   * @constructor
   */
  OnSelectCategory(pEvent: any): void
  {
    setTimeout(() => {
      this.categoryBySpecialtyForm.get("U_MantCategoria").setValue(pEvent.item.Key, {emitEvent: false});
    }, 0);
  }


  /**
   * Filter the families typeahead input options
   * @param pText$ Value that will be used to filter the options
   * @constructor
   */
  SearchFamilies: OperatorFunction<string, IKValue[]> = (pText$: Observable<string>) =>
  {
    const debouncedText$ = pText$.pipe(debounceTime(200), distinctUntilChanged());

    const clicksWithClosedPopup$ = this.clickInputTypeahead$
      .pipe(
        filter((value) => value === 'family' && !this.familiesTypeahead.isPopupOpen()),
        map(() => '')
      );

    const inputFocus$ = this.focusInputTypeahead$
      .pipe(
        filter((value) => value === 'family'),
        map(() => '')
      );

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)
      .pipe(
        map((term) =>
          (term === '' ? this.families : this.families.filter((kv) => `${kv.Key} - ${kv.Value}`.toLowerCase().includes(term.toLowerCase()))).slice(0, 10),
        ),
      );
  };

  /**
   * Format the result of families typeahead
   * @param pResult Result of the filter
   * @constructor
   */
  FamilyTypeaheadFormatter = (pResult: IKValue | string) => {
    if(typeof pResult === "object")
    {
      return `${pResult.Key} - ${pResult.Value}`;
    }

    let family = this.families.find((kv) => kv.Key === pResult)

    if(family)
    {
      return `${family.Key} - ${family.Value}`;
    }

    return '';
  }

  /**
   * Handle the select event of the families typeahead
   * @param pEvent Event data
   * @constructor
   */
  OnSelectFamily(pEvent: any): void
  {
    setTimeout(() => {
      this.categoryBySpecialtyForm.get("U_MantFamilia").setValue(pEvent.item.Key, {emitEvent: false});
    }, 0);
  }

  /**
   * Filter the families typeahead input options
   * @param pText$ Value that will be used to filter the options
   * @constructor
   */
  SearchSubFamilies: OperatorFunction<string, IKValue[]> = (pText$: Observable<string>) =>
  {
    const debouncedText$ = pText$.pipe(debounceTime(200), distinctUntilChanged());

    const clicksWithClosedPopup$ = this.clickInputTypeahead$
      .pipe(
        filter((value) => value === 'subfamily' && !this.subFamiliesTypeahead.isPopupOpen()),
        map(() => '')
      );

    const inputFocus$ = this.focusInputTypeahead$
      .pipe(
        filter((value) => value === 'subfamily'),
        map(() => '')
      );

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)
      .pipe(
        map((term) =>
          (term === '' ? this.subFamilies : this.subFamilies.filter((kv) => `${kv.Key} - ${kv.Value}`.toLowerCase().includes(term.toLowerCase()))).slice(0, 10),
        ),
      );
  };

  /**
   * Format the result of sub-families typeahead
   * @param pResult Result of the filter
   * @constructor
   */
  SubFamilyTypeaheadFormatter = (pResult: IKValue | string) => {
    if(typeof pResult === "object")
    {
      return `${pResult.Key} - ${pResult.Value}`;
    }

    let subFamily = this.subFamilies.find((kv) => kv.Key === pResult)

    if(subFamily)
    {
      return `${subFamily.Key} - ${subFamily.Value}`;
    }

    return '';
  }

  /**
   * Handle the select event of the sub-families typeahead
   * @param pEvent Event data
   * @constructor
   */
  OnSelectSubFamily(pEvent: any): void
  {
    setTimeout(() => {
      this.categoryBySpecialtyForm.get("U_MantSubFam").setValue(pEvent.item.Key, {emitEvent: false});
    }, 0);
  }


  /**
   * Dismiss the current modal
   * @constructor
   */
  Dismiss(): void
  {
    this.activeModal.dismiss();
  }

  /**
   * Send a request to save the information of the category specialty
   * @constructor
   */
  SaveCategorySpecialty(): void
  {
    let categorySpecialty = this.BuildCategorySpecialtyObject();

    this.blockUI.start();

    let createOrUpdateCategorySpecialty$: Observable<IBaseResponse> = this.categoryBySpecialtyToEditCode ?
      this.categoriesBySpecialtyService.Patch(categorySpecialty, this.categoryBySpecialtyToEditCode) :
      this.categoriesBySpecialtyService.Post(categorySpecialty);

    createOrUpdateCategorySpecialty$
      .pipe(
        finalize(() => this.blockUI.stop())
      )
      .subscribe({
        next: (response) => {
          this.Dismiss();
        },
        error: (error) => {
          console.error(error);

          this.alertService.ShowAlert("error", {text: error});
        }
      });
  }

  /**
   * Build the category specialty object that will be created or updated
   * @constructor
   * @private
   */
  private BuildCategorySpecialtyObject(): ICategorySpecialty
  {
    let {Code, Name, U_MantCategoria, U_MantFamilia, U_MantSubFam, U_MantAutRequerida, U_MantEstado, U_MantDescuento, U_MantDescMDSM} = this.categoryBySpecialtyForm.getRawValue();

    return {
      U_MantEstado: U_MantEstado ? 'Y' : 'N',
      U_MantAutRequerida: U_MantAutRequerida ? 'Y' : 'N',
      U_MantDescMDSM: parseFloat(U_MantDescMDSM.toFixed(2)),
      Name,
      U_MantCategoria,
      U_MantFamilia,
      U_MantSubFam,
      Code,
      U_MantDescuento: parseFloat(U_MantDescuento.toFixed(2))
    };
  }
}
