import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '.';
import { AppConstants } from '../models';
import { IResponse } from '../models/i-api-response';
import { IMinifiedWarehouse } from '../models/i-minified-warehouse';
import {IBaseResponse, IPagedRequestResponse} from '../models/responses';
import {IService} from "../models/i-service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class WarehouseService implements IService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }
  GetWarehouseForInventoryTransfer(): Observable<IResponse<IPagedRequestResponse<IMinifiedWarehouse[]>>>
  {
    return this.http.get<IResponse<IMinifiedWarehouse[]>>(`api/InventoryTransferRequests/Warehouses`)
      .pipe(
        map((response) => {
          return {
            Data: {
              Data: response.Data || [],
              RecordsCount: (response.Data || []).length
            },
            Result: true
          } as IResponse<IPagedRequestResponse<IMinifiedWarehouse[]>>
        })
      );
  }

  GetWarehouses(): Observable<IResponse<IMinifiedWarehouse[]>>{
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const URL = `${AppConstants.apiUrl}api/Warehouse/GetWarehouses`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<IResponse<IMinifiedWarehouse[]>>(URL, { headers });
  }

  GetWarehousesByHeadquarter(_headquarterId: number): Observable<IResponse<IMinifiedWarehouse[]>>{
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const URL = `${AppConstants.apiUrl}api/Warehouse/GetWarehousesByHeadquarter?headquarterId=${_headquarterId}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<IResponse<IMinifiedWarehouse[]>>(URL, { headers });
  }

  SaveWarehousesByHeadquarter(_headquarterId: number, _warehouses: IMinifiedWarehouse[]): Observable<IBaseResponse>{

    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const URL = `${AppConstants.apiUrl}api/Warehouse/SaveWarehousesByHeadquarter?headquarterId=${_headquarterId}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.post<IBaseResponse>(URL, _warehouses, { headers });
  }

  /**
   * Send a request to retrieve all warehouse of the company
   * @constructor
   */
  GetWarehouseForInventoryCounting(_whsCode: string): Observable<IResponse<IMinifiedWarehouse>>
  {
    return this.http.get<IResponse<IMinifiedWarehouse>>('api/Warehouse/MobileWarehouse', {
      params: {
        whsCode: _whsCode
      }
    });
  }

  GetWarehousesForInventoryCounting(pSearchCriteria: string): Observable<IResponse<IPagedRequestResponse<IMinifiedWarehouse[]>>>
  {
    let currentHeadquarter= this.storage.GetCurrentHeadquarter();

    return this.http.get<IResponse<IMinifiedWarehouse[]>>(`api/Warehouse/MobileWarehouses`, {
      params: {
        searchCriteria: pSearchCriteria,
        headquarter: currentHeadquarter.Code
      }
    })
      .pipe(
        map((response) => {
          return {
            Data: {
              Data: response.Data || [],
              RecordsCount: (response.Data || []).length
            },
            Result: true
          } as IResponse<IPagedRequestResponse<IMinifiedWarehouse[]>>
        })
      );
  }
}
