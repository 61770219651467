import {formatDate} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {filter, finalize} from 'rxjs/operators';
import {PatientComponent} from 'src/app/components/patient/patient.component';
import {AppConstants, IPatient, IModalStatus, IUIEvent, IPatientLog, ISearch} from 'src/app/models';
import {PATIENT_LOG_ACTION, PATIENT_LOG_STATUS} from 'src/app/models/constantes';
import {IResponse} from 'src/app/models/i-api-response';
import {AlertService, EventService, PatientService, StorageService} from 'src/app/services';
import {SearchService} from 'src/app/services/search.service';

@Component({
  selector: 'app-patient-dm',
  templateUrl: './patient-dm.component.html',
  styleUrls: ['./patient-dm.component.scss']
})
export class PatientDMComponent implements OnInit {
  //varbox
  isRequestingLogs: boolean;
  currentPageLogs = 1;
  pageSizeLogs = 10;
  collectionSizeLogs: number;
  filteredPatientsLogs: IPatientLog[];
  skip: number = 0;
  take: number = 10;

  isShowingError: boolean;
  patientEventDetail: string;
  patientModalTitle: string;
  patientLogs: IPatientLog[];
  patientTitle: string;
  isBinnacleActive: boolean;
  @BlockUI() blockUI: NgBlockUI;
  searchForm: FormGroup;
  searchLogForm: FormGroup;
  closeResult: string; // variable para saber porque se cerro la modal
  modalPay: any; // instancia de la modal de pagos
  page = 1;
  pageSize = 10;
  collectionSize: number;
  patients: IPatient[];
  filteredPatients: IPatient[];

  constructor(
    private modalService: NgbModal
    , private patientService: PatientService
    , private alertService: AlertService
    , private localStorageService: StorageService
    , private router: Router
    , private eventService: EventService
    , private formBuilder: FormBuilder
    , private searchService: SearchService
  ) {
  }

  RefreshPatients() {
    this.skip = (this.page - 1) * this.pageSize;
    this.take = this.pageSize;
    this.GetPatientes(this.searchForm.controls['searchInput'].value);
  }


  RefreshPatientsLogs() {
    this.filteredPatientsLogs = this.patientLogs.map((patient) => ({...patient}))
      .slice((this.currentPageLogs - 1) * this.pageSizeLogs, (this.currentPageLogs - 1) * this.pageSizeLogs + this.pageSizeLogs);
  }


  EVENTS: { [K: string]: Function } = {
    TogglePatientTitle: _ => this.TogglePatientTitle()
  };

  ngOnInit() {

    this.eventService.Flow().pipe(filter(next => next.View === this.router.url.split('/')[1])).subscribe(next => this.EVENTS[next.Target]());

    this.isBinnacleActive = true; // Se inicia en true porque se llama de un solo el método de hacer toggle al títutlo del la vista

    this.InitState();

    this.PreloadData();

    this.GetPatientes();

    this.TogglePatientTitle();
  }

  PreloadData(): void {
  }

  InitState(): void {
    this.patients = [];

    this.patientLogs = [];

    this.searchForm = new FormGroup({
      searchInput: new FormControl("")
    });

    let mDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    this.searchLogForm = this.formBuilder.group({
      User: [''],
      Action: ['A'],
      Status: ['A'],
      FromDate: [mDate],
      ToDate: [mDate]
    });
  }

  ResetBinnacleForm(): void {
    this.isRequestingLogs = false;
    this.currentPageLogs = 1;
    this.pageSizeLogs = 10;
    this.collectionSizeLogs = 0;
    this.patientLogs = [];

    let mDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');

    this.searchLogForm = this.formBuilder.group({
      User: [''],
      Action: ['A'],
      Status: ['A'],
      FromDate: [mDate],
      ToDate: [mDate]
    });
  }

  GetPatientes(_pattern: string = ''): void {
    this.blockUI.start("Obteniendo pacientes...");

    this.patients = [];
    this.filteredPatients = [];

    this.patientService.GetPatientsPagination(_pattern, this.take, this.skip).pipe(finalize(() => this.blockUI.stop())).subscribe({
      next: (next) => {
        if (next.Result) {
          if (next.Error) {
            this.alertService.Continue(AppConstants.GetError(next.Error), `info`);
          } else {
            this.patients = [...next.Data.Patients];
            this.collectionSize = next.Data.Quantity;
            //this.collectionSize = next.Data.Quantity;

            //this.RefreshPatients();
          }
        } else {
          this.alertService.errorAlert(AppConstants.GetError(next.Error));
        }
      },
      error: (error) => {
        this.alertService.Continue(AppConstants.GetError(error), `error`);
      }
    })

  }

  CreatePatient(): void {
    let modalOption: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'Modal-Xl'
    };

    this.modalPay = this.modalService.open(PatientComponent, modalOption);

    this.modalPay.componentInstance.modalStatus = {
      HeaderText: `Crear paciente`,
      Data: null,
      IsCreating: true
    } as IModalStatus<IPatient>;

    this.modalPay.result.then((next: IModalStatus<IPatient>) => {
      console.log(next)
    }, (next) => {
      if (next.IsCompleted) {
        this.alertService.PromptedAlert(`Cargar paciente en facturación`).then(result => {
          if (result) {
            this.localStorageService.SetPatientToPreload(next.Data);
            this.router.navigate(['invo/7']);
          } else {
            this.GetPatientes();
          }
        }, _ => {
          this.GetPatientes();
        });
      }
    });
  }

  UpdatePatient(_patient: IPatient): void {
    let modalOption: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'Modal-Xl'
    };

    this.modalPay = this.modalService.open(PatientComponent, modalOption);

    this.modalPay.componentInstance.modalStatus = {
      HeaderText: `Modificar paciente`,
      Data: _patient,
      IsCreating: false
    } as IModalStatus<IPatient>;

    this.modalPay.result.then(_ => {

    }, (next: IModalStatus<IPatient>) => {
      if (next.IsCompleted) {
        if (next.Data.U_Est_Paciente == "01") {
          this.alertService.PromptedAlert(`Cargar paciente en facturación`, `question`).then(result => {
            if (result) {
              this.localStorageService.SetPatientToPreload(next.Data);
              this.router.navigate(['invo/7']);
            } else {
              this.GetPatientes();
            }
          }, _ => this.GetPatientes());
        } else {
          this.GetPatientes();
        }
      }
    });
  }

  SearchPatients(_event: any): void {
    if (_event.charCode === 13) {
      this.take = 10;
      this.skip = 0;
      this.GetPatientes(this.searchForm.get("searchInput").value);
    }
  }

  TogglePatientTitle(): void {
    this.isBinnacleActive = !this.isBinnacleActive;
    this.patientTitle = this.isBinnacleActive ? 'Dato maestro de pacientes' : 'Bitácora de pacientes';

    if (this.isBinnacleActive) {
      this.patientModalTitle = ``;
      this.patientEventDetail = ``;
      this.GetStoredLogsCount();
    } else {
      this.GetPatientes();
    }

    const EVENT = {
      Target: `UpdatePatientsTitle`,
      View: `CONTENT_SECTION`,
      Data: this.patientTitle
    } as IUIEvent;

    this.eventService.PublishEvent(EVENT);
  }

  RaisePatientErrorModal(content, _patientLog: IPatientLog): void {
    this.patientModalTitle = `Detalle de error`;

    this.patientEventDetail = _patientLog.Detail;

    this.isShowingError = true;

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, _ => {
    });
  }

  RaisePatientSerializedModal(content, _patientLog: IPatientLog): void {
    this.patientModalTitle = `Objeto JSON procesado`;

    this.isShowingError = false;

    this.patientEventDetail = JSON.parse(_patientLog.JSON);

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, _ => {
    });
  }

  SearchPatientsLogs(event = null): void {
    const SEARCH_FORM: ISearch = this.searchLogForm.value;

    if (event) {
      this.currentPageLogs = +event;
    }

    SEARCH_FORM.Offset = (this.currentPageLogs * this.pageSizeLogs) - this.pageSizeLogs;

    console.log(SEARCH_FORM.Offset)

    SEARCH_FORM.Records = this.pageSizeLogs;

    this.blockUI.start(`Procesando, espere por favor`);

    this.patientLogs = [];

    this.searchService.GetPatientsLogs(SEARCH_FORM).pipe(finalize(() => this.blockUI.stop())).subscribe({
      next: (callback: IResponse<IPatientLog[]>) => {
        console.log(callback);
        if (callback.Result) {
          if (callback.Error) {
            this.alertService.infoAlert(AppConstants.GetError(callback.Error));
          }

          this.patientLogs = callback.Data;

          // this.RefreshPatientsLogs();
        } else {
          this.alertService.errorAlert(AppConstants.GetError(callback.Error));
        }
      },
      error: error => {
        this.alertService.errorAlert(AppConstants.GetError(error));
      }
    });
  }

  GetMappedAction(_action: string) {
    return PATIENT_LOG_ACTION[_action];
  }

  GetMappedStatus(_status: string) {
    return PATIENT_LOG_STATUS[_status];
  }

  GetStoredLogsCount(event = null): void {

    if (this.isRequestingLogs) {
      return;
    }
    this.isRequestingLogs = true;
    if (event) {
      this.SelectPage(event);
    }

    let SEARCH_FORM: ISearch = null;
    if (this.searchLogForm) {
      SEARCH_FORM = this.searchLogForm.value;
    }

    this.collectionSizeLogs = 0;

    this.blockUI.start(`Procesando, espere por favor`);
    this.searchService.GetStoredLogsCount(SEARCH_FORM).pipe(finalize(() => {
      this.blockUI.stop();
      this.isRequestingLogs = false;
    })).subscribe({
      next: (callback: IResponse<number>) => {
        if (callback.Result) {
          if (callback.Error) {
            this.alertService.infoAlert(AppConstants.GetError(callback.Error));
          }

          this.collectionSizeLogs = callback.Data;

          this.SearchPatientsLogs(event);
        }
      },
      error: error => {
        this.alertService.errorAlert(AppConstants.GetError(error));
      }
    });
  }

  SelectPage(Page: string): void {
    this.currentPageLogs = (parseInt(Page, 10) || 1);
    console.log(this.currentPageLogs);
  }
}
